import React from "react";
import { Link } from "react-router-dom";

const LegalOverview = () => {
  return (
    <div className="grid gap-5">
      <h1 className="font-bold uppercase text-3xl mb-4">Overview</h1>
      <div
        className="bg-cover bg-center bg-no-repeat w-full h-28 md:h-[305px] mb-4 rounded-[10px]"
        style={{
          backgroundImage: `url(${require("../../assets/img/legal/overview.jpg")})`,
        }}
      />
      <div className="grid gap-5">
        <p>
          Welcome to our legislations screen! Here, we've gathered all the
          important policies and terms you need to know while using our
          platform. This brief overview will guide you through the key sections,
          helping you get the most out of your experience while ensuring
          transparency and trust in our services. You ca navigate through the
          different articles via the handle on the sidebar that refers to each
          subject.
        </p>
        <p>
          We believe in transparency and trust, and these policies and terms are
          designed to protect both you and us, ensuring a safe and respectful
          environment for everyone.
        </p>
        <p>
          Should you have any questions or concerns while navigating through
          these sections, please don't hesitate to reach out to{" "}
          <Link to="/contact" className="text-green underline font-bold">
            our support team
          </Link>
          . Your understanding and compliance with these policies and terms are
          crucial for a positive experience on our platform.
        </p>
        <p>
          Thank you for choosing us as your online destination. We're committed
          to providing you with a secure and enjoyable online experience.
        </p>
      </div>
    </div>
  );
};

export default LegalOverview;
