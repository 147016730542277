import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useMap from "../../../hooks/useMap";

import { deleteMarker, listMarkers } from "../../../actions/mapActions";
import {
  MARKER_DELETE_RESET,
  MARKER_LIST_RESET,
  MARKER_TOGGLE_RESET,
} from "../../../constants/mapConstants";
import { listUsers } from "../../../actions/userActions";
import {
  USER_LIST_RESET,
  USER_TOGGLE_RESET,
} from "../../../constants/userConstants";

import { markersFromPartners } from "../../../constants/helpers";

import MapView from "../../map/MapView";
import AdminHeaderLayout from "../components/AdminHeaderLayout";
import CreateMarker from "./CreateMarker";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import AppSelect from "../../global/form/AppSelect";
import { Toggle } from "../../global/AppToggle";
import { AppDropdown } from "../../global/AppDropdown";
import Warning from "../../global/Warning";
import MapSelectedMarkerInfo from "../../map/MapSelectedMarkerInfo";

const ManageMapMarkers = () => {
  const markerList = useSelector((state) => state.markerList);
  const { loading: loadingMarkers, error: errorMarkers, markers } = markerList;

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: MARKER_LIST_RESET });
    dispatch({ type: USER_LIST_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (!markers) {
      dispatch(listMarkers());
    }
  }, [markers, dispatch]);

  useEffect(() => {
    if (!partners) {
      dispatch(listUsers("partner"));
    }
  }, [partners, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorMarkers) {
      setTimeout(() => {
        dispatch({ type: MARKER_LIST_RESET });
      }, 5000);
    }
  }, [errorMarkers, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  // leaflet
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { createMap, addMarker } = useMap();
  const { selectedMarkerId } = useParams();

  const addMarkers = (markers) => {
    try {
      markers.forEach((marker) => {
        const mapMarker = addMarker(
          [
            Number(marker.gpsCoordinates.latitude),
            Number(marker.gpsCoordinates.longitude),
          ],
          {
            icon: { iconAnchor: [10, 30] },
            obj: marker,
          }
        );
        mapMarker.on("click", () => {
          setSelectedMarker(marker);
        });
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (markers && partners) {
      try {
        const activeMarkers = markers
          .filter((marker) => marker.enabled)
          .concat(
            markersFromPartners(partners).filter((partner) => partner.enabled)
          );

        createMap("map", { center: [48.433494, -123.567963], zoom: 7 });
        addMarkers(activeMarkers);

        if (selectedMarkerId && activeMarkers) {
          const comingMarker = activeMarkers.find(
            (marker) => marker._id === selectedMarkerId
          );
          if (comingMarker) {
            setSelectedMarker(comingMarker);
          }
        }
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [markers, partners, dispatch]);

  //

  const markerCreate = useSelector((state) => state.markerCreate);
  const { error: errorCreate, success: created } = markerCreate;

  const markerToggle = useSelector((state) => state.markerToggle);
  const {
    loading: togglingMarker,
    error: errorTogglingMarker,
    success: markerIsToggled,
  } = markerToggle;

  const userToggle = useSelector((state) => state.userToggle);
  const {
    loading: togglingUser,
    error: errorTogglingUser,
    success: userIsToggled,
  } = userToggle;

  useEffect(() => {
    // resetting state due to errors
    if (errorTogglingMarker) {
      setTimeout(() => {
        dispatch({ type: MARKER_TOGGLE_RESET });
      }, 5000);
    }
  }, [errorTogglingMarker, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorTogglingUser) {
      setTimeout(() => {
        dispatch({ type: USER_TOGGLE_RESET });
      }, 5000);
    }
  }, [errorTogglingUser, dispatch]);

  useEffect(() => {
    // resetting state after success
    if (markerIsToggled) {
      setTimeout(() => {
        dispatch({ type: MARKER_TOGGLE_RESET });
        // dispatch({ type: USER_LIST_RESET });
        // dispatch({ type: MARKER_LIST_RESET });
      }, 3000);
    }
  }, [markerIsToggled, dispatch]);

  useEffect(() => {
    // resetting state after success
    if (userIsToggled) {
      setTimeout(() => {
        dispatch({ type: USER_TOGGLE_RESET });
        // dispatch({ type: USER_LIST_RESET });
        // dispatch({ type: MARKER_LIST_RESET });
      }, 3000);
    }
  }, [userIsToggled, dispatch]);

  const markerDelete = useSelector((state) => state.markerDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: deleted,
  } = markerDelete;

  useEffect(() => {
    // resetting state due to errors or success
    if (errorDelete || deleted) {
      setTimeout(() => {
        dispatch({ type: MARKER_DELETE_RESET });
        dispatch(listMarkers());
      }, 5000);
    }
  }, [errorDelete, deleted, dispatch]);

  //

  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [subCategoryValue, setSubCategoryValue] = useState("");
  const [organizationTypeValue, setOrganizationTypeValue] = useState("");
  const [visibilityValue, setVisibilityValue] = useState("");

  const resetFields = (id, value) => {
    if (id !== "category") setCategoryValue("");
    else setCategoryValue(value);
    if (id !== "subCategory") setSubCategoryValue("");
    else setSubCategoryValue(value);
    if (id !== "organization") setOrganizationTypeValue("");
    else setOrganizationTypeValue(value);
    if (id !== "visibility") setVisibilityValue("");
    else setVisibilityValue(value);
  };

  useEffect(() => {
    if (markers) {
      setFilteredMarkers(markers);
      resetFields("", "");
    }
  }, [markers]);

  useEffect(() => {
    if (partners) {
      setFilteredPartners(partners);
      resetFields("", "");
    }
  }, [partners]);

  const filterHandler = (e) => {
    switch (e.target.id) {
      case "reset":
        setFilteredMarkers(markers);
        setFilteredPartners(partners);
        break;
      case "category":
        if (e.target.value?.length)
          setFilteredMarkers(
            markers.filter((marker) => marker.category === e.target.value)
          );
        setFilteredPartners(
          partners.filter((partner) => partner.category === e.target.value)
        );
        break;
      case "subCategory":
        if (e.target.value?.length)
          setFilteredMarkers(
            markers.filter((marker) => marker.subCategory === e.target.value)
          );
        setFilteredPartners(
          partners.filter((partner) => partner.subCategory === e.target.value)
        );
        break;
      case "organization":
        if (e.target.value?.length)
          setFilteredMarkers(
            markers.filter(
              (marker) => marker.organizationType === e.target.value
            )
          );
        setFilteredPartners(
          partners.filter(
            (partner) => partner.organizationType === e.target.value
          )
        );
        break;
      case "visibility":
        if (e.target.value?.length) {
          if (e.target.value === "Visible") {
            setFilteredMarkers(markers.filter((marker) => marker.enabled));
            setFilteredPartners(partners.filter((partner) => partner.enabled));
          }
          if (e.target.value === "Hidden") {
            setFilteredMarkers(markers.filter((marker) => !marker.enabled));
            setFilteredPartners(partners.filter((partner) => !partner.enabled));
          }
        }
        break;
      default:
        break;
    }

    resetFields(e.target.id, e.target.value);
  };

  const deleteMarkerHandler = (id) => {
    dispatch(deleteMarker(id));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete (${
        markers.find((marker) => marker._id === e.target.id)?.title
      }) marker?`,
      callback: () => {
        deleteMarkerHandler(e.target.id);
      },
      whenDeclined: setWarning,
    });
  };

  return (
    <section className="flex flex-col h-full">
      {selectedMarker && (
        <MapSelectedMarkerInfo
          selectedMarker={selectedMarker}
          onClose={() => setSelectedMarker(null)}
        />
      )}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}
      {togglingMarker && <Loader />}
      {togglingUser && <Loader />}
      {loadingDelete && <Loader />}
      {errorCreate && <Message error body={errorCreate} />}
      {errorTogglingMarker && <Message error body={errorTogglingMarker} />}
      {errorTogglingUser && <Message error body={errorTogglingUser} />}
      {errorDelete && <Message error body={errorDelete} />}
      {markerIsToggled && (
        <Message info body={"Marker updated successfully!"} delay={3} />
      )}
      {userIsToggled && (
        <Message info body={"Marker updated successfully!"} delay={3} />
      )}
      {created && <Message info body={"Marker created successfully!"} />}
      {deleted && <Message info body={"Marker deleted successfully!"} />}
      <AdminHeaderLayout
        title="Manage Markers"
        // placeholder="Search Marker"
        button={<CreateMarker />}
      />
      {loadingMarkers || loadingPartners ? (
        <Loader />
      ) : errorMarkers ? (
        <Message error body={errorMarkers} />
      ) : (
        <>
          {markers?.length ? (
            <>
              <div className="flex flex-col lg:flex-row gap-5 mb-5">
                <span className="font-bold w-[200px]">Filter by: </span>
                <AppSelect
                  id="category"
                  items={[
                    ...new Set(
                      markers
                        .map((marker) => marker.category)
                        .concat(partners.map((partner) => partner.category))
                    ),
                  ]}
                  itemText="name"
                  itemValue="id"
                  label="Category"
                  placeholder="-- Please select one --"
                  labelClass="text-denim font-semibold"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  onChange={filterHandler}
                  value={categoryValue}
                />
                <AppSelect
                  id="subCategory"
                  items={[
                    ...new Set(
                      markers
                        .map((marker) => marker.subCategory)
                        .concat(partners.map((partner) => partner.subCategory))
                    ),
                  ]}
                  itemText="name"
                  itemValue="id"
                  label="Sub-category"
                  placeholder="-- Please select one --"
                  labelClass="text-denim font-semibold"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  onChange={filterHandler}
                  value={subCategoryValue}
                />
                <AppSelect
                  id="organization"
                  items={[
                    ...new Set(
                      markers
                        .map((marker) => marker.organizationType)
                        .concat(
                          partners.map((partner) => partner.organizationType)
                        )
                    ),
                  ]}
                  itemText="name"
                  itemValue="id"
                  label="Organization type"
                  placeholder="-- Please select one --"
                  labelClass="text-denim font-semibold"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  onChange={filterHandler}
                  value={organizationTypeValue}
                />
                <AppSelect
                  id="visibility"
                  items={["Visible", "Hidden"]}
                  itemText="name"
                  itemValue="id"
                  label="Visibility"
                  placeholder="-- Please select one --"
                  labelClass="text-denim font-semibold"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  onChange={filterHandler}
                  value={visibilityValue}
                />
                <div className="mt-8 mx-auto md:mx-0 w-44 h-fit border-2 border-transparent hover:border-denim rounded-full overflow-hidden">
                  <button
                    className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 px-12 mx-auto flex justify-center"
                    id="reset"
                    onClick={filterHandler}
                  >
                    <span className="reset">reset</span>
                  </button>
                </div>
              </div>
              <div className="flex-grow grid grid-cols-12 gap-2 lg:bg-white lg:rounded-xl h-full">
                <div className="col-span-12 lg:col-span-7 h-full z-0">
                  {markers && partners && (
                    <MapView
                      markers={markers.concat(markersFromPartners(partners))}
                      className="z-10 h-full"
                      defaultCenter={[48.45, -123.3]}
                      zoom={9}
                      options={{ minZoom: 4 }}
                      onSelectMarker={(marker) => setSelectedMarker(marker)}
                    />
                  )}
                </div>
                <div className="mt-10 col-span-12 lg:col-span-5 h-full lg:p-3">
                  {filteredMarkers?.length || filteredPartners?.length ? (
                    <div
                      className={`flex flex-col gap-6 w-full h-[540px] ${
                        filteredMarkers?.length + filteredPartners?.length > 8
                          ? "overflow-scroll"
                          : ""
                      }`}
                    >
                      {filteredMarkers?.length
                        ? filteredMarkers?.map((marker) => (
                            <div
                              key={marker._id}
                              className="flex justify-between items-center gap-3 h-fit"
                            >
                              <div>
                                <h6
                                  className={`${
                                    marker.role ? "text-green" : "text-magenta"
                                  } font-bold uppercase`}
                                >
                                  {marker.title}
                                </h6>
                                <p className="font-bold">{marker.category}</p>
                                <p>{marker.location?.country}</p>
                              </div>
                              {!marker.role && (
                                <div className="flex gap-2">
                                  {marker._id && (
                                    <Toggle
                                      id={marker._id}
                                      toggled={marker.enabled}
                                      type="marker"
                                    />
                                  )}
                                  <AppDropdown
                                    activator={
                                      <i
                                        className="iconify"
                                        data-icon="tabler:dots"
                                      />
                                    }
                                    className="dropdown-end"
                                  >
                                    <ul className="w-40 p-1">
                                      <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                                        <button
                                          id={marker._id}
                                          onClick={showDeleteWarning}
                                        >
                                          Delete marker
                                        </button>
                                      </li>
                                    </ul>
                                  </AppDropdown>
                                </div>
                              )}
                            </div>
                          ))
                        : ""}
                      {filteredPartners?.length
                        ? filteredPartners?.map((marker) => (
                            <div
                              key={marker._id}
                              className="flex justify-between items-center gap-3 h-fit"
                            >
                              <div>
                                <h6
                                  className={`${
                                    marker.role ? "text-green" : "text-magenta"
                                  } font-bold uppercase`}
                                >
                                  {marker.title}
                                </h6>
                                <p className="font-bold">
                                  <span>{marker.category}</span>,{" "}
                                  <span>{marker.location?.country}</span>
                                </p>
                              </div>
                              <div className="flex gap-2">
                                <Toggle
                                  id={marker._id}
                                  toggled={marker.enabled}
                                  type="partner"
                                />
                                <span className="px-3"></span>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  ) : (
                    <p>No markers available with this criteria!</p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p>No markers available!</p>
          )}
        </>
      )}
    </section>
  );
};

export default ManageMapMarkers;