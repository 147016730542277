import React, { useState } from "react";

const AppCustomModal = ({
  children,
  isOpen,
  styleOptions,
  onClose = () => {},
}) => {
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);

  const toggleOpen = () => {
    const newValue = !localIsOpen;
    setLocalIsOpen(newValue);
    if (newValue === false) {
      onClose?.();
    }
  };

  return (
    <div className={`modal bg-black/60 ${localIsOpen && "modal-open"}`}>
      <div
        className={`modal-box w-full md:w-[90%] max-w-[880px] p-0 rounded-lg mt-auto md:my-auto h-fit py-14 absolute ${styleOptions}`}
      >
        <label
          className={`z-50 absolute w-full px-6 top-2 rounded-full h-10 grid place-items-end bg-navy-blue cursor-pointer`}
          onClick={toggleOpen}
        >
          <i
            className={`iconify text-denim text-3xl bg-gray rounded-full p-1`}
            data-icon="ph:x-bold"
          />
        </label>
        {children}
      </div>
    </div>
  );
};

export default AppCustomModal;
