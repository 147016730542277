import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteUser, listUsers } from "../../../actions/userActions";
import {
  USER_DELETE_RESET,
  USER_LIST_RESET,
} from "../../../constants/userConstants";

import AdminHeaderLayout from "../components/AdminHeaderLayout";
import { HorizontalCards } from "../components/HorizontalCards";
import { AppDropdown } from "../../global/AppDropdown";
import CreateUser from "./CreateUser";
// import { AppPagination } from "../../global/AppPagination";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import Warning from "../../global/Warning";

const ManageUsers = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.hash.slice(1) === "users") {
      dispatch(listUsers("user"));
    }
  }, [location, dispatch]);

  const userCreate = useSelector((state) => state.userCreate);
  const { error: errorCreate, message: created } = userCreate;

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
  }, [dispatch]);

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users } = userList;

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: deleting,
    error: errorDelete,
    success: deleted,
  } = userDelete;

  const deleteUserHandler = (slug) => {
    dispatch(deleteUser("user", slug));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete the user (${e.target.id})?`,
      callback: () => {
        deleteUserHandler(e.target.id);
      },
      whenDeclined: setWarning,
    });
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorUsers) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorUsers, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch]);

  useEffect(() => {
    // reset state after success
    if (deleted) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [deleted, dispatch]);

  //

  return (
    <section>
      {errorCreate && <Message error body={errorCreate} />}
      {created && <Message info body={"User created successfully!"} />}
      {errorDelete && <Message error body={errorDelete} />}
      {deleted && <Message info body={"User deleted successfully!"} />}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}
      <AdminHeaderLayout
        title="Manage Users"
        // placeholder="Search Users"
        button={<CreateUser />}
        // pagination={<AppPagination totalItems="500" itemsPerPage={50} />}
      />
      {loadingUsers || deleting ? (
        <Loader />
      ) : errorUsers ? (
        <Message error body={errorUsers} />
      ) : (
        !errorDelete &&
        !deleted && (
          <>
            {users?.length ? (
              <div className="grid gap-2">
                {users.map((user, index) => (
                  <HorizontalCards
                    key={index}
                    info={{
                      url: `/user/profile/${user.slug}`,
                      img:
                        user.avatar ||
                        require("../../../assets/img/profile/profile.jpg"),
                      name: user.name,
                    }}
                    options={
                      <AppDropdown
                        activator={
                          <i className="iconify" data-icon="tabler:dots" />
                        }
                        className="dropdown-end"
                      >
                        <ul className="w-40 p-1">
                          {/* <li>
                            <button onClick={() => editUser(user.id)}>
                              Edit user
                            </button>
                          </li> */}
                          <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                            <button id={user.slug} onClick={showDeleteWarning}>
                              Delete user
                            </button>
                          </li>
                        </ul>
                      </AppDropdown>
                    }
                  />
                ))}
              </div>
            ) : (
              <p>No users found!</p>
            )}
          </>
        )
      )}
    </section>
  );
};

export default ManageUsers;
