import React from "react";
import { NavLink } from "react-router-dom";
import DenimLayout from "../layouts/DenimLayout";

const UnderConstruction = () => {
  return (
    <DenimLayout>
      <div className="bg-denim">
        <div className="container pb-10">
          <h1 className="text-3xl md:text-7xl uppercase font-semibold text-center text-white pt-28 pb-20">
            Page <span className="text-green">under construction</span>
          </h1>
          <img
            src={require("../assets/img/under-construction/under-construction.png")}
            alt=""
            className="mb-14 md:w-1/3 mx-auto"
          />

          <div className="mx-auto w-fit h-fit border-2 border-transparent hover:border-white rounded-full">
            <NavLink
              to="/"
              type="submit"
              className="font-semibold text-white uppercase app-primary-button animate-bg w-40 h-14 flex justify-center gap-2"
            >
              <span className="block">
                <i
                  className="iconify"
                  data-icon="ep:arrow-up-bold"
                  data-rotate="-90deg"
                />
                return home
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </DenimLayout>
  );
};

export default UnderConstruction;
