import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONTACTMAIL_CREATE_RESET } from "../constants/contactMailConstants";
import ContactForm from "../components/contact/ContactForm";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import WhiteLayout from "../layouts/WhiteLayout";

const ContactScreen = () => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { loading, error, message: successMessage } = contactMailCreate;

  useEffect(() => {
    if (successMessage || error) {
      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 5000);
    }
  }, [successMessage, error, dispatch]);

  return (
    <>
      {error && <Message error body={error} />}
      {successMessage && <Message info body={successMessage} />}
      {loading ? (
        <Loader />
      ) : (
        <WhiteLayout>
          <section className="xl:mt-28">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 container pt-24 xl:pt-0">
              <div>
                <h1 className="w-80 mb-7 text-4xl font-semibold uppercase">
                  Get in touch with the <span className="text-green">vr</span>{" "}
                  voyage team
                </h1>
                <p className="mb-10">
                  We love hearing from our community of explorers. Do you have a
                  question or comment about the platform, want to learn more
                  about how it works, or want to suggest a new marker? We are
                  always available to help! Send us a message and one of our
                  team will get back to you shortly.
                </p>
                <img
                  src={require("../assets/img/contact/earth-map.png")}
                  alt=""
                  className="w-full"
                />
              </div>
              <ContactForm />
            </div>
          </section>
        </WhiteLayout>
      )}
    </>
  );
};

export default ContactScreen;
