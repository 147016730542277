import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ACCESS_DISABLE_FAIL,
  ACCESS_DISABLE_RESET,
} from "../../../constants/guideConstants";

import Counter from "../../tours/Counter";
import { disableSession } from "../../../actions/guideActions";
import Loader from "../../global/Loader";
import Message from "../../global/Message";

const SessionCard = ({ session, className, options = null }) => {
  const [remaining, setRemaining] = useState(null);

  useEffect(() => {
    if (session.isValid && session.startedAt) {
      setRemaining(
        new Date(session.startedAt).getTime() + 7200000 - new Date().getTime(),
      );
    }
  }, [session]);

  const formattedCreatedAt = new Date(session.createdAt);
  const formattedStartedAt = session.startedAt
    ? new Date(session.startedAt)
    : null;

  // 1. session has to be not consumed
  // 2. requestedBy.slug and loggedIn.slug must be equal
  // 3. Otherwise, it's an admin

  const dispatch = useDispatch();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (
      session.isValid &&
      !session.startedAt &&
      loggedIn?.slug === session?.requestedBy?.slug
    ) {
      setAuthorized(true);
    }
  }, [session, loggedIn]);

  const cancelHandler = (e) => {
    e.preventDefault();

    try {
      if (!authorized) {
        throw new Error("Not authorized!");
      }

      if (!session) {
        throw new Error("Invalid inputs!");
      }

      dispatch(disableSession(session._id));
    } catch (error) {
      dispatch({ type: ACCESS_DISABLE_FAIL, payload: error.message });
    }
  };

  const sessionDisable = useSelector((state) => state.sessionDisable);
  const {
    loading: loadingCancel,
    error: errorCancel,
    success: cancelled,
  } = sessionDisable;

  useEffect(() => {
    // resetting state due to errors
    if (errorCancel || cancelled) {
      setTimeout(() => {
        dispatch({ type: ACCESS_DISABLE_RESET });
      }, 5000);
    }
  }, [errorCancel, cancelled, dispatch]);

  return (
    session && (
      <div className="flex bg-white shadow rounded-md h-fit">
        {loadingCancel && <Loader />}
        {errorCancel && <Message error body={errorCancel} />}
        {cancelled && <Message info body="Session deactivated successfully!" />}
        <div
          className={`relative px-4 py-5 flex flex-grow justify-between items-center ${className}`}
        >
          {authorized ? (
            <label
              onClick={cancelHandler}
              className="absolute w-[30px] h-[30px] rounded-full top-[20px] right-[20px] z-50 cursor-pointer"
              title="disable/refund"
            >
              <i
                className={`iconify text-white bg-denim hover:bg-magenta text-3xl rounded-full p-1 with-transition`}
                data-icon="ph:x-bold"
              />
            </label>
          ) : (
            <></>
          )}
          <div className="text-lg flex-grow capitalize font-bold pointer-events-none">
            <h5>
              session code:{" "}
              <span className="text-green text-2xl lowercase">
                {session.code}
              </span>
            </h5>
            <div className="flex flex-col">
              <p className="text-sm mt-2">guide: {session.guide.title}</p>
              <p className="text-sm mt-2">partner: {session.partner.title}</p>
              <p className="text-sm mt-2">
                Requested by: {session.requestedBy.title}
              </p>
              <p className="text-sm mt-2">
                Date requested:{" "}
                {`${
                  formattedCreatedAt
                    ? formattedCreatedAt.toLocaleString("en-US", {
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        year: "numeric",
                        month: "long",
                        weekday: "long",
                        day: "numeric",
                      })
                    : ""
                }`}
              </p>
              {formattedStartedAt ? (
                <p className="text-sm mt-2">
                  Date redeemed:{" "}
                  {`${
                    formattedStartedAt
                      ? formattedStartedAt.toLocaleString("en-US", {
                          hour12: true,
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          year: "numeric",
                          month: "long",
                          weekday: "long",
                          day: "numeric",
                        })
                      : ""
                  }`}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="md:hidden">
              <p
                className={`text-lg capitalize font-bold font-oswald ${
                  session.isValid ? "text-green" : "text-gray-border"
                }`}
              >
                {!session.startedAt ? (
                  <span className="text-green">Valid</span>
                ) : remaining ? (
                  <span className="text-denim">
                    <Counter value={Number(remaining)}></Counter>
                  </span>
                ) : (
                  <span className="text-magenta">Expired</span>
                )}
              </p>
            </div>
          </div>
          <div className={`pl-4 lg:pl-10 flex gap-4`}>
            <div className="hidden md:block pointer-events-none">
              <p
                className={`hidden md:block text-lg capitalize font-bold font-oswald ${
                  session.isValid ? "text-green" : "text-gray-border"
                }`}
              >
                {!session.startedAt ? (
                  <span className="text-green">Valid</span>
                ) : remaining ? (
                  <span className="text-denim">
                    <Counter value={Number(remaining)}></Counter>
                  </span>
                ) : (
                  <span className="text-magenta">Expired</span>
                )}
              </p>
            </div>
            <div>{options}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default SessionCard;
