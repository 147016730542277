import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import AppDrawer from "./components/AppDrawer";
import AppFooter from "./components/AppFooter";
import NavigationDesktop from "./components/NavigationDesktop";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";

export default function MainLayout({
  children,
  navClass,
  scrollNavClass,
  logo,
  textHover,
  textHoverWithScroll,
  styleBtn,
  styleBtnWithScroll,
  styleBurger,
  hiddeFooter,
}) {
  const subscriberRegister = useSelector((state) => state.subscriberRegister);
  const {
    loading: loadingSubscribe,
    error: errorSubscribe,
    message: success,
  } = subscriberRegister;

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".drawer-content")?.scrollTo(0, 0);
    }, 1);
  }, [pathname]);

  function onToggleDrawer(ev) {
    document.documentElement.style.overflow = ev.target.checked
      ? "hidden"
      : "auto";
  }

  return (
    <div className="drawer drawer-end h-full">
      {loadingSubscribe && <Loader />}
      {errorSubscribe && <Message error body={errorSubscribe} />}
      {success && (
        <Message
          info
          body="Thank you for subscribing to our newsletter.
          Stay tuned for exciting updates!"
        />
      )}
      <input
        id="app-drawer"
        type="checkbox"
        className="drawer-toggle"
        onChange={onToggleDrawer}
      />
      <div
        id="app-container"
        className="drawer-content flex flex-col min-h-screen"
      >
        <header className="hidden lg:block pb-4 md:pb-0 fixed top-0 z-40 md:w-full">
          <NavigationDesktop
            navClass={navClass}
            scrollNavClass={scrollNavClass}
            logo={logo}
            textHover={textHover}
            textHoverWithScroll={textHoverWithScroll}
            styleBtn={styleBtn}
            styleBtnWithScroll={styleBtnWithScroll}
          />
        </header>
        <main className="flex-1">{children}</main>
        {!hiddeFooter && <AppFooter />}
        <label
          htmlFor="app-drawer"
          className={`bg-white fixed z-20 top-2 right-3 grid place-items-center w-12 h-12 rounded-full shadow-md cursor-pointer lg:hidden ${styleBurger}`}
        >
          <i className="iconify text-4xl" data-icon="majesticons:menu" />
        </label>
      </div>
      <AppDrawer />
    </div>
  );
}
