import React from "react";
import { Link } from "react-router-dom";

const HomeMap = () => {
  return (
    <>
      <div>
        <img
          src={require("../../assets/img/home/us-map-mobile.png")}
          className="pt-10 md:hidden w-full"
          alt=""
        />
        <div
          className="hidden md:block h-[51.3rem] w-full bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${require("../../assets/img/home/map-desktop.jpg")})`,
          }}
        />
      </div>
      <div className="container">
        <div className="relative w-full">
          <div className="overflow-hidden lg:absolute lg:bottom-32 right-0 xl:max-w-xl lg:p-12 border-radius lg:bg-white/70 backdrop-blur-sm lg:shadow-lg">
            <h2 className="text-3xl uppercase font-semibold text-green mt-24 mb-8 lg:mt-0">
              Our Interactive <span className="block">Exploration Map</span>
            </h2>
            <p className="mb-8">
              A unique part of The VR Voyage is our interactive exploration map.
              Distinct markers help you explore in real time unique spaces in
              different communities that represent arts, culture, and natural
              and cultural heritage topics. With each passing month, we add new
              communities and new points of interest. Take a look for yourself!
            </p>
            <Link
              className="uppercase group mx-auto lg:mx-0 font-semibold text-white bg-green hover:bg-magenta rounded-full w-fit h-12 px-10 flex justify-center gap-3 items-center font-oswald with-transition"
              to="/map"
            >
              Explore
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeMap;
