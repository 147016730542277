import React from "react";

import logoFacebook from "../../../assets/logos/facebook.svg";
// import logoTwitter from "../../../assets/logos/twitter.svg";
import logoInstagram from "../../../assets/logos/instagram.svg";
import logoLinkedln from "../../../assets/logos/linkedln.svg";
// import logoYoutube from "../../../assets/logos/youtube.svg";
// import logoTiktok from "../../../assets/logos/tiktok.svg";

const SocialLinks = () => {
  return (
    <div className="flex gap-1 mt-12">
      {[
        { href: "https://www.facebook.com/thevrvoyage", src: logoFacebook },
        { href: "https://www.instagram.com/thevrvoyage", src: logoInstagram },
        // { href: "https://twitter.com/thevrvoyage", src: logoTwitter },
        {
          href: "https://www.linkedin.com/company/thevrvoyage",
          src: logoLinkedln,
        },
        // { href: "", src: logoYoutube },
        // { href: "", src: logoTiktok },
      ].map((ele, index) => (
        <a key={index} href={ele.href} target="_blank" rel="noreferrer">
          <img src={ele.src} alt={ele.href} />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
