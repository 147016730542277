import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  CONTACTMAIL_CREATE_REQUEST,
  CONTACTMAIL_CREATE_SUCCESS,
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_DELETE_REQUEST,
  CONTACTMAIL_DELETE_SUCCESS,
  CONTACTMAIL_DELETE_FAIL,
  CONTACTMAIL_LIST_REQUEST,
  CONTACTMAIL_LIST_SUCCESS,
  CONTACTMAIL_LIST_FAIL,
  CONTACTMAIL_DETAILS_REQUEST,
  CONTACTMAIL_DETAILS_SUCCESS,
  CONTACTMAIL_DETAILS_FAIL,
} from "../constants/contactMailConstants";

//
//
//
export const createContactMail = (contactMail) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTMAIL_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/contactmails`,
      { contactMail },
      config,
    );

    dispatch({
      type: CONTACTMAIL_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//
//
//
export const deleteContactMail = (mailId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACTMAIL_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${MAIN_ROUTE}/contactmails/${mailId}`,
      config,
    );

    dispatch({
      type: CONTACTMAIL_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//
//
//
export const listContactMailDetails =
  (mailId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTACTMAIL_DETAILS_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

      const { data } = await axios.get(
        `${MAIN_ROUTE}/contactmails/${mailId}`,
        config,
      );

      dispatch({
        type: CONTACTMAIL_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACTMAIL_DETAILS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const listContactMails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACTMAIL_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

    const { data } = await axios.get(`${MAIN_ROUTE}/contactmails`, config);

    dispatch({
      type: CONTACTMAIL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
