import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteOrder, listOrders } from "../../../actions/orderActions";

import { CreateOrder } from "./CreateOrder";
import AppSelect from "../../global/form/AppSelect";
import { AppDropdown } from "../../global/AppDropdown";
// import { AppPagination } from "../../global/AppPagination";
import {
  ORDER_DELETE_RESET,
  ORDER_LIST_RESET,
} from "../../../constants/orderConstants";
import AdminHeaderLayout from "../components/AdminHeaderLayout";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import OrderCard from "../components/OrderCard";
import Warning from "../../global/Warning";
import { USER_LIST_RESET } from "../../../constants/userConstants";
import { TOUR_LIST_RESET } from "../../../constants/tourConstants";

const ManageOrders = () => {
  const orderPlace = useSelector((state) => state.orderPlace);
  const { error: errorCreate, order: created } = orderPlace;

  const userDetails = useSelector((state) => state.userDetails);
  const { error: errorDetails } = userDetails;

  const userList = useSelector((state) => state.userList);
  const { error: errorUsers } = userList;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: TOUR_LIST_RESET });
    dispatch({ type: ORDER_LIST_RESET });
  }, [dispatch]);

  const orderList = useSelector((state) => state.orderList);
  const { loading: loadingOrders, error: errorOrders, orders } = orderList;

  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: deleting,
    error: errorDelete,
    success: deleted,
  } = orderDelete;

  useEffect(() => {
    if (!orders) {
      dispatch(listOrders());
    }
  }, [orders, dispatch]);

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrder(id));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete the order (${e.target.id})?`,
      callback: () => {
        deleteOrderHandler(e.target.id);
      },
      whenDeclined: setWarning,
    });
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorOrders) {
      setTimeout(() => {
        dispatch({ type: ORDER_LIST_RESET });
      }, 5000);
    }
  }, [errorOrders, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: ORDER_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch]);

  useEffect(() => {
    // reset state after success
    if (deleted) {
      setTimeout(() => {
        dispatch({ type: ORDER_DELETE_RESET });
        dispatch({ type: ORDER_LIST_RESET });
      }, 5000);
    }
  }, [deleted, dispatch]);

  //

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [partnerValue, setPartnerValue] = useState("");
  const [tourValue, setTourValue] = useState("");
  const [userValue, setUserValue] = useState("");

  const resetFields = (id, value) => {
    if (id !== "partner") setPartnerValue("");
    else setPartnerValue(value);
    if (id !== "tour") setTourValue("");
    else setTourValue(value);
    if (id !== "user") setUserValue("");
    else setUserValue(value);
  };

  useEffect(() => {
    setFilteredOrders(orders);
    resetFields("", "");
  }, [orders]);

  const filterHandler = (e) => {
    switch (e.target.id) {
      case "reset":
        setFilteredOrders(orders);
        break;
      case "partner":
        if (e.target.value?.length)
          setFilteredOrders(
            orders.filter((order) => order.partner.title === e.target.value)
          );
        break;
      case "tour":
        if (e.target.value?.length)
          setFilteredOrders(
            orders.filter((order) => order.tour.title === e.target.value)
          );
        break;
      case "user":
        if (e.target.value?.length)
          setFilteredOrders(
            orders.filter((order) => order?.user?.name === e.target.value)
          );
        break;
      default:
        break;
    }

    resetFields(e.target.id, e.target.value);
  };

  return (
    <section>
      {errorCreate && <Message error body={errorCreate} />}
      {errorDetails && <Message error body={errorDetails} />}
      {errorUsers && <Message error body={errorUsers} />}
      {created && <Message info body={"Order created successfully!"} />}
      {errorDelete && <Message error body={errorDelete} />}
      {deleted && <Message info body={"Order deleted successfully!"} />}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}
      <AdminHeaderLayout
        title="Manage orders"
        // placeholder="Search Tours"
        button={<CreateOrder />}
        // pagination={<AppPagination totalItems="500" itemsPerPage={50} />}
      />
      {loadingOrders || deleting ? (
        <Loader />
      ) : errorOrders ? (
        <Message error body={errorOrders} />
      ) : (
        !errorDelete &&
        !deleted && (
          <>
            {orders?.length ? (
              <>
                <div className="flex flex-col lg:flex-row gap-5 mb-5">
                  <span className="font-bold w-[200px]">Filter by: </span>
                  <AppSelect
                    id="partner"
                    items={[
                      ...new Set(orders.map((order) => order.partner.title)),
                    ]}
                    itemText="name"
                    itemValue="id"
                    label="Partner"
                    placeholder="-- Please select one --"
                    labelClass="text-denim font-semibold"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    onChange={filterHandler}
                    value={partnerValue}
                  />
                  <AppSelect
                    id="tour"
                    items={[
                      ...new Set(orders.map((order) => order.tour.title)),
                    ]}
                    itemText="name"
                    itemValue="id"
                    label="Tour"
                    placeholder="-- Please select one --"
                    labelClass="text-denim font-semibold"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    onChange={filterHandler}
                    value={tourValue}
                  />
                  <AppSelect
                    id="user"
                    items={[
                      ...new Set(orders.map((order) => order?.user?.name)),
                    ]}
                    itemText="name"
                    itemValue="id"
                    label="User"
                    placeholder="-- Please select one --"
                    labelClass="text-denim font-semibold"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    onChange={filterHandler}
                    value={userValue}
                  />
                  <div className="mt-8 mx-auto md:mx-0 w-44 h-fit border-2 border-transparent hover:border-denim  rounded-full">
                    <button
                      className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-fit- px-12 mx-auto flex justify-center"
                      id="reset"
                      onClick={filterHandler}
                    >
                      <span className="block">reset</span>
                    </button>
                  </div>
                </div>
                <div className="flex justify-between mb-5"></div>
                {filteredOrders?.length ? (
                  <div className="grid gap-2">
                    {filteredOrders.map((order) => (
                      <OrderCard
                        key={order._id}
                        order={order}
                        options={
                          <AppDropdown
                            activator={
                              <i className="iconify" data-icon="tabler:dots" />
                            }
                            className="dropdown-end"
                          >
                            <ul className="w-40 p-1">
                              <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                                <button
                                  id={order._id}
                                  onClick={showDeleteWarning}
                                >
                                  Delete Order
                                </button>
                              </li>
                            </ul>
                          </AppDropdown>
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <p>No orders available with this criteria!</p>
                )}
              </>
            ) : (
              <p>No orders found!</p>
            )}
          </>
        )
      )}
    </section>
  );
};

export default ManageOrders;