import React from "react";
import TourExploreHeader from "./TourExploreHeader";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { Link } from "react-router-dom";

export const TourExploreGalleryItem = ({ data, title, onBackClick, color }) => {
  const { md } = useBreakpoints();

  return (
    <div className="relative">
      {md ? (
        <div className="relative h-[90px] bg-gray w-full">
          <Link
            onClick={() => onBackClick()}
            className="bg-white rounded-full grid absolute top-4 left-4 z-10 w-8 items-center place-items-center aspect-square"
          >
            <i
              className="iconify pl-[6px] md:pl-0"
              data-icon={
                md
                  ? "ic:round-arrow-back"
                  : "material-symbols:arrow-back-ios-rounded"
              }
            />
          </Link>
        </div>
      ) : (
        <div className="w-full mb-9">
          <TourExploreHeader
            onBackClick={onBackClick}
            text={title}
            styles="bg-white fixed top-0 h-[60px] min-h-[60px]"
          />
        </div>
      )}
      {data.type === "image" && data.media ? (
        <img
          src={data.media}
          alt={data.title}
          className="md:h-[630px] object-cover w-full pt-6 md:pt-0"
        />
      ) : data.type === "video" ? (
        <video
          src={data.media}
          alt={data.title}
          className="md:h-[630px] object-cover w-full pt-6 md:pt-0"
          controls
        />
      ) : (
        <div
          className="mt-20 md:mt-0 h-44 md:h-[430px] mb-1.5 w-full grid place-items-center"
          style={{ backgroundColor: color }}
        >
          <span className="w-12 h-12 bg-white rounded-full  grid place-items-center">
            <i className="iconify" data-icon="bi:info-lg" />
          </span>
        </div>
      )}
      <article className="container md:max-w-[560px] mt-7 md:mt-12  pb-20">
        <h2 className="text-2xl font-bold uppercase">{data.title}</h2>
        <h4 className="text-lg my-4 font-semibold">{data.subtitle}</h4>
        {data.description ? (
          <div className="flex flex-col gap-4">
            {data.description
              .split("\n")
              .filter((paragraph) => paragraph.length)
              .map((paragraph, paragraphIndex) => (
                <p key={paragraphIndex}>{paragraph}</p>
              ))}
          </div>
        ) : (
          <></>
        )}
      </article>
    </div>
  );
};
