import {
  ORDER_PLACE_REQUEST,
  ORDER_PLACE_SUCCESS,
  ORDER_PLACE_FAIL,
  ORDER_PLACE_RESET,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_RESET,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_RESET,
} from "../constants/orderConstants";

//

// place order
export const orderPlaceReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PLACE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_PLACE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_PLACE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PLACE_RESET:
      return {};
    default:
      return state;
  }
};

// get order details
export const orderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

// delete order
export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

// all orders
export const orderListReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_LIST_RESET:
      return {};
    default:
      return state;
  }
};
