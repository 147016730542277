import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listTours } from "../../../actions/tourActions";
import { TOUR_LIST_RESET } from "../../../constants/tourConstants";
import { getUserDetails, listUsers } from "../../../actions/userActions";
import {
  USER_DETAILS_RESET,
  USER_LIST_RESET,
} from "../../../constants/userConstants";
import {
  ORDER_PLACE_FAIL,
  ORDER_PLACE_RESET,
} from "../../../constants/orderConstants";
import { listOrders, createNewOrder } from "../../../actions/orderActions";

import AppModal from "../../global/AppModal";
import AppSelect from "../../global/form/AppSelect";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import { requiredFieldsErrorMessage } from "../../../constants/helpers";

export const CreateOrder = () => {
  const dispatch = useDispatch();

  const tourList = useSelector((state) => state.tourList);
  const { loading: loadingTours, error: errorTours, tours } = tourList;

  useEffect(() => {
    if (!tours) {
      dispatch(listTours());
    }
  }, [tours, dispatch]);

  const [tour, setTour] = useState("");
  const tourHandler = (e) => {
    setTour(e.target.value);
  };
  const [selectedTour, setSelectedTour] = useState(null);

  useEffect(() => {
    if (tours && tour?.length) {
      setSelectedTour(tours.find((ele) => ele.title === tour));
    }
  }, [tours, tour, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: selectedPartner,
  } = userDetails;

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users } = userList;

  useEffect(() => {
    if (selectedTour) {
      dispatch(getUserDetails("partner", selectedTour.partner));
      dispatch(listUsers("user"));
    }
  }, [selectedTour, dispatch]);

  const [user, setUser] = useState("");
  const userHandler = (e) => {
    setUser(e.target.value);
  };
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (users && user?.length) {
      setSelectedUser(users.find((ele) => ele.name === user));
    }
  }, [users, user, dispatch]);

  const orderPlace = useSelector((state) => state.orderPlace);
  const {
    loading: loadingCreate,
    error: errorCreate,
    order: created,
  } = orderPlace;

  // action handlers
  const resetFields = () => {
    setTour("");
    setSelectedTour(null);
    setUser("");
    setSelectedUser(null);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (
        !selectedTour ||
        !selectedPartner ||
        !selectedUser
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      // dispatching
      dispatch(
        createNewOrder(selectedTour, selectedPartner, selectedUser)
      );
    } catch (error) {
      dispatch({
        type: ORDER_PLACE_FAIL,
        payload: error.message,
      });
    }
  };

  //
  useEffect(() => {
    // resetting state due to errors
    if (errorTours) {
      setTimeout(() => {
        dispatch({ type: TOUR_LIST_RESET });
      }, 5000);
    }
  }, [errorTours, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorUsers) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorUsers, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorDetails, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: ORDER_PLACE_RESET });
      }, 5000);
    }
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        dispatch({ type: ORDER_PLACE_RESET });
        resetFields();
        dispatch(listOrders());
      }, 5000);
    }
  }, [created, dispatch]);

  return (
    <AppModal
      activator={
        <button
          type="button"
          className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 h-12 flex items-center justify-center font-oswald gap-3 with-transition"
        >
          Create New Order
        </button>
      }
      styleModal="h-full lg:h-3/4 pb-14"
      styleLabelClose="sticky w-10 h-10 rounded-full"
      styleIconClose="text-denim text-3xl bg-gray rounded-full p-1"
      withLoading={loadingCreate || loadingUsers || loadingDetails}
    >
      <div>
        <p className="font-bold text-lg lg:sticky absolute top-4 left-4 lg:pl-6 z-[1]">
          Create New Order
        </p>
        {loadingTours ? (
          <Loader />
        ) : errorTours ? (
          <Message error body={errorTours} />
        ) : (
          tours && (
            <>
              {tours.length ? (
                <form
                  className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5"
                  onSubmit={submitHandler}
                >
                  <AppSelect
                    asRequired
                    id="tour"
                    items={tours.map((tour) => tour.title)}
                    itemText="name"
                    itemValue="id"
                    label="Tour"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    placeholder="-- Please select one --"
                    value={tour}
                    onChange={tourHandler}
                  />
                  {!errorUsers && users && (
                    <>
                      {users.length ? (
                        <>
                          <AppSelect
                            asRequired
                            id="user"
                            items={users.map((user) => user.name)}
                            itemText="name"
                            itemValue="id"
                            label="User"
                            labelClass="font-bold font-quicksand"
                            className="bg-transparent border-gray-border border-2 rounded-lg"
                            placeholder="-- Please select one --"
                            value={user}
                            onChange={userHandler}
                          />
                          <button
                            type="submit"
                            className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3"
                            onSubmit={submitHandler}
                          >
                            Create New Order
                          </button>
                        </>
                      ) : (
                        <p className="px-4 lg:px-24 pt-10 lg:pt-5">
                          Please add some users first in order to be able to
                          place orders.
                        </p>
                      )}
                    </>
                  )}
                </form>
              ) : (
                <p className="px-4 lg:px-24 pt-10 lg:pt-5">
                  Please add some tours first in order to be able to place
                  orders.
                </p>
              )}
            </>
          )
        )}
      </div>
    </AppModal>
  );
};
