import React, { useEffect, useState } from "react";
import { TourNavigation } from "./TourNavigation";
import TourExploreHeader from "./TourExploreHeader";

export const TourExploreHotSpotData = ({
  data,
  partner,
  color,
  onBackClick,
  onHotSpotClick,
  onGalleryItemClick,
  onMapClick,
}) => {
  const [selectedFloor, setSelectedFloor] = useState(null);
  const hasFloors = data.type === "floor";

  useEffect(() => {
    if (hasFloors) {
      setSelectedFloor(data.floorplans[0]);
    }
  }, [hasFloors, data]);

  if (data.type === "gallery") {
    return (
      <div className="relative bg-grey min-h-screen">
        <div className="sticky z-10 top-0 w-full">
          <TourExploreHeader
            onBackClick={() => onBackClick(data.parent)}
            text={
              data.name
                ? data.name
                : data.number
                ? `Scene ${data.number}`
                : null
            }
            title={data.title}
            styles="rounded-b-3xl bg-white shadow-md"
          />
        </div>
        <section className="container md:px-4 w-full pt-4 pb-32">
          {data.banner?.length ? (
            <div
              className="w-full h-[250px] absolute left-0 top-[50px]"
              style={{
                backgroundImage: `url(${data.banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          ) : (
            <></>
          )}
          <ul
            className="mx-auto"
            style={{
              columns: "2 auto",
              columnGap: "16px",
              paddingTop: data.banner?.length ? "200px" : "",
            }}
          >
            {data.hotspots.map((item, itemIndex) => (
              <li
                key={itemIndex}
                className="cursor-pointer"
                style={{ breakInside: "avoid" }}
                onClick={() =>
                  onGalleryItemClick({
                    ...item,
                    sceneNumber: `Scene ${data.number}`,
                  })
                }
              >
                {item.type === "image" && item.media ? (
                  <img
                    src={item.media}
                    alt={item.title}
                    className="mb-1.5 rounded-xl w-full"
                  />
                ) : item.type === "video" ? (
                  <video
                    src={item.media}
                    className="mb-1.5 rounded-xl w-full"
                  />
                ) : (
                  <div
                    className="h-36 mb-1.5 rounded-xl w-full grid place-items-center"
                    style={{ backgroundColor: color }}
                  >
                    <span className="w-12 h-12 bg-white rounded-full grid place-items-center">
                      <i className="iconify" data-icon="bi:info-lg" />
                    </span>
                  </div>
                )}
                <p className="font-bold text-xs mb-5">{item.title}</p>
              </li>
            ))}
          </ul>
        </section>
        <div className="fixed w-full bottom-0 z-10">
          <TourNavigation
            textGuide={data.script}
            audioUrl={data.audio}
            visual={data.banner}
            isFixed
            onMapClick={() => onMapClick()}
          />
        </div>
      </div>
    );
  } else if (hasFloors && selectedFloor) {
    return (
      <div className="relative bg-white flex flex-col min-h-screen">
        <div className="relative z-10 top-0 w-full">
          <TourExploreHeader
            onBackClick={() => onBackClick()}
            title={selectedFloor?.title}
            text={partner?.title}
            styles="rounded-b-3xl bg-white shadow-md"
          />
        </div>
        <div className="grid place-items-center flex-grow">
          <div
            className="bg-contain bg-no-repeat bg-center w-[360px] h-[640px] mx-auto relative"
            style={{ backgroundImage: `url(${selectedFloor.map})` }}
          >
            {selectedFloor.stops.map((stop) => (
              <button
                key={stop.number}
                className={`absolute -translate-x-1/2 -translate-y-1/2 grid place-items-center w-10 h-10 text-xs text-white rounded-full font-bold`}
                style={{
                  top: stop.coords.y + "px",
                  left: stop.coords.x + "px",
                  backgroundColor: color,
                }}
                onClick={() =>
                  onHotSpotClick({ ...stop, parent: data, type: "gallery" })
                }
              >
                {stop.number}
              </button>
            ))}
          </div>
        </div>
        <div className="grid sticky rounded-t-[20px] z-10 bottom-0 w-full h-[90px] bg-white shadow-[0_-0.8px_5px_rgba(0,0,0,0.3)]">
          <ul className="flex justify-center items-center">
            {data.floorplans.map((floorplan, floorplanIndex) => (
              <li
                key={floorplanIndex}
                className="flex-grow grid place-items-center h-full"
              >
                <button
                  onClick={() => setSelectedFloor(floorplan)}
                  className={`flex flex-col items-center ${
                    floorplan.title === selectedFloor.title
                      ? "opacity-100"
                      : "opacity-40"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#1A1E29"
                  >
                    <path d="M15 6.00008H22V9.00008H18V13.0001H14V17.0001H10V21.0001H3V18.0001H7V14.0001H11V10.0001H15V6.00008ZM4.83 8.34008L10.34 2.83008L12.17 4.66008L6.66 10.1701L8.5 12.0001H3V6.50008L4.83 8.34008Z" />
                  </svg>
                  <span className="text-xs font-bold">
                    {floorplan.title === selectedFloor.title ||
                    /^[^0-9]*$/.test(floorplan.title)
                      ? floorplan.title
                      : (floorplan.title.match(/\d+/) || [null])[0]}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
};
