export const AppDropdown = ({
  className,
  activator,
  children,
  contentClass,
}) => {
  return (
    <div className={`dropdown ${className}`}>
      <label tabIndex="0" className="cursor-pointer">
        {activator}
      </label>
      <div
        tabIndex="0"
        className={`dropdown-content menu shadow bg-white rounded-box ${contentClass}`}
      >
        {children}
      </div>
    </div>
  );
};
