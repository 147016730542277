import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import { uploadMedia } from "../actions/mediaActions";
import {
  getUserDetails,
  loginFromToken,
  updateUserInfo,
  validateUser,
} from "../actions/userActions";

import {
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../constants/mediaConstants";
import {
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_VALIDATE_RESET,
} from "../constants/userConstants";
import {
  passwordValidateErrorMessage,
  validatePassword,
} from "../constants/helpers";

import locations from "../data/locations.json";
import { listOfInterest } from "../constants/listOfInterest";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import SettingsUser from "../components/settings/SettingsUser";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import AppInput from "../components/global/form/AppInput";
import AppSelect from "../components/global/form/AppSelect";
import AppChipsInput from "../components/global/form/AppChipsInput";
import AppTextArea from "../components/global/form/AppTextArea";
import PasswordRules from "../components/global/PasswordRules";

const UserProfileScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: userInfoLogin } = vrvUserLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: userInfoDetails,
  } = userDetails;

  const userValidate = useSelector((state) => state.userValidate);
  const {
    loading: loadingValidate,
    error: errorValidate,
    success: valid,
  } = userValidate;

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    userInfo: userInfoUpdate,
  } = userDetailsUpdate;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    if (!userInfoLogin || userInfoLogin?.role === "admin") {
      navigate("/");
    }
  }, [userInfoLogin, navigate]);

  useEffect(() => {
    if (userInfoLogin) {
      if (!userInfoDetails) {
        dispatch(getUserDetails("user", userInfoLogin.slug));
      }
    }
  }, [userInfoLogin, userInfoDetails, dispatch]);

  useEffect(() => {
    if (userInfoDetails) {
      if (userInfoDetails.requiredVerification) {
        navigate(`/user/profile/${userInfoDetails.slug}`);
      }

      if (userInfoDetails.slug !== slug) {
        navigate("/");
      }
    }
  }, [userInfoDetails, slug, navigate]);

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [bio, setBio] = useState("");
  const [interests, setInterests] = useState([]);

  const allInterests = listOfInterest.map((item) => item.title);

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
    setCity("");
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const bioHandler = (e) => {
    setBio(e.target.value);
  };

  const interestsHandler = (value) => {
    setInterests(value);
  };

  useEffect(() => {
    if (userInfoDetails) {
      setName(userInfoDetails.name);
      setCountry(userInfoDetails.location?.country);
      setCity(userInfoDetails.location?.city);
      setBio(userInfoDetails.bio);
      setInterests(userInfoDetails.interests);
    }
  }, [userInfoDetails]);

  const [mediaType, setMediaType] = useState("");

  const uploadHandler = (e) => {
    const file = e.target.files[0];

    if (/jpg|jpeg|png/.test(file.type)) {
      setMediaType(e.target.id.split("-")[1]);
      const form = new FormData();
      form.append("image", file);
      dispatch(uploadMedia(form));
    } else {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: jpg and png)",
      });
      return;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      userInfoLogin &&
      userInfoDetails &&
      userInfoLogin.slug === userInfoDetails.slug
    ) {
      const updates = {
        name,
        bio,
        location: { city, country },
        interests,
      };

      dispatch(updateUserInfo("user", userInfoLogin.slug, updates));
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorUpdate) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
      }, 5000);
    }
  }, [errorUpdate, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorValidate) {
      setTimeout(() => {
        dispatch({ type: USER_VALIDATE_RESET });
      }, 5000);
    }
  }, [errorValidate, dispatch]);

  useEffect(() => {
    if (uploaded) {
      if (userInfoLogin) {
        if (mediaType === "avatar") {
          dispatch(
            updateUserInfo("user", userInfoLogin.slug, { avatar: uploaded }),
          );
        }

        if (mediaType === "banner") {
          dispatch(
            updateUserInfo("user", userInfoLogin.slug, { banner: uploaded }),
          );
        }
      }
    }

    // eslint-disable-next-line
  }, [uploaded, dispatch, userInfoLogin]);

  useEffect(() => {
    if (userInfoUpdate) {
      setTimeout(() => {
        if (mediaType === "avatar") {
          dispatch(loginFromToken(userInfoLogin.token));
        }

        // navigate(`/user/profile/${userInfoUpdate.slug}/edit`);
        window.location.reload();
      }, 5000);
    }

    // eslint-disable-next-line
  }, [userInfoUpdate, dispatch]);

  const [option, setOption] = useState(1);

  // reset password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const oldPasswordHandler = (e) => {
    e.preventDefault();
    setOldPassword(e.target.value);
  };

  const newPasswordHandler = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const completeProfileHandler = async (e) => {
    e.preventDefault();

    if (
      userInfoLogin &&
      userInfoDetails &&
      userInfoLogin.slug === userInfoDetails.slug
    ) {
      if (
        oldPassword.length > 0 &&
        newPassword.length > 0 &&
        confirmedPassword.length > 0
      ) {
        if (newPassword === confirmedPassword) {
          if (validatePassword(newPassword)) {
            dispatch(validateUser("user", oldPassword));
          } else {
            dispatch({
              type: USER_UPDATE_FAIL,
              payload: passwordValidateErrorMessage,
            });
          }
        } else {
          dispatch({
            type: USER_UPDATE_FAIL,
            payload: "Passwords don't match!",
          });
        }
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: "All fields are required!",
        });
      }
    } else {
    }
  };

  useEffect(() => {
    if (valid) {
      if (userInfoLogin) {
        setTimeout(() => {
          dispatch({ type: USER_VALIDATE_RESET });
          dispatch(
            updateUserInfo("user", userInfoLogin.slug, {
              password: newPassword,
            }),
          );
        }, 5000);
      }
    }

    // eslint-disable-next-line
  }, [valid, dispatch, userInfoLogin]);

  return (
    <>
      {errorDetails && <Message error body={errorDetails} />}
      {errorUpload && <Message error body={errorUpload} />}
      {errorUpdate && <Message error body={errorUpdate} />}
      {errorValidate && <Message error body={errorValidate} />}
      {userInfoUpdate && (
        <Message
          info
          body={
            "Congratulations on making updates to your profile! Please wait a moment as the page reloads to reflect the latest updates."
          }
        />
      )}
      {valid && (
        <Message
          info
          body="We are validating your information. Please wait until the page reloads!"
        />
      )}
      {uploading && <Loader />}
      {loadingDetails ? (
        <Loader />
      ) : loadingUpdate ? (
        <Loader />
      ) : (
        userInfoDetails && (
          <>
            <MainLayout
              scrollNavClass="bg-gray"
              logo={Logo}
              textHover="text-green"
              textHoverWithScroll="text-green"
              styleBtn="hover:text-denim"
              styleBtnWithScroll="hover:text-denim
              hover:border-denim"
              styleBurger="hidden"
            >
              <div className="lg:hidden flex justify-bettew mt-5 ml-4">
                <NavLink to="/settings-user">
                  <i
                    className="iconify"
                    data-icon="material-symbols:arrow-back-ios-rounded"
                  />
                </NavLink>
                <NavLink to="/" className="absolute right-4 top-5">
                  <img src={Logo} alt="Logo" className="w-48" />
                </NavLink>
              </div>
              <div className="container mt-12 lg:mt-32 lg:grid lg:grid-cols-6 lg:gap-8">
                <div className="hidden lg:block px-0 lg:col-span-2">
                  <SettingsUser setOption={setOption} />
                </div>
                <div className="mb-3 col-span-5 lg:col-span-4">
                  {option === 1 ? (
                    <>
                      <div
                        className="h-40 lg:h-80 rounded-t-[20px] bg-cover bg-no-repeat bg-center"
                        style={{
                          backgroundImage: `url(${
                            userInfoDetails.banner?.length > 0
                              ? userInfoDetails.banner
                              : require("../assets/img/profile/COVER.jpg")
                          })`,
                        }}
                      />
                      <div className="mt-5 flex items-center lg:items-end pr-0">
                        <div className="avatar">
                          <div
                            className="lg:ml-10 w-28 lg:w-40 lg:-mt-20 aspect-square bg-cover bg-center bg-no-repeat rounded-full border-4 boder-gray"
                            style={{
                              backgroundImage: `url(${
                                userInfoDetails.avatar?.length > 0
                                  ? userInfoDetails.avatar
                                  : require("../assets/img/profile/profile.jpg")
                              })`,
                              border: "5px solid #efefef",
                            }}
                          ></div>
                        </div>
                        <div className="container grow flex flex-col lg:flex-row gap-3 justify-end">
                          <div className="lg:w-full">
                            <input
                              type="file"
                              id="upload-avatar"
                              hidden
                              onChange={uploadHandler}
                            />
                            <label
                              htmlFor="upload-avatar"
                              className="uppercase lg:ml-auto font-semibold rounded-full px-4 border-2 py-2 flex items-center justify-center font-oswald gap-3 cursor-pointer with-transition hover:bg-magenta hover:text-white"
                            >
                              edit profile photo
                            </label>
                          </div>
                          <div className="lg:w-full">
                            <input
                              type="file"
                              id="upload-banner"
                              hidden
                              onChange={uploadHandler}
                            />
                            <label
                              htmlFor="upload-banner"
                              className="uppercase lg:ml-auto font-semibold rounded-full px-4 border-2 py-2 flex items-center justify-center font-oswald gap-3 cursor-pointer with-transition hover:bg-magenta hover:text-white"
                            >
                              edit banner photo
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="grid gap-5 mt-8 mb-24">
                        <AppInput
                          asRequired
                          id="name"
                          label="Name"
                          labelClass="font-semibold"
                          className="bg-transparent border-gray-border border-2 rounded-lg"
                          value={name}
                          onChange={nameHandler}
                        />
                        <div className="flex flex-col lg:flex-row gap-y-5 lg:gap-6">
                          <AppSelect
                            asRequired
                            items={locations.map((ele) => ele.country)}
                            itemText="name"
                            itemValue="id"
                            label="Country"
                            labelClass="font-semibold"
                            placeholder="-- Please select one --"
                            className="border-2 border-gray-border rounded-lg"
                            value={country}
                            onChange={countryHandler}
                          />
                          <AppSelect
                            asRequired
                            items={
                              locations.find((ele) => ele.country === country)
                                ?.city
                            }
                            itemText="name"
                            itemValue="id"
                            label="City"
                            labelClass="font-semibold"
                            placeholder="-- Please select one --"
                            className="bg-transparent border-gray-border border-2 rounded-lg"
                            value={city}
                            onChange={cityHandler}
                          />
                        </div>
                        <AppTextArea
                          asRequired
                          id="biography"
                          label="Biography"
                          labelClass="font-semibold"
                          className="bg-transparent border-gray-border border-2 rounded-lg"
                          rows="6"
                          value={bio}
                          onChange={bioHandler}
                        />
                        <AppChipsInput
                          items={allInterests}
                          value={interests}
                          asRequired
                          id="interests"
                          label="Interests"
                          labelClass="font-semibold"
                          className="bg-transparent border-gray-border border-2 rounded-lg"
                          onChange={(e) => interestsHandler(e)}
                        />
                        <div className="flex gap-3 justify-end">
                          <div className="border-transparent border-2 hover:border-denim rounded-full with-transition">
                            <Link
                              to={`/user/profile/${userInfoDetails.slug}`}
                              className="uppercase lg:mr-0 font-semibold text-white hover:text-denim bg-magenta hover:bg-transparent  w-28 h-12 flex items-center rounded-full justify-center font-oswald gap-3 with-transition"
                            >
                              go back
                            </Link>
                          </div>
                          <div className="border-transparent border-2 hover:border-denim rounded-full with-transition">
                            <button
                              className="uppercase lg:mr-0 font-semibold text-white hover:text-denim bg-magenta hover:bg-transparent  w-28 h-12 flex items-center rounded-full justify-center font-oswald gap-3 with-transition"
                              onClick={submitHandler}
                            >
                              submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : option === 2 ? (
                    <div className="flex flex-col gap-6">
                      {loadingValidate ? (
                        <Loader />
                      ) : (
                        <form
                          className="grid gap-5"
                          onSubmit={completeProfileHandler}
                        >
                          <AppInput
                            asRequired
                            id="old-password"
                            type="password"
                            label="Enter Your Old Password"
                            value={oldPassword}
                            onChange={oldPasswordHandler}
                            labelClass="font-semibold"
                            className="bg-transparent border-gray-border border-2 rounded-lg"
                          />
                          <p>
                            Or{" "}
                            <Link
                              to="/user/forgot-password"
                              className="font-bold text-green hover:text-magenta with-transition"
                            >
                              click here
                            </Link>{" "}
                            if you don't remember your password!
                          </p>

                          <AppInput
                            asRequired
                            id="new-password"
                            type="password"
                            label="Enter Your New Password"
                            value={newPassword}
                            onChange={newPasswordHandler}
                            labelClass="font-semibold"
                            className="bg-transparent border-gray-border border-2 rounded-lg"
                          />
                          <AppInput
                            asRequired
                            id="confirmed-password"
                            type="password"
                            label="Confirm Your New password"
                            value={confirmedPassword}
                            onChange={confirmedPasswordHandler}
                            labelClass="font-semibold"
                            className="bg-transparent border-gray-border border-2 rounded-lg"
                          />
                          <PasswordRules password={newPassword} />
                          <div className="w-fit rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto lg:mx-0 with-transition">
                            <button
                              type="submit"
                              className="font-semibold text-white hover:text-denim uppercase app-primary-button h-14 animate-bg w-44 flex justify-center lg:ml-0 with-transition"
                              onSubmit={completeProfileHandler}
                            >
                              <span className="block">save</span>
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </MainLayout>
          </>
        )
      )}
    </>
  );
};

export default UserProfileScreen;
