import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";

import { getUserDetails, updateUserInfo } from "../actions/userActions";
import {
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
} from "../constants/userConstants";
import { listOrdersByUser } from "../actions/orderActions";
import { ORDER_LIST_RESET } from "../constants/orderConstants";

import PasswordRules from "../components/global/PasswordRules";
import {
  passwordMatchingErrorMessage,
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validatePassword,
} from "../constants/helpers";

import { useBreakpoints } from "../hooks/useBreakpoints";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import { TabContent, TabLabel, TabWrapper } from "../components/global/AppTabs";
import AppTourCard from "../components/global/AppTourCard";
import AppInput from "../components/global/form/AppInput";

const UserProfileScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: userInfoLogin } = vrvUserLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: userInfoDetails,
  } = userDetails;

  const orderList = useSelector((state) => state.orderList);
  const { loading: loadingOrders, error: errorOrders, orders } = orderList;

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    if (userInfoDetails) {
      dispatch(listOrdersByUser(userInfoDetails._id));
    }
  }, [userInfoDetails, dispatch]);

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    userInfo: userInfoUpdate,
  } = userDetailsUpdate;

  useEffect(() => {
    if (slug?.length) {
      dispatch(getUserDetails("user", slug));
    }
  }, [slug, dispatch]);

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const passwordHandler = (e) => {
    setPassword(e.target.value);
    e.preventDefault();
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const resetFields = () => {
    setPassword("");
    setConfirmedPassword("");
  };

  const completeProfileHandler = (e) => {
    e.preventDefault();

    if (password.length > 0 && confirmedPassword.length > 0) {
      if (password === confirmedPassword) {
        if (validatePassword(password)) {
          const updates = {
            password,
          };

          dispatch(updateUserInfo("user", userInfoDetails?.slug, updates));
        } else {
          if (!validatePassword(password)) {
            dispatch({
              type: USER_UPDATE_FAIL,
              payload: passwordValidateErrorMessage,
            });
          }
        }
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: passwordMatchingErrorMessage,
        });
      }
    } else {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    if (userInfoUpdate) {
      resetFields();
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
        dispatch(getUserDetails("user", slug));
      }, 5000);
    }

    // eslint-disable-next-line
  }, [userInfoUpdate, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorUpdate) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
      }, 5000);
    }
  }, [userInfoUpdate, errorUpdate, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorOrders) {
      setTimeout(() => {
        dispatch({ type: ORDER_LIST_RESET });
      }, 5000);
    }
  }, [errorOrders, dispatch]);

  const { lg } = useBreakpoints();

  return (
    <>
      {loadingOrders && <Loader />}
      {errorDetails && <Message error body={errorDetails} />}
      {errorUpdate && <Message error body={errorUpdate} />}
      {errorOrders && <Message error body={errorOrders} />}
      {userInfoUpdate && (
        <Message
          info
          body={
            "Congratulations on completing your profile! Please wait a moment while we log you in."
          }
        />
      )}
      {loadingDetails ? (
        <Loader />
      ) : loadingUpdate ? (
        <Loader />
      ) : (
        userInfoDetails && (
          <>
            <MainLayout
              scrollNavClass="bg-gray"
              logo={Logo}
              textHover="text-green"
              textHoverWithScroll="text-green"
              styleBtn="hover:text-denim"
              styleBtnWithScroll="hover:text-denim
      hover:border-denim"
            >
              <NavLink to="/" className="lg:hidden absolute ml-4 mt-5">
                <img src={Logo} alt="Logo" className="w-48" />
              </NavLink>
              {userInfoDetails.requiredVerification ? (
                <div className="container mt-32 gap-24 pb-80">
                  <div className="lg:col-span-4 flex flex-col gap-4">
                    <form
                      className="grid gap-5"
                      onSubmit={completeProfileHandler}
                    >
                      <PasswordRules
                        password={password}
                        heading="Create your password"
                        body="Please make sure your password is strong enough not to be guessed easily. So, let's consider the following rules:"
                      />
                      <AppInput
                        asRequired
                        id="Password"
                        type="password"
                        label="Password"
                        value={password}
                        onChange={passwordHandler}
                        labelClass="font-semibold"
                        className="bg-transparent border-gray-border border-2 rounded-lg"
                      />
                      <AppInput
                        asRequired
                        id="confirmed-password"
                        type="password"
                        value={confirmedPassword}
                        onChange={confirmedPasswordHandler}
                        label="Confirm password"
                        labelClass="font-semibold"
                        className="bg-transparent border-gray-border border-2 rounded-lg"
                      />
                      <div className="w-fit rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto lg:mx-0">
                        <button
                          type="submit"
                          className="font-semibold text-white hover:text-denim uppercase app-primary-button h-14 animate-bg w-44 flex justify-center lg:ml-0"
                        >
                          <span className="block">submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="hidden lg:block col-span-3">
                    <div
                      className="bg-cover bg-center bg-no-repeat h-full rounded-tl-2xl rounded-br-2xl"
                      style={{
                        backgroundImage: `url(${require("../assets/img/login/create-password.png")})`,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="lg:container mt-20">
                  <div className="relative lg:min-h-[45rem] mt-9 lg:mt-28">
                    <div className="col-span-12 lg:col-span-4">
                      <TabWrapper
                        value="about"
                        renderAllContent={lg}
                        tabClass="rounded-[10px] w-1/2 py-2 font-semibold uppercase with-transition"
                        inactiveTabClass="opacity-50"
                        activeTabClass="opacity-100 bg-yellow scale-105 shadow-lg"
                      >
                        <div className="flex lg:hidden justify-between mb-8 rounded-[10px] mx-4 border-2 border-gray-border">
                          <TabLabel name="about">
                            <h6>about</h6>
                          </TabLabel>
                          <TabLabel name="interact">
                            <h6>interact</h6>
                          </TabLabel>
                        </div>
                        <div className="grid lg:grid-cols-6 grid-rows-auto gap-8">
                          <section className="lg:col-span-4">
                            <TabContent name="about">
                              <section className="col-span-12 lg:col-span-8 mb-8">
                                <div className="mb-3">
                                  <div
                                    className="h-40 lg:h-80 rounded-t-[20px] bg-cover bg-no-repeat bg-center"
                                    style={{
                                      backgroundImage: `url(${
                                        userInfoDetails.banner?.length > 0
                                          ? userInfoDetails.banner
                                          : require("../assets/img/profile/COVER.jpg")
                                      })`,
                                    }}
                                  />
                                  <div className="flex items-center">
                                    <div
                                      className="w-32 lg:w-40 border-4 border-white rounded-full -mt-6 lg:-mt-20 ml-5 aspect-square bg-cover bg-center bg-no-repeat"
                                      style={{
                                        backgroundImage: `url(${
                                          userInfoDetails.avatar?.length > 0
                                            ? userInfoDetails.avatar
                                            : require("../assets/img/profile/profile.jpg")
                                        })`,
                                      }}
                                    ></div>
                                    {userInfoLogin &&
                                      userInfoDetails &&
                                      userInfoLogin.slug ===
                                        userInfoDetails.slug && (
                                        <Link
                                          to={`/user/profile/${userInfoDetails?.slug}/edit`}
                                          className="uppercase ml-3 lg:ml-auto font-semibold text-white bg-magenta rounded-full hover:bg-transparent border-2 border-transparent hover:border-denim hover:text-denim w-fit px-6 h-12 flex items-center justify-center font-oswald gap-3 with-transition"
                                        >
                                          <i
                                            className="iconify"
                                            data-icon="clarity:settings-solid"
                                          />
                                          manage profile
                                        </Link>
                                      )}
                                  </div>
                                </div>
                              </section>
                              <h3 className="container uppercase font-oswald font-semibold text-3xl mb-2">
                                {userInfoDetails.name}
                              </h3>
                              <p
                                className={`container font-semibold mb-8 text-denim ${
                                  userInfoDetails.location?.city.length === 0 &&
                                  "/40"
                                }`}
                              >
                                {userInfoDetails.location?.city.length > 0
                                  ? userInfoDetails.location?.city
                                  : "Please add a city"}
                                ,{" "}
                                {userInfoDetails.location?.country.length > 0
                                  ? userInfoDetails.location?.country
                                  : "Please add a country"}
                              </p>
                              <p
                                className={`container mb-24 text-denim${
                                  userInfoDetails.bio?.length === 0 &&
                                  "/40 font-semibold"
                                }`}
                              >
                                {userInfoDetails.bio?.length > 0
                                  ? userInfoDetails.bio
                                  : "Please add bio"}
                              </p>
                            </TabContent>
                          </section>
                          <TabContent
                            name="interact"
                            className=" lg:col-span-2"
                          >
                            <aside className="container overflow-auto lg:px-1 h-fit lg:border-radius lg:shadow-lg pb-5 grow">
                              <div className="lg:px-5">
                                <div className="gap-3 mb-6">
                                  <h6 className="mb-8 uppercase font-semibold">
                                    My Interests
                                  </h6>
                                  <div className="flex flex-wrap gap-2">
                                    {userInfoDetails.interests?.length > 0 &&
                                    userInfoDetails.interests[0]?.length > 0 ? (
                                      <>
                                        {userInfoDetails.interests?.map(
                                          (interest, index) => (
                                            <span
                                              key={index}
                                              className="text-xs border-2 rounded-full px-4 py-1"
                                            >
                                              {interest}
                                            </span>
                                          ),
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        Enhance your profile by adding your
                                        interests! It will unlock a personalized
                                        experience. Don't miss out on this
                                        opportunity to make the most of your
                                        journey with us.
                                      </>
                                    )}
                                  </div>
                                </div>
                                <h6 className="mb-8 uppercase font-semibold">
                                  My Tours
                                </h6>
                                {!errorOrders && orders?.length ? (
                                  <div className="grid grid-cols-1 gap-3 mb-16">
                                    {orders.map((order, index) => (
                                      // <Link
                                      //   key={index}
                                      //   to={`/user/profile/${userInfoLogin.slug}/my-orders/${order._id}`}
                                      // >
                                      //   {order.tour.title}
                                      // </Link>
                                      <AppTourCard
                                        key={index}
                                        name={order.tour.title}
                                        link={`/user/profile/${userInfoLogin.slug}/my-orders/${order._id}`}
                                        img={order.tour.cover}
                                        partnerLogo={order.partner.avatar}
                                        className="h-32 border-4 border-transparent hover:border-green"
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <>The tours you purchased will appear here.</>
                                )}
                              </div>
                            </aside>
                          </TabContent>
                        </div>
                      </TabWrapper>
                    </div>
                  </div>
                </div>
              )}
            </MainLayout>
          </>
        )
      )}
    </>
  );
};

export default UserProfileScreen;
