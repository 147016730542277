import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";
import {
  TOUR_CREATE_REQUEST,
  TOUR_CREATE_SUCCESS,
  TOUR_CREATE_FAIL,
  TOUR_DETAILS_REQUEST,
  TOUR_DETAILS_SUCCESS,
  TOUR_DETAILS_FAIL,
  TOUR_DELETE_REQUEST,
  TOUR_DELETE_SUCCESS,
  TOUR_DELETE_FAIL,
} from "../constants/tourConstants";

import {
  GUIDE_LIST_REQUEST,
  GUIDE_LIST_SUCCESS,
  GUIDE_LIST_FAIL,
  GUIDE_ACCESS_REQUEST,
  GUIDE_ACCESS_SUCCESS,
  GUIDE_ACCESS_FAIL,
  ACCESS_LIST_REQUEST,
  ACCESS_LIST_SUCCESS,
  ACCESS_LIST_FAIL,
  ACCESS_DETAILS_REQUEST,
  ACCESS_DETAILS_SUCCESS,
  ACCESS_DETAILS_FAIL,
  ACCESS_DISABLE_REQUEST,
  ACCESS_DISABLE_SUCCESS,
  ACCESS_DISABLE_FAIL,
} from "../constants/guideConstants";

// create a guide
export const createGuide = (guide) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_CREATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/guides`,
      { guide },
      config,
    );

    dispatch({
      type: TOUR_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get single guide details by slug
export const getGuideDetails = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: TOUR_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/guides/${slug}`);

    dispatch({
      type: TOUR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get all guides
export const listGuides = () => async (dispatch) => {
  try {
    dispatch({
      type: GUIDE_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/guides`);

    dispatch({
      type: GUIDE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GUIDE_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get all guides related to a partner
export const listGuidesByPartner = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GUIDE_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/guides/by-partner/${slug}`);

    dispatch({
      type: GUIDE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GUIDE_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// delete guide
export const deleteGuide = (slug) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${MAIN_ROUTE}/guides/${slug}`, config);

    dispatch({
      type: TOUR_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// import guide data
export const importGuide = () => {
  return localStorage?.getItem("guide")
    ? JSON.parse(localStorage.getItem("guide"))
    : null;
};

// write guide data
export const writeGuide = (guide) => {
  localStorage.setItem("guide", JSON.stringify(guide));
};
//

// request access -> (partner, affiliate)
export const requestGuideAccess =
  (accessRequest) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GUIDE_ACCESS_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/access`,
        { accessRequest },
        config,
      );

      dispatch({
        type: GUIDE_ACCESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GUIDE_ACCESS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// get all access sessions
export const listSessions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCESS_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${MAIN_ROUTE}/access`, config);

    dispatch({
      type: ACCESS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCESS_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get access sessions by user role
export const listSessionsBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: ACCESS_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/access/byrole/${slug}`);

    dispatch({
      type: ACCESS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCESS_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get session by code
export const getSessionByCode = (code) => async (dispatch) => {
  try {
    dispatch({
      type: ACCESS_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/access/${code}`);

    dispatch({
      type: ACCESS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCESS_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// disable session
export const disableSession = (sessionId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCESS_DISABLE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${MAIN_ROUTE}/access/disable/${sessionId}`,
      {},
      config,
    );

    dispatch({
      type: ACCESS_DISABLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCESS_DISABLE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
