import React from "react";
import { Link } from "react-router-dom";

const AppBusinessCard = ({ img, role, comingSoon, className, color }) => (
  <Link
    to={`/join/${role}`}
    className={`${
      comingSoon ? "pointer-events-none" : ""
    } group/arrowCards h-full relative block border-radius overflow-hidden ring-4 ${className} with-transition`}
  >
    <div
      className="h-52 w-full bg-cover bg-no-repeat bg-center "
      style={{ backgroundImage: `url(${img})` }}
    />
    <div className="relative py-8 px-6">
      <h5 className="text-2xl font-semibold mb-2 uppercase">{`I'm a${
        "aeiou".includes(role?.charAt(0)) ? "n" : ""
      } ${role}`}</h5>
      <p className="text-sm uppercase">
        {" "}
        {comingSoon ? "coming soon" : "or willing to be one!"}{" "}
      </p>
      <span
        // className={`absolute -top-2 right-3.5 -translate-y-5 w-14 aspect-square grid place-items-center rounded-full bg-denim group-hover/arrowCards:bg-white ring-4 ring-${
        //   role === "partner"
        //     ? "green"
        //     : role === "affiliate"
        //     ? "magenta"
        //     : "yellow"
        // } with-transition`}
        className={`absolute -top-2 right-3.5 -translate-y-5 w-14 aspect-square grid place-items-center rounded-full bg-denim group-hover/arrowCards:bg-white ring-4  with-transition`}
        style={{
          "--tw-ring-opacity": "1",
          "--tw-ring-color": color
        }}
      >
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25 1.5L8.75 9L1.25 16.5"
            className="stroke-white group-hover/arrowCards:stroke-slate-900 with-transition"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  </Link>
);

export default AppBusinessCard;
