import React from "react";

const AppMiniAudioPlayer = ({
  id,
  src,
  mainClassName,
  className,
  controlsClassName,
}) => {
  const volumeUp = (volume) => {
    if (volume <= 0.9) {
      document.getElementById(id).volume += 0.1;
    }
  };

  const volumeDown = (volume) => {
    if (volume > 0.1) {
      document.getElementById(id).volume -= 0.1;
    }
  };

  return (
    <div className={`w-fit ${mainClassName ? mainClassName : ""}`}>
      <audio id={id} src={src}></audio>
      <div className={className}>
        <button
          className={controlsClassName}
          onClick={() => document.getElementById(id).play()}
        >
          {`>`}
        </button>
        <button
          className={controlsClassName}
          onClick={() => document.getElementById(id).pause()}
        >
          ▪▪
        </button>
        <button
          className={controlsClassName}
          onClick={() => volumeDown(document.getElementById(id).volume)}
        >
          -
        </button>
        <button
          className={controlsClassName}
          onClick={() => volumeUp(document.getElementById(id).volume)}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default AppMiniAudioPlayer;
