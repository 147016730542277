import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { getTourDetails, listTours } from "../actions/tourActions";
import {
  TOUR_DETAILS_RESET,
  TOUR_LIST_RESET,
} from "../constants/tourConstants";
import { getUserDetails, listUsers } from "../actions/userActions";
import {
  USER_DETAILS_RESET,
  USER_LIST_RESET,
} from "../constants/userConstants";
import { ORDER_PLACE_RESET } from "../constants/orderConstants";
import { getStripePublishableKey } from "../actions/stripeActions";

import MainLayout from "../layouts/MainLayout";
import CheckoutForm from "../components/global/CheckoutForm";
import Logo from "../assets/logos/logo-green.svg";
import AppCarousel from "../components/global/AppCarousel";
import AppTourCard from "../components/global/AppTourCard";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import Rating from "../components/global/Rating";
import AppCustomModal from "../components/global/AppCustomModal";
import PartnerInfo from "../components/partners/PartnerInfo";
import TourViewer from "../components/tours/TourViewer";

const PrePurchaseTourScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tourDetails = useSelector((state) => state.tourDetails);
  const {
    loading: loadingTourDetails,
    error: errorTourDetails,
    tour,
  } = tourDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorTourDetails) {
      setTimeout(() => {
        dispatch({ type: TOUR_DETAILS_RESET });
      }, 5000);
    }
  }, [errorTourDetails, dispatch]);

  useEffect(() => {
    if (slug) {
      dispatch(getTourDetails(slug));
    }
  }, [slug, dispatch]);

  useEffect(() => {
    if (tour) {
      dispatch(getUserDetails("partner", tour.partner));
    }
  }, [tour, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    userInfo: partner,
  } = userDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorUserDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorUserDetails, dispatch]);

  useEffect(() => {
    if (partner) {
      dispatch(listTours());
      dispatch(listUsers("partner"));
    }
  }, [partner, dispatch]);

  const tourList = useSelector((state) => state.tourList);
  const { loading: loadingTours, error: errorTours, tours } = tourList;

  useEffect(() => {
    // resetting state due to errors
    if (errorTours) {
      setTimeout(() => {
        dispatch({ type: TOUR_LIST_RESET });
      }, 5000);
    }
  }, [errorTours, dispatch]);

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingUsers,
    error: errorUsers,
    users: partners,
  } = userList;

  useEffect(() => {
    // resetting state due to errors
    if (errorUsers) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorUsers, dispatch]);

  const [demoViewer, setDemoViewer] = useState(false);
  // const [demoVersion, setDemoVersion] = useState("en");

  const demoViewerHandler = (e) => {
    e.preventDefault();
    // setDemoVersion(e.target.id);
    setDemoViewer(!demoViewer);
  };

  const stripeKey = useSelector((state) => state.stripeKey);
  const { loading: loadingStripeKey, error: errorStripeKey, key } = stripeKey;

  const [isOpen, toggleOpen] = useState(false);

  const purchaseHandler = (e) => {
    e.preventDefault();
    if (loggedIn?.role === "user") {
      toggleOpen(!isOpen);
      if (!key) {
        dispatch(getStripePublishableKey());
      }
    } else {
      navigate("/login");
    }
  };

  const orderPlace = useSelector((state) => state.orderPlace);
  const { loading: loadingOrder, error: errorOrder, order } = orderPlace;

  useEffect(() => {
    // resetting state due to errors
    if (errorOrder) {
      setTimeout(() => {
        dispatch({ type: ORDER_PLACE_RESET });
      }, 5000);
    }
  }, [errorOrder, dispatch]);

  useEffect(() => {
    // resetting state after success
    if (order && loggedIn) {
      setTimeout(() => {
        toggleOpen(!isOpen);
        dispatch({ type: ORDER_PLACE_RESET });
        navigate(`/user/profile/${loggedIn.slug}/my-orders/${order._id}`);
      }, 5000);
    }

    // eslint-disable-next-line
  }, [order, loggedIn, dispatch, navigate]);

  return demoViewer ? (
    <>
      {tour && (
        <>
          <TourViewer tour={tour} demoVersion={tour.urls.demoUrl} />
          <button
            className="uppercase bg-white hover:bg-gradient-primary hover:text-white items-center px-5 lg:px-6 py-3 rounded-full absolute bottom-[40px] left-[40px] font-semibold font-oswald gap-3 flex justify-center md:justify-start"
            onClick={demoViewerHandler}
          >
            <i className="iconify text-xl" data-icon="bxs:left-arrow" />
          </button>
        </>
      )}
    </>
  ) : (
    <MainLayout
      logo={Logo}
      navClass="text-white"
      textHover="text-green"
      styleBtn="hover:text-white hover:border-white"
    >
      {loadingTours && <Loader />}
      {loadingUsers && <Loader />}
      {loadingOrder && <Loader />}
      {loadingStripeKey && <Loader />}
      {errorTourDetails && <Message error body={errorTourDetails} />}
      {errorUserDetails && <Message error body={errorUserDetails} />}
      {errorTours && <Message error body={errorTours} />}
      {errorUsers && <Message error body={errorUsers} />}
      {errorOrder && <Message error body={errorOrder} />}
      {errorStripeKey && <Message error body={errorStripeKey} />}
      {order && (
        <Message info body={`Order (${order._id}) placed successfully!`} />
      )}
      <div className="min-h-[100vh]">
        {loadingTourDetails ? (
          <Loader />
        ) : loadingUserDetails ? (
          <Loader />
        ) : (
          !errorTourDetails &&
          !errorUserDetails && (
            <>
              {tour && partner && (
                <>
                  <>
                    {!order && key && isOpen && (
                      <AppCustomModal
                        isOpen={isOpen}
                        onClose={() => {
                          toggleOpen(!isOpen);
                        }}
                        styleOptions="bg-denim"
                      >
                        <div className="pt-5 lg:px-28">
                          <Elements stripe={loadStripe(key)}>
                            <CheckoutForm
                              tourSlug={tour.slug}
                              partnerSlug={tour.partner}
                              tourPrice={tour.price}
                              tour={tour}
                            />
                          </Elements>
                        </div>
                      </AppCustomModal>
                    )}
                    <div
                      className="app-hero gradient-y"
                      style={{
                        "--bg-image": `url(${tour.cover})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                      }}
                    >
                      <div>
                        <span className="md:hidden absolute top-5 left-4">
                          <i
                            className="iconify text-white text-2xl"
                            data-icon="material-symbols:arrow-back-ios-new-rounded"
                          />
                        </span>
                        <div className="container text-white font-semibold pt-80 lg:pt-[32rem]">
                          <div className="md:-translate-y-28 drop-shadow-md">
                            <div className="flex gap-1 flex-wrap">
                              {tour.tags?.map((tag, index) => (
                                <p
                                  key={index}
                                  className="text-xs border-2 rounded-full px-4 py-1"
                                >
                                  {tag}
                                </p>
                              ))}
                            </div>
                            <h1 className="text-2xl md:text-3xl mt-7 uppercase">
                              {tour.title}
                            </h1>
                            {tour.subtitle?.length > 0 && (
                              <p className="text-lg font-oswald mt-1 capitalize">
                                {tour.subtitle}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-denim">
                      <div className="md:-translate-y-28">
                        <div className="container flex flex-wrap gap-3 pt-5 md:pt-6 font-semibold font-oswald">
                          <button
                            className="uppercase bg-magenta hover:bg-white text-white hover:text-denim flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full with-transition"
                            onClick={demoViewerHandler}
                          >
                            <i
                              className="iconify text-3xl"
                              data-icon="material-symbols:play-arrow-rounded"
                              id="en"
                            />
                            <span>Launch demo</span>
                          </button>
                          {loggedIn?.role === "admin" ? (
                            <Link
                              to={`/tour/${tour.slug}/edit`}
                              className="uppercase bg-green hover:bg-denim text-white flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full with-transition"
                            >
                              {/* <i
                                className="iconify text-3xl"
                                data-icon="material-symbols:play-arrow-rounded"
                                id="en"
                              /> */}
                              <span>Edit Tour</span>
                            </Link>
                          ) : null}

                          {/* <AppDropdown
                            activator={
                              <button className="uppercase bg-white hover:bg-gradient-primary hover:text-white flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full">
                                <i
                                  className="iconify text-3xl"
                                  data-icon="material-symbols:play-arrow-rounded"
                                  id="en"
                                />
                                <span>Launch demo</span>
                              </button>
                            }
                            contentClass="mt-2"
                          >
                            <ul className="w-40">
                              <li className="hover:bg-yellow hover:rounded-t-2xl">
                                <button onClick={demoViewerHandler} id="en">
                                  English
                                </button>
                              </li>
                              <li className="hover:bg-yellow hover:rounded-b-2xl">
                                <button onClick={demoViewerHandler} id="fr">
                                  Français
                                </button>
                              </li>
                            </ul>
                          </AppDropdown> */}
                          {loggedIn?.role !== "admin" &&
                          loggedIn?.role !== "partner" ? (
                            <button
                              className="uppercase with-transition bg-magenta hover:bg-transparent border-2 border-magenta hover:border-white text-white px-9 py-3 rounded-full"
                              onClick={purchaseHandler}
                            >
                              {loggedIn?.role === "user"
                                ? "Purchase"
                                : "Login to purchase"}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="container mt-4 md:mt-6 flex items-center gap-5 font-oswald text-2xl">
                          <p className="text-white text-2xl md:text-3xl">
                            ${tour.price}
                            {tour.price.toString().split(".").length === 1
                              ? ".00"
                              : ""}
                            {tour.price.toString().split(".")[1]?.length === 1
                              ? "0"
                              : ""}
                          </p>
                          {tour.reviews?.length > 0 && (
                            <Rating
                              rating={
                                tour.reviews?.reduce(
                                  (acc, review) => acc + review.rating,
                                  0,
                                ) / tour.reviews?.length
                              }
                              numReviews={tour.reviwes?.length}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" bg-denim">
                      <div className="container flex flex-col md:flex-row gap-5 md:gap-32 text-white py-12 lg:pb-20 lg:pt-0 lg:-mt-9">
                        <div className="text-sm md:order-2">
                          <p>
                            Partner:
                            <span className="font-bold"> {partner.title}</span>
                          </p>
                          <p>
                            Type:
                            <span className="font-bold"> {tour.type} Tour</span>
                          </p>
                          <p>
                            Location:
                            <span className="font-bold">
                              {" "}
                              {tour.location?.city}, {tour.location?.country}
                            </span>
                          </p>
                          {tour.guide?.length > 0 && (
                            <p>
                              Guide:
                              <span className="font-bold capitalize">
                                {" "}
                                {tour.guide}
                              </span>
                            </p>
                          )}
                          <p>
                            Duration:
                            <span className="font-bold"> 48:00 hrs</span>
                          </p>
                        </div>
                        <p className="md:w-3/5">{tour.description}</p>
                      </div>
                    </div>
                  </>
                  <PartnerInfo partner={partner} />
                  <div className="container mt-6">
                    <h2 className="pr-5 mt-24 md:mt-12 text-3xl uppercase font-semibold text-green ">
                      More From {partner.title}
                    </h2>
                    {tours && (
                      <AppCarousel withArrows>
                        {tours
                          .filter((tour) => tour.partner === partner.slug)
                          .map((tour, index) => (
                            <div key={index}>
                              <AppTourCard
                                key={tour.title}
                                name={tour.title}
                                link={`/tour/${tour.slug}/demo`}
                                img={tour.cover}
                                partnerLogo={partner.avatar}
                                className="h-52 w-64 border-4 border-transparent hover:border-green"
                              />
                            </div>
                          ))}
                      </AppCarousel>
                    )}
                  </div>
                  <aside className="container mt-24 lg:mt-12 lg:mb-28">
                    <div className="grid grid-cols-1 md:grid-cols-3">
                      <div className="md:order-1 md:py-8 md:px-4 md:bg-grey md:border-radius h-fit">
                        <p className="text-lg font-bold text-center mb-8 w-3/4 mx-auto">
                          Other Experiences You May Be Interested In
                        </p>
                        <div>
                          {tours && partners && (
                            <div className="grid grid-cols-1 gap-3 -z-10 ">
                              {tours
                                .filter((tour) => tour.partner !== partner.slug)
                                .map((exp, expIndex) => (
                                  <AppTourCard
                                    key={expIndex}
                                    name={exp.title}
                                    link={`/tour/${exp.slug}/demo`}
                                    img={exp.cover}
                                    partnerLogo={
                                      partners.find(
                                        (ptr) => ptr.slug === exp.partner,
                                      )?.avatar
                                    }
                                    className="h-32 border-4 border-transparent hover:border-green"
                                  />
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 grid gap-8 md:gap-y-8 mb-24 xl:mb-0 pr-[40px]">
                        {tour.reviews?.length ? (
                          <>
                            <h2 className="mt-28 md:mt-0 md:mb-4 text-3xl text-green uppercase font-semibold">
                              reviews
                            </h2>
                            {tour.reviews.map((review, index) => (
                              <div key={index} className="flex gap-5">
                                <div
                                  className="w-20 h-20 aspect-square rounded-full bg-cover bg-norepeat bg-center"
                                  style={{
                                    backgroundImage: `url(${review.avatar})`,
                                  }}
                                />
                                <div className="flex flex-col gap-1">
                                  <p className="font-bold text-lg">
                                    {review.name}
                                  </p>
                                  <Rating rating={review.rating} />
                                  <p>{review.comment}</p>
                                  <span className="font-semibold text-gray-border text-xs">
                                    {review.date
                                      .split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* <div className="flex gap-2 items-center font-semibold mt-11 mx-auto">
                          <p>See more</p>
                          <i
                            className="iconify text-base"
                            data-icon="ep:arrow-up-bold"
                            data-rotate="180deg"
                          />
                        </div> */}
                      </div>
                    </div>
                  </aside>
                </>
              )}
            </>
          )
        )}
      </div>
    </MainLayout>
  );
};

export default PrePurchaseTourScreen;
