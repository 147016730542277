import React, { useEffect, useState } from "react";

const Message = ({ body, info, error, caution, noClose, delay = 5 }) => {
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    setDisplay("flex");
    setTimeout(() => setDisplay("none"), delay * 1000);
    // eslint-disable-next-line
  }, [body]);

  const close = (e) => {
    e.preventDefault();
    setDisplay("none");
  };

  return (
    <div
      className="message"
      style={{
        backgroundColor: "transparent",
        display: `${display}`,
        zIndex: "500000",
        top: "0",
      }}
    >
      <div className="popup-overlay"></div>
      {body && (
        <div className="container message-body">
          {info && (
            <div className="mx-auto border-4 bg-white border-green border-radius flex items-center p-3 md:p-6 gap-2 md:gap-5 message-box">
              <span className="p-2 md:p-4 bg-green aspect-square rounded-full">
                <i className="iconify" data-icon="mingcute:check-fill" />
              </span>
              <div className="grow">
                <h2 className="text-green font-semibold text-2xl">SUCCESS!</h2>
                <p className="mt-3 text-denim">{body}</p>
              </div>
              {!noClose ? (
                <button onClick={close}>
                  <i
                    className="iconify font-semibold text-3xl text-denim"
                    data-icon="ph:x-bold"
                  />
                </button>
              ) : (
                <></>
              )}
            </div>
          )}
          {error && (
            <div className="mx-auto border-4 bg-white border-magenta border-radius flex items-center p-3 md:p-6 gap-2 md:gap-5 message-box">
              <span className="p-1 text-white bg-magenta aspect-square rounded-full">
                <i
                  className="iconify font-semibold text-5xl p-2"
                  data-icon="octicon:x-12"
                />
              </span>
              <div className="grow">
                <h2 className="text-magenta font-semibold text-2xl uppercase">
                  {body?.includes("<!DOCTYPE") || body?.includes("proxy")
                    ? "Not available!"
                    : "ERROR!"}
                </h2>
                <p className="mt-3 text-denim">
                  {body?.includes("<!DOCTYPE") || body?.includes("proxy")
                    ? "Something went wrong! Please contact the administrator."
                    : body}
                </p>
              </div>
              {!noClose ? (
                <button onClick={close}>
                  <i
                    className="iconify font-semibold text-3xl text-denim"
                    data-icon="ph:x-bold"
                  />
                </button>
              ) : (
                <></>
              )}
            </div>
          )}
          {caution && (
            <div className="mx-auto border-4 bg-white border-yellow border-radius flex items-center p-3 md:p-6 gap-2 md:gap-5 message-box">
              <span className="p-2 md:p-4 bg-yellow aspect-square rounded-full">
                <i className="iconify text-5xl" data-icon="uil:exclamation" />
              </span>
              <div className="grow">
                <h2 className="text-denim font-semibold text-2xl">CAUTION!</h2>
                <p className="mt-3 text-denim">{body}</p>
              </div>
              {!noClose ? (
                <button onClick={close}>
                  <i
                    className="iconify font-semibold text-3xl text-denim"
                    data-icon="ph:x-bold"
                  />
                </button>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Message;
