import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listUsers } from "../../../actions/userActions";
import { createGuide } from "../../../actions/guideActions";
import { USER_LIST_RESET } from "../../../constants/userConstants";
import { MEDIA_UPLOAD_RESET } from "../../../constants/mediaConstants";
import {
  TOUR_CREATE_FAIL,
  TOUR_CREATE_RESET,
} from "../../../constants/tourConstants";

import {
  generateSlug,
  requiredFieldsErrorMessage,
  requiredTagsErrorMessage,
  requiredVisualsErrorMessage,
  validateRequiredFields,
} from "../../../constants/helpers";
import locations from "../../../data/locations.json";
import { guideTypes } from "../../../constants/tours";
import { subCategories } from "../../../constants/categories";

import AppInput from "../../global/form/AppInput";
import AppSelect from "../../global/form/AppSelect";
import AppTextArea from "../../global/form/AppTextArea";
import AppChipsInput from "../../global/form/AppChipsInput";
import AppCustomInputFile from "../../global/form/AppCustomInputFile";
import Loader from "../../global/Loader";

export const CreateGuide = ({ guideObj, displayMenu }) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  const tourCreate = useSelector((state) => state.tourCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: created,
  } = tourCreate;

  useEffect(() => {
    dispatch(listUsers("partner"));
  }, [dispatch]);

  //

  const [partner, setPartner] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [guide, setGuide] = useState("");
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [tags, setTags] = useState([]);
  const [cover, setCover] = useState(guideObj.cover || "");
  const [fullUrl, setFullUrl] = useState("");
  const [demoUrl, setDemoUrl] = useState("");
  const [price, setPrice] = useState(0.0);
  const [note, setNote] = useState("");

  useEffect(() => {
    setCover(guideObj.cover);
  }, [guideObj.cover]);

  const partnerHandler = (e) => {
    setPartner(e.target.value);
  };

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const subtitleHandler = (e) => {
    setSubtitle(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const guideHandler = (e) => {
    setGuide(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const tagsHandler = (e) => {
    setTags(e);
  };

  const priceHandler = (e) => {
    setPrice(Number(e.target.value));
  };

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  //

  useEffect(() => {
    if (partners?.length > 0 && partner?.length > 0) {
      const found = partners?.find((ptr) => ptr.slug === partner);
      setCategory(found?.category);
      setTags(found?.tags);
    }
  }, [partners, partner]);

  // action handlers
  const resetFields = () => {
    setPartner("");
    setTitle("");
    setSubtitle("");
    setDescription("");
    setType("");
    setGuide("");
    setCategory("");
    setCity("");
    setCountry("");
    setTags([]);
    setCover("");
    setFullUrl("");
    setDemoUrl("");
    setPrice(0.0);
    setNote("");
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  useEffect(() => {
    if (uploaded) {
      setCover(uploaded);
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [uploaded, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (!cover?.length) {
        throw new Error(requiredVisualsErrorMessage);
      }

      if (
        !validateRequiredFields([
          partner,
          title,
          description,
          type,
          country,
          city,
          category,
          fullUrl,
          demoUrl,
        ]) ||
        !price
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!guideObj.map?.length) {
        throw new Error("Tour main map is required!");
      }

      if (!tags?.length) {
        throw new Error(requiredTagsErrorMessage);
      }

      const selfGuide = {
        partner,
        title,
        subtitle,
        slug: generateSlug(title),
        description,
        type,
        cover,
        location: { country, city },
        guide,
        content: guideObj,
        category,
        tags,
        price,
        reviews: [],
        numPurchased: 0,
        numReviews: 0,
        rating: 0,
        note,
      };

      dispatch(createGuide(selfGuide));
    } catch (error) {
      dispatch({
        type: TOUR_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

  //
  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: TOUR_CREATE_RESET });
      }, 5000);
    }
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        dispatch({ type: TOUR_CREATE_RESET });
        resetFields();
        window.location.reload();
      }, 5000);
    }
  }, [created, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  return (
    <section className="w-full min-h-[80vh] mb-16 relative border-2 border-denim text-denim rounded-lg flex flex-col gap-8 p-8">
      {uploading || loadingCreate || loadingPartners ? <Loader /> : <></>}
      <div>
        <p>All fields marked with an * are required!</p>
        {!errorPartners && partners?.length > 0 ? (
          <form
            className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5"
            onSubmit={submitHandler}
          >
            <AppSelect
              asRequired
              id="partner"
              items={partners.map((partner) => partner.slug)}
              itemText="name"
              itemValue="id"
              label="Partner"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              placeholder="-- Please select one --"
              value={partner}
              onChange={partnerHandler}
            />
            {partner?.length > 0 && (
              <>
                <AppCustomInputFile
                  asRequired
                  id="cover"
                  label="Cover"
                  placeholder="Add photo library"
                  labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                  onClick={displayMenu}
                  value={cover}
                />
                <AppInput
                  asRequired
                  id="title"
                  label="Title"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={title}
                  onChange={titleHandler}
                />
                <AppInput
                  id="subtitle"
                  label="Subtitle (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={subtitle}
                  onChange={subtitleHandler}
                />
                <AppTextArea
                  asRequired
                  id="description"
                  label="Description"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={description}
                  onChange={descriptionHandler}
                />
                <div className="grid lg:grid-cols-2 gap-5 lg:gap-8">
                  <AppSelect
                    asRequired
                    id="type"
                    items={guideTypes}
                    itemText="name"
                    itemValue="id"
                    label="Type"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={type}
                    onChange={typeHandler}
                  />
                  <AppInput
                    id="guide"
                    label="Narrator (optional)"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent"
                    value={guide}
                    onChange={guideHandler}
                  />
                  <AppSelect
                    asRequired
                    id="country"
                    items={locations.map((ele) => ele.country)}
                    itemText="name"
                    itemValue="id"
                    label="Country"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={country}
                    onChange={countryHandler}
                  />
                  <AppSelect
                    asRequired
                    id="city"
                    items={
                      locations.find((ele) => ele.country === country)?.city
                    }
                    itemText="name"
                    itemValue="id"
                    label="City"
                    placeholder="-- Please select a country first --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={city}
                    onChange={cityHandler}
                  />
                </div>
                <AppChipsInput
                  items={subCategories}
                  asRequired
                  id="tags"
                  label="Tags"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  value={tags}
                  onChange={(e) => tagsHandler(e)}
                />
                <AppInput
                  asRequired
                  id="price"
                  label="Price (CAD)"
                  type="number"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={price}
                  onChange={priceHandler}
                />
                <AppTextArea
                  id="note"
                  label="Note (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={note}
                  onChange={noteHandler}
                />
                <button
                  type="submit"
                  className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3"
                  onSubmit={submitHandler}
                >
                  Create New Self-Guide
                </button>
              </>
            )}
          </form>
        ) : (
          <p className="px-4 lg:px-24 pt-10 lg:pt-5">
            Please add some partners first in order to be able to create and
            link related self-guides to them.
          </p>
        )}
      </div>
    </section>
  );
};
