import axios from "axios";

import {
  TOUR_LIST_REQUEST,
  TOUR_LIST_SUCCESS,
  TOUR_LIST_FAIL,
  TOUR_CREATE_REQUEST,
  TOUR_CREATE_SUCCESS,
  TOUR_CREATE_FAIL,
  TOUR_VALIDATE_REQUEST,
  TOUR_VALIDATE_SUCCESS,
  TOUR_VALIDATE_FAIL,
  TOUR_DETAILS_REQUEST,
  TOUR_DETAILS_SUCCESS,
  TOUR_DETAILS_FAIL,
  TOUR_UPDATE_REQUEST,
  TOUR_UPDATE_SUCCESS,
  TOUR_UPDATE_FAIL,
  TOUR_DELETE_REQUEST,
  TOUR_DELETE_SUCCESS,
  TOUR_DELETE_FAIL,
} from "../constants/tourConstants";

import { MAIN_ROUTE } from "../constants/api";

// get all tours
export const listTours = () => async (dispatch) => {
  try {
    dispatch({
      type: TOUR_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/tours`);

    dispatch({
      type: TOUR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get all tours related to a partner
export const listToursByPartner = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: TOUR_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/tours/by-partner/${slug}`);

    dispatch({
      type: TOUR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// create a tour
export const createTour = (tour) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_CREATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`${MAIN_ROUTE}/tours`, { tour }, config);

    dispatch({
      type: TOUR_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get my tours
export const listMyTours = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // find({ order.user: req.user._id })
    const { data } = await axios.get(`${MAIN_ROUTE}/mytours`, config);

    dispatch({
      type: TOUR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get single tour details by slug
export const getTourDetails = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: TOUR_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/tours/${slug}`);

    dispatch({
      type: TOUR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// request tour validation from admin
export const requestTourValidate =
  (tour, stamp) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOUR_VALIDATE_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `${MAIN_ROUTE}/tours/requestValidation`,
        { tour, stamp },
        config,
      );

      dispatch({
        type: TOUR_VALIDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: TOUR_VALIDATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// update tour
export const updateTour = (slug, updates) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_UPDATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${MAIN_ROUTE}/tours/${slug}`,
      { updates },
      config,
    );

    dispatch({
      type: TOUR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// add tour review
export const addTourReview = (id, review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_UPDATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/tours/${id}/review`,
      { review },
      config,
    );

    dispatch({
      type: TOUR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOUR_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// delete tour
export const deleteTour = (slug) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOUR_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${MAIN_ROUTE}/tours/${slug}`, config);

    dispatch({
      type: TOUR_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
