import React, { useEffect, useState } from "react";

import useMap from "../../hooks/useMap";
import Message from "../global/Message";

const MapView = ({
  markers,
  className,
  defaultCenter = [47.938549, -120.802858],
  zoom = 8,
  onSelectMarker,
  single,
  options = {},
}) => {
  const [mapError, setMapError] = useState(null);

  const { createMap, addMarker } = useMap();

  const addMarkers = (markers) => {
    try {
      markers.forEach((marker) => {
        const mapMarker = addMarker(
          [
            Number(marker.gpsCoordinates.latitude),
            Number(marker.gpsCoordinates.longitude),
          ],
          {
            icon: { iconAnchor: [10, 30] },
            obj: marker,
          },
          marker.enabled,
          marker.role
        );
        if (!single) {
          mapMarker.on("click", () => {
            onSelectMarker?.(marker);
          });
        }
      });
    } catch (error) {
      // todo: fix the missing body props error
      setMapError(error.message);
    }
  };

  useEffect(() => {
    if (single) {
      try {
        createMap("map", {
          ...options,
          center: defaultCenter,
          zoom,
        });

        addMarkers([
          {
            gpsCoordinates: {
              latitude: defaultCenter[0],
              longitude: defaultCenter[1],
            },
          },
        ]);
      } catch (error) {
        setMapError(error.message);
      }
    }

    // eslint-disable-next-line
  }, [single]);

  useEffect(() => {
    if (markers) {
      try {
        createMap("map", {
          center: defaultCenter,
          zoom,
          ...options,
        });
        addMarkers(markers);
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [markers, options]);

  useEffect(() => {
    if (mapError) {
      setTimeout(() => {
        setMapError(null);
      }, 5000);
    }
  }, [mapError]);

  return (
    <>
      {mapError && <Message error body={mapError} />}
      <div id="map" className={className} />
    </>
  );
};

export default MapView;
