import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getGuideDetails, listGuides } from "../actions/guideActions";
import { TOUR_DETAILS_RESET } from "../constants/tourConstants";
import { GUIDE_LIST_RESET } from "../constants/guideConstants";

import { getUserDetails, listUsers } from "../actions/userActions";
import {
  USER_DETAILS_RESET,
  USER_LIST_RESET,
} from "../constants/userConstants";
import { requestGuideAccess } from "../actions/guideActions";
import {
  GUIDE_ACCESS_FAIL,
  GUIDE_ACCESS_RESET,
} from "../constants/guideConstants";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-green.svg";
import AppCarousel from "../components/global/AppCarousel";
import AppTourCard from "../components/global/AppTourCard";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import Rating from "../components/global/Rating";
import PartnerInfo from "../components/partners/PartnerInfo";
import CodePopup from "../components/guide/CodePopup";
import AppCustomModal from "../components/global/AppCustomModal";
import GoBack from "../components/global/GoBack";
import { useBreakpoints } from "../hooks/useBreakpoints";
//

const PrePurchaseGuideScreen = () => {
  const { xl } = useBreakpoints();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const { slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (slug) {
      dispatch(getGuideDetails(slug));
    }
  }, [slug, dispatch]);

  const tourDetails = useSelector((state) => state.tourDetails);
  const {
    loading: loadingTourDetails,
    error: errorTourDetails,
    tour,
  } = tourDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorTourDetails) {
      setTimeout(() => {
        dispatch({ type: TOUR_DETAILS_RESET });
      }, 5000);
    }
  }, [errorTourDetails, dispatch]);

  useEffect(() => {
    if (tour) {
      dispatch(getUserDetails("partner", tour.partner));
    }
  }, [tour, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    userInfo: partner,
  } = userDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorUserDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorUserDetails, dispatch]);

  useEffect(() => {
    if (partner) {
      dispatch(listGuides());
      dispatch(listUsers("partner"));
    }
  }, [partner, dispatch]);

  const guideList = useSelector((state) => state.guideList);
  const { loading: loadingGuides, error: errorGuides, guides } = guideList;

  useEffect(() => {
    // resetting state due to errors
    if (errorGuides) {
      setTimeout(() => {
        dispatch({ type: GUIDE_LIST_RESET });
      }, 5000);
    }
  }, [errorGuides, dispatch]);

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingUsers,
    error: errorUsers,
    users: partners,
  } = userList;

  useEffect(() => {
    // resetting state due to errors
    if (errorUsers) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorUsers, dispatch]);

  // access request
  const requestAccessHandler = (e) => {
    e.preventDefault();

    try {
      if (loggedIn?._id === partner?._id || loggedIn?.role === "affiliate") {
        if (tour && partner) {
          const accessRequest = {
            requestedBy: {
              id: loggedIn?._id,
              role: loggedIn?.role,
              title: loggedIn?.name,
              slug: loggedIn?.slug,
              email: loggedIn?.email,
            },
            guide: {
              id: tour?._id,
              title: tour?.title,
              slug: tour?.slug,
              price: tour?.price,
            },
            partner: {
              id: partner?._id,
              title: partner?.title,
              slug: partner?.slug,
              avatar: partner?.avatar,
            },
          };

          dispatch(requestGuideAccess(accessRequest));
        } else {
          throw new Error("Something went wrong!");
        }
      } else {
        throw new Error(
          "Only guide hosts or affiliates are authorized for this action!"
        );
      }
    } catch (error) {
      dispatch({ type: GUIDE_ACCESS_FAIL, payload: error.message });
    }
  };

  const guideAccessRequest = useSelector((state) => state.guideAccessRequest);
  const {
    loading: loadingAccess,
    error: errorAccess,
    access,
  } = guideAccessRequest;

  useEffect(() => {
    // resetting state due to errors
    if (errorAccess) {
      setTimeout(() => {
        dispatch({ type: GUIDE_ACCESS_RESET });
      }, 5000);
    }
  }, [errorAccess, dispatch]);

  return (
    <>
      {access && (
        <AppCustomModal
          isOpen={true}
          onClose={() => {
            window.location.reload();
          }}
          styleOptions="bg-denim"
        >
          <div className="lg:px-28 flex justify-center items-center">
            <CodePopup access={access} />
          </div>
        </AppCustomModal>
      )}
      <MainLayout
        logo={Logo}
        navClass="text-white"
        textHover="text-green"
        styleBtn="hover:text-white hover:border-white"
      >
        {loadingGuides && <Loader />}
        {loadingUsers && <Loader />}
        {loadingAccess && <Loader />}
        {errorTourDetails && <Message error body={errorTourDetails} />}
        {errorUserDetails && <Message error body={errorUserDetails} />}
        {errorGuides && <Message error body={errorGuides} />}
        {errorUsers && <Message error body={errorUsers} />}
        {errorAccess && <Message error body={errorAccess} />}

        <div className="min-h-[100vh]">
          {loadingTourDetails ? (
            <Loader />
          ) : loadingUserDetails ? (
            <Loader />
          ) : (
            !errorTourDetails &&
            !errorUserDetails && (
              <>
                {tour && partner && (
                  <>
                    <div
                      className="app-hero gradient-y"
                      style={{
                        "--bg-image": `url(${tour.cover})`,
                      }}
                    >
                      <div>
                        {!xl ? <GoBack /> : <></>}
                        <div className="container text-white font-semibold pt-80 lg:pt-[32rem]">
                          <div className="md:-translate-y-28 drop-shadow-md">
                            <div className="flex gap-1 flex-wrap">
                              {tour.tags?.map((tag, index) => (
                                <p
                                  key={index}
                                  className="text-xs border-2 rounded-full px-4 py-1"
                                >
                                  {tag}
                                </p>
                              ))}
                            </div>
                            <h1 className="text-2xl md:text-3xl mt-7 uppercase">
                              {tour.title}
                            </h1>
                            {tour.subtitle?.length > 0 && (
                              <p className="text-lg font-oswald mt-1 capitalize">
                                {tour.subtitle}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-denim">
                      <div className="md:-translate-y-28">
                        <div className="container flex flex-wrap gap-3 pt-5 md:pt-6 font-semibold font-oswald">
                          {loggedIn?.role === "admin" ? (
                            <Link
                              to={`/test/guide/${tour.slug}`}
                              className="uppercase bg-white hover:bg-gradient-primary hover:text-white hover:bg-magenta flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full with-transition"
                            >
                              <i
                                className="iconify text-3xl"
                                data-icon="material-symbols:play-arrow-rounded"
                                id="en"
                              />
                              <span>Launch guide</span>
                            </Link>
                          ) : (
                            <></>
                          )}
                          {loggedIn?._id === partner?._id ||
                          loggedIn?.role === "affiliate" ? (
                            <>
                              <button
                                className="uppercase bg-white hover:bg-gradient-primary hover:text-white hover:bg-magenta flex items-center gap-2 px-5 lg:px-6 py-3 rounded-full with-transition"
                                onClick={requestAccessHandler}
                              >
                                <i
                                  className="iconify"
                                  data-icon="ic:baseline-access-time"
                                  id="en"
                                />
                                <span>generate access session</span>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          {!loggedIn && !xl ? (
                            <Link
                              to="/redeem"
                              className="uppercase text-white hover:text-denim bg-magenta hover:bg-white flex items-center gap-2 px-5 px-8 py-3 rounded-full with-transition"
                            >
                              <i
                                className="iconify text-3xl"
                                data-icon="material-symbols:redeem"
                                id="en"
                              />
                              <span>Redeem Code</span>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="container mt-4 md:mt-6 flex items-center gap-5 font-oswald text-2xl">
                          <p className="text-white text-2xl md:text-3xl">
                            ${tour.price}
                            {tour.price.toString().split(".").length === 1
                              ? ".00"
                              : ""}
                            {tour.price.toString().split(".")[1]?.length === 1
                              ? "0"
                              : ""}
                          </p>
                          {tour.reviews?.length > 0 && (
                            <Rating
                              rating={
                                tour.reviews?.reduce(
                                  (acc, review) => acc + review.rating,
                                  0
                                ) / tour.reviews?.length
                              }
                              numReviews={tour.reviwes?.length}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" bg-denim">
                      <div className="container flex flex-col md:flex-row gap-5 md:gap-32 text-white py-12 lg:pb-20 lg:pt-0 lg:-mt-9">
                        <div className="text-sm md:order-2">
                          <p>
                            Partner:
                            <span className="font-bold"> {partner.title}</span>
                          </p>
                          <p>
                            Type:
                            <span className="font-bold"> {tour.type} Tour</span>
                          </p>
                          <p>
                            Location:
                            <span className="font-bold">
                              {" "}
                              {tour.location?.city}, {tour.location?.country}
                            </span>
                          </p>
                          {tour.guide?.length > 0 && (
                            <p>
                              Guide:
                              <span className="font-bold capitalize">
                                {" "}
                                {tour.guide}
                              </span>
                            </p>
                          )}
                          <p>
                            Duration:
                            <span className="font-bold"> 2 hrs</span>
                          </p>
                        </div>
                        <p className="md:w-3/5">{tour.description}</p>
                      </div>
                    </div>{" "}
                    <PartnerInfo partner={partner} />
                    <div className="container mt-6">
                      <h2 className="pr-5 mt-24 md:mt-12 uppercase text-3xl uppercase font-semibold text-green ">
                        More From {partner.title}
                      </h2>
                      {guides && (
                        <AppCarousel withArrows>
                          {guides
                            .filter((tour) => tour.partner === partner.slug)
                            .map((tour, index) => (
                              <div key={index}>
                                <AppTourCard
                                  key={tour.title}
                                  name={tour.title}
                                  link={`/tour/${tour.slug}/demo`}
                                  img={tour.cover}
                                  partnerLogo={partner.avatar}
                                  className="h-52 w-64 border-4 border-transparent hover:border-green"
                                />
                              </div>
                            ))}
                        </AppCarousel>
                      )}
                    </div>
                    <aside className="container mt-24 lg:mt-12 lg:mb-28">
                      <div className="grid grid-cols-1 md:grid-cols-3">
                        <div className="md:order-1 md:py-8 md:px-4 md:bg-grey md:border-radius h-fit">
                          <p className="text-lg font-bold text-center mb-8 w-3/4 mx-auto">
                            Other Experiences You May Be Interested In
                          </p>
                          <div>
                            {guides && partners ? (
                              <div className="grid grid-cols-1 gap-3 -z-10 ">
                                {guides
                                  .filter(
                                    (guide) => guide.partner !== partner.slug
                                  )
                                  .map((exp, expIndex) => (
                                    <AppTourCard
                                      key={expIndex}
                                      name={exp.title}
                                      link={`/tour/${exp.slug}/demo`}
                                      img={exp.cover}
                                      partnerLogo={
                                        partners.find(
                                          (ptr) => ptr.slug === exp.partner
                                        )?.avatar
                                      }
                                      className="h-32 border-4 border-transparent hover:border-green"
                                    />
                                  ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="col-span-2 grid gap-8 md:gap-y-8 mb-24 xl:mb-0 pr-[40px]">
                          {tour.reviews?.length ? (
                            <>
                              <h2 className="mt-28 md:mt-0 md:mb-4 text-3xl text-green uppercase font-semibold">
                                reviews
                              </h2>
                              {tour.reviews.map((review, index) => (
                                <div key={index} className="flex gap-5">
                                  <div
                                    className="w-20 h-20 aspect-square rounded-full bg-cover bg-norepeat bg-center"
                                    style={{
                                      backgroundImage: `url(${review.avatar})`,
                                    }}
                                  />
                                  <div className="flex flex-col gap-1">
                                    <p className="font-bold text-lg">
                                      {review.name}
                                    </p>
                                    <Rating rating={review.rating} />
                                    <p>{review.comment}</p>
                                    <span className="font-semibold text-gray-border text-xs">
                                      {review.date
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </aside>
                  </>
                )}
              </>
            )
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default PrePurchaseGuideScreen;
