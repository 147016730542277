import React, { useEffect, useState } from "react";
import PartnerShowcase from "./PartnerShowcase";
import AffiliateShowcase from "./AffiliateShowcase";

const CandidateShowcase = ({ candidate, activateHandler, deleteHandler }) => {
  const [role, setRole] = useState("");

  useEffect(() => {
    if (candidate) {
      setRole(candidate.role);
    }
  }, [candidate]);

  return candidate ? (
    <>
      {role === "partner" ? (
        <PartnerShowcase
          partner={candidate}
          activateHandler={activateHandler}
          deleteHandler={deleteHandler}
        />
      ) : role === "affiliate" ? (
        <AffiliateShowcase
          affiliate={candidate}
          activateHandler={activateHandler}
          deleteHandler={deleteHandler}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default CandidateShowcase;
