export const provincesObj = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const provinces = Object.values(provincesObj);
export const correspondingProvinces = Object.keys(provincesObj);

export const regions = [
  {
    province: provinces[0],
    list: [
      "Athabasca–Grande Prairie–Peace River",
      "Banff–Jasper–Rocky Mountain House",
      "Calgary",
      "Camrose–Drumheller",
      "Edmonton",
      "Lethbridge–Medicine Hat",
      "Red Deer",
      "Wood Buffalo–Cold Lake",
    ],
  },
  {
    province: provinces[1],
    list: [
      "Cariboo",
      "Kootenay",
      "Lower Mainland–Southwest",
      "Nechako",
      "North Coast",
      "Northeast",
      "Thompson–Okanagan",
      "Vancouver Island–Coast",
    ],
  },
  {
    province: provinces[2],
    list: [
      "Interlake",
      "North",
      "North Central",
      "Parklands",
      "Southeast",
      "South Central",
      "Southwest",
      "Winnipeg",
    ],
  },
  {
    province: provinces[3],
    list: [
      "aint John–St. Stephen",
      "Campbellton–Miramichi",
      "Edmundston–Woodstock",
      "Fredericton–Oromocto",
      "Moncton–Richibucto",
    ],
  },
  {
    province: provinces[4],
    list: [
      "Avalon Peninsula",
      "Notre Dame–Central Bonavista Bay",
      "South Coast–Burin Peninsula",
      "West Coast–Northern Peninsula–Labrador",
    ],
  },
  {
    province: provinces[5],
    list: ["Northwest Territories"],
  },
  {
    province: provinces[6],
    list: [
      "Annapolis Valley",
      "Cape Breton",
      "Halifax",
      "North Shore",
      "Southern",
    ],
  },
  {
    province: provinces[7],
    list: ["Nunavut"],
  },
  {
    province: provinces[8],
    list: [
      "Ottawa",
      "Kingston–Pembroke",
      "Muskoka–Kawarthas",
      "Toronto",
      "Kitchener–Waterloo–Barrie",
      "Hamilton–Niagara Peninsula",
      "London",
      "Windsor–Sarnia",
      "Stratford–Bruce Peninsula",
      "Northeast",
      "Northwest",
    ],
  },
  {
    province: provinces[9],
    list: ["Prince Edward Island"],
  },
  {
    province: provinces[10],
    list: [
      "Gaspésie–Îles-de-la-Madeleine",
      "Bas-Saint-Laurent",
      "Capitale-Nationale",
      "Chaudière-Appalaches",
      "Estrie",
      "Centre-du-Québec",
      "Montérégie",
      "Montréal",
      "Laval",
      "Lanaudière",
      "Laurentides",
      "Outaouais",
      "Abitibi-Témiscamingue",
      "Mauricie",
      "Saguenay–Lac-Saint-Jean",
      "Côte-Nord",
      "Nord-du-Québec",
    ],
  },
  {
    province: provinces[11],
    list: [
      "Regina–Moose Mountain",
      "Swift Current–Moose Jaw",
      "Saskatoon–Biggar",
      "Yorkton–Melville",
      "Prince Albert",
      "Northern",
    ],
  },
  {
    province: provinces[12],
    list: ["Yukon"],
  },
];
