import React from "react";
import { Link } from "react-router-dom";

const BecomePartner = ({ role }) => {
  return (
    <div className="lg:grid lg:grid-cols-2 gap-20 pb-2 lg:pb-24">
      <div className="col-span-2 lg:col-span-1">
        <h1 className="text-green font-semibold uppercase text-3xl text-start mt-12 lg:mt-0">
          {role.heading}
        </h1>
        <div className="flex flex-col gap-y-5 pb-9 xl:pb-0">
          <p className="mt-8 font-semibold">{role.brief}</p>
          <p>{role.body}</p>
        </div>
        <div className="my-4 w-fit h-fit border-2 border-transparent hover:border-denim rounded-full with-transition">
          <Link
            to="/why-vrv"
            className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-fit flex justify-center gap-5 with-transition"
          >
            <span className="block w-fit px-8">
              Why partnering with The VR Voyage
            </span>
          </Link>
        </div>
      </div>
      <div
        className="bg-cover bg-no-repeat bg-center border-radius h-80 w-full xl:h-full mt-12 lg:mt-0"
        style={{
          backgroundImage: `url(${require("../../assets/img/come-host-with-us/door.jpg")})`,
        }}
      />
    </div>
  );
};

export default BecomePartner;
