import React, { forwardRef } from "react";

const AppSelect = (
  {
    id,
    label,
    placeholder,
    value,
    className: customizedStyle,
    selectClassName,
    itemText = "name",
    itemValue = "id",
    labelClass,
    error,
    items = [],
    asRequired,
    onChange,
    ...rest
  },
  ref,
) => {
  // const parsedItems = items.map((item) => ({
  //   text: item[itemText] || item,
  //   value: item[itemValue] || item,
  // }));

  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <span
        className={`relative flex with-transition ${labelClass} ${
          asRequired && "required-help"
        } ${error && "text-red-500"}`}
      >
        {label}
        <span className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}>
          *
        </span>
      </span>
      <div
        className={`py-0.5 border-gray-border ${
          customizedStyle.includes("rounded-full")
            ? "rounded-full"
            : "rounded-lg"
        } ${customizedStyle}`}
      >
        <select
          value={value}
          ref={ref}
          id={id}
          className={`
          select select-sm font-normal w-full focus:outline-white caret-current with-transition
          ${selectClassName} ${error && "border-red-500"}
        `}
          onChange={(event) => onChange?.(event)}
          {...rest}
        >
          {placeholder && <option value="">{placeholder}</option>}

          {items?.length &&
            items.map((item, index) => (
              <option key={index} value={item} className="text-denim">
                {item}
              </option>
            ))}
        </select>
      </div>
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};

export default forwardRef(AppSelect);
