import React, { useEffect, useRef, useState } from "react";

const AppCarousel = ({
  children,
  isInfinite,
  speed,
  withArrows,
  className,
}) => {
  const items = React.Children.map(children, (child) => child);
  const [duplications, setDuplications] = useState(1);
  const carouselRef = useRef(null);
  const bandRef = useRef(null);
  const [showArrows, setShowArrows] = useState({ next: true, prev: true });

  useEffect(() => {
    handleShowArrows();
    if (isInfinite) {
      calculateItemsQuantity();
    }
  }, [isInfinite]);

  function calculateItemsQuantity() {
    const duplicationQuantity = Math.ceil(
      carouselRef.current.clientWidth / bandRef.current.clientWidth
    );
    setDuplications(duplicationQuantity * 2);
  }

  function next() {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth,
        behavior: "smooth",
      });
      handleShowArrows();
    }
  }

  function prev() {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth,
        behavior: "smooth",
      });
      handleShowArrows();
    }
  }

  function handleShowArrows() {
    setTimeout(() => {
      const { scrollWidth, scrollLeft, clientWidth } =
        carouselRef?.current || {};
      setShowArrows({
        prev: scrollLeft > 0,
        next: scrollWidth - scrollLeft > clientWidth,
      });
    }, 1000);
  }

  return (
    <div
      className={`overflow-hidden relative w-full group/carousel ${className}`}
    >
      {withArrows && (
        <div className="w-0 h-0">
          {showArrows.prev && (
            <div className="absolute z-10 top-0 left-0 -translate-x-full group-hover/carousel:translate-x-0 grid place-items-center w-16 h-full bg-arrow-carousel with-transition">
              <button onClick={prev} className="p-3 text-white text-xl">
                <i
                  className="iconify"
                  data-icon="material-symbols:arrow-back-ios-rounded"
                />
              </button>
            </div>
          )}
          {showArrows.next && (
            <div className="absolute z-10 top-0 right-0 translate-x-full group-hover/carousel:translate-x-0 grid place-items-center w-16 h-full bg-arrow-carousel rotate-180 with-transition">
              <button onClick={next} className="p-3 text-white text-xl">
                <i
                  className="iconify"
                  data-icon="material-symbols:arrow-back-ios-rounded"
                />
              </button>
            </div>
          )}
        </div>
      )}
      <div ref={carouselRef} className="app-carousel overflow-auto w-full">
        <div
          ref={bandRef}
          className={`app-carousel__band flex gap-4 w-fit mt-2 ${
            isInfinite ? "move" : ""
          }`}
          style={{
            animationDuration: speed,
          }}
        >
          {Array(duplications)
            .fill(items)
            .map((itemsGroup) =>
              itemsGroup.map((items, index) => (
                <div key={index} className="flex-grow-0 flex-shrink-0">
                  {items}
                </div>
              ))
            )}
        </div>
      </div>
    </div>
  );
};

export default AppCarousel;
