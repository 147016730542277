import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../actions/userActions";

function NavigationDesktop({
  navClass = "",
  scrollNavClass = "text-white bg-denim",
  logo,
  textHover = "text-white",
  textHoverWithScroll = "text-green",
  styleBtn,
  styleBtnWithScroll = "hover:border-white hover:text-white",
}) {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountHandler = (e) => {
    e.preventDefault();

    if (loggedIn) {
      dispatch(logout());
      navigate("/");
      return;
    }

    navigate("/login");
  };

  //

  const { md } = useBreakpoints();
  const [hasScroll, setHasScroll] = useState(false);
  useEffect(() => {
    const pageContainer = document.querySelector(".drawer-content");
    function pageScroll() {
      setHasScroll(pageContainer.scrollTop >= 10);
    }

    if (md) {
      pageContainer.addEventListener("scroll", pageScroll);
    }
    return () => {
      pageContainer.removeEventListener("scroll", pageScroll);
    };
  }, [md]);

  return (
    <nav
      className={`font-semibold grid navbar with-transition z-50 ${
        hasScroll ? scrollNavClass : navClass
      }`}
    >
      <ul
        className={`container w-full flex justify-between uppercase font-semibold app-nav-desktop-links`}
      >
        <NavLink to="/" className="mt-4 md:mt-0">
          <img src={logo} alt="Logo" className="w-52 lg:pl-2" />
        </NavLink>
        <div className="hidden lg:flex items-center gap-4">
          <li>
            <div
              className={`dropdown hover:${
                hasScroll ? textHoverWithScroll : textHover
              }`}
            >
              <label
                id="about-group"
                tabIndex={0}
                htmlFor="division-desktop"
                className="cursor-pointer"
              >
                <span
                  className={`flex items-center gap-1 pl-4 py-1 hover:${
                    hasScroll ? textHoverWithScroll : textHover
                  } with-transition`}
                >
                  <h6>about</h6>
                  <span className="rotate-0 peer-checked:rotate-180">
                    <i
                      className="iconify text-3xl"
                      data-icon="tabler:chevron-down"
                    ></i>
                  </span>
                </span>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-compact dropdown-content shadow bg-white text-denim w-44 rounded-lg rounded-tl-none"
              >
                <li className="hover:bg-yellow py-2 with-transition">
                  <NavLink
                    to="/our-story"
                    className="flex gap-1 capitalize text-base"
                  >
                    Our Story
                  </NavLink>
                </li>
                <li className="hover:bg-yellow py-2 with-transition">
                  <NavLink
                    to="/our-network"
                    className="flex gap-1 capitalize text-base"
                  >
                    Our Network
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`hover:${hasScroll ? textHoverWithScroll : textHover}`}
          >
            <NavLink to="/map" className="px-4 py-1 grid place-items-center with-transition">
              <h6>Map</h6>
            </NavLink>
          </li>
          {/*  */}
          <li>
            <div
              className={`dropdown hover:${
                hasScroll ? textHoverWithScroll : textHover
              }`}
            >
              <label
                id="experiences-group"
                tabIndex={0}
                htmlFor="division-desktop"
                className="cursor-pointer"
              >
                <span
                  className={`flex items-center gap-1 pl-4 py-1 hover:${
                    hasScroll ? textHoverWithScroll : textHover
                  } with-transition`}
                >
                  <h6>experiences</h6>
                  <span className="rotate-0 peer-checked:rotate-180">
                    <i
                      className="iconify text-3xl"
                      data-icon="tabler:chevron-down"
                    ></i>
                  </span>
                </span>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-compact dropdown-content shadow bg-white text-denim w-44 rounded-lg rounded-tl-none"
              >
                <li className="hover:bg-yellow py-2 with-transition">
                  <NavLink
                    to="/catalogue/online"
                    className="flex gap-1 capitalize text-base"
                  >
                    Online
                  </NavLink>
                </li>
                <li className="hover:bg-yellow py-2 with-transition">
                  <NavLink
                    to="/catalogue/in-person"
                    className="flex gap-1 capitalize text-base"
                  >
                    In-Person
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          {/*  */}
          <li
            className={`hover:${
              hasScroll ? textHoverWithScroll : textHover
            } with-transition`}
          >
            <NavLink to="/contact" className="grid place-items-center">
              <h6 className="pr-4 py-1">Contact</h6>
            </NavLink>
          </li>
          {loggedIn ? (
            <li>
              <div className="flex justify-between">
                <div>
                  <div className="dropdown relative">
                    <label
                      id="about-group"
                      tabIndex={0}
                      htmlFor="division-desktop"
                      className="cursor-pointer"
                    >
                      <div className="avatar">
                        <div className="w-12 border-2 border-white rounded-full flex">
                          {loggedIn?.role === "admin" ? (
                            <img
                              src={require("../../assets/img/profile/admin.png")}
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                loggedIn?.avatar && loggedIn?.avatar.length > 0
                                  ? loggedIn?.avatar
                                  : require("../../assets/img/profile/profile.jpg")
                              }
                              alt="Avatar"
                            />
                          )}
                        </div>
                      </div>
                    </label>
                    <ul
                      tabIndex={0}
                      className="menu menu-compact dropdown-content shadow bg-white text-denim w-32 rounded-lg rounded-tl-none absolute right-0 with-transition"
                    >
                      {loggedIn?.role === "admin" ? (
                        <>
                          <li className="hover:bg-yellow py-2 with-transition">
                            <NavLink
                              to={"/admin/config"}
                              className="flex gap-1 capitalize text-base"
                            >
                              Dashboard
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="hover:bg-yellow py-2 with-transition">
                            <NavLink
                              to={`/${loggedIn?.role}/profile/${loggedIn?.slug}`}
                              className="flex gap-1 capitalize text-base"
                            >
                              My profile
                            </NavLink>
                          </li>
                          {loggedIn?.role === "user" ? (
                            <li className="hover:bg-yellow py-2 with-transition">
                              <NavLink
                                to={`/${loggedIn?.role}/profile/${loggedIn?.slug}/edit`}
                                className="flex gap-1 capitalize text-base"
                              >
                                Settings
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      <li className="hover:bg-yellow py-2 with-transition">
                        <button
                          className="flex gap-1 capitalize text-base"
                          onClick={accountHandler}
                        >
                          Log out
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          ) : (
            <>
              <li>
                <NavLink
                  to="/dimensioning-future"
                  className="grid place-items-center"
                >
                  <h6
                    className={`bg-green
                  px-8 py-3 rounded-full hover:bg-magenta hover:text-white ${
                    hasScroll ? styleBtnWithScroll : styleBtn
                  } with-transition`}
                  >
                    For Creators
                  </h6>
                </NavLink>
              </li>
              <li>
                <NavLink
                  type="submit"
                  className="grid place-items-center uppercase"
                >
                  <h6
                    className={`text-denim bg-yellow
                  px-8 py-3 rounded-full hover:bg-magenta hover:text-white ${
                    hasScroll ? styleBtnWithScroll : styleBtn
                  } with-transition`}
                    onClick={accountHandler}
                  >
                    For Voyagers
                  </h6>
                </NavLink>
              </li>
            </>
          )}
        </div>
      </ul>
    </nav>
  );
}

export default NavigationDesktop;
