import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listSessions } from "../../../actions/guideActions";
import { ACCESS_LIST_RESET } from "../../../constants/guideConstants";

// import { AppPagination } from "../../global/AppPagination";

import AdminHeaderLayout from "../components/AdminHeaderLayout";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import SessionCard from "../components/SessionCard";

const ManageSessions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listSessions());
  }, [dispatch]);

  const accessList = useSelector((state) => state.accessList);
  const {
    loading: loadingSessions,
    error: errorSessions,
    sessions,
  } = accessList;

  useEffect(() => {
    // resetting state due to errors
    if (errorSessions) {
      setTimeout(() => {
        dispatch({ type: ACCESS_LIST_RESET });
      }, 5000);
    }
  }, [errorSessions, dispatch]);

  //

  return (
    <section>
      <AdminHeaderLayout
        title="Manage sessions"
        // placeholder="Search Tours"
        // button={<CreateOrder />}
        // pagination={<AppPagination totalItems="500" itemsPerPage={50} />}
      />
      {loadingSessions ? (
        <Loader />
      ) : errorSessions ? (
        <Message error body={errorSessions} />
      ) : (
        <>
          {sessions?.length ? (
            <>
              <div className="flex justify-between mb-5"></div>
              <div className="grid gap-2 pb-8">
                {sessions
                  .sort((a, b) =>
                    Number(new Date(a.createdAt)) <
                    Number(new Date(b.createdAt))
                      ? -1
                      : Number(new Date(a.createdAt)) <
                        Number(new Date(b.createdAt))
                      ? 1
                      : 0,
                  )
                  .map((session, sessionIndex) =>
                    session ? (
                      <SessionCard key={sessionIndex} session={session} />
                    ) : (
                      <></>
                    ),
                  )}
              </div>
            </>
          ) : (
            <p>No orders found!</p>
          )}
        </>
      )}
    </section>
  );
};

export default ManageSessions;
