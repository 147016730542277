import React from "react";
import { Link } from "react-router-dom";

const LegalRevenueSharePolicy = () => {
  return (
    <main>
      <div className="grid gap-5">
        <h1 className="font-bold uppercase text-3xl">REVENUE SHARE POLICY</h1>
        <span className="text-xs font-bold">
          This Policy was last updated on 23 August 2023.
        </span>
      </div>
      <p className="py-12">
        There is no fee to become an official The VR Voyage publisher
        (“Publisher”), nor is there a fee to publish virtual tour products
        (“Experiences”). We believe in creating a sustainable model that
        benefits institutions and tour operators of all sizes. By creating a
        marketplace for Publishers to reach new audiences and develop new
        revenue generation streams, we hope to build long-lasting partnerships
        with each new Publisher.
      </p>
      <ol className="grid gap-10">
        <li className="grid gap-5">
          <p className="text-lg font-bold">1. REVENUE BREAKDOWN</p>
          <p>
            When an Experience is purchased, we calculate the gross amount
            (“Gross Amount”) as the total amount received for the purchase. From
            this, we subtract a 3% processing fee and any amounts paid to third
            parties in connection with promotional activities to calculate the
            net amount of the sale (“Net Amount”).
          </p>
          <p>
            For all sales, 70% of the Net Amount is distributed to you, while
            30% is kept as an admin fee to continue to operate and grow The VR
            Voyage platform. Regarding the creation of alternate language tours,
            a different revenue share is outlined in our{" "}
            <Link
              to="/legal/policies/foreign-language-policy"
              className="text-green underline font-bold"
            >
              Foreign Language Policy
            </Link>
            {"."}
          </p>
          <p>
            Forager Education makes all Publisher payments in Canadian Dollars
            (CAD) regardless of your local currency. Monthly Revenue Reports
            will show sales in both local currency and payment currency (CAD).
          </p>
        </li>
        <li className="grid gap-5">
          <p className="font-bold text-lg">2. PUBLISHER PROMOTION</p>
          <p>
            There is a 90% revenue share on sales made by Publishers where the
            customer accesses The VR Voyage marketplace through a unique
            Publisher URL. The remaining 10% is kept to maintain  The VR Voyage
            as an admin fee. The Publisher URL can be used in any type of
            digital media, and each Publisher receives one to track the
            engagement of their audience.
          </p>
          <p>
            There is the added opportunity to earn a 10% commission on the sale
            of third party Experiences made by Publisher URLs. This would happen
            when a customer comes from your site, but purchases a tour made by
            another Publisher.
          </p>
        </li>
        <li>
          <p className="font-bold text-lg">3. REPORTING</p>
          <p className="mt-5">
            Publishers will receive a monthly Revenue Report, but can request a
            report at any time. These reports will show Publishers the dates of
            each purchase, the location of customers, the source of the
            customer, and the total price paid.
          </p>
        </li>
        <li className="grid gap-5">
          <p className="text-lg font-bold">4. MODIFICATIONS</p>
          <p>
            On occasion, we may update this Policy to further define our
            promotional program. The VR Voyage reserves the right to modify or
            make changes to this policy at any time. When changes to the Policy
            are made, you will be notified by the email that is associated with
            your account. Changes will take effect on the day the Policy is
            posted.
          </p>
          <p>
            Continued use of The VR Voyage’s marketplace after changes are
            posted shall mean each Publisher’s acceptance of the modifications.
            The revised Policy shall supersede all previous editions of the
            Policy.
          </p>
        </li>
        <li>
          <p className="font-bold text-lg">5. HOW TO CONTACT US</p>
          <p className="mt-5">
            The best way to contact The VR Voyage is through your Account
            Manager. If you are a prospective publisher or have not been
            assigned a Account Manager, use{" "}
            <span className="text-green underline font-bold">
              partners@foragereducation.com
            </span>{" "}
            and an agent will be in touch. We are always looking for feedback on
            our services and would love to hear from you.
          </p>
        </li>
      </ol>
    </main>
  );
};

export default LegalRevenueSharePolicy;
