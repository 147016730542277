export const PARTNERS = {
  ROEDDE_HOUSE_MUSEUM: {
    title: "Roedde House Museum",
    slug: "roedde-house-museum",
    email: "info@roeddehouse.org",
    password: "qwe123QWE!@#",
    role: "partner",
    avatar: require("../assets/img/partners/roedde-mouse-museum/logo-maison-musee-roedde-house-museum-1893.png"),
    banner: require("../assets/img/partners/roedde-mouse-museum/roedde-house-museum.png"),
    about: [
      "The Roedde House Museum is a Victorian house museum in the West End neighbourhood of Vancouver, Canada. The house was built for the family of Vancouver’s first bookbinder, Gustav Roedde, in 1893. Gustav Roedde was born in 1860 in Grossbodungen, Thuringen, Germany, and trained as a bookbinder in Leipzig. He immigrated to the United States in 1881 and settled in Cleveland, Ohio, where he met and married Matilda Marie Cassebohm, who was from the island of Heligoland, Germany. The couple moved to San Francisco, California, then Victoria, British Columbia and finally to Vancouver where, in 1888, Gustav opened one of the City’s first book binderies. By 1893, the Roeddes could afford to have a new house built at 1415 Barclay Street.",
      "The family lived in this house until 1924, after which time it became a rooming and boarding house. Its design is attributed to architect Francis Rattenbury and has been designated as a Class A Heritage House, owned by the City of Vancouver. The Roedde House Preservation Society was established in 1984 to undertake the restoration and furnishing of the house to reflect family life in the late Victorian period. Work on the interior was painstaking, with faithful attention to detail gleaned from historical records, from consultation with surviving members of the Roedde family and from the process of stripping paint and wallpaper from century-old walls to discover the original colours and finishings. In 1990 the museum was officially opened to the public and remains a popular attraction in the neighbourhood.",
    ],
    aboutVisual: require("../assets/img/partners/roedde-mouse-museum/about-the-roedde-house-museum.png"),
    values: [
      "The Roedde House Preservation Society is dedicated to the preserving, maintaining and enhancing of the Roedde House Museum and to presenting heritage exhibits and programs reflecting the early history of Vancouver for the benefit and enjoyment of present and future generations. Since the Museum’s official opening in May 1990, the Roedde House Preservation Society has run Roedde House as a museum promoted with guided tours, including an active schools program; as a venue for music and other cultural entertainment; and, as a rental facility for small receptions and artistic projects.",
      "The planning, coordination and overall management is carried out by the Board of Directors and the House Manager. Financial support comes from the Provincial Government in the form of an annual Direct Access grant, revenue from rentals, donations, admission and membership fees. As well, over the years, specific projects have been possible with the help of foundations and private funding.",
    ],
    valuesVisual: require("../assets/img/partners/roedde-mouse-museum/exploring-roedde-house-museum-in-Person.png"),
    landAcknowledgement:
      "The Roedde House Preservation Society is located on the traditional and unceded territory of the Squamish, Musqueam, and Tsleil-Waututh Nations.",
    category: "Museum",
    tags: ["Museum", "Tag1", "Tag2", "Etcetera"],
    info: {
      phone: "604 684 7040",
      website: "https://www.roeddehouse.org",
      availability: {
        time: { from: "08:03 AM", to: "06:45 PM" },
        dayOfWeeks: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thrusday",
          "Friday",
          "Saturday",
        ],
      },
    },
    location: {
      country: "Canada",
      province: "British Columbia",
      region: "Mainland Southwest",
      city: "Vancouver",
      streetAddress: "1415 Barclay Street V6G 1J6",
      coordinates: { latitude: "49.2868718", longitude: "-123.1321483" },
    },
    link: "/",
  },
};

export const newPartners = [
  {
    logo: require("../assets/logos/chase-museum.png"),
    title: "Chase Museum",
  },
  {
    logo: require("../assets/logos/barkerville.png"),
    title: "Barkeville",
  },
  {
    logo: require("../assets/logos/west-vancouver.png"),
    title: "West Vancouver Art Museum",
  },
  {
    logo: require("../assets/logos/boundary-museum.png"),
    title: "Boundary Museum",
  },
  {
    logo: require("../assets/logos/the-museum.png"),
    title: "The Museum",
  },
];

export const ourPartners = [
  {
    logo: require("../assets/logos/coquitlam-heritaje.png"),
    title: "Coquitlam Heritaje",
  },
  {
    logo: require("../assets/logos/graaigdarroch-castle.png"),
    title: "Craigrroch Castle",
  },
  {
    logo: require("../assets/logos/dawson-creek-art-gallery.png"),
    title: "Dawson Creek Art Gallery",
  },
  {
    logo: require("../assets/logos/doukhobor-discovery-centre.png"),
    title: "Doukhobor Discovery Centre",
  },
  {
    logo: require("../assets/logos/carr-house.png"),
    title: "Carr House",
  },
  {
    logo: require("../assets/logos/monova.png"),
    title: "Monova",
  },
  {
    logo: require("../assets/logos/heritage-park-museum.png"),
    title: "Heritage Park Museum",
  },
  {
    logo: require("../assets/logos/kelowna.png"),
    title: "Kelowna Museums",
  },
  {
    logo: require("../assets/logos/kiixin.png"),
    title: "Kiixin",
  },
  {
    logo: require("../assets/logos/jake-country.png"),
    title: "Jake Country",
  },
  {
    logo: require("../assets/logos/maritime-museum.png"),
    title: "Maritime Museum",
  },
  {
    logo: require("../assets/logos/vancouver-holocaust.png"),
    title: "Vancouver Holocaust",
  },
  {
    logo: require("../assets/logos/NIKKEI.png"),
    title: "Nikkei",
  },
  {
    logo: require("../assets/logos/oliver-district.png"),
    title: "Kelowna Museums",
  },
  {
    logo: require("../assets/logos/osoyoos-desert-centre.png"),
    title: "Osoyoos Desert Centre.png",
  },
  {
    logo: require("../assets/logos/point-ellice-house.png"),
    title: "Point Ellice House",
  },
  {
    logo: require("../assets/logos/port-moody-station-museum.png"),
    title: "Port Moody Station Museum",
  },
  {
    logo: require("../assets/logos/global-geopark.png"),
    title: "Global Geopark",
  },
  {
    logo: require("../assets/logos/barkerville.png"),
    title: "Barkeville",
  },
  {
    logo: require("../assets/logos/west-vancouver.png"),
    title: "West Vancouver Art Museum",
  },
  {
    logo: require("../assets/logos/atcheliz.png"),
    title: "Atchewliz",
  },
  {
    logo: require("../assets/logos/boundary-museum.png"),
    title: "Boundary Museum",
  },
  {
    logo: require("../assets/logos/chase-museum.png"),
    title: "Chase Museum",
  },
  {
    logo: require("../assets/logos/the-museum.png"),
    title: "The Museum",
  },
  {
    logo: require("../assets/logos/maison-musee-roedde.png"),
    title: "Maison Musee Roedde",
  },
  {
    logo: require("../assets/logos/skwxwu7mesh-lilwat7ul.png"),
    title: "Skwxwu7mesh Lilwat7ul",
  },
  {
    logo: require("../assets/logos/the-creston-museum.png"),
    title: "The Creston Museum",
  },
  {
    logo: require("../assets/logos/MILE-PARK.png"),
    title: "Mile Park",
  },
];
