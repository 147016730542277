import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/logos/logo.svg";

const HomeHero = () => {
  useEffect(() => {
    document.getElementById("video").play();
  }, []);
  return (
    <section className="bg-denim pb-24 md:pb-40 xl:pb-0">
      <div className="relative">
        <NavLink to="/" className="md:hidden absolute ml-4 mt-5">
          <img src={Logo} alt="Logo" className="w-48" />
        </NavLink>
        <div className="flex justify-end w-full">
          <video
            id="video"
            className="md:hidden w-full "
            width="100%"
            height="740"
            autoPlay
            loop
            muted
          >
            <source
              src={require("../../assets/img/home/banner-mobile.mp4")}
              type="video/mp4"
            />
          </video>

          <video
            id="video"
            className="hidden md:block"
            width="100%"
            height="740"
            autoPlay
            loop
            muted
          >
            <source
              src={require("../../assets/img/home/BannerDesktop.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="container">
          <div className="md:absolute top-1/3 lg:top-[10%] md:w-3/12 2xl:w-[450px] mt-5 text-center md:text-left 2xl:left-28 3xl:left-96">
            <h1 className="text-white font-light font-title uppercase">
              The World From{" "}
              <span className="text-green font-semibold block">
                Another Angle
              </span>
            </h1>
            <p className="text-white my-8">
              We are a community of arts, culture, and heritage organizations
              that want to help you explore the world through the stories that
              make each community so unique.
            </p>
            <Link
              to="/our-network"
              className="uppercase group mx-auto lg:mx-0 font-semibold text-white bg-magenta hover:bg-green hover:text-denim rounded-full w-fit h-12 px-6 flex items-center justify-center font-oswald gap-3 with-transition"
            >
              Explore
              <i
                className="iconify text-xl"
                data-icon="material-symbols:arrow-forward-ios-rounded"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
