export const newGuide = {
  color: "#1A1E29",
  voiceOver: "",
  map: "",
  stops: [],
  structures: [],
  cover: "",
};

export const codeLength = 5;

export const GUIDE_LIST_REQUEST = "GUIDE_LIST_REQUEST";
export const GUIDE_LIST_SUCCESS = "GUIDE_LIST_SUCCESS";
export const GUIDE_LIST_FAIL = "GUIDE_LIST_FAIL";
export const GUIDE_LIST_RESET = "GUIDE_LIST_RESET";

export const GUIDE_WRITE_REQUEST = "GUIDE_WRITE_REQUEST";
export const GUIDE_WRITE_SUCCESS = "GUIDE_WRITE_SUCCESS";
export const GUIDE_WRITE_FAIL = "GUIDE_WRITE_FAIL";
export const GUIDE_WRITE_RESET = "GUIDE_WRITE_RESET";

export const GUIDE_ACCESS_REQUEST = "GUIDE_ACCESS_REQUEST";
export const GUIDE_ACCESS_SUCCESS = "GUIDE_ACCESS_SUCCESS";
export const GUIDE_ACCESS_FAIL = "GUIDE_ACCESS_FAIL";
export const GUIDE_ACCESS_RESET = "GUIDE_ACCESS_RESET";

export const ACCESS_LIST_REQUEST = "ACCESS_LIST_REQUEST";
export const ACCESS_LIST_SUCCESS = "ACCESS_LIST_SUCCESS";
export const ACCESS_LIST_FAIL = "ACCESS_LIST_FAIL";
export const ACCESS_LIST_RESET = "ACCESS_LIST_RESET";

export const ACCESS_DETAILS_REQUEST = "ACCESS_DETAILS_REQUEST";
export const ACCESS_DETAILS_SUCCESS = "ACCESS_DETAILS_SUCCESS";
export const ACCESS_DETAILS_FAIL = "ACCESS_DETAILS_FAIL";
export const ACCESS_DETAILS_RESET = "ACCESS_DETAILS_RESET";

export const ACCESS_DISABLE_REQUEST = "ACCESS_DISABLE_REQUEST";
export const ACCESS_DISABLE_SUCCESS = "ACCESS_DISABLE_SUCCESS";
export const ACCESS_DISABLE_FAIL = "ACCESS_DISABLE_FAIL";
export const ACCESS_DISABLE_RESET = "ACCESS_DISABLE_RESET";
