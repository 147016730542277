import React from "react";
import { Toggle } from "../../global/AppToggle";
import { Link } from "react-router-dom";

export const HorizontalCards = ({
  info,
  options,
  stylePhotoProfile = "ml-4 my-auto h-11 aspect-square rounded-full",
}) => {
  const logState = (state) => {
    console.log("Toggled:", state);
  };
  return (
    <div className="flex bg-white hover:bg-gray shadow rounded-md with-transition">
      {info?.img?.length > 0 && (
        <Link
          to={info.url}
          className={`bg-cover bg-center bg-no-repeat ${stylePhotoProfile}`}
          style={{
            backgroundImage: `url(${info.img})`,
          }}
        />
      )}
      <div className="px-4 py-5 flex flex-grow justify-between items-center">
        <Link to={info.url} className="text-lg flex-grow capitalize font-bold">
          <h5>
            {info.name} {info.role ? `(${info.role})` : <></>}
          </h5>
          {info?.partner ? (
            <p className="text-sm mt-2">{info.partner}</p>
          ) : (
            <></>
          )}
          {info.time && (
            <div className="md:hidden">
              <p
                className={`text-lg capitalize font-bold font-oswald md:absolute ${
                  info.timeActive ? "text-green" : "text-gray-border"
                }`}
              >
                {info.time}
              </p>
            </div>
          )}
        </Link>
        <div className={`pl-4 lg:pl-10 flex ${info.time ? "gap-40" : "gap-4"}`}>
          {info.time && (
            <Link to={info.url} className="hidden md:block">
              <p
                className={`text-lg capitalize font-bold font-oswald md:absolute ${
                  info.timeActive ? "text-green" : "text-gray-border"
                }`}
              >
                {info.time}
              </p>
            </Link>
          )}
          {info.toggle && <Toggle toggled={true} onClick={logState} />}
          <div>{options}</div>
        </div>
      </div>
    </div>
  );
};
