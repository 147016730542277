import React, { useEffect, useState } from "react";
import Counter from "../../tours/Counter";
import { Link } from "react-router-dom";
import { generateSlug } from "../../../constants/helpers";

const OrderCard = ({ order, options }) => {
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    if (order?.isValid) {
      setRemaining(
        new Date(order.createdAt).getTime() + 172800000 - new Date().getTime(),
      );
    }
  }, [order]);

  return (
    order && (
      <div className="flex bg-white shadow rounded-md h-fit">
        <div className="px-4 py-5 flex flex-grow justify-between items-center">
          <Link
            to={`/user/profile/${
              generateSlug(order.user.name) || true
            }/my-orders/${order._id}`}
            className="text-lg flex-grow capitalize font-bold"
          >
            <h5>Order ID: {order._id}</h5>
            <div className="flex flex-col">
              <p className="text-sm mt-2">partner: {order.partner.title}</p>
              <p className="text-sm mt-2">tour: {order.tour.title}</p>
              <p className="text-sm mt-2">user: {order.user.name}</p>
            </div>
            <div className="md:hidden">
              <p
                className={`text-lg capitalize font-bold font-oswald ${
                  order.isValid ? "text-green" : "text-gray-border"
                }`}
              >
                {remaining ? (
                  <Counter value={remaining}></Counter>
                ) : (
                  <span className="text-magenta">Order Expired</span>
                )}
              </p>
            </div>
          </Link>
          <div className={`pl-4 lg:pl-10 flex gap-4`}>
            <Link
              to={`/user/profile/${
                generateSlug(order.user.name) || true
              }/my-orders/${order._id}`}
              className="hidden md:block"
            >
              <p
                className={`hidden md:block text-lg capitalize font-bold font-oswald ${
                  order.isValid ? "text-green" : "text-gray-border"
                }`}
              >
                {remaining ? (
                  <Counter value={remaining}></Counter>
                ) : (
                  <span className="text-magenta">Order Expired</span>
                )}
              </p>
            </Link>
            <div>{options}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default OrderCard;
