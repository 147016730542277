import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import socketClient from "socket.io-client";

import {
  ACCESS_DETAILS_FAIL,
  ACCESS_DETAILS_RESET,
  ACCESS_DETAILS_SUCCESS,
  codeLength,
} from "../constants/guideConstants";

import SelfGuideScreen from "./SelfGuideScreen";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import AppInput from "../components/global/form/AppInput";
import GoBack from "../components/global/GoBack";
import { useBreakpoints } from "../hooks/useBreakpoints";

const RedeemGuideAccessScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ACCESS_DETAILS_RESET });
  }, [dispatch]);

  const navigate = useNavigate();

  const { xl } = useBreakpoints();

  const [unautorized, setUnautorized] = useState(false);

  useEffect(() => {
    if (xl) {
      setUnautorized(true);
    }
  }, [xl, unautorized]);

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  useEffect(() => {
    if (loggedIn) {
      setUnautorized(true);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (unautorized) {
      setTimeout(() => {
        setUnautorized(false);
        navigate("/");
      }, 5000);
    }
  }, [unautorized, navigate]);

  const [code, setCode] = useState("");

  const codeHandler = (e) => {
    setCode(e.target.value);
  };

  const sessionDetails = useSelector((state) => state.sessionDetails);
  const {
    loading: loadingSession,
    error: errorSession,
    session,
  } = sessionDetails;

  useEffect(() => {
    // resseting state due to errors
    if (errorSession) {
      setTimeout(() => {
        dispatch({ type: ACCESS_DETAILS_RESET });
      }, 5000);
    }
  }, [errorSession, dispatch]);

  useEffect(() => {
    // resseting state due to invalidity
    if (session && !session.isValid) {
      dispatch({
        type: ACCESS_DETAILS_FAIL,
        payload: "Session is expired!",
      });
    }
  }, [session, dispatch]);

  const [sessionEndedError, setSessionEndedError] = useState(null);

  useEffect(() => {
    if (sessionEndedError) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [sessionEndedError]);

  // io
  const socket = useRef();

  useEffect(() => {
    socket.current = socketClient();
  }, [socket]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      if (!code?.length || code?.length !== codeLength) {
        throw new Error("Please insert a valid code");
      }

      if (socket.current) {
        socket.current.emit("getSession", { code, id: socket.current.id });
        setCode("");
      }
    } catch (error) {
      dispatch({
        type: ACCESS_DETAILS_FAIL,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    socket?.current?.on("takeSession", ({ sessionData, id }) => {
      try {
        if (socket?.current?.id === id) {
          if (sessionData) {
            if (sessionData.error) {
              throw new Error(sessionData.error);
            }

            if (sessionData.isValid) {
              dispatch({
                type: ACCESS_DETAILS_SUCCESS,
                payload: sessionData,
              });
            } else {
              throw new Error("Session expired!");
            }
          }
        }
      } catch (error) {
        dispatch({
          type: ACCESS_DETAILS_FAIL,
          payload: error.message,
        });
      }
    });

    if (session) {
      socket?.current?.on("endSession", (code) => {
        if (session.code === code) {
          dispatch({
            type: ACCESS_DETAILS_RESET,
          });

          setSessionEndedError(
            "Session ended! Thank you for using The VR Voyage platform.",
          );
        }
      });

      socket?.current?.on("kickSession", ({ code, id }) => {
        if (session.code === code && socket?.current?.id === id) {
          dispatch({
            type: ACCESS_DETAILS_RESET,
          });

          setSessionEndedError(
            "Session has been requested by another user. To use it here, please submit the code again. If you haven't requested this in another device, please contact your vendor!",
          );
        }
      });
    }
  }, [socket, dispatch, session]);

  useEffect(() => {
    if (socket && session) {
      for (var i = 0; i < 122; i++) {
        let k = i;
        setTimeout(function () {
          socket.current.emit("validateSession", session.code);
        }, 5 * 1000 * (k + 1));
      }
    }
  }, [socket, session]);

  // DOM
  return unautorized ? (
    <Message error body={"Not Authorized!"} noClose />
  ) : (
    <>
      {loadingSession ? (
        <Loader />
      ) : errorSession ? (
        <Message error body={errorSession} noClose />
      ) : sessionEndedError ? (
        <Message error body={sessionEndedError} noClose />
      ) : session?.isValid ? (
        <SelfGuideScreen slug={session.guide.slug} />
      ) : (
        <div>
          <div className="bg-gradiant flex">
            <h1 className="text-green font-title font-semibold mt-20 mb-16 ml-4">
              WELCOME
            </h1>
            <img
              src={require("../assets/img/tour-explore-example/self-guided-tour-activation.png")}
              alt=""
              className="w-44 absolute right-0 mt-9"
            />
            <GoBack />
          </div>
          <div className="container">
            <p className="my-12">
              Welcome to the self-guided tour activation screen. Before you can
              activate your tour, the organization you purchased the tour from
              needs to provide you an activation code. Please enter it below.
            </p>
            <form
              className="bg-white border-radius shadow-sm px-9 py-12"
              onSubmit={submitHandler}
            >
              <AppInput
                id="latitud"
                label="Enter Code"
                labelClass="font-bold mb-1.5"
                className="bg-transparent"
                value={code}
                onChange={codeHandler}
              />
              <button
                type="submit"
                className="uppercase font-semibold text-white bg-magenta rounded-full w-full py-3 flex items-center justify-center font-oswald gap-3 mt-5"
                onSubmit={submitHandler}
              >
                Submit
              </button>
            </form>
            <p className="text-center py-12 text-xs">
              <span className="font-bold">Note:</span> If you have not purchased
              a self-guided tour yet, please note that tours are sold on-site by
              the institution that created the tour or at registered affiliates
              like tourism information centres, travel concierge, and more.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RedeemGuideAccessScreen;
