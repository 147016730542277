import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { /*block, */ redirect } from "./constants/helpers";

// pre-registration
import HomeScreen from "./screens/HomeScreen";
import OurStory from "./screens/OurStory";
import OurNetwork from "./screens/OurNetwork";
import DimensioningFuture from "./screens/DimensioningFuture";
import WhyPartneringScreen from "./screens/WhyPartneringScreen";
import BusinessRegistrationScreen from "./screens/BusinessRegistrationScreen";
import BusinessLoginScreen from "./screens/BusinessLoginScreen";
// import PartnerProfileScreen from "./screens/PartnerProfileScreen";
// import AffiliateProfileScreen from "./screens/AffiliateProfileScreen";
import BusinessProfileScreen from "./screens/BusinessProfileScreen";
import ExperienceCatalogueScreen from "./screens/ExperienceCatalogueScreen";
import MapScreen from "./screens/MapScreen";
import ContactScreen from "./screens/ContactScreen";
import LegalScreen from "./screens/LegalScreen";
import RegisterScreen from "./screens/RegisterScreen";
import LoginScreen from "./screens/LoginScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";

// admins
import AdminLoginScreen from "./screens/AdminLoginScreen";
import AdminDashboardScreen from "./screens/AdminDashboardScreen";
import AdminActivationScreen from "./screens/AdminActivationScreen";

// authorized-users
import ActivationScreen from "./screens/ActivationScreen";
import TokenLoginScreen from "./screens/TokenLoginScreen";
import UserProfileScreen from "./screens/UserProfileScreen";
import EditUserProfileScreen from "./screens/EditUserProfileScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";

// online-tours
import EditTourScreen from "./screens/EditTourScreen";
import TourScreen from "./screens/TourScreen";
import PrePurchaseTourScreen from "./screens/PrePurchaseTourScreen";
import PostPurchaseTourScreen from "./screens/PostPurchaseTourScreen";

// in-person-tours
import GuideScreen from "./screens/GuideScreen";
import PrePurchaseGuideScreen from "./screens/PrePurchaseGuideScreen";
import RedeemGuideAccessScreen from "./screens/RedeemGuideAccessScreen";

// misc
import UnderConstruction from "./screens/UnderConstruction";
import Error404 from "./screens/Error404";

const App = () => {
  useEffect(() => {
    redirect();
    // block();
  });

  return (
    <Router>
      <Routes>
        {/* pre-registration */}
        <Route path="/" element={<HomeScreen />} exact />
        <Route path="/our-story" element={<OurStory />} exact />
        <Route path="/our-network" element={<OurNetwork />} exact />
        <Route
          path="/dimensioning-future"
          element={<DimensioningFuture />}
          exact
        />
        <Route path="/why-vrv" element={<WhyPartneringScreen />} exact />
        <Route
          path="/join/:role"
          element={<BusinessRegistrationScreen />}
          exact
        />
        <Route path="/login/:role" element={<BusinessLoginScreen />} exact />
        {/* <Route
          path="/partner/profile/:slug"
          element={<PartnerProfileScreen />}
          exact
        />
        <Route
          path="/affiliate/profile/:slug"
          element={<AffiliateProfileScreen />}
          exact
        /> */}
        <Route
          path="/:role/profile/:slug"
          element={<BusinessProfileScreen />}
          exact
        />
        <Route
          path="/catalogue/:type"
          element={<ExperienceCatalogueScreen />}
          exact
        />
        <Route path="/map/:selectedMarkerId?" element={<MapScreen />} />
        <Route path="/contact" element={<ContactScreen />} exact />
        <Route
          path="/legal/:category?/:policy?"
          element={<LegalScreen />}
          exact
        />
        <Route path="/register" element={<RegisterScreen />} exact />
        <Route path="/login" element={<LoginScreen />} exact />
        <Route
          path="/user/forgot-password"
          element={<ForgotPasswordScreen />}
          exact
        />
        {/*  */}

        {/* admins */}
        <Route path="/admin" element={<AdminLoginScreen />} exact />
        <Route path="/admin/config" element={<AdminDashboardScreen />} exact />
        <Route
          path="/:role/activate/:id"
          element={<AdminActivationScreen />}
          exact
        />
        {/*  */}

        {/* authorized-users */}
        <Route path="/activate/:token" element={<ActivationScreen />} exact />
        <Route
          path="/account/:role/:token"
          element={<TokenLoginScreen />}
          exact
        />
        <Route
          path="/user/profile/:slug"
          element={<UserProfileScreen />}
          exact
        />
        <Route
          path="/user/profile/:slug/edit"
          element={<EditUserProfileScreen />}
          exact
        />
        <Route
          path="/user/reset-password/:token"
          element={<ResetPasswordScreen />}
          exact
        />
        {/*  */}

        {/* online-tours */}
        <Route
          path="/tour/:slug/edit"
          element={<EditTourScreen />}
          exact
        />
        <Route
          path="/tour/:partner/:title/:language/:type"
          element={<TourScreen />}
          exact
        />
        <Route
          path="/tour/:slug/demo"
          element={<PrePurchaseTourScreen />}
          exact
        />
        <Route
          path="/user/profile/:slug/my-orders/:id"
          element={<PostPurchaseTourScreen />}
          exact
        />
        {/*  */}

        {/* in-person-tours */}
        <Route
          path="/guide/:slug/demo"
          element={<PrePurchaseGuideScreen />}
          exact
        />
        <Route path="/redeem" element={<RedeemGuideAccessScreen />} exact />
        <Route path="/test/guide/:slug" element={<GuideScreen />} exact />
        {/*  */}

        {/* misc */}
        <Route
          path="/under-construction"
          element={<UnderConstruction />}
          exact
        />
        <Route path="/not-found" element={<Error404 />} exact />
        <Route path="*" element={<Error404 />} exact />
        {/*  */}
      </Routes>
    </Router>
  );
};

export default App;
