import React from "react";

const Rating = ({ rating, numReviews }) => {
  return (
    rating && (
      <div className="flex items-center justify-start gap-2">
        <div className="flex md:text-3xl">
          {new Array(5).fill(0).map((ele, index) => (
            <i
              key={index}
              className="iconify text-green"
              data-icon={
                rating >= index + 1
                  ? "material-symbols:star-rate"
                  : rating >= index + 0.5
                  ? "material-symbols:star-rate-half"
                  : "material-symbols:star-rate-outline"
              }
            />
          ))}
        </div>
        {numReviews && <span className="text-white">({numReviews})</span>}
      </div>
    )
  );
};

export default Rating;
