import React, { useEffect, useState } from "react";

import { useBreakpoints } from "../../hooks/useBreakpoints";

import { TourNavigation } from "./TourNavigation";
import GoBack from "../global/GoBack";

export const TourExploreWelcome = ({ tour, partner, onMapClick }) => {
  const { md } = useBreakpoints();

  const [color, setColor] = useState("#000");

  useEffect(() => {
    if (tour) setColor(tour.content.color);
  }, [tour]);

  return (
    <div className="relative flex flex-col h-screen">
      <div className="flex-grow">
        <GoBack className="bg-white hover:bg-denim with-transition hover:text-white rounded-full grid absolute top-4 left-4 z-10 w-8 items-center place-items-center aspect-square" />
        <div
          className="relative h-48 md:h-96 bg-cover bg-no-repeat mb-16 md:mb-28"
          style={{
            backgroundImage: `url(${tour.cover})`,
          }}
        >
          <div
            className="absolute bg-cover bg-center bg-no-repeat left-1/2 -translate-x-1/2 translate-y-1/2 bottom-0 w-28 md:w-40 aspect-square rounded-full border-4 border-gray"
            style={{
              backgroundImage: `url(${partner.avatar})`,
            }}
          />
        </div>
        <div className="container">
          <div className="max-w-lg mx-auto">
            <h1 className="leading-[28px] uppercase text-center text-2xl font-semibold">
              {tour.title}
            </h1>
            {/* {tour.subtitle?.length ? (
              <h2 className="leading-[28px] mb-4 capitalize text-center text-lg">
                {tour.subtitle}
              </h2>
            ) : (
              <></>
            )} */}
            <p className="my-8 text-center">
              {partner.landAcknowledgement?.length > 300
                ? partner.landAcknowledgement.substr(0, 300) + "..."
                : partner.landAcknowledgement}
            </p>
            <ul className="grid gap-2 mb-8 capitalize px-4">
              <li className="flex gap-4 items-center">
                <span className="grid place-items-center w-10 aspect-square rounded-full bg-white shadow-lg">
                  <i
                    className={`iconify`}
                    style={{ color }}
                    data-icon="typcn:location"
                  />
                </span>
                <p>
                  {tour.location.city}, {tour.location.country}
                </p>
              </li>
              <li className="flex gap-4 items-center">
                <span className="grid place-items-center w-10 aspect-square rounded-full bg-white shadow-lg">
                  <i
                    className={`iconify`}
                    style={{ color }}
                    data-icon="mdi:partnership"
                  />
                </span>
                <p>{partner.title}</p>
              </li>
              {tour.guide?.length ? (
                <li className="flex gap-4 items-center">
                  <span className="grid place-items-center w-10 aspect-square rounded-full bg-white shadow-lg">
                    <i
                      className={`iconify`}
                      style={{ color }}
                      data-icon="fluent:person-feedback-16-filled"
                    />
                  </span>
                  <p>{tour.guide}</p>
                </li>
              ) : (
                <></>
              )}
            </ul>
            <div className="pb-14">
              <button
                className={`uppercase flex justify-center items-center gap-1 px-5 lg:px-6 py-3 w-full rounded-full text-white`}
                style={{ backgroundColor: color }}
                onClick={() => onMapClick()}
              >
                <strong className="uppercase font-oswald">
                  {md ? "continue" : "start"}
                </strong>
                <i
                  className="iconify text-lg"
                  data-icon="material-symbols:arrow-back-ios-rounded"
                  data-rotate="2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <TourNavigation
        color={color}
        styles="mt-14"
        visual={tour.cover}
        textGuide={tour.description}
        autoPlay={false}
        audioUrl={tour.content.voiceOver}
        onMapClick={() => onMapClick()}
      />
    </div>
  );
};
