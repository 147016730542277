import React from "react";
import { Link } from "react-router-dom";

const AppTourCard = ({ className, name, link, img, partnerLogo }) => {
  return (
    <Link
      className={`relative p-2 block overflow-hidden rounded-xl bg-no-repeat bg-cover bg-center ${className} with-transition`}
      to={link}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="absolute bottom-2 flex items-center gap-2 max-w-[13.4rem] w-11/12 p-1 pr-2 mt-auto rounded-full bg-white/50 backdrop-blur-sm">
        <div
          className="h-8 w-9 aspect-square rounded-full bg-white bg-cover"
          style={{ backgroundImage: `url(${partnerLogo})` }}
        />
        <p className="text-sm font-semibold text-ellipsis truncate overflow-hidden">
          {name}
        </p>
      </div>
    </Link>
  );
};

export default AppTourCard;
