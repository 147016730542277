import React from "react";
import { passwordRules } from "../../constants/helpers";

const PasswordRules = ({
  password,
  heading,
  body = `Please make sure your new password is strong enough not to be guessed easily. So, let's consider the following rules:`,
}) => {
  return (
    <>
      {heading && (
        <h1 className="mb-4 text-3xl uppercase font-semibold uppercase">
          {heading}
        </h1>
      )}
      <p className="mb-5">{body}</p>
      <ul className="pl-4 mb-6 flex flex-col gap-2">
        {passwordRules.map((ele, index) => (
          <li key={index} className="flex gap-2">
            <span
              className={`text-${
                ele.rule.test(password) ? "green" : "magenta"
              } font-bold`}
            >
              {ele.rule.test(password) ? "✓" : "x"}
            </span>
            <span>{ele.text}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PasswordRules;
