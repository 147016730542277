import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import DenimLayout from "../layouts/DenimLayout";

const Error404 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname !== "/not-found") {
      navigate(`/not-found`);
    }

    // eslint-disable-next-line
  }, [navigate]);

  return (
    <DenimLayout>
      <div className="bg-denim">
        <div className="container pb-10">
          <h1 className="text-3xl md:text-7xl uppercase font-semibold text-center text-white pt-28 pb-20">
            Page <span className="text-green">Not Found</span>
          </h1>
          <img
            src={require("../assets/img/error-404/error.png")}
            alt=""
            className="mb-14 md:w-1/3 mx-auto"
          />
          <p className="md:w-1/2 mx-auto text-center text-white mb-10">
            Sometimes in exploration, there are dead ends. To continue your
            exploration of natural and cultural heritage return to the home
            page.
          </p>
          <div className="mx-auto w-fit h-fit border-2 border-transparent hover:border-white rounded-full">
            <NavLink
              to="/"
              type="submit"
              className="font-semibold text-white uppercase app-primary-button animate-bg w-40 h-14"
            >
              <p className="flex justify-center gap-2">
                <i
                  className="iconify"
                  data-icon="ep:arrow-up-bold"
                  data-rotate="-90deg"
                />
                return home
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </DenimLayout>
  );
};

export default Error404;
