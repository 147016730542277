export const ORDER_PLACE_REQUEST = "ORDER_PLACE_REQUEST";
export const ORDER_PLACE_SUCCESS = "ORDER_PLACE_SUCCESS";
export const ORDER_PLACE_FAIL = "ORDER_PLACE_FAIL";
export const ORDER_PLACE_RESET = "ORDER_PLACE_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_LIST_RESET = "ORDER_LIST_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";
