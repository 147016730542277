import React from "react";
import Lottie from "lottie-react";

import * as loading from "../../assets/img/global/vrvLoading.json";

const Loader = () => {
  return (
    <div
      className={`z-[5000] w-[150px] h-[150px] top-[150px] left-[50%] my-0 mx-auto translate-x-[-50%] fixed`}
    >
      <Lottie animationData={loading} loop={true} />
    </div>
  );
};

export default Loader;
