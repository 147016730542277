import { useState } from "react";

const validations = {
  // ADD RULES HERE
  email(inputElem, errorMessage = "Please use a valid email") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { value } = inputElem;
    const isValid = emailRegex.test(value);
    if (isValid) {
      inputElem.setCustomValidity("");
      return null;
    }
    inputElem.setCustomValidity(errorMessage);
    return errorMessage;
  },
  required(inputElem, ruleValue, errorMessage = "This field is required") {
    const { value, name } = inputElem;
    const isValid = Boolean(!ruleValue || value.trim());
    if (isValid) {
      inputElem.setCustomValidity("");
      return null;
    }
    errorMessage = errorMessage.replace(/{([^}]+)}/g, name);
    inputElem.setCustomValidity(errorMessage);
    return errorMessage;
  },
};

export const useForm = (options = {}) => {
  const { autoValidate = false, defaultValues = {} } = options;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(defaultValues);
  const fields = {};
  const watchers = {};

  function register(name, rules = {}) {
    if (!(name in formData)) {
      setFormData({ ...formData, [name]: null });
      setErrors({ ...errors, [name]: null });
    }
    return {
      name,
      ref(inputElem) {
        fields[name] = { el: inputElem, rules };
      },
      onChange: handleInput,
    };
  }

  function handleInput(event) {
    let { name, value, type, files } = event.target;
    if (type === "file") {
      value = files[0];
    }
    setFormData({ ...formData, [name]: value });
    if (watchers[name]) {
      watchers[name](value, fields[name]);
    }
    if (autoValidate) {
      const error = getInputErrors(event.target);
      setErrors({ ...errors, [name]: error });
    }
  }

  function getInputErrors(inputElem) {
    const { name } = inputElem;
    let inputError = null;
    for (const rule in fields[name].rules) {
      if (!inputError) {
        const [ruleValue, errorMessage] = fields[name].rules[rule];
        if (validations[rule]) {
          inputError = validations[rule](inputElem, ruleValue, errorMessage);
        }
      }
    }
    return inputError;
  }

  function handleSubmit(onSuccess, onFail) {
    return {
      autoComplete: "off",
      noValidate: true,
      onSubmit(event) {
        const form = event.target;
        event.preventDefault();
        const newErrors = { ...errors };
        let hasError = false;
        for (const name in fields) {
          const error = getInputErrors(form.elements[name]);
          newErrors[name] = error;
          if (error) {
            hasError = true;
          }
        }
        setErrors(newErrors);
        if (hasError === false && onSuccess) {
          onSuccess(formData);
        } else if (hasError === true && onFail) {
          onFail({ formData, errors });
        }
      },
    };
  }

  function watch(name, callback) {
    watchers[name] = callback;
  }

  return {
    formData,
    fields,
    errors,
    register,
    handleSubmit,
    watch,
  };
};
