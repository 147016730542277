import React from "react";

const CodePopup = ({ access }) => {
  return access ? (
    <div className="relative bg-denim w-fit h-fit p-8 flex flex-col gap-4 items-start justify-center rounded-md text-white">
      <h1 className="text-8xl mb-8 self-center">{access.code}</h1>
      <div className="flex flex-wrap justify-start items-center text-2xl w-full">
        <h2 className="w-[20%]">Tour:</h2>
        <h2 className="grow text-green">{access.guide?.title}</h2>
      </div>
      <div className="flex flex-wrap justify-start items-center text-2xl w-full">
        <h2 className="w-[20%]">Price:</h2>
        <h2 className="grow text-green">${access.guide?.price}</h2>
      </div>
      <div className="flex flex-wrap justify-start items-center text-2xl w-full">
        <h2 className="w-[20%]">Validity:</h2>
        <h2 className="grow text-green">120 minutes</h2>
      </div>
      <div className="mt-8 flex flex-col gap-2">
        <p>
          * Please make sure to write down this code before you close this
          window
        </p>
      </div>
    </div>
  ) : null;
};

export default CodePopup;
