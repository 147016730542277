import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { deleteTour, listTours } from "../../../actions/tourActions";
import {
  TOUR_DELETE_RESET,
  TOUR_LIST_RESET,
} from "../../../constants/tourConstants";

import AdminHeaderLayout from "../components/AdminHeaderLayout";
import { HorizontalCards } from "../components/HorizontalCards";
import { AppDropdown } from "../../global/AppDropdown";
import { CreateTour } from "./CreateTour";
import Warning from "../../global/Warning";
import Message from "../../global/Message";
import Loader from "../../global/Loader";
import { useNavigate } from "react-router-dom";
// import { AppPagination } from "../../global/AppPagination";

const ManageTours = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: TOUR_LIST_RESET });
  }, [dispatch]);

  const tourList = useSelector((state) => state.tourList);
  const { loading: loadingTours, error: errorTours, tours } = tourList;

  const userList = useSelector((state) => state.userList);
  const { error: errorPartners } = userList;

  const tourCreate = useSelector((state) => state.tourCreate);
  const { error: errorCreate, success: created } = tourCreate;

  const tourDelete = useSelector((state) => state.tourDelete);
  const {
    loading: deleting,
    error: errorDelete,
    success: deleted,
  } = tourDelete;

  useEffect(() => {
    dispatch(listTours());
  }, [dispatch]);

  const navigate = useNavigate();

  const editTourHandler = (e) => {
    if (e.target.id) {
      navigate(`/tour/${e.target.id}/edit`);
    }
  };

  const deleteTourHandler = (e) => {
    e.preventDefault();

    dispatch(deleteTour(e.target.id));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete ${
        tours.find((tour) => tour.slug === e.target.id).title
      }?`,
      callback: () => {
        deleteTourHandler(e);
      },
      whenDeclined: setWarning,
    });
  };

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { error: errorUpload } = mediaUpload;

  useEffect(() => {
    // resetting state due to errors
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: TOUR_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch]);

  useEffect(() => {
    // reset state after success
    if (deleted) {
      setTimeout(() => {
        dispatch({ type: TOUR_DELETE_RESET });
        dispatch(listTours());
      }, 5000);
    }
  }, [deleted, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorTours) {
      setTimeout(() => {
        dispatch({ type: TOUR_LIST_RESET });
      }, 5000);
    }
  }, [errorTours, dispatch]);

  return (
    <section>
      {errorPartners && <Message error body={errorPartners} />}
      {errorUpload && <Message error body={errorUpload} />}
      {errorCreate && <Message error body={errorCreate} />}
      {created && <Message info body={"Tour created successfully!"} />}
      {errorDelete && <Message error body={errorDelete} />}
      {deleted && <Message info body={"Tour deleted successfully!"} />}
      {/*  */}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}
      <AdminHeaderLayout
        title="Manage tours"
        // placeholder="Search Tours"
        button={<CreateTour />}
        // pagination={<AppPagination totalItems="500" itemsPerPage={50} />}
      />
      {loadingTours || deleting ? (
        <Loader />
      ) : errorTours ? (
        <Message error body={errorTours} />
      ) : (
        !errorDelete &&
        !deleted && (
          <>
            {tours?.length > 0 ? (
              <div className="grid gap-2 mb-8">
                {tours.map((tour, index) => (
                  <HorizontalCards
                    key={index}
                    info={{
                      name: tour.title,
                      partner: tour.partner.split("-").join(" "),
                      img: tour.cover,
                      url: `/tour/${tour.slug}/demo`,
                    }}
                    stylePhotoProfile="rounded-tl-md rounded-bl-md w-[71px] md:w-44"
                    options={
                      <AppDropdown
                        activator={
                          <i className="iconify" data-icon="tabler:dots" />
                        }
                        className="dropdown-end"
                      >
                        <ul className="w-40">
                          <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                            <button id={tour.slug} onClick={editTourHandler}>
                              Edit Tour
                            </button>
                          </li>
                          <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                            <button id={tour.slug} onClick={showDeleteWarning}>
                              Delete Tour
                            </button>
                          </li>
                        </ul>
                      </AppDropdown>
                    }
                  />
                ))}
              </div>
            ) : (
              <p>No tours found!</p>
            )}
          </>
        )
      )}
      <div className="grid gap-2"></div>
    </section>
  );
};

export default ManageTours;
