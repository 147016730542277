const daysOfWeek = [
  { text: "M", value: "Monday" },
  { text: "T", value: "Tuesday" },
  { text: "W", value: "Wednesday" },
  { text: "T", value: "Thrusday" },
  { text: "F", value: "Friday" },
  { text: "S", value: "Saturday" },
  { text: "S", value: "Sunday" },
];

export default daysOfWeek;
