import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_VALIDATE_REQUEST,
  USER_VALIDATE_SUCCESS,
  USER_VALIDATE_FAIL,
  USER_NOTIFY_REQUEST,
  USER_NOTIFY_SUCCESS,
  USER_NOTIFY_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_TOGGLE_REQUEST,
  USER_TOGGLE_SUCCESS,
  USER_TOGGLE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  //
  SUBSCRIBER_REGISTER_REQUEST,
  SUBSCRIBER_REGISTER_SUCCESS,
  SUBSCRIBER_REGISTER_FAIL,
} from "../constants/userConstants";

//

// list all users by type
export const listUsers =
  (type, isCandidate = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${MAIN_ROUTE}/${type}s${isCandidate ? "/candidates" : ""}`,
      );

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// create
export const createUser = (type, user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CREATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/${type}s/admin`,
      { user },
      config,
    );

    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// user join request (partner, affiliate, investor)
export const requestToJoin = (type, user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/${type}s`,
      { user },
      config,
    );

    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// request to join
export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`${MAIN_ROUTE}/users`, user, config);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// activate registration request
export const activateUserRegiteration = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ACTIVATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/users/activate/${token}`,
      config,
    );

    dispatch({
      type: USER_ACTIVATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// validate user
export const validateUser = (type, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_VALIDATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/${type}s/validate/${userInfo.slug}`,
      { password },
      config,
    );

    dispatch({
      type: USER_VALIDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_VALIDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// validate user
export const verifyUserToken = (type, token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VALIDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/${type}s/verify/${token}`,
      config,
    );

    dispatch({
      type: USER_VALIDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_VALIDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// notify user
export const notifyUser =
  (type, email, options = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_NOTIFY_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/${type}s/notify/${email}${
          options ? `${options.approved ? "/approved" : "/declined"}` : ""
        }`,
        config,
      );

      dispatch({
        type: USER_NOTIFY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_NOTIFY_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// continue with google
export const authWithGoogle = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/users/by-google/${token}`,
      config,
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// login
export const login = (role, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/${role}s/login`,
      { email, password },
      config,
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// login from token
export const loginFromToken =
  (token, role = "user") =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/${role}s/login/${token}`,
        config,
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// get user details
export const getUserDetails =
  (type, slug, isCandidate = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      // todo: continue the details scenario
      const { data } = await axios.get(
        `${MAIN_ROUTE}/${type}s${isCandidate ? "/candidate" : ""}/${slug}`,
      );

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// update user details
export const updateUserInfo =
  (type, slug, updates) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${MAIN_ROUTE}/${type}s/${slug}`,
        updates,
        config,
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// update user details via token
export const updateUserInfoViaToken =
  (type, slug, token, updates) => async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.put(
        `${MAIN_ROUTE}/${type}s/${slug}`,
        updates,
        config,
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// logout
export const logout = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });

  localStorage.removeItem("userInfo");

  dispatch({
    type: USER_LOGIN_RESET,
  });
};

//
// toggle user status
export const toggleUser = (type, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TOGGLE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${MAIN_ROUTE}/${type}s/${id}/toggle`,
      {},
      config,
    );

    dispatch({
      type: USER_TOGGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_TOGGLE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// delete user
export const deleteUser =
  (type, slug, isCandidate) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DELETE_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${MAIN_ROUTE}/${type}s${isCandidate ? "/candidate" : ""}/${slug}`,
        config,
      );

      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

//

// subscribe to newsletter
export const subscribe = (subscriber) => async (dispatch) => {
  try {
    dispatch({
      type: SUBSCRIBER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/subscribers`,
      { subscriber },
      config,
    );

    dispatch({
      type: SUBSCRIBER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIBER_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
