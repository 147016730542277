import React from "react";

import HeaderPages from "../components/global/HeaderPages";
import DenimLayout from "../layouts/DenimLayout";

const WhyPartneringScreen = () => {
  const partnerBenefits = [
    {
      img: require("../assets/img/afilliate/innovative-virtual-tours.jpg"),
      title: "Innovative Virtual Tours:",
      description:
        "Delight your visitors with cutting-edge self-guided tours and virtual content that transport bring destinations to life more than a simple pamphlet. Our platform showcases a curated selection of immersive experiences, providing your guests with a unique way to explore new destinations.",
    },
    {
      img: require("../assets/img/afilliate/commission-rewards.jpg"),
      title: "Commission Rewards:",
      description:
        "Earn generous commissions for every tour sold through your efforts. As our valued affiliate partner, you'll receive a commission on each sale, allowing you to monetize your promotional endeavours and boost your revenue stream.",
    },
    {
      img: require("../assets/img/afilliate/tailored-marketing-support.jpg"),
      title: "Tailored Marketing Support:",
      description:
        "Leverage our marketing resources and tools to enhance your promotional campaigns. From eye-catching visuals to compelling content, we've got you covered. Our team is dedicated to helping you maximize your outreach and drive engagement.",
    },
    {
      img: require("../assets/img/afilliate/broad-audience-appeal.jpg"),
      title: "Broad Audience Appeal:",
      description:
        "The VR Voyage caters to a diverse audience of travel enthusiasts. Whether your audience consists of adventure seekers, culture connoisseurs, or relaxation aficionados, our platform offers a growing range of self-guided tours to captivate their interests.",
    },
    {
      img: require("../assets/img/afilliate/simple-integration.jpg"),
      title: "Simple Integration:",
      description:
        "Seamless integration is key, and we make it easy for you. Our user-friendly platform ensures a hassle-free experience for both you and your guests. Start promoting self-guided tours and earning commissions with just a few clicks.",
    },
  ];
  const howItWorks = [
    {
      title: "Sign Up",
      description:
        "Joining The VR Voyage Affiliate Program is a breeze. Sign up and unlock a world of opportunities.",
      style: "bg-green",
    },
    {
      title: "Explore & Promote",
      description:
        "Dive into our extensive collection of self-guided tours and virtual content. Choose the ones that resonate with your audience and start promoting them through your channels.",
      style: "bg-denim text-white ",
    },
    {
      title: "Earn Commissions",
      description:
        "For every successful sale originating from your efforts, enjoy a commission that rewards your dedication to promoting tourism experiences.",
      style: "bg-yellow",
    },
  ];
  return (
    <DenimLayout>
      <HeaderPages
        titlePage={
          <>
            <p className="text-left font-title font-normal">
              Why
              <span className="text-green font-semibold"> Partnering </span>
              with The VR Voyage?
            </p>
          </>
        }
        styleTitlePage="pb-[65px] lg:pb-[19px] xl:pb-[19px] pt-[112px] lg:pt-[140px]"
      />
      <div className="container">
        <section className="grid gap-12 md:gap-16 py-12 lg:py-32">
          {partnerBenefits.map((e, i) => (
            <div
              key={i}
              className="select-odd grid md:grid-cols-5 xl:grid-cols-12 md:gap-10 xl:gap-14 lg:w-10/12 lg:mx-auto md:place-items-center"
            >
              <div
                className="img w-full h-52 md:h-44 md:col-span-2 xl:col-span-4 bg-cover border-radius bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${e.img})`,
                }}
              />
              <div className="md:col-span-3 xl:col-span-8">
                <h3 className="text-green uppercase font-semibold text-2xl mt-8 md:mt-0 mb-5">
                  {e.title}
                </h3>
                <p>{e.description}</p>
              </div>
            </div>
          ))}
        </section>
        <section>
          <h2 className="text-green text-3xl uppercase font-semibold text-center mt-12 lg:mt-0">
            How It Works
          </h2>
          <div className="grid lg:grid-cols-3 gap-8 md:gap-6 mt-8 mb-24 lg:mb-32">
            {howItWorks.map((e, i) => (
              <div
                key={i}
                className={`text-center h-[271px] grid pt-12 border-radius ${e.style}`}
              >
                <div className="px-5 md:px-16 lg:px-5 2xl:px-16">
                  <h3 className="font-semibold uppercase text-3xl mb-7">
                    {e.title}
                  </h3>
                  <p>{e.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <section>
        <div className="bg-denim">
          <div className="container ">
            <div className="lg:w-10/12 lg:mx-auto md:grid md:grid-cols-3 md:place-items-center py-24 md:gap-5 md:py-14">
              <div
                className="w-full h-[334px] md:h-[465px] md:col-span-1 bg-contain border-radius bg-center md:bg-left bg-no-repeat"
                style={{
                  backgroundImage: `url(${require("../assets/img/afilliate/join-us.png")})`,
                }}
              />
              <div className="md:col-span-2 text-white">
                <h2 className="font-semibold uppercase text-green text-3xl mb-7">
                  Join Us on This Virtual Expedition!
                </h2>
                <p>
                  Become a trailblazer in the world of tourism innovation and
                  elevate your promotional efforts with The VR Voyage Affiliate
                  Program. Embrace innovation, embark on adventures, keep it
                  simple, and let your intelligence shine through as you
                  captivate your audience with the wonders of digital and
                  virtual exploration.
                </p>
                <p className="mt-5">
                  Ready to redefine the future of travel promotion? Join The VR
                  Voyage Affiliate Program today!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DenimLayout>
  );
};

export default WhyPartneringScreen;
