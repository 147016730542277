import React from "react";
import { Link } from "react-router-dom";

const PartnerInfo = ({ partner }) => {
  return (
    <div className="container mt-24 md:mt-12">
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="col-span-2 pr-[40px]">
          <h2 className="text-green text-3xl uppercase font-semibold mb-8">
            About our partner ({partner.title})
          </h2>
          {partner.about?.map((ele, index) => (
            <p key={index} className="mb-7">
              {ele}
            </p>
          ))}
        </div>
        <div className="my-auto">
          <div
            className="w-full h-32 md:h-36 bg-center bg-no-repeat bg-cover rounded-t-[20px]"
            style={{
              backgroundImage: `url(${partner.banner})`,
            }}
          />
          <div className="bg-denim text-center text-white font-semibold uppercase rounded-b-[20px]">
            <div className="bg-white aspect-square rounded-full w-fit mx-auto -translate-y-12">
              <img
                src={partner.avatar}
                alt={partner.title}
                className="w-28 p-3"
              />
            </div>
            <div className="-translate-y-12 mt-5">
              <h2 className="text-2xl pb-5 md:px-8 mb-4">{partner.title}</h2>
              <Link
                to={`/partner/profile/${partner.slug}`}
                className="w-fit mx-auto border-2 px-10 py-3 rounded-full font-oswald uppercase hover:bg-white with-transition hover:text-denim"
              >
                view partner profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerInfo;