import React from "react";
import { Link } from "react-router-dom";

const LegalForeingLanguagePolicy = () => {
  return (
    <main>
      <section className="grid gap-10 lg:gap-12">
        <div className="grid gap-5">
          <h1 className="text-3xl uppercase font-semibold">
            FOREIGN LANGUAGE POLICY
          </h1>
          <span className="text-xs font-bold">
            This Policy was last updated on 18 August 2023.
          </span>
        </div>
        <p>
          When you sign up to take part in Forager Education Ltd.’s (“Forager
          Education”) tour translation program, you agree to the following
          Foreign Language Policy (“Translation Policy”). This Policy covers all
          details of the relationship between you and Forager Education in
          relation to the creation of alternate language tours using content
          originally uploaded by you.
        </p>

        <ol className="grid gap-10 lg:gap-12">
          <li>
            <p className="text-lg font-bold pb-5">
              1. PUBLISHER REVENUE SHARE OVERVIEW{" "}
            </p>
            <p>
              A change to the{" "}
              <Link
                to="/legal/policies/revenue-share-policy"
                className="text-green underline font-bold"
              >
                Revenue Share Policy
              </Link>{" "}
              comes with opting into Forager Education’s Translation Program.
              With the added investment on behalf of Forager Education to
              translate and record new interactive elements and guided audio,
              the breakdowns for this program are as follows.
            </p>
            <ol className="pl-5 pt-5 grid gap-5">
              <li>
                <p className="font-bold">1.1. The VR Voyage Organic</p>
                <p>
                  With Forager Education translated Experiences, the revenue
                  split when it is an organic sale made through The VR Voyage is
                  50/50. This means where the sale does not come through a
                  Publisher URL that is for your own Experience. This occurs
                  after a user browses The VR Voyage’s marketplace, or makes a
                  purchase via one of The VR Voyage’s promotions.
                </p>
              </li>
              <li>
                <p className="font-bold">1.2. Publisher Promotion</p>
                <p>
                  If the sale of the Experience comes from a Publisher’s unique
                  URL, the revenue split is 60% for the Publisher and 40% for
                  The VR Voyage as an admin fee.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold">2. MODIFICATIONS</p>
            <p className="py-5 ">
              On occasion, we may update this Policy to further define our
              promotional program. The VR Voyage reserves the right to modify or
              make changes to this policy at any time. When changes to the
              Policy are made, you will be notified by the email that is
              associated with your account. Changes will take effect on the day
              the Policy is posted.
            </p>
            <p>
              Continued use of The VR Voyage’s marketplace after changes are
              posted shall mean each Publisher’s acceptance of the
              modifications. The revised Policy shall supersede all previous
              editions of the Policy.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold">3. HOW TO CONTACT US</p>
            <p className="pt-5 ">
              The best way to contact The VR Voyage is through your Acount
              Manager. If you are a prospective publisher or have not been
              assigned a Account Manager, use{" "}
              <span className="text-green underline font-bold">
                partners@foragereducation.com
              </span>{" "}
              and an agent will be in touch. We are always looking for feedback
              on our services and would love to hear from you.
            </p>
          </li>
        </ol>
      </section>
    </main>
  );
};

export default LegalForeingLanguagePolicy;
