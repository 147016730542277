import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { logout } from "../../actions/userActions";

export default function AppDrawer() {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo } = vrvUserLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountHandler = (e) => {
    e.preventDefault();

    if (userInfo) {
      dispatch(logout());
    }

    navigate("/login");
  };

  return (
    <>
      <div className="drawer-side">
        <label htmlFor="app-drawer" className="drawer-overlay" />
        <nav className="bg-denim menu flex w-48 pt-4 relative">
          <label
            htmlFor="app-drawer"
            className="px-5 drawer-button text-white font-extrabold z-50 ml-auto"
          >
            <i className="iconify text-3xl" data-icon="heroicons-solid:x" />
          </label>
          <ul className="w-10/12 text-white uppercase">
            {userInfo ? (
              <li className="p-0">
                <div className="flex justify-between">
                  <div className="avatar">
                    <div className="w-16 rounded-full">
                      <NavLink
                        to={
                          userInfo.role === "admin"
                            ? "/admin/config"
                            : `/${userInfo.role}/profile/${userInfo.slug}`
                        }
                      >
                        {userInfo.role === "admin" ? (
                          <img
                            src={require("../../assets/img/profile/admin.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            src={
                              userInfo.avatar && userInfo.avatar.length > 0
                                ? userInfo.avatar
                                : require("../../assets/img/profile/profile.jpg")
                            }
                            alt=""
                          />
                        )}
                      </NavLink>
                    </div>
                  </div>
                  <NavLink
                    to={
                      userInfo.role === "admin"
                        ? `/admin`
                        : `/user/profile/${userInfo.slug}/edit`
                    }
                    className="absolute -right-1.5"
                  >
                    <i
                      className="iconify text-xl"
                      data-icon="clarity:settings-solid"
                    />
                  </NavLink>
                </div>
              </li>
            ) : (
              <>
                <li className="mt-4">
                  <NavLink
                    to="/dimensioning-future"
                    className="font-semibold pl-5 text-green hover:text-magenta with-transition"
                  >
                    For Creators
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/login"
                    className="font-semibold pl-5 text-yellow hover:text-magenta with-transition"
                  >
                    For Voyagers
                  </NavLink>
                </li>
              </>
            )}

            <li className="relative mt-4">
              <input
                id="about"
                className="peer/about hidden"
                type="checkbox"
                name="status"
              />
              <label
                htmlFor="about"
                className="z-10 pl-5 rounded-l-full font-semibold"
              >
                <h6>about</h6>
              </label>
              <span className="absolute -right-[22.4px] rotate-90 peer-checked/about:-rotate-90">
                <i
                  className="iconify text-xl rotate-0 peer-checked/about:-rotate-90"
                  data-icon="ep:arrow-up-bold"
                  data-rotate="90deg"
                />
              </span>
              <nav
                className="
                relative overflow-hidden max-h-0 p-0 rounded-bl-3xl
                peer-checked/about:max-h-60 peer-checked/about:overflow-visible
                bg-dark-denim with-transition
                before:absolute before:left-0 before:-top-6 before:w-full before:h-7 before:bg-inherit
              "
              >
                <ul className="py-1 text-white">
                  <li>
                    <NavLink to="/our-story" className="flex gap-1">
                      <span className="pb-2 capitalize text-base">
                        Our Story
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/our-network" className="flex gap-1">
                      <span className="pb-2 text-base capitalize">
                        Our Network
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </li>
            <li>
              <NavLink
                to="/map"
                className="pl-5 rounded-l-3xl font-semibold"
              >
                <h6>Map</h6>
              </NavLink>
            </li>
            <li className="relative">
              <input
                id="catalogue"
                className="peer/catalogue hidden"
                type="checkbox"
                name="status"
              />
              <label
                htmlFor="catalogue"
                className="z-10 pl-5 rounded-l-full font-semibold"
              >
                <h6>experiences</h6>
              </label>
              <span className="absolute -right-[22.4px] rotate-90 peer-checked/catalogue:-rotate-90">
                <i
                  className="iconify text-xl rotate-0 peer-checked/catalogue:-rotate-90"
                  data-icon="ep:arrow-up-bold"
                  data-rotate="90deg"
                />
              </span>
              <nav
                className="
                relative overflow-hidden max-h-0 p-0 rounded-bl-3xl
                peer-checked/catalogue:max-h-60 peer-checked/catalogue:overflow-visible
                bg-dark-denim with-transition
                before:absolute before:left-0 before:-top-6 before:w-full before:h-7 before:bg-inherit
              "
              >
                <ul className="py-1 text-white">
                  <li>
                    <NavLink to="/catalogue/online" className="flex gap-1">
                      <span className="pb-2 capitalize text-base">Online</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/catalogue/in-person" className="flex gap-1">
                      <span className="pb-2 text-base capitalize">
                        In-Person
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="pl-5 rounded-l-3xl font-semibold"
              >
                <h6>Contact</h6>
              </NavLink>
            </li>
            {userInfo ? (
              <li>
                <button
                  onClick={accountHandler}
                  className="pl-5 rounded-l-3xl font-semibold uppercase"
                >
                  <h6>Log out</h6>
                </button>
              </li>
            ) : (
              <li className="pl-5 mt-8">
                <NavLink
                  to="/redeem"
                  className="uppercase font-bold text-denim hover:text-white bg-yellow hover:bg-magenta rounded-full w-full h-12 flex items-center justify-center font-oswald gap-3 with-transition"
                >
                  <h6>Redeem</h6>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
}
