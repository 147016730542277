import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  createUser,
  deleteUser,
  getUserDetails,
  notifyUser,
} from "../actions/userActions";
import {
  USER_CREATE_RESET,
  USER_DELETE_RESET,
  USER_DETAILS_RESET,
  USER_NOTIFY_RESET,
} from "../constants/userConstants";

import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import CandidateShowcase from "../components/activation/CandidateShowcase";
import WhiteLayout from "../layouts/WhiteLayout";
import Warning from "../components/global/Warning";

const AdminActivationScreen = () => {
  // authenticate
  const navigate = useNavigate();
  const [errorAuth, setError] = useState(false);

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: userInfoLogin } = vrvUserLogin;

  useEffect(() => {
    if (!userInfoLogin || userInfoLogin.role !== "admin") {
      setError(true);
      setTimeout(() => {
        navigate("/admin");
      }, 5000);
    }
  }, [userInfoLogin, navigate]);

  // process
  const dispatch = useDispatch();
  const { role, id } = useParams();

  useEffect(() => {
    if (role && id) {
      if (role?.length && id?.length) {
        dispatch(getUserDetails("user", id, true));
      }
    }
  }, [role, id, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: candidate,
  } = userDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
        navigate("/");
      }, 3000);
    }
  }, [errorDetails, dispatch, navigate]);

  // create
  const userCreate = useSelector((state) => state.userCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    message: created,
  } = userCreate;

  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET });
      }, 5000);
    }
    // resetFields();
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET });
        dispatch(deleteUser("user", id, true));
      }, 5000);
    }
    // eslint-disable-next-line
  }, [created, dispatch]);

  //
  const activateHandler = (e) => {
    e.preventDefault();

    if (candidate) {
      let banner, avatar, aboutVisual, valuesVisual, exploringVisual;
      banner =
        avatar =
        aboutVisual =
        valuesVisual =
        exploringVisual =
          "https://res.cloudinary.com/vrvclassroom/image/upload/v1706564836/undefined.png";

      dispatch(
        createUser(candidate.role, {
          ...candidate,
          banner,
          avatar,
          aboutVisual,
          valuesVisual,
          exploringVisual,
        }),
      );
    }
  };

  const deleteHandler = (id) => {
    if (candidate) dispatch(deleteUser("user", id, true));
  };

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: deleting,
    error: errorDelete,
    success: deleted,
  } = userDelete;

  const [warning, setWarning] = useState(null);
  const [discarded, setDiscarded] = useState(false);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete the user (${candidate._id})?`,
      callback: () => {
        setDiscarded(true);
        deleteHandler(candidate._id);
      },
      whenDeclined: setWarning,
    });
  };

  useEffect(() => {
    // resetting state due to errors or success
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch, navigate]);

  useEffect(() => {
    // resetting state due to errors or success
    if (deleted) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
        if (discarded) {
          // navigate("/");
          dispatch(
            notifyUser(candidate?.role, candidate?.email, { approved: false }),
          );
        } else {
          dispatch(
            notifyUser(candidate?.role, candidate?.email, { approved: true }),
          );
        }
      }, 5000);
    }
  }, [candidate, deleted, discarded, dispatch, navigate]);

  // notify
  const userNotify = useSelector((state) => state.userNotify);
  const {
    loading: loadingNotify,
    error: errorNotify,
    success: notified,
  } = userNotify;

  useEffect(() => {
    if (errorNotify || notified) {
      setTimeout(() => {
        dispatch({ type: USER_NOTIFY_RESET });
        navigate("/admin");
      }, 7000);
    }
  }, [errorNotify, notified, dispatch, navigate]);

  return errorAuth ? (
    <Message error body={"Not authorized!"} />
  ) : userInfoLogin?.role === "admin" ? (
    <WhiteLayout>
      {(loadingDetails || loadingCreate || deleting || loadingNotify) && (
        <Loader />
      )}
      {errorDetails && <Message error body={errorDetails} delay={3} />}
      {errorCreate && <Message error body={errorCreate} />}
      {errorDelete && <Message error body={errorDelete} />}
      {errorNotify && <Message error body={errorNotify} />}
      {created && (
        <Message
          info
          body={`${
            role.charAt(0).toUpperCase() + role.slice(1)
          } created successfully! We are informing the ${role} Please wait...`}
        />
      )}
      {notified && (
        <Message
          info
          body={`${
            role.charAt(0).toUpperCase() + role.slice(1)
          } notified successfully!`}
        />
      )}
      {deleted && discarded && (
        <Message info body={`Candidate discarded successfully!`} />
      )}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}

      {candidate ? (
        <CandidateShowcase
          candidate={candidate}
          activateHandler={activateHandler}
          deleteHandler={showDeleteWarning}
        />
      ) : (
        <>hi</>
      )}
    </WhiteLayout>
  ) : (
    <></>
  );
};

export default AdminActivationScreen;
