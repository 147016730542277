import React, { useEffect } from "react";
import HomeHero from "../components/home/HomeHero";
import ListingCarousel from "../components/home/ListingCarousel";
import HomeMap from "../components/home/HomeMap";
import HomeExploreTheWorld from "../components/home/HomeExploreTheWorld";
import JoinUorNewsLetterForm from "../components/global/JoinUorNewsLetterForm";
import loader from "../assets/img/loading/Pantalla-de-carga.gif";
import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-green.svg";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../actions/userActions";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import { USER_LIST_RESET } from "../constants/userConstants";
import { TOUR_LIST_RESET } from "../constants/tourConstants";

const HomeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: TOUR_LIST_RESET });
    dispatch(listUsers("partner"));
  }, [dispatch]);

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  useEffect(() => {
    if (!partners) {
    }
  }, [partners, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  return (
    <>
      <MainLayout logo={Logo}>
        <HomeHero />
        {errorPartners && <Message error body={errorPartners} />}
        {loadingPartners ? (
          <Loader />
        ) : (
          !errorPartners &&
          partners?.length && (
            <ListingCarousel
              heading="explore our partners"
              collection={partners}
              type="partner"
              link="/our-network"
              styleOptions={
                "bg-denim pb-12 -mb-[292px] md:-mb-[252px] lg:-mb-[212px]"
              }
            />
          )
        )}
        <HomeMap />
        <HomeExploreTheWorld />
        <div className="bg-denim">
          <div className="container grid grid-cols-2 gap-y-14 gap-x-40 pt-10">
            <div className="col-span-2 md:col-span-1 lg:ml-auto">
              <JoinUorNewsLetterForm />
            </div>
            <img
              className="mx-auto lg:ml-0 col-span-2 md:col-span-1 w-8/12 max-w-lg"
              src={require("../assets/img/home/man-walking-world.png")}
              alt=""
            />
          </div>
        </div>
        <div
          className={`fixed top-0 z-50 h-screen w-screen grid place-items-center bg-denim transition-all duration-200 ease-in opacity-0 pointer-events-none`}
        >
          <img src={loader} alt="" />
        </div>
      </MainLayout>
    </>
  );
};

export default HomeScreen;
