import leaflet from "leaflet";
import disabledMarkerIcon from "../assets/icons/marker-disabled.svg";
import markerIcon from "../assets/icons/marker.svg";
import partnerIcon from "../assets/icons/partner-marker.svg";

const useMap = () => {
  let map = null;
  const defaultMapOptions = {
    coords: [],
    zoom: 13,
    scrollWheelZoom: true,
  };

  function createMap(elementId, options) {
    map = leaflet.map(elementId, { ...defaultMapOptions, ...options });

    leaflet
      .tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      })
      .addTo(map);
  }

  function addMarker(LatLng, options = {}, original = true, partner) {
    const icon = leaflet.icon({
      iconUrl:
        options?.icon?.iconUrl ||
        (!original ? disabledMarkerIcon : partner ? partnerIcon : markerIcon),
      ...options.icon,
    });
    const marker = leaflet.marker(LatLng, {
      icon,
    });
    marker.addTo(map);
    return marker;
  }

  return {
    createMap,
    addMarker,
  };
};
export default useMap;
