import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../constants/helpers";

import { SUBSCRIBER_REGISTER_RESET } from "../../../constants/userConstants";

import AppInput from "../form/AppInput";
import { subscribe } from "../../../actions/userActions";

const Newsletter = ({ headerProvided, copyProvided }) => {
  const subscriberRegister = useSelector((state) => state.subscriberRegister);
  const { error, message: success } = subscriberRegister;

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (name.length > 0 && email.length > 0 && validateEmail(email)) {
      const subscriber = {
        name,
        email,
      };
      dispatch(subscribe(subscriber));
    }
  };

  useEffect(() => {
    if (error || success) {
      setTimeout(() => {
        dispatch({ type: SUBSCRIBER_REGISTER_RESET });
      }, 5000);
    }
  }, [error, success, dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setName("");
        setEmail("");
      }, 5000);
    }
  }, [success]);

  return (
    <>
      <div className="w-full">
        {!headerProvided && (
          <h6 className="mb-7 uppercase text-xl font-semibold text-green">
            Subscribe to news
          </h6>
        )}
        {!copyProvided && (
          <p className="mb-10">
            Join our mailing list to keep up to date with the newest experiences
            our partners are developing and to see the next destinations we are
            set to explore for you.
          </p>
        )}
        <form
          className="lg:flex flex-col flex-wrap w-full"
          onSubmit={submitHandler}
        >
          <div className="flex-grow-0 w-full max-w-xs mb-6">
            <AppInput
              name="name"
              label="Name"
              type="text"
              labelClass="text-green"
              className="bg-transparent border-[1px] pl-3 rounded-lg text-white"
              value={name}
              onChange={nameHandler}
            />
          </div>
          <div className="flex-grow-0 w-full max-w-xs mb-6">
            <AppInput
              name="email"
              label="Email"
              type="email"
              labelClass="text-green"
              className="bg-transparent border-[1px] pl-3 rounded-lg text-white"
              value={email}
              onChange={emailHandler}
            />
          </div>
          <div className="w-fit h-fit border-2 border-transparent hover:border-white rounded-full with-transition animate-bg">
            <button
              type="submit"
              className="flex-grow-0 w-fit app-primary-button animate-bg uppercase font-semibold text-white px-6 py-3 with-transition"
              onSubmit={submitHandler}
            >
              <span className="block">subscribe</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Newsletter;
