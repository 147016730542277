import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listSessionsBySlug } from "../actions/guideActions";
import { ACCESS_LIST_RESET } from "../constants/guideConstants";

import cityDescriptions from "../constants/cityDescriptions";
import daysOfWeek from "../constants/daysOfWeek";

import { useBreakpoints } from "../hooks/useBreakpoints";

import WhiteLayout from "../layouts/WhiteLayout";
import MapView from "../components/map/MapView";
import { TabContent, TabLabel, TabWrapper } from "../components/global/AppTabs";
import Message from "../components/global/Message";
import Loader from "../components/global/Loader";
import SessionCard from "../components/admin-dashboard/components/SessionCard";

const AffiliateProfileScreen = ({ user: affiliate }) => {
  const { lg } = useBreakpoints();

  const dispatch = useDispatch();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  useEffect(() => {
    if (affiliate?.slug === loggedIn?.slug) {
      dispatch(listSessionsBySlug(affiliate.slug));
    }
  }, [affiliate, loggedIn, dispatch]);

  const accessList = useSelector((state) => state.accessList);
  const {
    loading: loadingSessions,
    error: errorSessions,
    sessions,
  } = accessList;

  useEffect(() => {
    // resetting state due to errors
    if (errorSessions) {
      setTimeout(() => {
        dispatch({ type: ACCESS_LIST_RESET });
      }, 5000);
    }
  }, [errorSessions, dispatch]);

  const sessionDisable = useSelector((state) => state.sessionDisable);
  const {
    success: cancelled,
  } = sessionDisable;

  useEffect(() => {
    // resetting state due to errors
    if (cancelled && affiliate) {
      setTimeout(() => {
        dispatch(listSessionsBySlug(affiliate.slug));
      }, 5000);
    }
  }, [cancelled, dispatch, affiliate]);

  return (
    <>
      {loadingSessions && <Loader />}
      {errorSessions && <Message error body={errorSessions} />}
      <WhiteLayout>
        <div className="min-h-[100vh]">
          {affiliate ? (
            <div className="relative grid grid-cols-12 grid-rows-auto container lg:min-h-[75rem] xl:min-h-[88rem] mt-20 lg:mt-28 overflow-hidden">
              <section className="col-span-12 lg:col-span-8 mb-20">
                <div className="mb-3">
                  <div
                    className="rounded-t-2xl h-28 md:h-[305px] w-full z-0 relative bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${affiliate?.banner})`,
                    }}
                  />
                  <div
                    className="w-24 lg:w-36 h-24 lg:h-36 mb-14 lg:mb-6 -mt-6 lg:-mt-20 mr-6 lg:ml-6 lg:mr-auto rounded-full relative bg-cover bg-center bg-no-repeat border-4 border-white"
                    style={{
                      backgroundImage: `url(${affiliate?.avatar})`,
                    }}
                  />
                  <div className="flex gap-2">
                    {affiliate?.tags?.map((ele, index) => (
                      <p
                        key={index}
                        className="w-fit px-3 py-1 -mt-7 lg:mt-5 font-semibold text-xs border-black border-2 rounded-full"
                      >
                        {ele}
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <h1 className="mb-2 text-3xl font-semibold uppercase">
                    {affiliate?.title}
                  </h1>
                  <p className="mb-5 text-lg font-semibold">
                    {affiliate?.location?.city}, {affiliate?.location?.country}
                  </p>
                  <p>{affiliate?.landAcknowledgement}</p>
                </div>
              </section>
              <div className="col-span-12 lg:col-span-8">
                <TabWrapper
                  value="about"
                  renderAllContent={lg}
                  tabClass="w-1/2 px-5 py-2 font-semibold uppercase rounded-lg with-transition"
                  inactiveTabClass="opacity-50"
                  activeTabClass="opacity-100 bg-yellow scale-105 shadow-lg"
                >
                  <div className="flex lg:hidden justify-between mb-14 rounded-lg border-2 border-gray">
                    <TabLabel name="about">
                      <h6>about</h6>
                    </TabLabel>
                    <TabLabel name="interact">
                      <h6>interact</h6>
                    </TabLabel>
                  </div>
                  <div>
                    <section>
                      <TabContent name="about">
                        <TabWrapper
                          value="about-about"
                          tabClass="uppercase font-semibold"
                          inactiveTabClass="opacity-50 hover:text-green"
                        >
                          <TabContent name="about-location">
                            <p className="mb-7 text-lg font-semibold">
                              Our Location
                            </p>
                            <p className="mb-7">{affiliate?.location?.city}</p>
                            <p className="mb-7">
                              {
                                cityDescriptions.find(
                                  (ele) =>
                                    ele.name === affiliate?.location?.city,
                                )?.description
                              }
                            </p>
                          </TabContent>
                          <p className="pr-12 mb-16">
                            To learn more about {affiliate?.title}, visit their
                            website:{" "}
                            <a
                              href={affiliate?.info?.website}
                              className="text-green hover:text-magenta font-semibold with-transition"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {affiliate?.title}
                            </a>
                          </p>
                        </TabWrapper>
                      </TabContent>
                    </section>
                    <TabContent
                      name="interact"
                      className="lg:absolute lg:top-0 lg:right-8 xl:right-24 lg:w-3/12 flex h-full pb-10"
                    >
                      <aside className="overflow-auto lg:px-1 h-full lg:rounded-lg lg:shadow-lg pb-5 z-0">
                        <MapView
                          defaultCenter={[
                            affiliate?.location?.coordinates?.latitude,
                            affiliate?.location?.coordinates?.longitude,
                          ]}
                          single
                          zoom={12}
                          className="rounded-t-3xl w-full h-48"
                          alt=""
                        />
                        <div className="lg:px-5">
                          <div className="grid grid-cols-2 gap-3 my-6">
                            <p className="col-span-2 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Name
                              </span>
                              {affiliate?.title}
                            </p>
                            <p className="col-span-2 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Street Address
                              </span>
                              {affiliate?.location?.streetAddress}
                            </p>
                            <p className="col-span-1 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                City
                              </span>
                              {affiliate?.location?.city}
                            </p>
                            {affiliate?.location?.region?.length > 0 && (
                              <p className="col-span-1 font-semibold">
                                <span className="block text-xs text-neutral-400">
                                  Region
                                </span>
                                {affiliate?.location?.region}
                              </p>
                            )}
                            {affiliate?.location?.province?.length > 0 && (
                              <p className="col-span-1 font-semibold">
                                <span className="block text-xs text-neutral-400">
                                  Province/Territory
                                </span>
                                {affiliate?.location?.province}
                              </p>
                            )}
                            <p className="col-span-1 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Country
                              </span>
                              {affiliate?.location?.country}
                            </p>
                            <p className="col-span-2 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Organization type
                              </span>
                              {affiliate?.category}
                            </p>
                            {/* <p className="col-span-2 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Email
                              </span>
                              {affiliate?.email}
                            </p>
                            <p className="col-span-1 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                Phone
                              </span>
                              +{affiliate?.info?.phone}
                            </p> */}
                          </div>
                          <div className="grid grid-cols-1 gap-1 relative w-full">
                            <span className="block text-xs text-neutral-400">
                              Availability
                            </span>
                            <div className="flex gap-2">
                              {affiliate?.info?.availability?.daysOfWeek?.map(
                                (day, index) => (
                                  <button
                                    key={index}
                                    className={`cursor-default pointer-events-none w-9 col-span-6 aspect-square py-1.5 border-2 rounded-lg border-${
                                      day ? "green" : "gray"
                                    }-border font-bold ${
                                      day ? "bg-green text-white" : ""
                                    }`}
                                  >
                                    {daysOfWeek[index].text}
                                  </button>
                                ),
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-3 my-6">
                            <p className="col-span-1 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                From
                              </span>
                              {Number(
                                affiliate?.info?.availability?.timeFrom?.split(
                                  ":",
                                )[0],
                              ) === 0
                                ? "12"
                                : Number(
                                    affiliate?.info?.availability?.timeFrom?.split(
                                      ":",
                                    )[0],
                                  ) > 12
                                ? Number(
                                    affiliate?.info?.availability?.timeFrom?.split(
                                      ":",
                                    )[0],
                                  ) - 12
                                : Number(
                                    affiliate?.info?.availability?.timeFrom?.split(
                                      ":",
                                    )[0],
                                  )}
                              {":"}
                              {
                                affiliate?.info?.availability?.timeFrom?.split(
                                  ":",
                                )[1]
                              }{" "}
                              {Number(
                                affiliate?.info?.availability?.timeTo?.split(
                                  ":",
                                )[0],
                              ) === 0
                                ? "12"
                                : Number(
                                    affiliate?.info?.availability?.timeFrom?.split(
                                      ":",
                                    )[0],
                                  ) > 11
                                ? "PM"
                                : "AM"}
                            </p>
                            <p className="col-span-1 font-semibold">
                              <span className="block text-xs text-neutral-400">
                                To
                              </span>
                              {Number(
                                affiliate?.info?.availability?.timeTo?.split(
                                  ":",
                                )[0],
                              ) > 12
                                ? Number(
                                    affiliate?.info?.availability?.timeTo?.split(
                                      ":",
                                    )[0],
                                  ) - 12
                                : Number(
                                    affiliate?.info?.availability?.timeTo?.split(
                                      ":",
                                    )[0],
                                  )}
                              {":"}
                              {
                                affiliate?.info?.availability?.timeTo?.split(
                                  ":",
                                )[1]
                              }{" "}
                              {Number(
                                affiliate?.info?.availability?.timeTo?.split(
                                  ":",
                                )[0],
                              ) > 11
                                ? "PM"
                                : "AM"}
                            </p>
                          </div>
                          <div className="mx-auto mt-6 w-48 h-fit border-2 border-transparent hover:border-denim overflow-hidden rounded-full with-transition">
                            <a
                              href={affiliate?.info?.website}
                              className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-48 mx-auto flex justify-center gap-5 with-transition"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="block">Visit their website</span>
                            </a>
                          </div>
                          {sessions ? (
                            <div className="grid grid-cols-1 mt-16">
                              <h6 className="mb-7 uppercase font-semibold">
                                Our requested sessions
                              </h6>
                              <div className="grid grid-cols-1 gap-3 mb-16">
                                {sessions?.length ? (
                                  <>
                                    {sessions.map((session, index) => (
                                      <SessionCard
                                        key={index}
                                        session={session}
                                        className="flex-col gap-8"
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <p>
                                    Your requested sessions will appear here!
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </aside>
                    </TabContent>
                  </div>
                </TabWrapper>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </WhiteLayout>
    </>
  );
};

export default AffiliateProfileScreen;
