import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GoBack = ({ className, onBackClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  const handleClick = () => {
    if (hasPreviousState) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <div
      className={
        className
          ? className
          : "rounded-full bg-white absolute w-8 aspect-square mt-4 ml-4 grid items-center"
      }
      onClick={onBackClick || handleClick}
    >
      <i
        className="iconify ml-2"
        data-icon="material-symbols:arrow-back-ios-rounded"
        onClick={handleClick}
      />
    </div>
  );
};

export default GoBack;
