import React, { forwardRef, useEffect, useState } from "react";
import { areArraysEqual, removeAccents } from "../../../helpers";

const AppChipsInput = (
  {
    id,
    items = [],
    value = [],
    label,
    placeholder,
    type,
    error,
    className,
    labelClass,
    asRequired,
    onChange,
    ...rest
  },
  ref,
) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalItemsList, setTotalItemsList] = useState([
    ...new Set([...items]),
  ]);

  useEffect(() => {
    const newItems = [...new Set([...selectedItems, ...value])];
    setSelectedItems((oldValue) =>
      areArraysEqual(newItems, oldValue) ? oldValue : newItems,
    );

    // eslint-disable-next-line
  }, [value, items]);

  useEffect(() => {
    const newItems = [...new Set([...items])];
    setTotalItemsList((oldValue) =>
      areArraysEqual(newItems, oldValue) ? oldValue : newItems,
    );
  }, [items]);

  useEffect(() => {
    const filterResult = totalItemsList.filter((item) => {
      const normalizedSearch = removeAccents(search);
      const normalizedItem = removeAccents(item);
      const isSelected = selectedItems.includes(item);
      return normalizedItem.includes(normalizedSearch) && !isSelected;
    });
    if (search.length > 0 && !selectedItems.includes(search)) {
      filterResult.push(search);
    }
    setFilteredItems((oldValue) =>
      areArraysEqual(filterResult, oldValue) ? oldValue : filterResult,
    );

    // eslint-disable-next-line
  }, [isListOpen, search, items]);

  const onchange = (ev) => {
    setIsListOpen(true);
    setSearch(ev.target.value.trim());
  };

  const addItem = (itemToAdd) => {
    if (!selectedItems.includes(itemToAdd)) {
      const newItems = [...selectedItems, itemToAdd];
      setSelectedItems(newItems);
      updateValue(newItems);
      setIsListOpen(false);
      setSearch("");
    }
  };

  const removeItem = (itemToRemove) => {
    const filterResult = selectedItems.filter((item) => item !== itemToRemove);
    setSelectedItems(filterResult);
    updateValue(filterResult);
  };

  const updateValue = (newValue) => {
    onChange?.(newValue);
  };

  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <span
        className={`relative flex with-transition ${
          error ? "text-red-500" : labelClass
        } ${asRequired && "required-help"}`}
      >
        {label}
        <span className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}>
          *
        </span>
      </span>
      <div
        className={` flex flex-wrap gap-2
            w-full py-1.5 px-2 focus:outline-none caret-current with-transition border-2 border-gray-border rounded-lg
            ${error && "border-red-500"}
            ${className}
          `}
      >
        <ul className="flex flex-wrap gap-2">
          {selectedItems?.length ? (
            selectedItems.map((item) => (
              <li
                key={item}
                className="flex items-center gap-2 py-1 px-2 border border-denim rounded-full px-4"
              >
                {item}
                <button onClick={() => removeItem(item)}>x</button>
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
        <input
          className="bg-transparent"
          ref={ref}
          type={type || "text"}
          id={id}
          placeholder={placeholder}
          value={search}
          onChange={onchange}
          onFocus={() => setIsListOpen(true)}
          onBlur={() => setIsListOpen(false)}
          {...rest}
        />
        <label
          htmlFor={id}
          className="ml-auto cursor-pointer grid items-center"
        >
          <i
            className="iconify text-lg text-gray-border"
            data-icon="mingcute:add-fill"
            data-rotate="180deg"
          />
        </label>
      </div>
      <div className={`dropdown ${isListOpen && "dropdown-open"}`}>
        <ul
          tabIndex={0}
          className="dropdown-content menu grid w-full max-h-60 overflow-auto p-2 shadow bg-white rounded-box"
        >
          {filteredItems?.length &&
            filteredItems.map((item, i) => (
              <li key={`${item}-${i + 1}`}>
                <span onMouseDown={() => addItem(item)}>{item}</span>
              </li>
            ))}
        </ul>
      </div>
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};

export default forwardRef(AppChipsInput);
