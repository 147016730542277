import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addTourReview } from "../../../actions/tourActions";

import AppTextArea from "./AppTextArea";
import SetRating from "./SetRating";

const ReviewForm = ({ tour, user }) => {
  const dispatch = useDispatch();

  const [reviewBody, setReviewBody] = useState(``);
  const [reviewRating, setReviewRating] = useState(1);

  const resetReviewFields = () => {
    setReviewBody(``);
    setReviewRating(1);
  };

  const reviewBodyHandler = (e) => {
    e.preventDefault();
    setReviewBody(e.target.value);
  };

  const submitReviewHandler = (e) => {
    e.preventDefault();

    if (reviewBody?.length) {
      const review = {
        user: user._id,
        avatar: user.avatar,
        name: user.name,
        rating: reviewRating,
        comment: reviewBody,
      };

      dispatch(addTourReview(tour._id, review));

      resetReviewFields();
    }
  };

  return (
    <>
      <div>
        <p className="text-gray-border mb-8">Add a review</p>
        <p className="font-bold">
          Your Rating <span className="text-green">*</span>
        </p>
        <div className="flex text-4xl text-gray-border mb-4">
          <SetRating rating={reviewRating} setRating={setReviewRating} />
        </div>
        <form
          className="grid md:grid-cols-2 gap-5 mb-16"
          onSubmit={submitReviewHandler}
        >
          <div className="col-span-2">
            <div>
              <AppTextArea
                asRequired
                id="message"
                label="Your Review"
                labelClass="font-semibold"
                className="lg:py-3 bg-transparent border-gray-border border-2 rounded-lg"
                rows="6"
                value={reviewBody}
                onChange={reviewBodyHandler}
              />
            </div>
            <div className="mt-6 mx-auto md:mx-0 w-44 h-fit rounded-full">
              <button
                type="submit"
                onSubmit={submitReviewHandler}
                className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 border-2 border-transparent hover:border-denim"
              >
                <span className="block">submit review</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReviewForm;
