import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGuideDetails } from "../actions/guideActions";
import { TOUR_DETAILS_RESET } from "../constants/tourConstants";
import { getUserDetails } from "../actions/userActions";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { useBreakpoints } from "../hooks/useBreakpoints";

import { TourExploreWelcome } from "../components/tour-explore/TourExploreWelcome";
import { TabContent, TabWrapper } from "../components/global/AppTabs";
import { TourExploreMap } from "../components/tour-explore/TourExploreMap";
import { TourExploreHotSpotData } from "../components/tour-explore/TourExploreHotSpotData";
import { TourExploreGalleryItem } from "../components/tour-explore/TourExploreGalleryItem";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";

const SelfGuideScreen = ({ slug }) => {
  const dispatch = useDispatch();
  const { xl } = useBreakpoints();

  const [tabSection, setTabSection] = useState("welcome");
  const [selectedHotSpot, setSelectedHotSpot] = useState(null);
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(null);

  useEffect(() => {
    if (slug) {
      dispatch(getGuideDetails(slug));
    }
  }, [slug, dispatch]);

  const tourDetails = useSelector((state) => state.tourDetails);
  const {
    loading: loadingTourDetails,
    error: errorTourDetails,
    tour: selfGuide,
  } = tourDetails;

  useEffect(() => {
    if (errorTourDetails) {
      setTimeout(() => {
        dispatch({ type: TOUR_DETAILS_RESET });
      }, 5000);
    }
  }, [errorTourDetails, dispatch]);

  useEffect(() => {
    if (selfGuide) {
      dispatch(getUserDetails("partner", selfGuide.partner));
    }
  }, [selfGuide, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    userInfo: partner,
  } = userDetails;

  useEffect(() => {
    if (errorUserDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorUserDetails, dispatch]);

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [tabSection]);

  const hotspotHandler = (hotSpot) => {
    setSelectedHotSpot(hotSpot);
    setTabSection("hotSpot");
  };

  const galleryItemHandler = (item) => {
    setSelectedGalleryItem(item);
    setTabSection("galleryItem");
  };

  return !xl && slug ? (
    <main className="min-h-screen">
      {errorTourDetails && <Message error body={errorTourDetails} />}
      {errorUserDetails && <Message error body={errorUserDetails} />}
      {loadingTourDetails || loadingUserDetails ? (
        <Loader />
      ) : selfGuide && partner ? (
        <TabWrapper value={tabSection} className="h-screen">
          <TabContent name="welcome">
            <TourExploreWelcome
              tour={selfGuide}
              partner={partner}
              onMapClick={() => setTabSection("map")}
            />
          </TabContent>
          <TabContent name="map" className="h-full">
            <TourExploreMap
              tour={selfGuide}
              onBackClick={() => setTabSection("welcome")}
              onHotSpotClick={(hotSpot) => hotspotHandler(hotSpot)}
            />
          </TabContent>
          <TabContent name="hotSpot">
            <TourExploreHotSpotData
              data={selectedHotSpot}
              color={selfGuide.content.color}
              onBackClick={(parent) =>
                parent ? hotspotHandler(parent) : setTabSection("map")
              }
              onGalleryItemClick={(item) => galleryItemHandler(item)}
              onHotSpotClick={(hotSpot) => hotspotHandler(hotSpot)}
              onMapClick={() => setTabSection("map")}
            />
          </TabContent>
          <TabContent name="galleryItem">
            <TourExploreGalleryItem
              color={selfGuide.content.color}
              data={selectedGalleryItem}
              onBackClick={() => setTabSection("hotSpot")}
            />
          </TabContent>
        </TabWrapper>
      ) : (
        <></>
      )}
    </main>
  ) : null;
};

export default SelfGuideScreen;
