import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { MAIN_ROUTE } from "../constants/api";
import axios from "axios";

import ValidateCode from "../components/tours/ValidateCode";
import TourViewer from "../components/tours/TourViewer";

const TourScreen = () => {
  const [code, setCode] = useState("");
  const [viewer, setViewer] = useState(false);

  const codeHandler = (e) => {
    setCode(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const { data } = await axios.get(`${MAIN_ROUTE}/tours/testauth`);

    if (code === data) {
      setViewer(true);
      setCode("");
    }
  };

  //
  const { partner, title, language, type } = useParams();

  return viewer ? (
    <TourViewer
      tour={{
        title,
      }}
      order={{
        tour: {
          src: `https://thevrvoyage.s3.ca-central-1.amazonaws.com/tours/${partner}/${title}/${language}/${type}/index.htm`,
        },
      }}
    />
  ) : (
    <ValidateCode
      code={code}
      codeHandler={codeHandler}
      submitHandler={submitHandler}
    />
  );
};

export default TourScreen;
