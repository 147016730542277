import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

import {
  getUserDetails,
  loginFromToken,
  updateUserInfoViaToken,
} from "../actions/userActions";
import {
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
} from "../constants/userConstants";

import {
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validatePassword,
} from "../constants/helpers";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import AppInput from "../components/global/form/AppInput";
import PasswordRules from "../components/global/PasswordRules";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const { token } = useParams();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const {
    loading: loadingLogin,
    error: errorLogin,
    userInfo: userInfoLogin,
  } = vrvUserLogin;

  useEffect(() => {
    if (token) {
      if (userInfoLogin) {
        dispatch(getUserDetails("user", userInfoLogin.slug));
      } else {
        if (!loadingLogin && !errorLogin) {
          dispatch(loginFromToken(token));
        }
      }
    }
  }, [token, userInfoLogin, loadingLogin, errorLogin, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: found,
  } = userDetails;

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    userInfo: userInfoUpdate,
  } = userDetailsUpdate;

  const navigate = useNavigate();

  useEffect(() => {
    if (token && userInfoLogin) {
      dispatch(getUserDetails("user", jwt_decode(token).id));
    }
  }, [dispatch, token, userInfoLogin]);

  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const resetFields = () => {
    setNewPassword("");
    setConfirmedPassword("");
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();

    if (newPassword.length > 0 && confirmedPassword.length > 0) {
      if (newPassword === confirmedPassword) {
        if (validatePassword(newPassword)) {
          const updates = {
            password: newPassword,
          };
          dispatch(updateUserInfoViaToken("user", found.slug, token, updates));
        } else {
          if (!validatePassword(newPassword)) {
            dispatch({
              type: USER_UPDATE_FAIL,
              payload: passwordValidateErrorMessage,
            });
          }
        }
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: "Passwords don't match!",
        });
      }
    } else {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    if (userInfoUpdate) {
      resetFields();
      setTimeout(() => {
        resetFields();
        dispatch({ type: USER_UPDATE_RESET });
        navigate(
          `/user/profile/${userInfoLogin?.slug || jwt_decode(token).id}`,
        );
      }, 5000);
    }

    // eslint-disable-next-line
  }, [userInfoUpdate, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (userInfoUpdate || errorUpdate) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
      }, 5000);
    }
  }, [userInfoUpdate, errorUpdate, dispatch]);

  return (
    <>
      {errorDetails && <Message error body={errorDetails} />}
      {errorUpdate && <Message error body={errorUpdate} />}
      {userInfoUpdate && (
        <Message
          info
          body={
            "Congratulations! Please wait a moment! We're setting things up for you."
          }
        />
      )}
      {loadingDetails ? (
        <Loader />
      ) : loadingUpdate ? (
        <Loader />
      ) : (
        found && (
          <>
            <MainLayout
              scrollNavClass="bg-gray"
              logo={Logo}
              textHover="text-green"
              textHoverWithScroll="text-green"
              styleBtnWithScroll="hover:text-denim hover:border-denim"
            >
              <NavLink to="/" className="lg:hidden block ml-4 mt-6">
                <img src={Logo} alt="Logo" className="w-48" />
              </NavLink>
              <div>
                <>
                  <div className="container lg:grid grid-cols-7 mt-12 lg:mt-32 gap-24 pb-24">
                    <div className="lg:col-span-3 flex flex-col gap-4">
                      <PasswordRules
                        password={newPassword}
                        heading={`Hello ${found.name}, Let's reset your password!`}
                        body="Please make sure that your new password is strong
                          enough not to get guessed easily. So, let's consider
                          the following rules:"
                      />
                      <form
                        className="grid gap-5"
                        onSubmit={resetPasswordHandler}
                      >
                        <AppInput
                          asRequired
                          id="new-password"
                          type="password"
                          label="Enter Your New Password"
                          value={newPassword}
                          onChange={newPasswordHandler}
                          labelClass="font-semibold"
                          className="bg-transparent border-gray-border border-2 rounded-lg"
                        />
                        <AppInput
                          asRequired
                          id="confirmed-password"
                          type="password"
                          label="Confirm Your New password"
                          value={confirmedPassword}
                          onChange={confirmedPasswordHandler}
                          labelClass="font-semibold"
                          className="bg-transparent border-gray-border border-2 rounded-lg"
                        />
                        <div className="w-full rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto lg:mx-0">
                          <button
                            type="submit"
                            className="font-semibold text-white hover:text-denim uppercase app-primary-button h-14 w-full flex justify-center lg:ml-0"
                            onSubmit={resetPasswordHandler}
                          >
                            <span className="block">submit</span>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="hidden lg:block col-span-4">
                      <div
                        className="bg-cover bg-center bg-no-repeat h-full rounded-tl-2xl rounded-br-2xl"
                        style={{
                          backgroundImage: `url(${require("../assets/img/login/create-password.png")})`,
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
            </MainLayout>
          </>
        )
      )}
    </>
  );
};

export default ResetPasswordScreen;
