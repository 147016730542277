import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { logout, deleteUser } from "../../actions/userActions";
import Warning from "../global/Warning";
import Loader from "../global/Loader";
import Message from "../global/Message";
import { USER_DELETE_RESET } from "../../constants/userConstants";

const SettingsUser = ({ setOption }) => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: userInfoLogin } = vrvUserLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = userDelete;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settingsOptions = [
    {
      name: "main profile",
      link: `/user/profile/${userInfoLogin?.slug}`,
      dataIcon: "mdi:user",
    },
    {
      name: "edit tours",
      link: "/",
      dataIcon: "mdi:airplane",
    },
    {
      name: "reset password",
      link: "/user/reset-password",
      dataIcon: "zondicons:lock-closed",
    },
    // {
    //   name: "help center",
    //   link: "/help",
    //   dataIcon: "mdi:information-variant",
    // },
    {
      name: "delete account",
      link: "/",
      dataIcon: "material-symbols:delete-rounded",
    },
    {
      name: "log out",
      link: "/login",
      dataIcon: "iconoir:log-in",
    },
  ];

  const [selected, setSelected] = useState(1);

  useEffect(() => {
    setOption(selected);

    // eslint-disable-next-line
  }, [selected]);

  const accountHandler = (e) => {
    e.preventDefault();

    if (userInfoLogin) {
      dispatch(logout());
      navigate("/login");
    }
  };

  const deleteAccountHandler = (e) => {
    if (userInfoLogin) {
      dispatch(deleteUser("user", userInfoLogin.slug));
    }
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: "Before you proceed, we want to ensure that you understand the consequences of this action. By deleting your account, all your profile settings and information will be permanently lost. Additionally, please note that you will lose access to any subscriptions or services associated with your account.",
      callback: deleteAccountHandler,
      whenDeclined: setWarning,
    });
  };

  useEffect(() => {
    if (successDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
        dispatch(logout());
        navigate("/");
      }, 7000);
    }
  }, [successDelete, dispatch, navigate]);

  useEffect(() => {
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
      }, 7000);
    }
  }, [errorDelete, dispatch]);

  return (
    <>
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
            setSelected(1);
          }}
        />
      )}
      {errorDelete && <Message error body={errorDelete} />}
      {loadingDelete ? (
        <Loader />
      ) : successDelete ? (
        <Message info body="User deleted successfully!" />
      ) : (
        <div className="w-11/12 h-screen">
          <div className="flex flex-col gap-1">
            {settingsOptions.map((ele, index) => (
              <NavLink
                to={ele.link}
                className={`flex justify-between rounded-lg py-2 px-4 hover:bg-grey ${
                  selected === index ? "bg-white" : ""
                } with-transition`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setSelected(index);
                  switch (ele.name) {
                    case "main profile":
                      navigate(`/user/profile/${userInfoLogin.slug}`);
                      break;
                    case "reset password":
                      break;
                    case "log out":
                      accountHandler(e);
                      break;
                    case "delete account":
                      showDeleteWarning(e);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <div className="flex items-center gap-3">
                  <i className="iconify" data-icon={ele.dataIcon} />
                  <p className="capitalize">{ele.name}</p>
                </div>
                <i
                  className="iconify"
                  data-icon="material-symbols:arrow-back-ios-rounded"
                  data-rotate="180deg"
                />
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsUser;
