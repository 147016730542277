import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createUser } from "../../../actions/userActions";
import {
  USER_CREATE_FAIL,
  USER_CREATE_RESET,
  USER_LIST_RESET,
} from "../../../constants/userConstants";

import {
  matchPasswords,
  passwordMatchingErrorMessage,
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validateEmail,
  validatePassword,
  validateRequiredFields,
} from "../../../constants/helpers";

import AppModal from "../../global/AppModal";
import AppInput from "../../global/form/AppInput";

const CreateUser = () => {
  const dispatch = useDispatch();

  const userCreate = useSelector((state) => state.userCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    message: created,
  } = userCreate;

  //

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmedPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (!validateRequiredFields([name, email, password, confirmedPassword])) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!validateEmail(email)) {
        throw new Error("Invalid email format!");
      }

      if (!validatePassword(password) || !validatePassword(confirmedPassword)) {
        throw new Error(passwordValidateErrorMessage);
      }

      if (!matchPasswords(password, confirmedPassword)) {
        throw new Error(passwordMatchingErrorMessage);
      }

      const user = {
        name,
        email,
        password,
      };

      dispatch(createUser("user", user));
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET });
      }, 5000);
    }
    // resetFields();
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        resetFields();
        dispatch({ type: USER_CREATE_RESET });
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [created, dispatch]);

  return (
    <AppModal
      activator={
        <button
          type="button"
          className="order-2 lg:order-3 uppercase font-semibold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3 with-transition"
        >
          Create New user
        </button>
      }
      styleModal="h-full lg:h-3/4 pb-14"
      styleLabelClose="sticky w-10 h-10 rounded-full"
      styleIconClose="text-denim text-3xl bg-gray rounded-full p-1"
      withLoading={loadingCreate}
    >
      <div>
        <p className="font-bold text-lg lg:sticky absolute top-4 left-4 lg:pl-6 z-[1]">
          Create New User
        </p>
        <form
          className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5"
          onSubmit={submitHandler}
        >
          <AppInput
            asRequired
            id="name"
            label="Name"
            type="text"
            labelClass="font-bold font-quicksand"
            className="bg-transparent"
            value={name}
            onChange={nameHandler}
          />
          <AppInput
            asRequired
            id="Email"
            label="Email"
            type="email"
            labelClass="font-bold font-quicksand"
            className="bg-transparent"
            value={email}
            onChange={emailHandler}
          />
          <div className="lg:flex gap-8">
            <AppInput
              asRequired
              id="password"
              type="password"
              label="Password"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={password}
              onChange={passwordHandler}
            />
            <AppInput
              asRequired
              id="confirmed-password"
              type="password"
              label="Confirm Password"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={confirmedPassword}
              onChange={confirmedPasswordHandler}
            />
          </div>
          <button
            type="submit"
            className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3"
            onSubmit={submitHandler}
          >
            Create User
          </button>
        </form>
      </div>
    </AppModal>
  );
};

export default CreateUser;
