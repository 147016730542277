import React from "react";
import Newsletter from "./footer/Newsletter";

const JoinUorNewsLetterForm = () => {
  return (
    <div className="overflow-hidden flex flex-col items-start gap-8 w-full max-w-md px-11 py-11 bg-white/5 backdrop-blur-sm border-radius">
      <h3 className="w-10/12 uppercase text-3xl uppercase font-semibold text-green">
        join our newsletter
      </h3>
      <p className="text-white">
        Join our mailing list to keep up to date with the newest experiences our
        partners are developing and to see the next destinations we are set to
        explore for you.
      </p>
      <Newsletter headerProvided copyProvided />
    </div>
  );
};

export default JoinUorNewsLetterForm;
