import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Draggable from "react-draggable";

import { deleteGuide, listGuides } from "../../../actions/guideActions";
import { listMedia, uploadGuideMedia } from "../../../actions/mediaActions";
import { CreateGuide } from "./CreateGuide";
import { TabContent, TabLabel, TabWrapper } from "../../global/AppTabs";

import { importGuide, writeGuide } from "../../../actions/guideActions";
import { GUIDE_LIST_RESET, newGuide } from "../../../constants/guideConstants";

import {
  MEDIA_DELETE_RESET,
  MEDIA_LIST_RESET,
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../../../constants/mediaConstants";
import { TOUR_DELETE_RESET } from "../../../constants/tourConstants";

import UploadMenu from "../components/UploadMenu";
import { HorizontalCards } from "../components/HorizontalCards";
import { AppDropdown } from "../../global/AppDropdown";
import AppMiniAudioPlayer from "../../global/AppMiniAudioPlayer";
import SimpleSearch from "../components/SimpleSearch";
import Loader from "../../global/Loader";
import Message from "../../global/Message";
import Warning from "../../global/Warning";

const ManageGuides = () => {
  const dispatch = useDispatch();

  const [guideObj, setGuide] = useState(null);

  useEffect(() => {
    setGuide(importGuide() || newGuide);
  }, []);

  const guideList = useSelector((state) => state.guideList);
  const {
    loading: loadingGuides,
    error: errorGuides,
    guides,
  } = guideList;

  useEffect(() => {
    // reset fields due to errors
    if (errorGuides) {
      setTimeout(() => {
        dispatch({ type: GUIDE_LIST_RESET });
      }, 5000);
    }
  }, [errorGuides, dispatch]);

  useEffect(() => {
    dispatch(listGuides());
  }, [dispatch]);

  //
  const tourCreate = useSelector((state) => state.tourCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: created,
  } = tourCreate;

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        setGuide(newGuide);
      }, 3000);
    }
  }, [created, dispatch]);

  const deleteGuideHandler = (e) => {
    e.preventDefault();

    dispatch(deleteGuide(e.target.id));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete ${
        guides.find((guide) => guide.slug === e.target.id).title
      }?`,
      callback: () => {
        deleteGuideHandler(e);
      },
      whenDeclined: setWarning,
    });
  };

  const resetGuideData = () => {
    writeGuide(newGuide);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const showDiscardWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to discard guide?! All changes will be erased.`,
      callback: () => {
        resetGuideData();
      },
      whenDeclined: setWarning,
    });
  };

  const tourDelete = useSelector((state) => state.tourDelete);
  const {
    loading: deleting,
    error: errorGuideDelete,
    success: deletedGuide,
  } = tourDelete;

  useEffect(() => {
    // resetting state due to errors
    if (errorGuideDelete || deletedGuide) {
      setTimeout(() => {
        dispatch({ type: TOUR_DELETE_RESET });
      }, 5000);
    }
  }, [errorGuideDelete, deletedGuide, dispatch]);

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { error: errorUpload, file: uploaded } = mediaUpload;

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  useEffect(() => {
    if (uploaded) {
      setElementContent(uploaded);
      dispatch(listMedia());
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 3000);
    }
  }, [uploaded, dispatch]);

  const mediaList = useSelector((state) => state.mediaList);
  const { error: errorFiles, files } = mediaList;

  useEffect(() => {
    if (errorFiles) {
      setTimeout(() => {
        dispatch({ type: MEDIA_LIST_RESET });
        // dispatch(listMedia());
      }, 5000);
    }
  }, [errorFiles, dispatch]);

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listMedia());
  };

  const [filteredFiles, setFilteredFiles] = useState(null);

  useEffect(() => {
    if (files) {
      setFilteredFiles(files);
    }
  }, [files]);

  const filterFiles = (e) => {
    if (files) {
      if (e.length > 0) {
        setFilteredFiles(files.filter((file) => file[1].includes(e)));
      } else {
        setFilteredFiles(files);
      }
    }
  };

  const mediaDelete = useSelector((state) => state.mediaDelete);
  const { error: errorDelete, success: deleted } = mediaDelete;

  useEffect(() => {
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: MEDIA_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch]);

  useEffect(() => {
    if (deleted) {
      dispatch({ type: MEDIA_DELETE_RESET });
      dispatch(listMedia());
    }
  }, [deleted, dispatch]);

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const [displayWindow, setDisplayWindow] = useState(false);

  const displayMenu = (e) => {
    e.preventDefault();
    getMediaFiles(e);
    setElementType(e.target.id);
    setElementContent(``);
    setDisplayWindow(true);
  };

  const hideMenu = (e) => {
    e.preventDefault();
    dispatch({ type: MEDIA_LIST_RESET });
    setFilteredFiles(null);

    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  //
  const uploadVisual = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file || !/svg/.test(file.type)) {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: SVG)",
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    dispatch(uploadGuideMedia("svg", formData));
  };

  const uploadHotspotVisual = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file || !/jpg|jpeg|png/.test(file.type)) {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: JPG/JPEG/PNG)",
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    dispatch(uploadGuideMedia("svg", formData));
  };

  const setVisual = (e) => {
    const params = elementContent.split(".");

    if (elementContent?.length && elementType?.length) {
      if (elementType === "map") {
        if (params[params.length - 1] !== "svg") {
          dispatch({
            type: MEDIA_UPLOAD_FAIL,
            payload: "Invalid file format! (Supported formats: SVG)",
          });
        } else {
          setGuide({ ...guideObj, map: elementContent.replace("\\", "/") });
        }
      } else if (elementType === "cover") {
        setGuide({ ...guideObj, cover: elementContent.replace("\\", "/") });
      } else if (elementType.split("-")[0] === "floorplan") {
        if (params[params.length - 1] !== "svg") {
          dispatch({
            type: MEDIA_UPLOAD_FAIL,
            payload: "Invalid file format! (Supported formats: SVG)",
          });
        } else {
          setGuide({
            ...guideObj,
            structures: [
              ...guideObj.structures.map((structure, structureIndex) => {
                if (structureIndex === Number(elementType.split("-")[1])) {
                  structure.floorplans.map((floorplan, floorplanIndex) => {
                    if (floorplanIndex === Number(elementType.split("-")[2])) {
                      floorplan.map = elementContent.replace("\\", "/");
                    }

                    return floorplan;
                  });
                }

                return structure;
              }),
            ],
          });
        }
      } else if (elementType.split("-")[0] === "hotspot") {
        const ids = elementType.split("-");
        // main
        if (ids[1] === "main") {
          setGuide({
            ...guideObj,
            stops: [
              ...guideObj.stops.map((stop, stopIndex) => {
                if (stopIndex === Number(ids[2])) {
                  stop.hotspots.map((hotspot, hotspotIndex) => {
                    if (hotspotIndex === Number(ids[3])) {
                      hotspot.media = elementContent.replace("\\", "/");
                    }

                    return hotspot;
                  });
                }

                return stop;
              }),
            ],
          });
        } else if (ids[1] === "stop") {
          setGuide({
            ...guideObj,
            structures: [
              ...guideObj.structures.map((structure, structureIndex) => {
                if (structureIndex === Number(ids[2])) {
                  structure.floorplans.map((floorplan, floorplanIndex) => {
                    if (floorplanIndex === Number(ids[3])) {
                      floorplan.stops.map((stop, stopIndex) => {
                        if (stopIndex === Number(ids[4])) {
                          stop.hotspots.map((hotspot, hotspotIndex) => {
                            if (hotspotIndex === Number(ids[5])) {
                              hotspot.media = elementContent.replace("\\", "/");
                            }

                            return hotspot;
                          });
                        }

                        return stop;
                      });
                    }

                    return floorplan;
                  });
                }

                return structure;
              }),
            ],
          });
        }
        // internal
      } else if (elementType.split("-")[0] === "banner") {
        const ids = e.target.id.split("-");

        if (ids?.length === 2) {
          setGuide({
            ...guideObj,
            stops: [
              ...guideObj.stops.map((stop, index) => {
                if (index === Number(ids[1])) {
                  stop.banner = elementContent.replace("\\", "/");
                }
                return stop;
              }),
            ],
          });
        } else if (ids?.length > 2) {
          setGuide({
            ...guideObj,
            structures: [
              ...guideObj.structures.map((structure, structureIndex) => {
                if (structureIndex === Number(ids[1])) {
                  structure.floorplans.map((floorplan, floorplanIndex) => {
                    if (floorplanIndex === Number(ids[2])) {
                      floorplan.stops.map((stop, stopIndex) => {
                        if (stopIndex === Number(ids[3])) {
                          stop.banner = elementContent.replace("\\", "/");
                        }

                        return stop;
                      });
                    }

                    return floorplan;
                  });
                }

                return structure;
              }),
            ],
          });
        }
      }

      hideMenu(e);
      setElementContent("");
      setElementType("");
    }
  };

  //
  const uploadAudio = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file || !/mpeg|wav|mp3|acc|ogg/.test(file.type)) {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: MP3/WAV/ACC/OGG)",
      });
      return;
    }

    const formData = new FormData();
    formData.append("audio", file);
    dispatch(uploadGuideMedia("audio", formData));
  };

  const setAudio = (e) => {
    const ids = e.target.id.split("-");

    if (elementContent?.length && elementType?.length) {
      if (ids?.length === 1) {
        setGuide({
          ...guideObj,
          voiceOver: elementContent.replace("\\", "/"),
        });
      } else if (ids?.length === 2) {
        setGuide({
          ...guideObj,
          stops: [
            ...guideObj.stops.map((stop, index) => {
              if (index === Number(ids[1])) {
                stop.audio = elementContent.replace("\\", "/");
              }
              return stop;
            }),
          ],
        });
      } else if (ids?.length > 2) {
        setGuide({
          ...guideObj,
          structures: [
            ...guideObj.structures.map((structure, structureIndex) => {
              if (structureIndex === Number(ids[1])) {
                structure.floorplans.map((floorplan, floorplanIndex) => {
                  if (floorplanIndex === Number(ids[2])) {
                    floorplan.stops.map((stop, stopIndex) => {
                      if (stopIndex === Number(ids[3])) {
                        stop.audio = elementContent.replace("\\", "/");
                      }

                      return stop;
                    });
                  }

                  return floorplan;
                });
              }

              return structure;
            }),
          ],
        });
      }

      hideMenu(e);
      setElementContent("");
      setElementType("");
    }
  };

  const uploadVideo = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file || !/mp4/.test(file.type)) {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: MP4)",
      });
      return;
    }

    const formData = new FormData();
    formData.append("video", file);
    dispatch(uploadGuideMedia("video", formData));
  };

  const setVideo = (e) => {
    const ids = e.target.id.split("-");

    if (ids[1] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[2])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[3])) {
                  hotspot.media = elementContent.replace("\\", "/");
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[1] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[2])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[3])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[4])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[5])) {
                          hotspot.media = elementContent.replace("\\", "/");
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }

    hideMenu(e);
    setElementContent("");
    setElementType("");
  };

  //
  const handleColor = (e) => {
    setGuide({ ...guideObj, color: e.target.value });
  };

  useEffect(() => {
    writeGuide(guideObj);
  }, [guideObj]);

  //
  //
  //
  // stops
  const serializeItem = (array) => {
    if (!array?.length) return 1;

    const numbers = array.map((element) => Number(element.number));

    let i = 1;
    while (true) {
      if (!numbers.includes(i)) return i;
      i++;
    }
  };

  const addNewStop = (e) => {
    const ids = e.target?.id.split("-");

    if (ids[0] === "main") {
      const number = serializeItem(guideObj.stops);

      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops,
          {
            number,
            title: `new stop ${number}`,
            script: "",
            audio: "",
            banner: "",
            coords: {
              x: Math.floor(Math.random() * 1000) % (768 - 20),
              y: Math.floor(Math.random() * 1000) % (768 - 20),
            },
            hotspots: [],
          },
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                const number = serializeItem(floorplan.stops);

                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.push({
                    number,
                    title: `new stop ${number}`,
                    script: "",
                    audio: "",
                    banner: "",
                    coords: {
                      x: Math.floor(Math.random() * 1000) % (360 - 20),
                      y: Math.floor(Math.random() * 1000) % (640 - 20),
                    },
                    hotspots: [],
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const removeStop = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: guideObj.stops.filter((ele, index) => index !== Number(ids[1])),
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops = floorplan.stops.filter(
                    (ele, index) => index !== Number(ids[3]),
                  );
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopDraggingCoords = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              if (Number(e.layerX) >= 10 && Number(e.layerX) <= 758) {
                stop.coords.x = Number(e.layerX) - 10;
              }
              stop.coords.y = Number(e.layerY) + index * 20 - 10;
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      if (Number(e.layerX) >= 10 && Number(e.layerX) <= 360) {
                        stop.coords.x = Number(e.layerX) - 10;
                      }
                      stop.coords.y = Number(e.layerY) + stopIndex * 20 - 10;
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopCoordX = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              if (e.target.value >= 0 && e.target.value <= 748) {
                stop.coords.x = e.target.value;
              }
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      if (e.target.value >= 0 && e.target.value <= 748) {
                        stop.coords.x = e.target.value;
                      }
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopCoordY = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              if (e.target.value >= 0 && e.target.value <= 748) {
                stop.coords.y = e.target.value;
              }
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      if (e.target.value >= 0 && e.target.value <= 748) {
                        stop.coords.y = e.target.value;
                      }
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopNumber = (e) => {
    if (e.target.value <= 0) return;
    // todo: prevent duplication
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              if (e.target.value >= 1) {
                stop.number = Number(e.target.value);
              }
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      if (e.target.value >= 1) {
                        stop.number = Number(e.target.value);
                      }
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopTitle = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              stop.title = e.target.value;
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.title = e.target.value;
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleStopScript = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, index) => {
            if (index === Number(ids[1])) {
              stop.script = e.target.value;
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "floorplan") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.script = e.target.value;
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  //
  //
  //
  // hotspots
  const addNewHotspot = (e) => {
    const ids = e.target?.id.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              const number = serializeItem(stop.hotspots);

              stop.hotspots.push({
                number,
                type: "",
                media: "",
                title: `new hotspot ${number}`,
                subtitle: "",
                description: "",
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      const number = serializeItem(stop.hotspots);

                      stop.hotspots.push({
                        number,
                        type: "",
                        media: "",
                        title: `new hotspot ${number}`,
                        subtitle: "",
                        description: "",
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const removeHotspot = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots = stop.hotspots.filter(
                (ele, index) => index !== Number(ids[2]),
              );
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots = stop.hotspots.filter(
                        (ele, index) => index !== Number(ids[4]),
                      );
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleHotspotNumber = (e) => {
    if (e.target.value <= 0) return;

    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[2])) {
                  hotspot.number = e.target.value;
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[4])) {
                          hotspot.number = e.target.value;
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleHotspotTitle = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[2])) {
                  hotspot.title = e.target.value;
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[4])) {
                          hotspot.title = e.target.value;
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };
  const handleHotspotSubtitle = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[2])) {
                  hotspot.subtitle = e.target.value;
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[4])) {
                          hotspot.subtitle = e.target.value;
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleHotspotDescription = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[2])) {
                  hotspot.description = e.target.value;
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[4])) {
                          hotspot.description = e.target.value;
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  const handleHotspotType = (e) => {
    const ids = e.target?.id?.split("-");

    if (ids[0] === "main") {
      setGuide({
        ...guideObj,
        stops: [
          ...guideObj.stops.map((stop, stopIndex) => {
            if (stopIndex === Number(ids[1])) {
              stop.hotspots.map((hotspot, hotspotIndex) => {
                if (hotspotIndex === Number(ids[2])) {
                  hotspot.type = e.target.value;
                  hotspot.media = "";
                }

                return hotspot;
              });
            }

            return stop;
          }),
        ],
      });
    } else if (ids[0] === "stop") {
      setGuide({
        ...guideObj,
        structures: [
          ...guideObj.structures.map((structure, structureIndex) => {
            if (structureIndex === Number(ids[1])) {
              structure.floorplans.map((floorplan, floorplanIndex) => {
                if (floorplanIndex === Number(ids[2])) {
                  floorplan.stops.map((stop, stopIndex) => {
                    if (stopIndex === Number(ids[3])) {
                      stop.hotspots.map((hotspot, hotspotIndex) => {
                        if (hotspotIndex === Number(ids[4])) {
                          hotspot.type = e.target.value;
                          hotspot.media = "";
                        }

                        return hotspot;
                      });
                    }

                    return stop;
                  });
                }

                return floorplan;
              });
            }

            return structure;
          }),
        ],
      });
    }
  };

  //
  //
  //
  // structures
  const addNewStructure = () => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures,
        {
          title: `new structure ${guideObj.structures?.length + 1}`,
          coords: {
            x: Math.floor(Math.random() * 1000) % (768 - 30),
            y: Math.floor(Math.random() * 1000) % (768 - 30),
          },
          floorplans: [],
        },
      ],
    });
  };

  const removeStructure = (e) => {
    setGuide({
      ...guideObj,
      structures: guideObj.structures.filter(
        (ele, index) => index !== Number(e.target.id),
      ),
    });
  };

  const handleStructureDraggingCoords = (e) => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(e.target.id)) {
            if (Number(e.layerX) >= 15 && Number(e.layerX) <= 758) {
              structure.coords.x = Number(e.layerX) - 15;
            }
            structure.coords.y = Number(e.layerY) + index * 30 - 15;
          }
          return structure;
        }),
      ],
    });
  };

  const handleStructureCoordX = (e) => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(e.target.id)) {
            if (e.target.value >= 0 && e.target.value <= 748) {
              structure.coords.x = e.target.value;
            }
          }

          return structure;
        }),
      ],
    });
  };

  const handleStructureCoordY = (e) => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(e.target.id)) {
            if (e.target.value >= 0 && e.target.value <= 748) {
              structure.coords.y = e.target.value;
            }
          }

          return structure;
        }),
      ],
    });
  };

  const handleStructureTitle = (e) => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(e.target.id)) {
            structure.title = e.target.value;
          }

          return structure;
        }),
      ],
    });
  };

  //
  //
  //
  // floorplans
  const addNewFloorplan = (e) => {
    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(e.target.id)) {
            structure.floorplans.push({
              title: `new floorplan ${structure.floorplans?.length + 1}`,
              icon: 0,
              map: "",
              stops: [],
            });
          }

          return structure;
        }),
      ],
    });
  };

  const removeFloorplan = (e) => {
    const ids = e.target.id?.split("-");

    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(ids[0])) {
            structure.floorplans.splice(Number(ids[1]), 1);
          }

          return structure;
        }),
      ],
    });
  };

  const handleFloorplanTitle = (e) => {
    const ids = e.target.id?.split("-");

    setGuide({
      ...guideObj,
      structures: [
        ...guideObj.structures.map((structure, index) => {
          if (index === Number(ids[0])) {
            structure.floorplans[Number(ids[1])].title = e.target.value;
          }

          return structure;
        }),
      ],
    });
  };

  const tabs = [
    {
      name: "guides",
      text: "Guides",
      content: guides?.length ? (
        <div className="grid gap-2 mt-10">
          {guides.map((guide, guideIndex) => (
            <HorizontalCards
              key={guideIndex}
              info={{
                ...guideObj,
                name: guide.title,
                partner: guide.partner.split("-").join(" "),
                img: guide.cover,
                url: `/guide/${guide.slug}/demo`,
              }}
              stylePhotoProfile="rounded-tl-md rounded-bl-md w-[71px] md:w-44"
              options={
                <AppDropdown
                  activator={<i className="iconify" data-icon="tabler:dots" />}
                  className="dropdown-end"
                >
                  <ul className="w-40">
                    {/* <li>
                      <button id={tour._id} onClick={editTour}>
                        Edit Tour
                      </button>
                    </li> */}
                    <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                      <button id={guide.slug} onClick={showDeleteWarning}>
                        Delete Tour
                      </button>
                    </li>
                  </ul>
                </AppDropdown>
              }
            />
          ))}
        </div>
      ) : (
        <p>No guides found!</p>
      ),
    },
    {
      name: "main",
      text: "Main",
      content: (
        <section className="w-full relative">
          <div className="bg-denim absolute right-0 border-2 border-denim rounded-md p-4 flex flex-col gap-2 w-[300px] h-full overflow-scroll z-20">
            <label
              className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer"
              onClick={displayMenu}
              id="map"
            >
              <span id="map">add map</span>
            </label>
            <div className="flex gap-2">
              {guideObj?.voiceOver?.length ? (
                <AppMiniAudioPlayer
                  src={guideObj.voiceOver}
                  id={`audio-main`}
                  className="flex gap-1 bg-white p-1 rounded-full grow h-full"
                  controlsClassName="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                />
              ) : (
                <></>
              )}
              <label
                className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer"
                onClick={displayMenu}
                id="audio"
              >
                <span id="audio">add narration</span>
              </label>
            </div>
            <label
              className="hotspot-color-label w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer"
              id="color"
              htmlFor="picker"
            >
              <span id="color">marker color</span>
              <i id="color" className="iconify" data-icon="mdi:color" />
              <input
                id="picker"
                type="color"
                onChange={handleColor}
                className="w-[20px] h-[25px] hidden"
              />
              <input
                onChange={handleColor}
                value={guideObj?.color || "#1A1E29"}
                className="hotspot-color-picker rounded bg-transparent text-xs text-denim w-16 outline-0"
              />
            </label>
            <span className="bg-white w-full h-[2px] rounded-full"></span>
            <div className="flex flex-col gap-4 overflow-scroll h-[360px]">
              <div className="flex gap-2 justify-between items-center">
                <h2 className="text-white">Structs:</h2>
                <button
                  className="w-fit px-4 h-[30px] flex gap-1 justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex"
                  onClick={addNewStructure}
                >
                  <span>add structure</span>
                </button>
              </div>
              {guideObj?.structures?.length ? (
                <div className="flex flex-col gap-2 w-full h-fit overflow-scroll">
                  {guideObj?.structures.map((structure, structIndex) => (
                    <div
                      key={structIndex}
                      className="flex gap-2 flex-col bg-denim text-white justify-left items-start rounded border-2 border-white p-2"
                    >
                      <div className="w-full flex justify-end items-center">
                        <button
                          id={structIndex}
                          onClick={removeStructure}
                          className={`rounded-full bg-white hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-magenta hover:text-white`}
                        >
                          x
                        </button>
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <label className="pl-2 text-xs font-bold">
                          structure title:
                        </label>
                        <input
                          type="text"
                          id={structIndex}
                          className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                          onChange={handleStructureTitle}
                          value={structure.title}
                        />
                      </div>
                      <label className="pl-2 text-xs font-bold">
                        position:
                      </label>
                      <div className="flex gap-2 w-full px-1">
                        <div className="grow flex gap-2 w-[50%] items-center">
                          <label className="pl-1 text-xs">x:</label>
                          <input
                            type="number"
                            id={structIndex}
                            className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                            onChange={handleStructureCoordX}
                            value={Number(structure.coords.x)}
                          />
                        </div>
                        <div className="grow flex gap-2 w-[50%] items-center">
                          <label className="pl-1 text-xs">y:</label>
                          <input
                            type="number"
                            id={structIndex}
                            className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                            onChange={handleStructureCoordY}
                            value={Number(structure.coords.y)}
                          />
                        </div>
                      </div>
                      <div className="w-full flex gap-2 justify-between items-center">
                        <label className="pl-2 text-xs font-bold">
                          floorplans{" "}
                          {structure.floorplans?.length
                            ? `(${structure.floorplans.length})`
                            : ""}
                          :
                        </label>
                        <button
                          id={structIndex}
                          className="w-fit px-4 h-[30px] flex gap-1 justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex"
                          onClick={addNewFloorplan}
                        >
                          <span id={structIndex}>add</span>
                        </button>
                      </div>
                      {structure.floorplans?.length ? (
                        <div className="flex flex-col gap-1 w-full">
                          {structure.floorplans.map(
                            (floorplan, floorplanIndex) => (
                              <div
                                key={floorplanIndex}
                                className="border border-white rounded p-2 w-full grow"
                              >
                                <div className="w-full flex justify-end items-center">
                                  <button
                                    id={`${structIndex}-${floorplanIndex}`}
                                    onClick={removeFloorplan}
                                    className={`rounded-full bg-white hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-magenta hover:text-white`}
                                  >
                                    x
                                  </button>
                                </div>
                                <div className="flex flex-col gap-1 w-full">
                                  <label className="pl-2 text-xs font-bold">
                                    floorplan title:
                                  </label>
                                  <input
                                    type="text"
                                    id={`${structIndex}-${floorplanIndex}`}
                                    className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                    onChange={handleFloorplanTitle}
                                    value={floorplan.title}
                                  />
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
            <span className="bg-white w-full h-[2px] rounded-full"></span>
            <div className="flex flex-col gap-4 overflow-scroll h-[360px]">
              <div className="flex gap-2 justify-between items-center">
                <h2 className="text-white">Stops:</h2>
                <button
                  className="w-fit px-4 h-[30px] gap-1 justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex"
                  onClick={addNewStop}
                  id="main"
                >
                  <span id="main">add stop</span>
                </button>
              </div>
              {guideObj?.stops?.length ? (
                <div className="flex flex-col gap-2 w-full h-fit overflow-scroll">
                  {guideObj?.stops.map((stop, stopIndex) => (
                    <div
                      key={stopIndex}
                      className="flex gap-2 flex-col bg-denim text-white justify-left items-start rounded border-2 border-white p-2"
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="flex gap-2 w-full justify-start items-center">
                          <label className="pl-2 text-xs font-bold">
                            number:
                          </label>
                          <input
                            type="number"
                            id={`main-${stopIndex}`}
                            className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-16 py-1 outline-0 rounded"
                            onChange={handleStopNumber}
                            value={stop.number}
                          />
                        </div>
                        <button
                          id={`main-${stopIndex}`}
                          onClick={removeStop}
                          className={`rounded-full bg-white hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-magenta hover:text-white`}
                        >
                          x
                        </button>
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <label className="pl-2 text-xs font-bold">
                          stop title:
                        </label>
                        <input
                          type="text"
                          id={`main-${stopIndex}`}
                          className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                          onChange={handleStopTitle}
                          value={stop.title}
                        />
                      </div>
                      <label
                        className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer"
                        onClick={displayMenu}
                        id={`banner-${stopIndex}`}
                      >
                        <span id={`banner-${stopIndex}`}>add banner</span>
                      </label>
                      {stop.banner?.length ? (
                        <div
                          className="bg-white rounded"
                          title={stop.title}
                          style={{
                            backgroundImage: stop.banner?.length
                              ? `url(${stop.banner})`
                              : "",
                            width: "100%",
                            height: "100px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                      <label className="pl-2 text-xs font-bold">
                        position:
                      </label>
                      <div className="flex gap-2 w-full px-1">
                        <div className="grow flex gap-2 w-[50%] items-center">
                          <label className="pl-1 text-xs">x:</label>
                          <input
                            type="number"
                            id={`main-${stopIndex}`}
                            className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                            onChange={handleStopCoordX}
                            value={Number(stop.coords.x)}
                          />
                        </div>
                        <div className="grow flex gap-2 w-[50%] items-center">
                          <label className="pl-1 text-xs">y:</label>
                          <input
                            type="number"
                            id={`main-${stopIndex}`}
                            className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                            onChange={handleStopCoordY}
                            value={Number(stop.coords.y)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <label className="pl-2 text-xs font-bold">
                          script:
                        </label>
                        <textarea
                          id={`main-${stopIndex}`}
                          className="border-2 px-2 bg-white border-denim text-sm text-denim  text-xs w-full h-20 py-1 outline-0 rounded overflow-hidden"
                          onChange={handleStopScript}
                          value={stop.script}
                        />
                        <div className="flex gap-2 justify-between items-center">
                          {stop.audio?.length ? (
                            <AppMiniAudioPlayer
                              src={stop.audio}
                              id={`audio-${stopIndex}`}
                              className="flex gap-1 bg-white p-1 rounded-full grow"
                              controlsClassName="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                            />
                          ) : (
                            <></>
                          )}
                          <label
                            className="w-fit px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer self-end"
                            onClick={displayMenu}
                            id={`audio-${stopIndex}`}
                          >
                            <span id={`audio-${stopIndex}`}>audio</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="w-fit h-fit bg-denim rounded-md flex mb-8 p-8 border-denim flex items-center justify-center border-8 border-grey relative">
            <div
              className={`w-[768px] h-[768px] bg-white relative overflow-hidden`}
              style={{
                backgroundImage: `url(${guideObj?.map})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
                {guideObj?.stops?.length ? (
                  guideObj?.stops.map((stop, index) => (
                    <Draggable
                      key={index}
                      id={`main-${index}`}
                      axis="both"
                      handle={`.stop-handle-${index}`}
                      defaultPosition={{
                        x: Number(stop.coords.x),
                        y: Number(stop.coords.y),
                      }}
                      position={{
                        x: Number(stop.coords.x),
                        y: Number(stop.coords.y) - index * 20,
                      }}
                      scale={1}
                      bounds="parent"
                      onStop={handleStopDraggingCoords}
                    >
                      <div
                        id={`main-${index}`}
                        title={stop.title}
                        className={`stop-handle-${index} w-[20px] h-[20px]  text-white rounded-full flex items-center justify-center cursor-grabbing relative text-xs z-20`}
                        style={{
                          backgroundColor: `#${guideObj?.color?.replace(
                            /[#]/g,
                            "",
                          )}`,
                        }}
                      >
                        {stop.number}
                      </div>
                    </Draggable>
                  ))
                ) : (
                  <></>
                )}
              </div>
              <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
                {guideObj?.structures?.length ? (
                  guideObj?.structures.map((structure, index) => (
                    <Draggable
                      id={index}
                      key={index}
                      axis="both"
                      handle={`.structure-handle-${index}`}
                      defaultPosition={{
                        x: Number(structure.coords.x),
                        y: Number(structure.coords.y),
                      }}
                      position={{
                        x: Number(structure.coords.x),
                        y: Number(structure.coords.y) - index * 30,
                      }}
                      scale={1}
                      bounds="parent"
                      onStop={handleStructureDraggingCoords}
                    >
                      <div
                        id={index}
                        title={structure.title}
                        className={`structure-handle-${index} w-[30px] h-[30px] p-1 text-white rounded-full flex items-center justify-center cursor-grabbing relative text-xs z-20`}
                        style={{
                          backgroundColor: `#${guideObj?.color?.replace(
                            /[#]/g,
                            "",
                          )}`,
                        }}
                      >
                        +
                      </div>
                    </Draggable>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
      ),
    },
    {
      name: "floorplans",
      text: "Floorplans",
      //
      content: (
        <section className="w-full relative flex gap-4 justify-between">
          {guideObj?.structures?.length ? (
            <div className="max-w-[75%] h-full flex flex-col gap-4 pb-8">
              {guideObj?.structures.map((structure, structureIndex) => (
                <div
                  key={structureIndex}
                  className="border-2 border-denim text-denim p-4 rounded-lg flex flex-col gap-8"
                >
                  <h1 className="font-bold uppercase text-2xl">
                    {structure.title}
                  </h1>
                  <div className="w-full h-full flex flex-col gap-2 overflow-x-scroll">
                    <div className="flex gap-4 items-center w-fit h-fit">
                      {structure.floorplans?.length ? (
                        <>
                          {structure.floorplans.map(
                            (floorplan, floorplanIndex) => (
                              <div key={floorplanIndex}>
                                <div className="h-[100px] grow bg-magenta flex flex-col items-center justify-center gap-4">
                                  <span className="text-white font-bold">
                                    {floorplan.title}
                                  </span>
                                  <div className="flex items-center justify-center gap-2">
                                    <button
                                      className="w-fit h-[30px] px-4 flex gap-1 justify-center items-center rounded-full bg-white hover:bg-denim text-denim hover:text-white font-bold text-xs with-transition flex"
                                      onClick={addNewStop}
                                      id={`floorplan-${structureIndex}-${floorplanIndex}`}
                                    >
                                      <span
                                        id={`floorplan-${structureIndex}-${floorplanIndex}`}
                                      >
                                        add stop
                                      </span>
                                    </button>
                                    <label
                                      className="w-fit px-4 h-[30px] flex gap-1 justify-center items-center rounded-full bg-white hover:bg-denim text-denim hover:text-white font-bold text-xs with-transition cursor-pointer"
                                      onClick={displayMenu}
                                      id={`floorplan-${structureIndex}-${floorplanIndex}`}
                                    >
                                      <span
                                        id={`floorplan-${structureIndex}-${floorplanIndex}`}
                                      >
                                        add drawing
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={`w-fit h-fit flex mb-8 flex items-center justify-center  relative bg-white`}
                                >
                                  <div
                                    className={`w-[360px] h-[640px] relative overflow-hidden`}
                                    style={{
                                      backgroundImage: `url(${floorplan.map})`,
                                      backgroundSize: "100% 100%",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  >
                                    <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
                                      {floorplan.stops?.length ? (
                                        floorplan.stops.map(
                                          (stop, stopIndex) => (
                                            <Draggable
                                              key={stopIndex}
                                              id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                              axis="both"
                                              handle={`.stop-handle-${stopIndex}`}
                                              defaultPosition={{
                                                x: Number(stop.coords.x),
                                                y: Number(stop.coords.y),
                                              }}
                                              position={{
                                                x: Number(stop.coords.x),
                                                y:
                                                  Number(stop.coords.y) -
                                                  stopIndex * 20,
                                              }}
                                              scale={1}
                                              bounds="parent"
                                              onStop={handleStopDraggingCoords}
                                            >
                                              <div
                                                id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                title={stop.title}
                                                className={`stop-handle-${stopIndex} w-[20px] h-[20px]  text-white rounded-full flex items-center justify-center cursor-grabbing relative text-xs z-20`}
                                                style={{
                                                  backgroundColor: `#${guideObj?.color?.replace(
                                                    /[#]/g,
                                                    "",
                                                  )}`,
                                                }}
                                              >
                                                {stop.number}
                                              </div>
                                            </Draggable>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>Please add some structures in order to access floorplans.</p>
          )}
          <div className="bg-denim border-2 border-denim rounded-md p-4 flex flex-col gap-2 min-[300px] w-[25%] h-full overflow-scroll z-20">
            <div className="flex flex-col gap-4 overflow-scroll h-full">
              {guideObj?.structures?.length ? (
                <div className="flex flex-col gap-2 w-full h-fit overflow-scroll">
                  <div className="flex gap-2 justify-between items-center">
                    <h2 className="text-white">Floorplans:</h2>
                  </div>
                  {guideObj.structures.map((structure, structureIndex) => (
                    <div
                      key={structureIndex}
                      className="flex gap-2 flex-col bg-denim text-white justify-left items-start rounded border-2 border-white p-2"
                    >
                      {structure.floorplans?.length ? (
                        <div className="flex flex-col gap-2 w-full">
                          <label className="pl-2 text-sm font-bold text-magenta uppercase">
                            {structure.title}
                          </label>
                          {structure.floorplans.map(
                            (floorplan, floorplanIndex) => (
                              <div
                                key={floorplanIndex}
                                className="border border-white rounded p-2 w-full grow"
                              >
                                <div className="flex flex-col gap-2 w-full">
                                  <label className="pl-2 text-sm font-bold text-green">
                                    {floorplan.title}
                                  </label>
                                  {floorplan.stops?.length ? (
                                    <>
                                      <label className="pl-2 text-xs font-bold">
                                        Stops:
                                      </label>
                                      {floorplan.stops.map(
                                        (stop, stopIndex) => (
                                          <div
                                            key={stopIndex}
                                            className="flex gap-2 flex-col bg-denim text-white justify-left items-start rounded border border-white p-2"
                                          >
                                            <div className="w-full flex justify-between items-center">
                                              <div className="flex gap-2 w-full justify-start items-center">
                                                <label className="pl-2 text-xs font-bold">
                                                  number:
                                                </label>
                                                <input
                                                  type="number"
                                                  id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                  className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-12 py-1 outline-0 rounded"
                                                  onChange={handleStopNumber}
                                                  value={stop.number}
                                                />
                                              </div>
                                              <button
                                                id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                onClick={removeStop}
                                                className={`rounded-full bg-white hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-magenta hover:text-white`}
                                              >
                                                x
                                              </button>
                                            </div>
                                            <div className="flex flex-col gap-1 w-full">
                                              <label className="pl-2 text-xs font-bold">
                                                stop title:
                                              </label>
                                              <input
                                                type="text"
                                                id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                                onChange={handleStopTitle}
                                                value={stop.title}
                                              />
                                            </div>
                                            <label
                                              className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                              onClick={displayMenu}
                                              id={`banner-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                            >
                                              <span
                                                id={`banner-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                              >
                                                add banner
                                              </span>
                                            </label>
                                            {stop.banner?.length ? (
                                              <div
                                                className="bg-white rounded"
                                                title={stop.title}
                                                style={{
                                                  backgroundImage: stop.banner
                                                    ?.length
                                                    ? `url(${stop.banner})`
                                                    : "",
                                                  width: "100%",
                                                  height: "100px",
                                                  backgroundSize: "cover",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundPosition: "center",
                                                }}
                                              ></div>
                                            ) : (
                                              <></>
                                            )}
                                            <label className="pl-2 text-xs font-bold">
                                              position:
                                            </label>
                                            <div className="flex gap-2 w-full px-1">
                                              <div className="grow flex gap-2 w-[50%] items-center">
                                                <label className="pl-1 text-xs">
                                                  x:
                                                </label>
                                                <input
                                                  type="number"
                                                  id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                  className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                                                  onChange={handleStopCoordX}
                                                  value={Number(stop.coords.x)}
                                                />
                                              </div>
                                              <div className="grow flex gap-2 w-[50%] items-center">
                                                <label className="pl-1 text-xs">
                                                  y:
                                                </label>
                                                <input
                                                  type="number"
                                                  id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                  className="rounded w-[50px] grow text-denim pl-2 outline-0 text-xs py-1"
                                                  onChange={handleStopCoordY}
                                                  value={Number(stop.coords.y)}
                                                />
                                              </div>
                                            </div>
                                            <div className="flex flex-col gap-1 w-full">
                                              <label className="pl-2 text-xs font-bold">
                                                script:
                                              </label>
                                              <textarea
                                                id={`floorplan-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                className="border-2 px-2 bg-white border-denim text-sm text-denim  text-xs w-full h-20 py-1 outline-0 rounded overflow-hidden"
                                                onChange={handleStopScript}
                                                value={stop.script}
                                              />
                                            </div>
                                            <div className="flex gap-2 justify-between items-center w-full">
                                              {stop.audio?.length ? (
                                                <AppMiniAudioPlayer
                                                  src={stop.audio}
                                                  id={`audio-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                  className="flex gap-1 bg-white p-1 rounded-full grow"
                                                  controlsClassName="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                                />
                                              ) : (
                                                <></>
                                              )}
                                              <label
                                                className="w-fit px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-white text-white hover:text-denim font-bold text-xs with-transition flex gap-1 cursor-pointer self-end"
                                                onClick={displayMenu}
                                                id={`audio-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                              >
                                                <span
                                                  id={`audio-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                                >
                                                  audio
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      ),
    },
    {
      name: "hotspots",
      text: "Hotspots",
      content: (
        <section className="w-full relative flex gap-4 justify-between flex-col">
          {guideObj?.stops?.length ? (
            <div className="w-full h-full flex flex-col gap-4 pb-8">
              <div className="w-full border-2 border-denim text-denim p-4 rounded-lg flex flex-col gap-8">
                <h1 className="font-bold uppercase text-2xl">Main Scenes</h1>
                <div className="w-full h-full flex flex-col gap-2 overflow-x-scroll scrollbar-hide">
                  <div className="flex gap-4 items-center w-full h-fit flex-col">
                    <div className="w-full">
                      <div className="w-full h-fit flex gap-2 overflow-x-scroll">
                        {guideObj.stops.map((stop, stopIndex) => (
                          <div key={stopIndex}>
                            <div className="h-[100px] grow bg-magenta flex flex-col items-center justify-center gap-4">
                              <span className="text-white font-bold">
                                {stop.title}
                              </span>
                              <div className="flex items-center justify-center gap-2">
                                <button
                                  className="w-fit h-[30px] px-4 flex gap-1 justify-center items-center rounded-full bg-white hover:bg-denim text-denim hover:text-white font-bold text-xs with-transition flex gap-2"
                                  onClick={addNewHotspot}
                                  id={`main-${stopIndex}`}
                                >
                                  <span id={`main-${stopIndex}`}>
                                    add hotspot
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div
                              className={`w-fit h-fit flex mb-8 flex items-center justify-center relative bg-white`}
                            >
                              <div
                                className={`w-[360px] h-[640px] relative overflow-y-scroll scrollable`}
                              >
                                {stop.hotspots?.length ? (
                                  <div className="w-full h-full absolute top-0 left-0 flex gap-2 flex-col overflow-y-scroll p-4 scrollable">
                                    {stop.hotspots.map(
                                      (hotspot, hotspotIndex) => (
                                        <div
                                          className="w-full min-h-fit p-[10px] pt-[20px] flex flex-col gap-2 rounded-md relative border-2 border-denim"
                                          key={hotspotIndex}
                                          id={`main-${stopIndex}-${hotspotIndex}`}
                                        >
                                          <button
                                            id={`main-${stopIndex}-${hotspotIndex}`}
                                            onClick={removeHotspot}
                                            className={`rounded-full bg-denim hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-white absolute right-[10px] top-[10px]`}
                                          >
                                            x
                                          </button>
                                          <div className="flex gap-2 w-full justify-start items-center">
                                            <label className="pl-2 text-xs font-bold">
                                              number:
                                            </label>
                                            <input
                                              type="number"
                                              id={`main-${stopIndex}-${hotspotIndex}`}
                                              className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-16 py-1 outline-0 rounded"
                                              onChange={handleHotspotNumber}
                                              value={hotspot.number}
                                            />
                                          </div>
                                          <div className="flex flex-col gap-1 w-full">
                                            <label className="pl-2 text-xs font-bold">
                                              title:
                                            </label>
                                            <input
                                              type="text"
                                              id={`main-${stopIndex}-${hotspotIndex}`}
                                              className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                              onChange={handleHotspotTitle}
                                              value={hotspot.title}
                                            />
                                          </div>
                                          <div className="flex flex-col gap-1 w-full">
                                            <label className="pl-2 text-xs font-bold">
                                              subtitle:
                                            </label>
                                            <input
                                              type="text"
                                              id={`main-${stopIndex}-${hotspotIndex}`}
                                              className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                              onChange={handleHotspotSubtitle}
                                              value={hotspot.subtitle}
                                            />
                                          </div>
                                          <div className="flex flex-col gap-1 w-full">
                                            <label className="pl-2 text-xs font-bold">
                                              description:
                                            </label>
                                            <textarea
                                              id={`main-${stopIndex}-${hotspotIndex}`}
                                              className="border-2 px-2 bg-white border-denim text-sm text-denim  text-xs w-full h-20 py-1 outline-0 rounded overflow-hidden"
                                              onChange={
                                                handleHotspotDescription
                                              }
                                              value={hotspot.description}
                                            />
                                          </div>{" "}
                                          <div className="flex flex-col gap-1 w-full">
                                            <label className="pl-2 text-xs font-bold">
                                              type:
                                            </label>
                                            <div className="border-2 border-denim rounded relative p-0">
                                              <select
                                                className="select select-sm text-xs px-[10px] h-full"
                                                defaultValue={hotspot.type}
                                                onChange={handleHotspotType}
                                                id={`main-${stopIndex}-${hotspotIndex}`}
                                              >
                                                <option value="">{`-- select type --`}</option>
                                                {["image", "video"].map(
                                                  (typeEle, typeIndex) => (
                                                    <option
                                                      key={typeIndex}
                                                      value={typeEle}
                                                    >
                                                      {typeEle}
                                                    </option>
                                                  ),
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                          {hotspot.type?.length ? (
                                            <div className="flex flex-col gap-1 w-full">
                                              <div className=" rounded relative p-0">
                                                {hotspot.type === "image" ? (
                                                  <label
                                                    className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                                    onClick={displayMenu}
                                                    id={`hotspot-main-${stopIndex}-${hotspotIndex}`}
                                                  >
                                                    <span
                                                      id={`hotspot-main-${stopIndex}-${hotspotIndex}`}
                                                    >
                                                      add image
                                                    </span>
                                                  </label>
                                                ) : hotspot.type === "video" ? (
                                                  <label
                                                    className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                                    onClick={displayMenu}
                                                    id={`video-main-${stopIndex}-${hotspotIndex}`}
                                                  >
                                                    <span
                                                      id={`video-main-${stopIndex}-${hotspotIndex}`}
                                                    >
                                                      add video
                                                    </span>
                                                  </label>
                                                ) : (
                                                  <></>
                                                )}
                                                {hotspot.media?.length ? (
                                                  <>
                                                    {hotspot.type ===
                                                    "image" ? (
                                                      <div
                                                        className="bg-white rounded border-2 my-2"
                                                        title={hotspot.title}
                                                        style={{
                                                          backgroundImage:
                                                            hotspot.media
                                                              ?.length
                                                              ? `url(${hotspot.media})`
                                                              : "",
                                                          width: "100%",
                                                          height: "200px",
                                                          backgroundSize:
                                                            "cover",
                                                          backgroundRepeat:
                                                            "no-repeat",
                                                          backgroundPosition:
                                                            "center",
                                                        }}
                                                      ></div>
                                                    ) : hotspot.type ===
                                                      "video" ? (
                                                      <div className="bg-white rounded border-2 my-2">
                                                        <video controls>
                                                          <source
                                                            src={hotspot.media}
                                                            type="video/mp4"
                                                          />
                                                        </video>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {guideObj?.structures?.length ? (
            <div className="w-full h-full flex flex-col gap-4 pb-8">
              {guideObj.structures.map((structure, structureIndex) => (
                <div
                  key={structureIndex}
                  className="w-full border-2 border-denim text-denim p-4 rounded-lg flex flex-col gap-4"
                >
                  <h1 className="font-bold uppercase text-2xl">
                    {structure.title}
                  </h1>
                  <div className="w-full h-full flex flex-col gap-2 overflow-x-scroll scrollbar-hide">
                    <div className="flex gap-4 items-center w-full h-fit flex-col">
                      {structure.floorplans?.length ? (
                        <>
                          {structure.floorplans.map(
                            (floorplan, floorplanIndex) => (
                              <div key={floorplanIndex} className="w-full">
                                {floorplan.stops?.length ? (
                                  <h4 className="text-xl capitalize mb-2">
                                    Floorplan ({floorplanIndex + 1}):{" "}
                                    {floorplan.title}
                                  </h4>
                                ) : (
                                  <></>
                                )}
                                {floorplan.stops?.length ? (
                                  <div className="w-full h-fit flex gap-2 overflow-x-scroll">
                                    {floorplan.stops.map((stop, stopIndex) => (
                                      <div key={stopIndex}>
                                        <div className="h-[100px] grow bg-magenta flex flex-col items-center justify-center gap-4">
                                          <span className="text-white font-bold">
                                            {stop.title}
                                          </span>
                                          <div className="flex items-center justify-center gap-2">
                                            <button
                                              className="w-fit h-[30px] px-4 flex gap-1 justify-center items-center rounded-full bg-white hover:bg-denim text-denim hover:text-white font-bold text-xs with-transition flex gap-2"
                                              onClick={addNewHotspot}
                                              id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                            >
                                              <span
                                                id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}`}
                                              >
                                                add hotspot
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className={`w-fit h-fit flex mb-8 flex items-center justify-center relative bg-white`}
                                        >
                                          <div
                                            className={`w-[360px] h-[640px] relative overflow-y-scroll scrollable`}
                                          >
                                            {stop.hotspots?.length ? (
                                              <div className="w-full h-full absolute top-0 left-0 flex gap-2 flex-col overflow-y-scroll p-4 scrollable">
                                                {stop.hotspots.map(
                                                  (hotspot, hotspotIndex) => (
                                                    <div
                                                      className="w-full min-h-fit p-[10px] pt-[20px] flex flex-col gap-2 rounded-md relative border-2 border-denim"
                                                      key={hotspotIndex}
                                                      id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                    >
                                                      <button
                                                        id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                        onClick={removeHotspot}
                                                        className={`rounded-full bg-denim hover:bg-magenta with-transition w-[20px] h-[20px] flex justify-center items-center font-bold text-xs text-white absolute right-[10px] top-[10px]`}
                                                      >
                                                        x
                                                      </button>
                                                      <div className="flex gap-2 w-full justify-start items-center">
                                                        <label className="pl-2 text-xs font-bold">
                                                          number:
                                                        </label>
                                                        <input
                                                          type="number"
                                                          id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                          className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-16 py-1 outline-0 rounded"
                                                          onChange={
                                                            handleHotspotNumber
                                                          }
                                                          value={hotspot.number}
                                                        />
                                                      </div>
                                                      <div className="flex flex-col gap-1 w-full">
                                                        <label className="pl-2 text-xs font-bold">
                                                          title:
                                                        </label>
                                                        <input
                                                          type="text"
                                                          id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                          className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                                          onChange={
                                                            handleHotspotTitle
                                                          }
                                                          value={hotspot.title}
                                                        />
                                                      </div>
                                                      <div className="flex flex-col gap-1 w-full">
                                                        <label className="pl-2 text-xs font-bold">
                                                          subtitle:
                                                        </label>
                                                        <input
                                                          type="text"
                                                          id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                          className="border-2 p-2 bg-white border-denim text-sm text-denim  text-xs w-full py-1 outline-0 rounded"
                                                          onChange={
                                                            handleHotspotSubtitle
                                                          }
                                                          value={
                                                            hotspot.subtitle
                                                          }
                                                        />
                                                      </div>
                                                      <div className="flex flex-col gap-1 w-full">
                                                        <label className="pl-2 text-xs font-bold">
                                                          description:
                                                        </label>
                                                        <textarea
                                                          id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                          className="border-2 px-2 bg-white border-denim text-sm text-denim  text-xs w-full h-20 py-1 outline-0 rounded overflow-hidden"
                                                          onChange={
                                                            handleHotspotDescription
                                                          }
                                                          value={
                                                            hotspot.description
                                                          }
                                                        />
                                                      </div>{" "}
                                                      <div className="flex flex-col gap-1 w-full">
                                                        <label className="pl-2 text-xs font-bold">
                                                          type:
                                                        </label>
                                                        <div className="border-2 border-denim rounded relative p-0">
                                                          <select
                                                            className="select select-sm text-xs px-[10px] h-full"
                                                            defaultValue={
                                                              hotspot.type
                                                            }
                                                            onChange={
                                                              handleHotspotType
                                                            }
                                                            id={`stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                          >
                                                            <option value="">{`-- select type --`}</option>
                                                            {[
                                                              "image",
                                                              "video",
                                                            ].map(
                                                              (
                                                                typeEle,
                                                                typeIndex,
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    typeIndex
                                                                  }
                                                                  value={
                                                                    typeEle
                                                                  }
                                                                >
                                                                  {typeEle}
                                                                </option>
                                                              ),
                                                            )}
                                                          </select>
                                                        </div>
                                                      </div>
                                                      {hotspot.type?.length ? (
                                                        <div className="flex flex-col gap-1 w-full">
                                                          <div className=" rounded relative p-0">
                                                            {hotspot.type ===
                                                            "image" ? (
                                                              <label
                                                                className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                                                onClick={
                                                                  displayMenu
                                                                }
                                                                id={`hotspot-stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                              >
                                                                <span
                                                                  id={`hotspot-stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                                >
                                                                  add image
                                                                </span>
                                                              </label>
                                                            ) : hotspot.type ===
                                                              "video" ? (
                                                              <label
                                                                className="w-full px-4 h-[30px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                                                                onClick={
                                                                  displayMenu
                                                                }
                                                                id={`video-stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                              >
                                                                <span
                                                                  id={`video-stop-${structureIndex}-${floorplanIndex}-${stopIndex}-${hotspotIndex}`}
                                                                >
                                                                  add video
                                                                </span>
                                                              </label>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {hotspot.media
                                                              ?.length ? (
                                                              <>
                                                                {hotspot.type ===
                                                                "image" ? (
                                                                  <div
                                                                    className="bg-white rounded border-2 my-2"
                                                                    title={
                                                                      hotspot.title
                                                                    }
                                                                    style={{
                                                                      backgroundImage:
                                                                        hotspot
                                                                          .media
                                                                          ?.length
                                                                          ? `url(${hotspot.media})`
                                                                          : "",
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "200px",
                                                                      backgroundSize:
                                                                        "cover",
                                                                      backgroundRepeat:
                                                                        "no-repeat",
                                                                      backgroundPosition:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                ) : hotspot.type ===
                                                                  "video" ? (
                                                                  <div className="bg-white rounded border-2 my-2">
                                                                    <video
                                                                      controls
                                                                    >
                                                                      <source
                                                                        src={
                                                                          hotspot.media
                                                                        }
                                                                        type="video/mp4"
                                                                      />
                                                                    </video>
                                                                  </div>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  ),
                                                )}
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ),
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>Please add some structures in order to access floorplans.</p>
          )}
        </section>
      ),
    },
    {
      name: "publish",
      text: "Publish",
      content: <CreateGuide guideObj={guideObj} displayMenu={displayMenu} />,
    },
  ];

  return guideObj ? (
    <>
      {deleting && <Loader />}
      {loadingGuides && <Loader />}
      {loadingCreate && <Loader />}
      {errorGuides && <Message error body={errorGuides} />}
      {errorCreate && <Message error body={errorCreate} />}
      {errorGuideDelete && <Message error body={errorGuideDelete} />}
      {deletedGuide && (
        <Message info body={"Self-guide deleted successfully"} />
      )}
      {created && <Message info body={"Self-guide created successfully"} />}
      {errorUpload && <Message error body={errorUpload} />}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}

      {displayWindow ? (
        <UploadMenu>
          <div className="flex gap-2 items-center">
            <input
              type="text"
              placeholder={`enter media url`}
              min="0"
              className="px-4 py-2 rounded-full grow"
              value={elementContent}
              onChange={elementContentHandler}
            />
            <input
              type="file"
              className="hidden"
              id="upload-inline-image"
              onChange={
                elementType.split("-")[0] === "audio"
                  ? uploadAudio
                  : elementType.split("-")[0] === "video"
                  ? uploadVideo
                  : elementType.split("-")[0] === "hotspot" ||
                    elementType.split("-")[0] === "banner" ||
                    elementType.split("-")[0] === "cover"
                  ? uploadHotspotVisual
                  : uploadVisual
              }
            />
            <label
              htmlFor="upload-inline-image"
              className="w-fit py-2 px-8 h-fit flex gap-2 justify-center items-center rounded-full cursor-pointer bg-magenta hover:bg-white text-white hover:text-denim font-bold with-transition flex"
            >
              upload file
            </label>
            <button
              className="w-fit py-2 px-8 h-fit flex gap-2 justify-center items-center rounded-full cursor-pointer bg-magenta hover:bg-white text-white hover:text-denim font-bold with-transition flex"
              onClick={getMediaFiles}
            >
              refresh list
            </button>
          </div>
          <div className="flex gap-2 mt-2">
            <button
              className="w-fit py-2 px-8 h-fit flex gap-2 justify-center items-center rounded-full cursor-pointer bg-magenta hover:bg-white text-white hover:text-denim font-bold with-transition flex"
              id={elementType}
              onClick={
                elementType.split("-")[0] === "audio"
                  ? setAudio
                  : elementType.split("-")[0] === "video"
                  ? setVideo
                  : setVisual
              }
            >
              set
            </button>
            <button
              className="w-fit py-2 px-8 h-fit flex gap-2 justify-center items-center rounded-full cursor-pointer bg-magenta hover:bg-white text-white hover:text-denim font-bold with-transition flex"
              onClick={hideMenu}
            >
              cancel
            </button>
          </div>
          {files?.length ? (
            <SimpleSearch
              type="file"
              className="mt-0 mb-8 self-center mt-8"
              inputClass="grid md:flex md:items-center rounded-[10px] md:bg-white md:gap-3 md:pr-5"
              onChange={(value) => filterFiles(value)}
            />
          ) : (
            <></>
          )}

          <div className="scrollable media-lib h-[460px] flex flex-col gap-2 justify-start items-center overflow-y-scroll p-4">
            {filteredFiles?.length ? (
              <>
                {filteredFiles
                  .filter((file) => {
                    const handle = elementType.split("-")[0];

                    if (handle === "audio") {
                      return file[1].includes("wav") || file[1].includes("mp3");
                    } else if (handle === "video") {
                      return file[1].includes("mp4");
                    } else if (
                      elementType === "map" ||
                      handle === "floorplan"
                    ) {
                      return file[1].includes("svg");
                    } else if (
                      handle === "hotspot" ||
                      handle === "banner" ||
                      handle === "cover"
                    ) {
                      return (
                        file[1].includes("png") ||
                        file[1].includes("jpg") ||
                        file[1].includes("jpeg")
                      );
                    }

                    return file;
                  })
                  .map((file, index) => (
                    <div
                      className={`relative flex gap-8 items-center justify-${
                        file[1].includes("wav") || file[1].includes("mp3")
                          ? "between"
                          : "start"
                      } w-[50%] h-fit p-2 wrap cursor-pointer rounded-md border-2 border-${
                        elementContent === file[1] ? "magenta" : "transparent"
                      } hover:border-magenta bg-${
                        elementContent === file[1] ? "magenta" : "transparent"
                      } hover:bg-denim with-transition`}
                      key={index}
                      onClick={mediaFileUrlHandler}
                      id={file[1]}
                      title={file[1]}
                    >
                      <div
                        className="absolute left-0 top-0 w-full h-full bg-denim opacity-50 mix-blend-multiply rounded-md with-transition"
                        onClick={mediaFileUrlHandler}
                        id={file[1]}
                        title={file[1]}
                      ></div>
                      <span
                        className="w-[50px] h-[50px] bg-white bg-center bg-cover rounded cursor-pointer with-transition z-10 flex justify-center items-center"
                        style={
                          file[1].includes("image")
                            ? {
                                backgroundImage: `url(${file[1]}`,
                              }
                            : {}
                        }
                        onClick={mediaFileUrlHandler}
                        id={file[1]}
                        title={file[1]}
                      >
                        {file[1].includes("wav") || file[1].includes("mp3") ? (
                          <i
                            className="iconify"
                            data-icon="ant-design:audio-filled"
                            onClick={mediaFileUrlHandler}
                            id={file[1]}
                            title={file[1]}
                          />
                        ) : file[1].includes("mp4") ? (
                          <i
                            className="iconify"
                            data-icon="lets-icons:video-fill"
                            onClick={mediaFileUrlHandler}
                            id={file[1]}
                            title={file[1]}
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                      <span
                        className="text-white font-bold z-10 select-none grow"
                        onClick={mediaFileUrlHandler}
                        id={file[1]}
                        title={file[1]}
                      >
                        {file[1]
                          .split("/")
                          [file[1].split("/").length - 1].substring(50, 0)}
                        {file[1].split("/")[file[1].split("/").length - 1]
                          .length > 50
                          ? "..."
                          : ""}
                      </span>
                      {file[1].includes("wav") || file[1].includes("mp3") ? (
                        <AppMiniAudioPlayer
                          src={file[1]}
                          id={`audio-${file[1]}`}
                          mainClassName="z-10 justify-self-end mr-2"
                          className="flex gap-1 bg-white p-1 rounded-full grow"
                          controlsClassName="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-magenta hover:bg-denim text-white font-bold text-xs with-transition flex gap-1 cursor-pointer"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </UploadMenu>
      ) : (
        <></>
      )}
      <div className="col-span-12 lg:col-span-8">
        <TabWrapper
          value="guides"
          tabClass="font-bold"
          inactiveTabClass="text-denim"
          activeTabClass="text-green"
        >
          <div className="lg:flex justify-between w-full">
            <div className="order-2 flex justify-center mb-5 lg:mb-0"></div>
            <div className="flex justify-between lg:justify-start gap-2 lg:gap-8 w-full">
              {tabs.map((tab, index) => (
                <div key={index} className="flex justify-between">
                  <TabLabel name={tab.name}>
                    <span className="text-lg font-oswald capitalize">
                      {tab.name}
                    </span>
                  </TabLabel>
                </div>
              ))}
            </div>
            <div className="flex gap-2">
              {/* <button
              className="order-2 lg:order-3 uppercase font-bold text-white bg-green hover:bg-denim rounded-full w-full lg:w-48 h-12 flex items-center justify-center font-oswald gap-3 with-transition"
              onClick={() => {
                if (guides?.length) setGuide(guides[0].content)
              }}
            >
              Import Project
            </button> */}
              <button
                className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 h-12 flex items-center justify-center font-oswald gap-3 with-transition"
                onClick={showDiscardWarning}
              >
                Discard Project
              </button>
            </div>
          </div>
          <div>
            <section className="mt-5">
              {tabs.map((tab, index) => (
                <TabContent key={index} name={tab.name}>
                  {tab.content}
                </TabContent>
              ))}
            </section>
          </div>
        </TabWrapper>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ManageGuides;
