import React from "react";
import DenimLayout from "../layouts/DenimLayout";
import AppBusinessCard from "../components/global/AppBusinessCard";
import { roles } from "../data/data";

const DimensioningFuture = () => {
  return (
    <DenimLayout>
      <div className="bg-denim">
        <div className="container text-white flex flex-col items-center justify-center">
          <h1 className="font-title pt-32 text-center uppercase font-semibold flex justify-center gap-4 flex-wrap">
            <span>Dimensioning </span>
            <span className="text-green block">the Future</span>
          </h1>
          <p className="mt-8 text-center lg:w-[50%]">
            Embrace the innovation cosmos and unite with the pioneers! Join us
            on this odyssey, where limitless potential awaits as we co-create a
            world beyond imagination. Possibilities are infinite and tomorrow is
            going to be brighter!
          </p>
          <div className="grid md:grid-cols-2 xl:grid-cols-2 gap-5 xl:gap-8 py-12 w-full xl:w-2/3 2xl:w-1/2">
            {roles.map((role, i) => (
              <AppBusinessCard key={i} {...role} />
            ))}
          </div>
        </div>
      </div>
    </DenimLayout>
  );
};

export default DimensioningFuture;
