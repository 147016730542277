import React from "react";

const AppCustomInputFile = ({
  onClick,
  id,
  label,
  placeholder,
  error,
  labelClass,
  asRequired,
  audio,
  value,
}) => {
  return (
    <label
      htmlFor={id}
      id={id}
      className="grid grid-cols-1 gap-1 relative w-full cursor-pointer"
      onClick={onClick}
    >
      <p className="flex" id={id}>
        <label className={`font-semibold`} id={id}>
          {label}
        </label>
        <span
          className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}
          id={id}
        >
          *
        </span>
      </p>
      <div
        className={`flex justify-between relative with-transition ${labelClass} ${
          asRequired && "required-help"
        } ${error && "text-red-500"}`}
        id={id}
      >
        <span className="break-all" id={id}>
          {value?.length
            ? value.split("/")[value.split("/").length - 1]
            : placeholder}
        </span>
        <i
          id={id}
          className={`iconify ${audio ? "text-sm" : ""}`}
          data-icon={
            audio ? "ant-design:sound-filled" : "material-symbols:image"
          }
        />
      </div>
      <span className="empty:hidden shake text-red-500" id={id}>
        {error}
      </span>
    </label>
  );
};

export default AppCustomInputFile;
