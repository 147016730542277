import React, { forwardRef } from "react";

const AppInput = (
  {
    id,
    label,
    placeholder,
    type = "text",
    error,
    className,
    labelClass,
    asRequired,
    onChange,
    ...rest
  },
  ref
) => {
  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <span
        className={`flex relative with-transition ${
          error ? "text-red-500" : labelClass
        } ${asRequired && "required-help"}`}
      >
        {label}
        <span className={`text-green pl-1 ${asRequired ? "flex" : "hidden"}`}>
          *
        </span>
      </span>
      <input
        ref={ref}
        type={type}
        min={type === "number" ? "0.00" : null}
        step={type === "number" ? "0.01" : null}
        id={id}
        placeholder={placeholder}
        className={`
          w-full py-1.5 px-2 focus:outline-none caret-current border-2 border-gray-border rounded-lg
          ${error && "border-red-500"}
          ${className}
        `}
        onChange={(event) => onChange?.(event)}
        {...rest}
      />
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};

export default forwardRef(AppInput);
