import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  GOOGLE_AUTH_REQUEST,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  GOOGLE_BUTTON_STYLES,
} from "../constants/googleConstants";

// initialize oauth
export const googleAuth = (key, callback) => {
  /* global google */
  google.accounts.id.initialize({
    client_id: key,
    callback,
  });

  google.accounts.id.renderButton(
    document.getElementById("login-google"),
    GOOGLE_BUTTON_STYLES,
  );
};

// googleAuth
export const getGoogleOAuthClientID = () => async (dispatch) => {
  try {
    dispatch({
      type: GOOGLE_AUTH_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/google/oauth`);

    dispatch({
      type: GOOGLE_AUTH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GOOGLE_AUTH_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
