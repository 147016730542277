import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AppInput from "../global/form/AppInput";

const ValidateCode = ({ code, codeHandler, submitHandler, useBack = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  const handleClick = () => {
    if (hasPreviousState) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="w-[100%] h-[100%] flex justify-center items-center">
      <form
        className="grid gap-2 bg-white p-14 rounded shadow-xl"
        onSubmit={submitHandler}
      >
        <AppInput
          id="password"
          type="password"
          label="Enter code"
          value={code}
          onChange={codeHandler}
          labelClass="text-denim/40"
          className="bg-transparent mb-4"
        />
        <button
          type="submit"
          className="font-semibold text-white border-2 border-transparent hover:border-denim hover:text-denim uppercase app-primary-button animate-bg h-14 w-full flex justify-center gap-5 with-transition"
          onSubmit={submitHandler}
        >
          <span className="block">submit</span>
        </button>
        {useBack ? (
          <button
            type="button"
            onClick={handleClick}
            className="font-semibold text-white border-2 border-transparent hover:border-denim hover:text-denim uppercase app-primary-button animate-bg h-14 w-full px-4 flex justify-center gap-5 with-transition"
          >
            <span className="block flex gap-2 items-center justify-center pr-2">
              <i className="iconify text-xl" data-icon="bxs:left-arrow" />
              {" Go "}
              {hasPreviousState ? "Back" : "Home"}
            </span>
          </button>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default ValidateCode;
