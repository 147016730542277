import ManageTours from "./manage-tours/ManageTours";
import ManageGuides from "./manage-guides/ManageGuides";
import ManageOrders from "./manage-orders/ManageOrders";
import ManageUsers from "./manage-users/ManageUsers";
import ManageCandidates from "./manage-candidates/ManageCandidates";
import ManagePartners from "./manage-partners/ManagePartners";
import ManageMapMarkers from "./manage-map-markers/ManageMapMarkers";
import ManageSessions from "./manage-sessions/ManageSessions";
// import ManageMediaLibrary from "./manage-media-library/ManageMediaLibrary";
import ManageAffiliates from "./manage-affiliates/ManageAffiliates";
// import ManageCoworkers from "./manage-coworkers/ManageCoworkers";
// import ManageOffersPromotions from "./manage-offers-promotions/ManageOffersPromotions";
// import ManageBlog from "./manage-blog/ManageBlog";
// import ManageMails from "./manage-contact-mails/ManageMails";

const AdminDashboardTabs = [
  {
    name: "partners",
    text: "Partners",
    icon: "mdi:partnership",
    content: <ManagePartners />,
  },
  {
    name: "tours",
    text: "VR Content",
    icon: "mdi:local-airport",
    content: <ManageTours />,
  },
  {
    name: "orders",
    text: "Orders",
    icon: "ion:bag",
    content: <ManageOrders />,
  },
  {
    name: "guides",
    text: "Digital Guides",
    icon: "solar:notebook-bookmark-bold",
    content: <ManageGuides />,
  },
  // {
  //   name: "media-library",
  //   text: "Media Library",
  //   icon: "ph:play-fill",
  //   content: <ManageMediaLibrary />,
  // },
  {
    name: "sessions",
    text: "Sessions",
    icon: "ic:baseline-access-time",
    content: <ManageSessions />,
  },
  {
    name: "users",
    text: "Users",
    icon: "mdi:user",
    content: <ManageUsers />,
  },
  {
    name: "candidates",
    text: "Candidates",
    icon: "mdi:user-outline",
    content: <ManageCandidates />,
  },
  {
    name: "affiliates",
    text: "Affiliates",
    icon: "dashicons:megaphone",
    content: <ManageAffiliates />,
  },
  // {
  //   name: "coworkers",
  //   text: "Coworkers",
  //   icon: "ic:outline-work",
  //   content: <ManageCoworkers />,
  // },
  {
    name: "markers",
    text: "Markers",
    icon: "ph:map-pin-fill",
    content: <ManageMapMarkers />,
  },
  // {
  //   name: "blog",
  //   text: "Blog",
  //   icon: "fluent:textbox-16-filled",
  //   content: <ManageBlog />,
  // },
  // {
  //   name: "contact-mails",
  //   text: "Contact Mails",
  //   icon: "ic:round-email",
  //   content: <ManageMails />,
  // },
  // {
  //   name: "offers-promotions",
  //   text: "Offers & Promotions",
  //   icon: "mingcute:tag-fill",
  //   content: <ManageOffersPromotions />,
  // },
];

export default AdminDashboardTabs;
