export const MAIN_ROUTE = "/v1/api";

const BASE_URL = `${process.env.REACT_APP_API_URL}/`;
const BASE_AUTH_URL = `${BASE_URL}auth/`;

export const CONTACT_API_URL = `${BASE_AUTH_URL}contact/`;
export const GOOGLE_SIGN_UP_API_URL = "callback/google/login";
export const MAP_MARKERS_API_URL = "maps/mapsfilterbytag";
export const SIGN_IN_API_URL = `${BASE_AUTH_URL}login/`;
export const SIGN_UP_API_URL = `${BASE_AUTH_URL}register/`;
