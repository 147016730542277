import React, { useEffect, useState } from "react";
import AppInput from "../components/global/form/AppInput";
import MainLayout from "../layouts/MainLayout";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../assets/logos/logo-denim-green.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordValidateErrorMessage,
  validateEmail,
  validatePassword,
} from "../constants/helpers";
import { USER_LOGIN_FAIL, USER_LOGIN_RESET } from "../constants/userConstants";
import { login } from "../actions/userActions";
import Message from "../components/global/Message";
import Loader from "../components/global/Loader";

const AdminLoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { loading: loadingLogin, error: errorLogin, userInfo } = vrvUserLogin;

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "admin") {
        navigate("/admin/config");
      } else {
        navigate("/");
      }
    }
  }, [userInfo, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const resetFields = () => {
    setEmail("");
    setPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0) {
      if (validateEmail(email) && validatePassword(password)) {
        dispatch(login("admin", email, password));
        resetFields();
      } else {
        if (!validateEmail(email)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: "Invalid email format!",
          });
        }
        if (!validatePassword(password)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: passwordValidateErrorMessage,
          });
        }
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "All fields are required!",
      });
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorLogin) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }

    // eslint-disable-next-line
  }, [errorLogin, dispatch]);

  return (
    <>
      {errorLogin && <Message error body={errorLogin} />}
      {loadingLogin ? (
        <Loader />
      ) : (
        !userInfo && (
          <MainLayout
            scrollNavClass="bg-gray"
            logo={Logo}
            textHover="text-green"
            textHoverWithScroll="text-green"
            styleBtn="hover:text-denim"
            styleBtnWithScroll="hover:text-denim hover:border-denim"
          >
            <NavLink to="/" className="lg:hidden absolute ml-4 mt-5">
              <img src={Logo} alt="Logo" className="w-48" />
            </NavLink>
            <div className="container mt-32 pb-80 mx-auto lg:w-[375px]">
              <div className="lg:col-span-4 flex flex-col gap-4">
                <h2 className="text-2xl text-center mb-4 uppercase font-semibold font-oswald">
                  Admin Login
                </h2>
                <form className="grid gap-5" onSubmit={submitHandler}>
                  <AppInput
                    asRequired
                    id="email"
                    type="email"
                    label="Email"
                    labelClass="font-semibold"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={email}
                    onChange={emailHandler}
                  />
                  <AppInput
                    asRequired
                    id="password"
                    type="password"
                    label="Password"
                    labelClass="font-semibold"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={password}
                    onChange={passwordHandler}
                  />
                  <div className="w-full rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto overflow-hidden with-transition">
                    <button
                      type="submit"
                      className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-full flex justify-center with-transition"
                      onSubmit={submitHandler}
                    >
                      <span className="block">log in</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </MainLayout>
        )
      )}
    </>
  );
};

export default AdminLoginScreen;
