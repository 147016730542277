import React from "react";

const LegalPrivacyPolicy = () => {
  return (
    <main>
      <section className="grid gap-10 lg:gap-12">
        <div className="grid gap-5">
          <h1 className="font-bold uppercase text-3xl">PRIVACY POLICY</h1>
          <span className="text-xs font-bold">
            This Policy was last updated on 27 September 2023.
          </span>
        </div>
        <p>
          At The VR Voyage, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy (“Privacy Policy”) outlines how we collect, use, disclose, and
          safeguard your data when you interact with our digital platform.
        </p>
        <ol className="grid gap-10 lg:gap-12">
          <li>
            <p className="text-lg font-bold mb-5">1. Type Of Collected Data</p>
            <ol className="grid gap-5 pl-5">
              <li>
                <ul className="grid gap-5">
                  <li>
                    <span className="font-bold">Personal Information: </span>
                    When you create an account on The VR Voyage platform, we
                    collect the following personal information: your full name,
                    email address, phone number, and billing address. This
                    information is essential for account creation, booking
                    experiences online, and providing personalized services.
                  </li>
                  <li>
                    <span className="font-bold">Payment Information:</span> To
                    process bookings and payments, we collect credit card or
                    debit card information. Please note that we do not store
                    this sensitive payment data on our servers. Our trusted
                    payment processing partner handles all transactions
                    securely, and their privacy policy governs the use of
                    payment information.
                  </li>
                  <li>
                    <span className="font-bold">Location Data:</span> To We may
                    collect location information through GPS and IP addresses to
                    offer location-specific content and improve your user
                    experience. You can choose to allow or deny access to your
                    location data through your device settings.
                  </li>
                  <li>
                    <span className="font-bold">Usage Data:</span> To We
                    automatically collect certain information about how you
                    interact with our platform, including pages visited, links
                    clicked, and actions taken. This data helps us analyze user
                    behaviour and enhance our services.
                  </li>
                  <li>
                    <span className="font-bold">Communication Data:</span> When
                    you subscribe to our newsletters, blogs, or other marketing
                    materials, we collect your email address to deliver the
                    requested content.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              2. Lawful Basis For Collecting Data
            </p>
            <p className="pl-5">
              The VR Voyage relies on your consent as the lawful basis for
              collecting personal data, payment information, and location data.
              By creating an account and using our platform, you explicitly
              consent to the collection and processing of your data as described
              in this Privacy Policy.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              3. How We Protect Collected Data
            </p>
            <p className="pl-5">
              We value your trust and take data security seriously. The VR
              Voyage implements industry-standard security measures to protect
              against unauthorized access, alteration, disclosure, or
              destruction of personal data. We use encryption protocols,
              firewalls, access controls, and secure data storage practices to
              ensure maximum data security.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              4. Retention of Collected Information
            </p>
            <p className="pl-5">
              We retain your data only for as long as it is necessary to fulfill
              the purposes outlined in this Privacy Policy or as required by
              law. If you request the deletion of your account, we will
              anonymize or securely delete your data within a reasonable
              timeframe, as per the British Columbia privacy laws.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              5. How We Use The Collected Data:
            </p>
            <p className="pb-5 pl-5">
              The VR Voyage uses the collected data for the following purposes:
            </p>
            <ol className="grid gap-5 pl-5">
              <li>
                <ul className="grid gap-5">
                  <li>
                    <span className="font-bold">Account Management: </span>
                    We use your information to create and manage your account,
                    process bookings, and provide customer support.
                  </li>
                  <li>
                    <span className="font-bold">Personalization:</span> Your
                    data enables us to customize your user experience, recommend
                    relevant activities, and provide location-specific content.
                  </li>
                  <li>
                    <span className="font-bold">Communication:</span> We use
                    your email address to send newsletters, blogs, and
                    promotional materials you have subscribed to receive.
                  </li>
                  <li>
                    <span className="font-bold">Service Improvement:</span> We
                    analyze usage patterns, conduct data analysis, and enhance
                    our platform's functionality and user interface to improve
                    our services continually.
                  </li>
                  <li>
                    <span className="font-bold">Legal Compliance:</span> We may
                    process your data to comply with applicable laws,
                    regulations, and legal processes.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              6. List Of Data Subject Rights
            </p>
            <p className="pb-5 pl-5">
              The VR Voyage acknowledges the following data subject rights under
              the General Data Protection Regulation (GDPR) and the British
              Columbia Personal Information Protection Act (PIPA) and commits to
              respect and fulfill these rights:
            </p>
            <ol className="grid gap-5 pl-5">
              <li>
                <ul className="grid gap-5">
                  <li>
                    <span className="font-bold"> Right of Access: </span>
                    You have the right to request access to your personal data
                    that we process and receive a copy of the information.
                  </li>
                  <li>
                    <span className="font-bold">Right to Be Informed:</span>
                    You have the right to be informed about the collection and
                    use of your personal data in a clear and transparent manner.
                  </li>
                  <li>
                    <span className="font-bold">Right to Erasure:</span> You can
                    request the deletion of your personal data from our platform
                    and systems, subject to any legal obligations that may
                    require us to retain certain information.
                  </li>
                  <li>
                    <span className="font-bold"> Right to Object:</span> You
                    have the right to object to the processing of your personal
                    data in certain circumstances, including direct marketing.
                  </li>
                  <li>
                    <span className="font-bold">Right of Rectification:</span>
                    You can request the correction or updating of inaccurate or
                    incomplete personal data.
                  </li>
                  <li>
                    <span className="font-bold"> Right of Portability:</span>
                    You can request to receive your personal data in a commonly
                    used and machine-readable format or have it transmitted to
                    another controller.
                  </li>
                  <li>
                    <span className="font-bold">
                      Right to Restrict Processing:
                    </span>
                    You can request the restriction of processing your personal
                    data under specific circumstances, such as when the accuracy
                    of the data is contested.
                    <p className="py-5">
                      Rights in Relation to Automated Data Processing and
                      Profiling: You have the right not to be subject to
                      automated decision-making processes and profiling that
                      significantly affect you.
                    </p>
                    <p>
                      To inquire about or exercise these rights, please contact
                      us at VR EMAIL.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold mb-5">
              7. Updating The Privacy Policy
            </p>
            <p className="pl-5">
              The VR Voyage reserves the right to update this Privacy Policy
              periodically to reflect changes in our data practices or legal
              requirements. You will be notified of any significant changes, and
              the updated policy will be published on our platform.
            </p>
          </li>
        </ol>
      </section>
    </main>
  );
};

export default LegalPrivacyPolicy;
