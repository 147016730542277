import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AppInput from "../global/form/AppInput";

const Search = ({
  collection,
  type,
  className,
  inputClass,
  collectionClass,
  prepend,
  append,
  onChange,
}) => {
  const [keyword, setKeyword] = useState(``);
  const [filteredCollection, setFilteredCollection] = useState([]);

  const keywordHandler = (e) => {
    setKeyword(e.target.value.toLowerCase());
    onChange?.(e.target.value);
  };

  useEffect(() => {
    if (collection) {
      setFilteredCollection(collection);
    }
  }, [collection]);

  useEffect(() => {
    if (keyword && collection) {
      if (keyword.length > 0) {
        setFilteredCollection(
          collection.filter((tour) =>
            tour.title.toLowerCase().includes(keyword),
          ),
        );
      } else {
        setFilteredCollection([]);
      }
    }
  }, [keyword, collection]);

  return (
    <div
      className={`w-full md:w-[565px] flex items-center flex-wrap mx-auto lg:mx-0 rounded-[10px] ${className}`}
    >
      <div className={`w-full ${inputClass} relative`}>
        <div className="w-full flex items-center bg-white px-2 rounded-lg">
          {prepend}
          <AppInput
            placeholder={`Search ${type}s`}
            className="pb-3 placeholder:denim border-transparent outline-none"
            onChange={keywordHandler}
          />
          <i className="iconify font-bold" data-icon="ion:search" />
        </div>
        {keyword.length && filteredCollection.length ? (
          <div
            tabIndex="0"
            className={`w-full menu shadow bg-white rounded-box absolute bottom-0`}
          >
            <ul
              className={`w-full absolute left-0 top-0 z-10 mt-2 ${collectionClass}`}
            >
              {filteredCollection.map((ele, index) => (
                <li key={index} className="bg-white hover:bg-yellow with-transition">
                  {type && (
                    <Link
                      to={
                        type === "partner"
                          ? `/${type}/profile/${ele.slug}`
                          : type === "online"
                          ? `/tour/${ele.slug}/demo`
                          : type === "in-person"
                          ? `/guide/${ele.slug}/demo`
                          : ""
                      }
                      id="en"
                    >
                      {ele.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
        {append}
      </div>
    </div>
  );
};

export default Search;

/*
<div className="-mt-[86px] mb-[137px] lg:mb-[169px] flex items-center mx-auto lg:mx-0 lg:w-[375px] px-1 bg-white overflow-hidden rounded-lg pr-2">
            <AppInput
              {...register("catalogue-search")}
              placeholder="Search experiences"
              className="pb-3 placeholder:denim border-transparent outline-none"
            />
            <i className="iconify font-bold" data-icon="octicon:search-16" />
          </div>
 */
