import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBreakpoints } from "../hooks/useBreakpoints";

import Logo from "../assets/logos/logo-denim-green.svg";
import MainLayout from "../layouts/MainLayout";
import { TabContent, TabLabel, TabWrapper } from "../components/global/AppTabs";
import AdminDashboardTabs from "../components/admin-dashboard/AdminDashboardTabs";

const AdminDashboardScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo } = vrvUserLogin;

  const navigate = useNavigate();
  const { lg } = useBreakpoints();

  const [activeTab, setActiveTab] = useState(lg ? "partners" : null);

  useEffect(() => {
    if (!userInfo || userInfo?.role !== "admin") {
      navigate("/");
    }
  }, [userInfo, navigate]);

  return (
    userInfo && (
      <>
        <MainLayout
          scrollNavClass="bg-gray"
          logo={Logo}
          textHover="text-green"
          textHoverWithScroll="text-green"
          styleBtn="hover:text-denim"
          styleBtnWithScroll="hover:text-denim hover:border-denim"
          styleBurger="hidden"
          hiddeFooter
        >
          <div className="flex lg:hidden justify-bettew mt-5 ml-4">
            <NavLink to="/admin">
              <i
                className="iconify text-xl"
                data-icon="material-symbols:arrow-back-ios-rounded"
              />
            </NavLink>
            <NavLink to="/" className="absolute right-4 top-5">
              <img src={Logo} alt="Logo" className="w-48" />
            </NavLink>
          </div>
          <div className="container min-h-[920px] pb-12">
            <TabWrapper
              useUrl
              value={activeTab}
              tabClass="flex justify-between lg:px-2 lg:py-[10px]"
              activeTabClass="bg-white rounded-md"
              onChange={(tab) => setActiveTab(tab)}
            >
              <div className="grid lg:grid-cols-12 gap-6 lg:h-[920px] pt-14 lg:pt-24">
                <div
                  className={`${
                    activeTab ? "hidden" : "grid"
                  } lg:flex flex-col col-span-12 lg:col-span-4 xl:col-span-3 gap-5 lg:gap-0 px-3`}
                >
                  {AdminDashboardTabs.map((item) => (
                    <TabLabel key={item.name} name={item.name}>
                      <div className="flex gap-2 xl:gap-4 items-center">
                        <i
                          className={`iconify text-right`}
                          data-icon={item.icon}
                        />
                        {item.text}
                      </div>
                      <i
                        className="iconify lg:hidden"
                        data-icon="material-symbols:arrow-back-ios-rounded"
                        data-rotate="180deg"
                      />
                    </TabLabel>
                  ))}
                </div>
                <div
                  className={`${
                    activeTab ? "block" : "hidden lg:block"
                  } col-span-12 lg:col-span-8 xl:col-span-9 pl-2`}
                >
                  {AdminDashboardTabs.map((item) => (
                    <TabContent
                      key={item.name}
                      name={item.name}
                      className="h-full"
                    >
                      {item.content}
                    </TabContent>
                  ))}
                </div>
              </div>
            </TabWrapper>
          </div>
        </MainLayout>
      </>
    )
  );
};

export default AdminDashboardScreen;
