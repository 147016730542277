import React from "react";

import GoBack from "../global/GoBack";

const TourExploreHeader = ({ visual, onBackClick, text, title, styles }) => {
  return (
    <div
      className={`w-full flex justify-center ${
        visual?.length ? "" : "p-4"
      } items-center min-h-[100px] h-fit ${styles}`}
    >
      <GoBack
        className="bg-white with-transition rounded-full grid absolute top-4 left-4 z-10 w-8 items-center place-items-center aspect-square"
        onBackClick={() => onBackClick()}
      />
      <div className="text-center">
        <div className={visual?.length ? "p-4" : ""}>
          {text ? (
            <p className="mx-auto capitalize">
              {text.substring(0, 24)}
              {text?.length > 24 ? "..." : ""}
            </p>
          ) : (
            <></>
          )}
          <h2 className="text-center text-lg font-bold mt-2">{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default TourExploreHeader;
