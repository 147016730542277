import React from "react";
import { Link } from "react-router-dom";

const AppPartnerCard = ({ img, title, text, link, className, ringColor }) => (
  <Link
    to={link}
    className={`group/arrowCards h-full relative block w-72 lg:w-96 border-radius overflow-hidden ring-4 ${className} with-transition`}
  >
    <div
      className="h-52 w-full bg-cover bg-no-repeat bg-center zoomed"
      style={{ backgroundImage: `url(${img})` }}
    />
    <div className="relative py-8 px-6">
      <h5 className="text-2xl font-semibold mb-2 uppercase">{title}</h5>
      {text && <p className="text-sm"> {text} </p>}
      <span
        className={` absolute -top-2 right-3.5 -translate-y-5 w-14 aspect-square grid place-items-center rounded-full bg-denim group-hover/arrowCards:bg-white ring-4 ${ringColor} with-transition`}
      >
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25 1.5L8.75 9L1.25 16.5"
            className="stroke-white group-hover/arrowCards:stroke-slate-900"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  </Link>
);

export default AppPartnerCard;
