import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { requestToJoin } from "../../actions/userActions";
import { USER_CREATE_FAIL } from "../../constants/userConstants";

import {
  generateSlug,
  invalidCoordinatesErrorMessage,
  matchPasswords,
  passwordMatchingErrorMessage,
  passwordValidateErrorMessage,
  requiredDaysErrorMessage,
  requiredFieldsErrorMessage,
  requiredTagsErrorMessage,
  validateCoordinate,
  validateEmail,
  validatePassword,
  validateRequiredFields,
} from "../../constants/helpers";
import { categories, subCategories } from "../../constants/categories";
import daysOfWeek from "../../constants/daysOfWeek";
import { provinces, regions } from "../../constants/geography";
import locations from "../../data/locations.json";
import AppInput from "../../components/global/form/AppInput";
import AppSelect from "../../components/global/form/AppSelect";
import AppTextArea from "../../components/global/form/AppTextArea";
import AppChipsInput from "../../components/global/form/AppChipsInput";
import { AppPhoneInput } from "../../components/global/form/AppPhoneInput";
import { AppTimeInput } from "../../components/global/form/AppTimeInput";

const PartnerRegistrationForm = () => {
  const dispatch = useDispatch();

  const userCreate = useSelector((state) => state.userCreate);
  const { message: created } = userCreate;

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        resetFields();
      }, 5000);
    }
  }, [created]);

  // main
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [organizationType, setOrganizationType] = useState("");

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const categoryHandler = (e) => {
    setCategory(e.target.value);
    setSubCategory("");
    setOrganizationType("");
  };

  const subCategoryHandler = (e) => {
    setSubCategory(e.target.value);
    setOrganizationType("");
  };

  const organizationTypeHandler = (e) => {
    setOrganizationType(e.target.value);
  };

  //personal
  const [bio, setBio] = useState([]);
  const [about, setAbout] = useState([]);
  const [values, setValues] = useState([]);
  const [exploring, setExploring] = useState([]);
  const [landAcknowledgement, setLandAcknowledgement] = useState("");
  const [tags, setTags] = useState([]);

  const bioHandler = (e) => {
    setBio(e.target.value.split("\n"));
  };

  const aboutHandler = (e) => {
    setAbout(e.target.value.split("\n"));
  };

  const valuesHandler = (e) => {
    setValues(e.target.value.split("\n"));
  };

  const exploringHandler = (e) => {
    setExploring(e.target.value.split("\n"));
  };

  const tagsHandler = (e) => {
    setTags(e);
  };

  const landAcknowledgementHandler = (e) => {
    setLandAcknowledgement(e.target.value);
  };

  // info
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [timeFrom, setTimeFrom] = useState("09:00");
  const [timeTo, setTimeTo] = useState("18:00");
  const [days, setDays] = useState(new Array(daysOfWeek.length).fill(false));

  const phoneHandler = (e) => {
    setPhone(e);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const timeFromHandler = (e) => {
    setTimeFrom(e.target.value);
  };

  const timeToHandler = (e) => {
    setTimeTo(e.target.value);
  };

  const daysHandler = (positionDays) => {
    const updatedDays = days.map((itemDays, indexDays) =>
      indexDays === positionDays ? !itemDays : itemDays,
    );

    setDays(updatedDays);
  };

  // location
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [region, setRegion] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const countryHandler = (e) => {
    setCountry(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const regionHandler = (e) => {
    setRegion(e.target.value);
  };

  const streetAddressHandler = (e) => {
    setStreetAddress(e.target.value);
  };

  const latitudeHandler = (e) => {
    setLatitude(e.target.value);
  };

  const longitudeHandler = (e) => {
    setLongitude(e.target.value);
  };

  // action handlers
  const resetFields = () => {
    setTitle("");
    setEmail("");
    setPassword("");
    setConfirmedPassword("");
    setCategory("");
    setSubCategory("");
    setTags([]);
    setCountry("");
    setCity("");
    setProvince("");
    setRegion("");
    setStreetAddress("");
    setLandAcknowledgement("");
    setPhone("");
    setWebsite("");
    setTimeFrom("");
    setTimeTo("");
    setDays(new Array(daysOfWeek.length).fill(false));
    setLatitude("");
    setLongitude("");
    setBio([]);
    setAbout([]);
    setValues([]);
    setExploring([]);
  };

  // submit
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (!validateEmail(email)) {
        throw new Error("Invalid email format!");
      }

      if (!validatePassword(password) || !validatePassword(confirmedPassword)) {
        throw new Error(passwordValidateErrorMessage);
      }

      if (!matchPasswords(password, confirmedPassword)) {
        throw new Error(passwordMatchingErrorMessage);
      }

      if (
        !validateRequiredFields([
          title,
          category,
          subCategory,
          organizationType,
          landAcknowledgement,
          phone,
          website,
          timeFrom,
          timeTo,
          country,
          city,
          streetAddress,
        ]) ||
        !bio.filter((word) => /\S/.test(word)) ||
        !about.filter((word) => /\S/.test(word)) ||
        !values.filter((word) => /\S/.test(word))
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!validateCoordinate(latitude) || !validateCoordinate(longitude)) {
        throw new Error(invalidCoordinatesErrorMessage);
      }

      if (country === "Canada") {
        if (!validateRequiredFields([province, region])) {
          throw new Error(requiredFieldsErrorMessage);
        }
      }

      if (!days.map((ele) => ele === true)?.length) {
        throw new Error(requiredDaysErrorMessage);
      }

      if (!tags?.length) {
        throw new Error(requiredTagsErrorMessage);
      }

      // dispatching
      const partner = {
        title,
        slug: generateSlug(title),
        email,
        password,
        role: "partner",
        banner: "",
        avatar: "",
        category,
        subCategory,
        organizationType,
        bio: bio.filter((word) => /\S/.test(word)),
        about: about.filter((word) => /\S/.test(word)),
        values: values.filter((word) => /\S/.test(word)),
        exploring: exploring.filter((word) => /\S/.test(word)),
        aboutVisual: "",
        valuesVisual: "",
        exploringVisual: "",
        landAcknowledgement,
        tags,
        info: {
          phone,
          website,
          availability: { timeFrom, timeTo, daysOfWeek: days },
        },
        location: {
          country,
          province,
          region,
          city,
          streetAddress,
          coordinates: { latitude, longitude },
        },
      };
      // join request
      dispatch(requestToJoin("partner", partner));
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

  return (
    <form className="flex flex-col gap-5 mt-5 pb-5" onSubmit={submitHandler}>
      <>
        <h6 className="mt-7 uppercase font-semibold text-2xl">
          personal information
        </h6>
        <AppInput
          asRequired
          id="organizationName"
          label="Organization name"
          labelClass="font-bold font-quicksand"
          className="bg-transparent"
          value={title}
          onChange={titleHandler}
        />
        <AppInput
          asRequired
          id="email"
          label="Email"
          type="email"
          labelClass="font-bold font-quicksand"
          className="bg-transparent"
          value={email}
          onChange={emailHandler}
        />
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-4">
          <AppInput
            asRequired
            id="password"
            type="password"
            label="Password"
            labelClass="font-bold font-quicksand"
            className="bg-transparent"
            value={password}
            onChange={passwordHandler}
          />
          <AppInput
            asRequired
            id="confirmedPassword"
            type="password"
            label="Confirm password"
            labelClass="font-bold font-quicksand"
            className="bg-transparent"
            value={confirmedPassword}
            onChange={confirmedPasswordHandler}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-4">
          <AppSelect
            asRequired
            id="category"
            items={categories.map((ele) => ele.name)}
            itemText="name"
            itemValue="id"
            label="Category"
            placeholder="-- Please select one --"
            labelClass="font-bold font-quicksand"
            className="bg-transparent border-gray-border border-2 rounded-lg"
            value={category}
            onChange={categoryHandler}
          />
          {category?.length ? (
            <AppSelect
              asRequired
              id="subCategory"
              items={[]
                .concat(
                  ...categories
                    .find((ele) => ele.name === category)
                    .types.map((type) => type.items),
                )
                .map((item) => item.name)}
              itemText="name"
              itemValue="id"
              label="Sub-category"
              placeholder="-- Please select one --"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={subCategory}
              onChange={subCategoryHandler}
            />
          ) : (
            <></>
          )}
        </div>
        <AppSelect
          asRequired
          id="organizationType"
          items={category?.length && subCategory?.length ? subCategories : []}
          itemText="name"
          itemValue="id"
          label="Organization type"
          placeholder={`-- Please select ${
            category?.length && subCategory?.length
              ? "one"
              : "category and sub-category first"
          } --`}
          labelClass="font-bold font-quicksand"
          className="bg-transparent border-gray-border border-2 rounded-lg"
          value={organizationType}
          onChange={organizationTypeHandler}
        />
        <AppChipsInput
          asRequired
          items={[].concat(
            categories
              .map((c) => c.types.map((t) => t.items))
              .flat(2)
              .map((t) => t.name)
              .concat(subCategories),
          )}
          id="tags"
          label="Tags"
          labelClass="font-bold font-quicksand"
          className="bg-transparent border-gray-border border-2 rounded-lg"
          value={tags}
          onChange={(e) => tagsHandler(e)}
        />

        <h6 className="mt-7 uppercase font-semibold text-2xl">location</h6>
        <div className="lg:flex lg:gap-8">
          <AppSelect
            asRequired
            id="country"
            items={locations.map((ele) => ele.country)}
            itemText="name"
            itemValue="id"
            label="Country"
            placeholder="-- Please select one --"
            labelClass="font-semibold"
            className="border-2 border-gray-border rounded-lg"
            value={country}
            onChange={countryHandler}
          />
          <AppSelect
            asRequired
            id="city"
            items={locations.find((ele) => ele.country === country)?.city}
            itemText="name"
            itemValue="id"
            label="City"
            placeholder="-- Please select a country first --"
            labelClass="font-semibold"
            className="bg-transparent border-gray-border border-2 rounded-lg"
            value={city}
            onChange={cityHandler}
          />
        </div>
        {country === "Canada" && (
          <div className="lg:flex lg:gap-8">
            <AppSelect
              asRequired
              id="province"
              items={provinces}
              itemText="name"
              itemValue="id"
              label="Province"
              placeholder="-- Please select one --"
              labelClass="font-semibold"
              className="border-2 border-gray-border rounded-lg"
              value={province}
              onChange={provinceHandler}
            />
            <AppSelect
              asRequired
              id="region"
              items={
                province && province.length > 0
                  ? regions.find((ele) => ele.province === province).list.sort()
                  : []
              }
              itemText="name"
              itemValue="id"
              label="Region"
              placeholder="-- Please select a province first --"
              labelClass="font-semibold"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={region}
              onChange={regionHandler}
            />
          </div>
        )}
        <AppInput
          asRequired
          id="streetAddress"
          label="Street address"
          labelClass="font-bold font-quicksand"
          className="bg-transparent"
          value={streetAddress}
          onChange={streetAddressHandler}
        />
        <AppTextArea
          asRequired
          id="landAcknowledgment"
          label="Land acknowledgment"
          labelClass="font-bold font-quicksand"
          className="bg-transparent border-gray-border border-2 rounded-lg"
          rows="4"
          value={landAcknowledgement}
          onChange={landAcknowledgementHandler}
        />

        <div className="grid gap-5">
          <h6 className="mt-7 uppercase font-semibold text-2xl">
            Availability
          </h6>
          <div className="lg:flex lg:gap-8">
            <AppPhoneInput
              asRequired
              id="phone"
              label="Phone"
              labelClass="font-bold font-quicksand"
              className="-mt-1"
              value={phone}
              onChange={(e) => phoneHandler(e)}
            />
            <AppInput
              asRequired
              id="website"
              label="Website"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={website}
              onChange={websiteHandler}
            />
          </div>
          <div className="grid gap-5 lg:gap-8">
            <div className="grid lg:grid-cols-12 gap-5 lg:gap-8">
              <div className="col-span-6">
                <p className="font-bold">Time</p>
                <div className="flex items-center gap-2">
                  <AppTimeInput
                    asRequired
                    label="Opening"
                    id="startTime"
                    className="bg-transparent text-center"
                    value={timeFrom}
                    onChange={timeFromHandler}
                  />
                  -
                  <AppTimeInput
                    asRequired
                    label="Closing"
                    id="endTime"
                    className="bg-transparent text-center"
                    value={timeTo}
                    onChange={timeToHandler}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-1 relative w-full">
                <span
                  className={`flex relative with-transition text-500 font-bold required-help`}
                >
                  Days
                  <span className={`text-green pl-1 flex`}>*</span>
                </span>
                <div className="flex gap-2">
                  {daysOfWeek?.map((item, index) => (
                    <button
                      type="button"
                      key={index}
                      className={`w-9 col-span-6 aspect-square py-1.5 border-2 rounded-lg border-gray-border font-bold hover:border-denim ${
                        days[index] ? "bg-green text-white" : ""
                      } with-transition`}
                      onClick={() => daysHandler(index)}
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <h6 className="mt-7 uppercase font-semibold text-2xl">
            GPS Coordinates
          </h6>
          <div className="grid gap-5">
            <div className="grid gap-5 lg:grid-cols-2 lg:gap-8">
              <AppInput
                asRequired
                id="latitude"
                label="Latitude"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={latitude}
                onChange={latitudeHandler}
              />
              <AppInput
                asRequired
                id="longitude"
                label="Longitude"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={longitude}
                onChange={longitudeHandler}
              />
            </div>
            <div className="grid gap-5">
              <h6 className="mt-7 uppercase font-semibold text-2xl">about</h6>
              <AppTextArea
                asRequired
                id="bio"
                label="Bio"
                description="This data is essential as it will be utilized to present your organization on our interactive map screen"
                labelClass="font-bold"
                className="bg-transparent border-gray-border border-2 rounded-lg"
                rows="4"
                value={bio.join("\n")}
                onChange={bioHandler}
              />
              <AppTextArea
                asRequired
                id="orgAbout"
                label="About the organization"
                labelClass="font-bold"
                className="bg-transparent border-gray-border border-2 rounded-lg"
                rows="4"
                value={about.join("\n")}
                onChange={aboutHandler}
              />
              <AppTextArea
                asRequired
                label="Organization values"
                id="orgValues"
                labelClass="font-bold"
                className="bg-transparent border-gray-border border-2 rounded-lg"
                rows="4"
                value={values.join("\n")}
                onChange={valuesHandler}
              />
              <AppTextArea
                asRequired
                id="exploring"
                label="Expolring in-person"
                labelClass="font-bold"
                className="bg-transparent border-gray-border border-2 rounded-lg"
                rows="4"
                value={exploring.join("\n")}
                onChange={exploringHandler}
              />
            </div>
          </div>
        </div>
        <p className="py-8">
          By filling out this form, you agree to a partnership with Forager
          International through The VR Voyage. Please read through our{" "}
          <Link
            to="/legal/policies/publisher-policy"
            className="capitalize underline text-green hover:text-magenta font-bold with-transition"
          >
            Publisher Policy
          </Link>{" "}
          to fully understand our partnership program.
        </p>
        <button
          type="submit"
          className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta hover:bg-green rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3 mb-16 with-transition"
          onSubmit={submitHandler}
        >
          Join Us
        </button>
      </>
    </form>
  );
};

export default PartnerRegistrationForm;
