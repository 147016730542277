import React, { forwardRef } from "react";

const AppTextArea = (
  {
    id,
    label,
    placeholder,
    description,
    error,
    className,
    maxLength,
    labelClass,
    asRequired,
    onChange,
    ...rest
  },
  ref,
) => {
  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <div className="flex justify-between">
        <span
          className={`relative flex with-transition ${labelClass} ${
            asRequired && "required-help"
          } ${error && "text-red-500"}`}
        >
          {label}
          <span
            className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}
          >
            *
          </span>
        </span>
        <div className={`grid items-end ${maxLength ? "block" : "hidden"}`}>
          <p className="text-xs text-gray-border">{maxLength}</p>
        </div>
      </div>
      <textarea
        ref={ref}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`
          w-full py-1.5 px-2 focus:outline-none border-gray-border border-2 rounded-lg
          ${error && "border-red-500"}
          ${className}
        `}
        onChange={(event) => onChange?.(event)}
        {...rest}
      />
      {description && <span className="text-sm">{description}</span>}
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};

export default forwardRef(AppTextArea);
