import React from "react";
import AppWayToExploreCard from "../global/AppWayToExploreCard";
import { HOME_WAYS_TO_EXPLORE } from "../../constants/home";
import AppCarousel from "../global/AppCarousel";

const HomeExploreTheWorld = () => {
  const imgCarousel = [
    {
      img: require("../../assets/img/home/bridge-ilustration.png"),
    },
    {
      img: require("../../assets/img/home/castle-carousel.png"),
    },
    {
      img: require("../../assets/img/home/castle-ilustration.png"),
    },
    {
      img: require("../../assets/img/home/house-carousel.png"),
    },
    {
      img: require("../../assets/img/home/bridge-ilustration.png"),
    },
    {
      img: require("../../assets/img/home/castle-carousel.png"),
    },
    {
      img: require("../../assets/img/home/castle-ilustration.png"),
    },
    {
      img: require("../../assets/img/home/house-carousel.png"),
    },
  ];
  return (
    <>
      <section className="container mx-auto mt-24 lg:mt-20">
        <div className="max-w-7xl mx-auto flex flex-col xl:flex-row lg:justify-center gap-x-10 lg:pt-12">
          <div className="lg:pl-20 my-auto ml-auto">
            <h2 className="uppercase text-3xl uppercase font-semibold text-green">
              A New Way To <span className="block"> Explore The World </span>
            </h2>
            <p className="my-8 lg:mb-0">
              Now, with virtual content you can explore the world from the
              comfort of your own home. Use The VR Voyage to explore places you
              might never reach in-person or use it as a unique way to plan your
              next trip. Don't let random Google searches dictate your next
              destination, get advice and see what you can experience in-person
              by local arts, culture, and heritage organizations who know the
              community best. Explore your next destination through a new
              perspective.
            </p>
          </div>
          <div className="xl:pr-20">
            <div
              className="w-full h-[194px] lg:w-[566px] lg:h-[318px] mx-auto xl:mr-auto bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${require("../../assets/img/home/explore-the-world.png")})`,
              }}
            />
          </div>
        </div>
        <div className="mt-24 lg:mt-32">
          <div className="text-center">
            <h2 className="text-3xl uppercase font-semibold text-green">
              Ways To Explore The Community
            </h2>
            <p className="my-8">
              Get a taste for the three different ways you can explore arts,
              culture, and heritage through The VR Voyage.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:flex justify-center place-items-center gap-y-8 gap-x-6 items-stretch pb-2">
            {HOME_WAYS_TO_EXPLORE.map((card, index) => (
              <AppWayToExploreCard {...card} key={index} />
            ))}
          </div>
        </div>
      </section>
      <div className="relative  mx-auto mt-20 lg:mt-28">
        <div className="pb-72">
          <AppCarousel isInfinite speed="120s">
            {imgCarousel.map((e, i) => (
              <div key={i} className="grid gap-6">
                <div
                  className="w-72 h-[473px] border-radius bg-cover bg-no-repeat bg-center"
                  style={{ backgroundImage: `url(${e.img})` }}
                />
              </div>
            ))}
          </AppCarousel>
        </div>
        <div className="left-[12%] lg:left-[37.4%] absolute z-5">
          <img
            className="w-[298px] -mt-[28.7rem]"
            src={require("../../assets/img/home/vr-woman.png")}
            alt=""
          />
        </div>
      </div>
      <div className="overflow-hidden h-20 -mt-10 md:-mt-7">
        <svg
          className="w-full h-full"
          viewBox="0 0 500 140"
          preserveAspectRatio="none"
        >
          <path
            className="fill-denim f-"
            d="M-6.77,47.72 C176.07,-18.20 339.16,154.02 501.69,38.86 L500.00,149.60 L-1.69,152.06 Z"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default HomeExploreTheWorld;
