import React, { useState } from "react";

const SetRating = ({ rating, setRating }) => {
  const [starArray, setStarryArray] = useState(
    new Array(5)
      .fill(false)
      .map((ele, index) => (index <= rating - 1 ? true : false))
  );

  const starsHandler = (position) => {
    const updatedStars = starArray.map((ele, index) => index <= position ? true : false);

    setStarryArray(updatedStars);
    setRating(position + 1);
  };


  return  (
    <div className="set-rating">
      {starArray.map((ele, index) => (
        <button key={index} id={index + 1} onClick={() => starsHandler(index)}>
          {ele ? (
            <svg className="text-green" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="m12.001.63l2.903 8.35l8.839.181l-7.045 5.341l2.56 8.462L12 17.914l-7.256 5.05l2.56-8.462L.26 9.161l8.839-.18L12 .63Z" />
            </svg>
          ) : (
            <svg className="text-green" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="m12 .63l2.903 8.35l8.839.181l-7.045 5.341l2.56 8.462L12 17.914l-7.256 5.05l2.56-8.462L.259 9.161l8.839-.18L12 .63Zm0 6.092l-1.47 4.23l-4.478.091l3.569 2.706l-1.297 4.287L12 15.478l3.676 2.558l-1.296-4.287l3.568-2.706l-4.477-.09L12 6.721Z" />
            </svg>
          )}
        </button>
      ))}
    </div>
  );
};

export default SetRating;
