import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getTourDetails, updateTour } from "../actions/tourActions";
import {
  TOUR_DETAILS_FAIL,
  TOUR_DETAILS_RESET,
  TOUR_UPDATE_FAIL,
  TOUR_UPDATE_RESET,
} from "../constants/tourConstants";
import { uploadMedia } from "../actions/mediaActions";
import {
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../constants/mediaConstants";

import { tourTypes } from "../constants/tours";
import locations from "../data/locations.json";
import { subCategories } from "../constants/categories";

import DenimLayout from "../layouts/DenimLayout";
import HeaderPages from "../components/global/HeaderPages";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import AppInput from "../components/global/form/AppInput";
import AppTextArea from "../components/global/form/AppTextArea";
import AppSelect from "../components/global/form/AppSelect";
import AppChipsInput from "../components/global/form/AppChipsInput";
import AppInputFile from "../components/global/form/AppInputFile";
import {
  requiredFieldsErrorMessage,
  requiredTagsErrorMessage,
  requiredVisualsErrorMessage,
  validateRequiredFields,
} from "../constants/helpers";

const EditTourScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug } = useParams();

  useEffect(() => {
    dispatch({ type: TOUR_UPDATE_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn.role !== "admin") {
      dispatch({ type: TOUR_DETAILS_FAIL, payload: "Not authorized!" });

      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      if (!slug) {
        dispatch({ type: TOUR_DETAILS_FAIL, payload: "Something went wrong!" });

        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        dispatch(getTourDetails(slug));
      }
    }
  }, [loggedIn, dispatch, navigate, slug]);

  const tourDetails = useSelector((state) => state.tourDetails);
  const {
    loading: loadingTourDetails,
    error: errorTourDetails,
    tour,
  } = tourDetails;

  useEffect(() => {
    // resetting state due to errors
    if (errorTourDetails) {
      setTimeout(() => {
        dispatch({ type: TOUR_DETAILS_RESET });
      }, 5000);
    }
  }, [errorTourDetails, dispatch]);

  //
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [guide, setGuide] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [tags, setTags] = useState([]);
  const [cover, setCover] = useState("");
  const [price, setPrice] = useState(0.0);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (tour) {
      setTitle(tour?.title || "");
      setSubtitle(tour?.subtitle || "");
      setDescription(tour?.description || "");
      setType(tour?.type || "");
      setGuide(tour?.guide || "");
      setCountry(tour?.location?.country || "");
      setCity(tour?.location?.city || "");
      setTags(tour?.tags || []);
      setCover(tour?.cover || "");
      setPrice(Number(tour?.price) || 0.0);
      setCover(tour?.cover || "");
      setNote(tour?.note || "");
    }
  }, [tour]);

  const uploadHandler = (file) => {
    if (/jpg|jpeg|png/.test(file.type)) {
      const form = new FormData();
      form.append("image", file);
      dispatch(uploadMedia(form));
    } else {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: jpg and png)",
      });
      return;
    }
  };

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  useEffect(() => {
    if (uploaded) {
      setCover(uploaded);
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [uploaded, dispatch]);

  //
  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const subtitleHandler = (e) => {
    setSubtitle(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const guideHandler = (e) => {
    setGuide(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const tagsHandler = (e) => {
    setTags(e);
  };

  const priceHandler = (e) => {
    setPrice(Number(e.target.value));
  };

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // validating...
    try {
      if (!cover?.length) {
        throw new Error(requiredVisualsErrorMessage);
      }

      if (
        !validateRequiredFields([
          cover,
          title,
          description,
          type,
          country,
          city,
        ]) ||
        !price
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!tags?.length) {
        throw new Error(requiredTagsErrorMessage);
      }

      // dispatching...
      dispatch(
        updateTour(tour.slug, {
          cover,
          title,
          subtitle,
          description,
          type,
          guide,
          location: {
            country,
            city,
          },
          tags,
          price,
          note,
        }),
      );
    } catch (error) {
      dispatch({
        type: TOUR_UPDATE_FAIL,
        payload: error.message,
      });
    }
  };

  const tourUpdate = useSelector((state) => state.tourUpdate);
  const {
    loading: updating,
    error: errorTourUpdate,
    tour: updated,
  } = tourUpdate;

  useEffect(() => {
    // resetting state due to errors
    if (errorTourUpdate) {
      setTimeout(() => {
        dispatch({ type: TOUR_UPDATE_RESET });
      }, 5000);
    }
  }, [errorTourUpdate, dispatch]);

  useEffect(() => {
    // resetting state due to success
    if (updated) {
      const slugUpdated = updated.slug;
      setTimeout(() => {
        dispatch({ type: TOUR_UPDATE_RESET });
        navigate(`/tour/${slugUpdated}/demo`);
      }, 5000);
    }
  }, [updated, dispatch, navigate]);

  return (
    <>
      {uploading || loadingTourDetails || updating ? <Loader /> : null}
      {errorUpload ? <Message error body={errorUpload} /> : null}
      {errorTourUpdate ? <Message error body={errorTourUpdate} /> : null}
      {updated ? <Message info body="Tour updated successfully!" /> : null}
      {errorTourDetails ? (
        <Message error body={errorTourDetails} />
      ) : tour && loggedIn?.role === "admin" ? (
        <DenimLayout>
          <HeaderPages
            titlePage={
              <p className="text-green font-semibold text-3xl lg:text-[80px] text-left">
                Edit{" "}
                <span className="text-white block md:inline-block">
                  Experience
                </span>
              </p>
            }
            styleTitlePage="pb-[88px] xl:pb-[56px] pt-[112px] xl:pt-[150px]"
          />
          <section>
            <div className="container py-[40px] lg:py-[100px]">
              <form
                className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5"
                onSubmit={submitHandler}
              >
                <p>All fields marked with an * are required!</p>
                <div className="w-full h-fit flex flex-col">
                  <AppInputFile
                    asRequired
                    label="Cover"
                    placeholder={cover?.length ? cover : "Add photo library"}
                    labelClass="bg-gray-border rounded-t-[10px] px-4 py-3"
                    className="bg-transparent"
                    onChange={uploadHandler}
                  />
                  <div
                    className={`w-full ${
                      cover?.length ? "h-[300px]" : ""
                    } bg-cover bg-center rounded-b-[10px]`}
                    style={{ backgroundImage: `url(${cover})` }}
                  ></div>
                </div>
                <AppInput
                  asRequired
                  id="title"
                  label="Title"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={title}
                  onChange={titleHandler}
                />
                <AppInput
                  id="subtitle"
                  label="Subtitle (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={subtitle}
                  onChange={subtitleHandler}
                />
                <AppTextArea
                  asRequired
                  id="description"
                  label="Description"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={description}
                  onChange={descriptionHandler}
                />
                <div className="grid lg:grid-cols-2 gap-5 lg:gap-8">
                  <AppSelect
                    asRequired
                    id="type"
                    items={tourTypes}
                    itemText="name"
                    itemValue="id"
                    label="Type"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={type}
                    onChange={typeHandler}
                  />
                  <AppInput
                    id="guide"
                    label="Guide (optional)"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent"
                    value={guide}
                    onChange={guideHandler}
                  />
                  <AppSelect
                    asRequired
                    id="country"
                    items={locations.map((ele) => ele.country)}
                    itemText="name"
                    itemValue="id"
                    label="Country"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={country}
                    onChange={countryHandler}
                  />
                  <AppSelect
                    asRequired
                    id="city"
                    items={
                      locations.find((ele) => ele.country === country)?.city
                    }
                    itemText="name"
                    itemValue="id"
                    label="City"
                    placeholder="-- Please select a country first --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={city}
                    onChange={cityHandler}
                  />
                </div>
                <AppChipsInput
                  items={subCategories}
                  asRequired
                  id="tags"
                  label="Tags"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  value={tags}
                  onChange={(e) => tagsHandler(e)}
                />
                <AppInput
                  asRequired
                  id="price"
                  label="Price (CAD)"
                  type="number"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={price}
                  onChange={priceHandler}
                />
                <AppTextArea
                  id="note"
                  label="Note (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={note}
                  onChange={noteHandler}
                />
                <div className="order-2 lg:order-3 flex gap-2">
                  <button
                    type="submit"
                    className="uppercase font-bold text-white bg-green hover:bg-denim rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3 with-transition"
                    onSubmit={submitHandler}
                  >
                    Update Tour
                  </button>
                  <Link
                    to={`/tour/${tour.slug}/demo`}
                    className="uppercase font-bold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3 with-transition"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </section>
        </DenimLayout>
      ) : null}
    </>
  );
};

export default EditTourScreen;
