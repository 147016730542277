export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_RESET = "USER_CREATE_RESET";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";
export const USER_ACTIVATE_RESET = "USER_ACTIVATE_RESET";

export const USER_VALIDATE_REQUEST = "USER_VALIDATE_REQUEST";
export const USER_VALIDATE_SUCCESS = "USER_VALIDATE_SUCCESS";
export const USER_VALIDATE_FAIL = "USER_VALIDATE_FAIL";
export const USER_VALIDATE_RESET = "USER_VALIDATE_RESET";

export const USER_NOTIFY_REQUEST = "USER_NOTIFY_REQUEST";
export const USER_NOTIFY_SUCCESS = "USER_NOTIFY_SUCCESS";
export const USER_NOTIFY_FAIL = "USER_NOTIFY_FAIL";
export const USER_NOTIFY_RESET = "USER_NOTIFY_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_TOGGLE_REQUEST = "USER_TOGGLE_REQUEST";
export const USER_TOGGLE_SUCCESS = "USER_TOGGLE_SUCCESS";
export const USER_TOGGLE_FAIL = "USER_TOGGLE_FAIL";
export const USER_TOGGLE_RESET = "USER_TOGGLE_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

//

export const SUBSCRIBER_REGISTER_REQUEST = "SUBSCRIBER_REGISTER_REQUEST";
export const SUBSCRIBER_REGISTER_SUCCESS = "SUBSCRIBER_REGISTER_SUCCESS";
export const SUBSCRIBER_REGISTER_FAIL = "SUBSCRIBER_REGISTER_FAIL";
export const SUBSCRIBER_REGISTER_RESET = "SUBSCRIBER_REGISTER_RESET";