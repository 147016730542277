import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBreakpoints } from "../../hooks/useBreakpoints";

import { ReactComponent as LogoShort } from "../../assets/logos/logo-short.svg";
import Newsletter from "../../components/global/footer/Newsletter";
import SocialLinks from "../../components/global/footer/SocialLinks";
import Copyrights from "../../components/global/footer/Copyrights";

function AppFooter() {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo } = vrvUserLogin;

  const { lg } = useBreakpoints();

  return (
    <>
      <footer className="bg-denim pt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-11 container text-white">
          <div>
            <LogoShort />
            <p className="mt-10 pr-8">
              The VR Voyage is an innovative community-driven platform
              showcasing the stories and experiences that make each community
              unique. Explore, get inspired, and support local organizations
              along the way.
            </p>
            <SocialLinks />
          </div>
          <div className="lg:mx-auto">
            <h6 className="mb-7 uppercase text-xl font-semibold text-green">
              Menu
            </h6>
            <div className="flex flex-col gap-5">
              <NavLink to="/our-story">
                <p className="hover:text-green with-transition">Our Story</p>
              </NavLink>
              <NavLink to="/our-network">
                <p className="hover:text-green with-transition">Our Network</p>
              </NavLink>
              <NavLink to="/map">
                <p className="hover:text-green with-transition">Interactive Map</p>
              </NavLink>
              <NavLink to="/catalogue/online">
                <p className="hover:text-green with-transition">
                  Online Experiences
                </p>
              </NavLink>
              <NavLink to="/catalogue/in-person">
                <p className="hover:text-green with-transition">
                  In-Person Experiences
                </p>
              </NavLink>
              {userInfo?.role !== "admin" ? (
                <NavLink to="/login">
                  <p className="hover:text-green with-transition">
                    {userInfo ? "My Profile" : "Log in"}
                  </p>
                </NavLink>
              ) : (
                <></>
              )}
              <NavLink to="/contact">
                <p className="hover:text-green with-transition">Contact</p>
              </NavLink>
              <NavLink to={lg ? "/legal/overview" : "/legal"}>
                <p className="hover:text-green with-transition">Legal</p>
              </NavLink>
            </div>
          </div>
          <Newsletter />
          <Copyrights />
        </div>
      </footer>
    </>
  );
}

export default AppFooter;
