import React from "react";
import HeaderPages from "../global/HeaderPages";
import womanVr from "../../assets/img/our-story/woman-vr.png";
import manVr from "../../assets/img/our-story/vr.jpg";

const OurStoryContent = () => {
  return (
    <>
      <HeaderPages
        titlePage={
          <>
            <div className="flex items-center justify-center lg:justify-start gap-4">
              <img
                src={require("../../assets/img/our-story/the-vr.png")}
                alt=""
                className="mt-1 lg:mt-2 w-[184px] lg:w-[371px] lg:h-full"
              />
              <span className="font-title font-light"> story</span>
            </div>
          </>
        }
        styleTitlePage="pb-28"
        bg={require("../../assets/img/our-story/bg.jpg")}
        img={require("../../assets/img/our-story/our-story.png")}
        styleImg="w-72 xl:w-[500px] bottom-8 xl:-bottom-9"
      />

      <div className="container mt-24">
        <div className="grid grid-cols-2 gap-8 lg:gap-20 md:mt-32 lg:w-10/12 lg:mx-auto">
          <div className="col-span-2 md:col-span-1 grid gap-y-5">
            <h2 className="text-green text-3xl uppercase font-semibold text-center md:text-start mb-3">
              OUR STORY
            </h2>
            <p>
              As explorers, we always strive to experience new destinations. For
              reasons we all know too well, that wasn’t always possible over the
              past few years. With new technologies available and a passion for
              finding innovative ways to support local communities, we saw an
              opportunity to create something truly exciting.
            </p>
            <p>
              We wanted to find a way to continue exploring the world without
              risking breaking travel restrictions. After consultation with the
              BC Museums Association, we began our development journey to create
              The VR Voyage platform. With their assistance and the support of
              Futurpreneur Canada, our team began working with a first batch of
              arts, culture, and heritage organizations from British Columbia
              showcasing what we could create together.
            </p>
            <p>
              From that initial site, our team received international attention
              as a finalist in the Aceler@Tech 2021 tourism business startup
              competition from Acredita Portugal and sponsored by Turismo de
              Portugal. Since then, the platform has been developing more and
              more and has been part of the Canadian delegation to the G20 Young
              Entrepreneurs’ Alliance Summit in Milan, Italy in 2021 and
              Hamburg, Germany in 2022, and Delhi, India in 2023.
            </p>
          </div>
          <div
            className="col-span-2 md:col-span-1 bg-contain bg-no-repeat bg-center border-radius h-[412px] lg:h-full w-full"
            style={{ backgroundImage: `url(${womanVr})` }}
          />
        </div>
        <div className="grid grid-cols-2 gap-8 lg:gap-20 my-24 md:my-32 lg:w-10/12 lg:mx-auto">
          <div className="md:order-2 col-span-2 md:col-span-1 grid gap-y-5 ">
            <h2 className="text-green text-3xl text-center md:text-start font-semibold mb-3 uppercase">
              Our vision
            </h2>
            <p>
              We strive to inspire more people to explore beyond their own
              communities by virtually travelling to destinations they never
              dreamed possible.
            </p>
            <p>
              As the anthropologist Wade Davis once said, “The world in which
              you were born is just one model of reality. Other cultures are not
              failed attempts at being you; they are unique manifestations of
              the human spirit.” Our vision is to bring people together by
              showing them the beauty of the world, one experience at a time.
            </p>
            <p>
              Using virtual reality and creating partnerships across the globe,
              we are opening people’s minds to the wonders of the world in a
              more meaningful and interactive way. Whether it is just not
              possible or cost is a hindrance, The VR Voyage wants to create a
              way for anyone to experience what makes the world so wonderful.
            </p>
            <p>
              Explore nature with conservationists, Indigenous leaders, and
              outdoor adventurers; excavate ruins with archaeologists; wander
              through the halls of museums large and small with their curators;
              or, stroll the streets of a new city with a local guide.
            </p>
          </div>
          <div
            className="col-span-2 md:col-span-1 bg-cover bg-no-repeat bg-center border-radius h-[412px] lg:h-full w-full"
            style={{ backgroundImage: `url(${manVr})` }}
          />
        </div>
      </div>
    </>
  );
};

export default OurStoryContent;
