import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_RESET,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_RESET,
  USER_VALIDATE_REQUEST,
  USER_VALIDATE_SUCCESS,
  USER_VALIDATE_FAIL,
  USER_VALIDATE_RESET,
  USER_NOTIFY_REQUEST,
  USER_NOTIFY_SUCCESS,
  USER_NOTIFY_FAIL,
  USER_NOTIFY_RESET,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_TOGGLE_REQUEST,
  USER_TOGGLE_SUCCESS,
  USER_TOGGLE_FAIL,
  USER_TOGGLE_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  //
  SUBSCRIBER_REGISTER_REQUEST,
  SUBSCRIBER_REGISTER_SUCCESS,
  SUBSCRIBER_REGISTER_FAIL,
  SUBSCRIBER_REGISTER_RESET,
} from "../constants/userConstants";

//

// list
export const userListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };
    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// create
export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// register
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

// activate register
export const userActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return {
        loading: true,
      };
    case USER_ACTIVATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_ACTIVATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_ACTIVATE_RESET:
      return {};
    default:
      return state;
  }
};

// validate user
export const userValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VALIDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_VALIDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_VALIDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_VALIDATE_RESET:
      return {};
    default:
      return state;
  }
};

// notify user
export const userNotifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_NOTIFY_REQUEST:
      return {
        loading: true,
      };
    case USER_NOTIFY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_NOTIFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_NOTIFY_RESET:
      return {};
    default:
      return state;
  }
};

// login
export const userLoginReducer = (state = { userInfo: null }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        userInfo: null,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LOGIN_RESET:
      return {
        userInfo: null,
      };
    case USER_LOGOUT:
      return { userInfo: null };
    default:
      return state;
  }
};

// get user details
export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

// Update user details
export const userDetailsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};


// toggle user status
export const userToggleReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TOGGLE_REQUEST:
      return {
        loading: true,
      };
    case USER_TOGGLE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_TOGGLE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_TOGGLE_RESET:
      return {
        markers: null,
      };
    default:
      return state;
  }
};

// Delete user
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return {
        loading: true,
      };
    case USER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

//

// subscribe to newsletter
export const subscriberRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBER_REGISTER_REQUEST:
      return {
        loading: true,
      };
    case SUBSCRIBER_REGISTER_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SUBSCRIBER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SUBSCRIBER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};
