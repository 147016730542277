export const MARKER_LIST_REQUEST = "MARKER_LIST_REQUEST";
export const MARKER_LIST_SUCCESS = "MARKER_LIST_SUCCESS";
export const MARKER_LIST_FAIL = "MARKER_LIST_FAIL";
export const MARKER_LIST_RESET = "MARKER_LIST_RESET";

export const MARKER_CREATE_REQUEST = "MARKER_CREATE_REQUEST";
export const MARKER_CREATE_SUCCESS = "MARKER_CREATE_SUCCESS";
export const MARKER_CREATE_FAIL = "MARKER_CREATE_FAIL";
export const MARKER_CREATE_RESET = "MARKER_CREATE_RESET";

export const MARKER_TOGGLE_REQUEST = "MARKER_TOGGLE_REQUEST";
export const MARKER_TOGGLE_SUCCESS = "MARKER_TOGGLE_SUCCESS";
export const MARKER_TOGGLE_FAIL = "MARKER_TOGGLE_FAIL";
export const MARKER_TOGGLE_RESET = "MARKER_TOGGLE_RESET";

export const MARKER_DELETE_REQUEST = "MARKER_DELETE_REQUEST";
export const MARKER_DELETE_SUCCESS = "MARKER_DELETE_SUCCESS";
export const MARKER_DELETE_FAIL = "MARKER_DELETE_FAIL";
export const MARKER_DELETE_RESET = "MARKER_DELETE_RESET";
