import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { newPartners } from "../constants/partners";

import HeaderPages from "../components/global/HeaderPages";
import AppCarousel from "../components/global/AppCarousel";
import Search from "../components/catalogue/Search";
import { listUsers } from "../actions/userActions";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import { categories } from "../constants/categories";
import ListingCarousel from "../components/home/ListingCarousel";
import DenimLayout from "../layouts/DenimLayout";

const OurNetwork = () => {
  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partners) {
      dispatch(listUsers("partner"));
    }
  }, [partners, dispatch]);

  return (
    <DenimLayout>
      {loadingPartners && <Loader />}
      {errorPartners && <Message error body={errorPartners} />}
      <>
        <HeaderPages
          titlePage={
            <>
              <p className="font-light">
                Our Community{" "}
                <span className="text-green font-semibold block xl:inline">
                  Partners
                </span>
              </p>
            </>
          }
          styleTitlePage="pb-16"
          bg={require("../assets/img/our-network/bg.jpg")}
          img={require("../assets/img/our-network/Partner.png")}
          styleImg="w-[345px] xl:w-[390px]"
        >
          {!errorPartners && partners?.length && (
            <Search
              collection={partners}
              type="partner"
              className="z-10 lg:z-0"
            />
          )}
        </HeaderPages>
        <div className="container">
          <div className="grid grid-cols-2 gap-20 md:mt-32 lg:w-10/12 lg:mx-auto">
            <div className="col-span-2 lg:col-span-1">
              <h1 className="text-green font-semibold uppercase text-3xl text-center lg:text-start mt-24 lg:mt-0">
                Strength In Collaboration
              </h1>
              <div className="flex flex-col gap-y-5">
                <p className="mt-8 font-semibold">
                  Explore The Organizations That
                  <span className="block lg:inline">
                    {" "}
                    Contribute To Our Platform
                  </span>
                </p>
                <p>
                  The VR Voyage is a collaborative platform and we partner with
                  museums, art galleries, Indigenous cultural centres, science
                  centres, environmental organizations, and more to bring a
                  wealth of engaging and expertly curated content to you.
                </p>
                <p>
                  Here you can find a list of the organizations that are adding
                  their unique stories to this platform. Through these
                  partnerships, we are building a rich database of heritage
                  content for everyone interested in looking a little deeper.
                </p>
              </div>
              <div className="mt-8 mx-auto md:mx-0 w-44 h-fit rounded-full">
                <Link
                  to="/dimensioning-future"
                  className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 mx-auto flex justify-center border-2 border-transparent hover:border-denim with-transition"
                >
                  <span className="block">Join our network</span>
                </Link>
              </div>
            </div>
            <div
              className="hidden lg:block bg-cover bg-no-repeat bg-center border-radius h-full w-full"
              style={{
                backgroundImage: `url(${require("../assets/img/our-network/collaboration.jpg")})`,
              }}
            />
          </div>
        </div>
        <section className="my-24 lg:my-32"></section>
        <div className="mb-20">
          <h1 className="text-center text-3xl font-semibold uppercase text-green mt-24 mb-6 lg:mt-32 lg:mb-6">
            New Partners
          </h1>
          <div>
            <AppCarousel isInfinite speed="120s">
              {[...newPartners, ...newPartners].map((e, i) => (
                <div
                  key={i}
                  className="bg-white w-60 h-52 rounded-xl grid items-center"
                >
                  <img src={e.logo} alt={e.title} className="w-28 mx-auto" />
                </div>
              ))}
            </AppCarousel>
          </div>
        </div>
        {loadingPartners ? (
          <Loader />
        ) : !errorPartners && partners?.length ? (
          <div className="flex flex-col bg-denim py-20">
            <h2 className="container text-green uppercase text-3xl lg:text-[60px] font-semibold text-start lg:text-center lg:mb-[100px]">
              Explore our partners
            </h2>
            {categories
              .map((ele) => ele.name)
              .map((ele, index) => (
                <div key={index}>
                  {partners.filter((partner) => partner.category === ele)
                    ?.length && (
                    <ListingCarousel
                      heading={ele}
                      collection={partners.filter(
                        (partner) => partner.category === ele,
                      )}
                      type="partner"
                      styleOptions="mb-20"
                    />
                  )}
                </div>
              ))}
          </div>
        ) : (
          <div className="mt-8">
            <div className="container flex justify-center gap-8 text-denim px-4 pb-6">
              <p className="my-14">No partners available</p>
            </div>
          </div>
        )}
        {/*  */}

        {/* <div className="container">
        <h2 className="mt-24 lg:mt-32 mb-8 text-center text-3xl uppercase font-semibold uppercase text-green">
          All Our Partners
        </h2>
        <div className="grid grid-cols-2 lg:grid-cols-6 gap-5 pb-24 lg:pb-32">
          {ourPartners.map((e, i) => (
            <div
              key={i}
              className="bg-white h-32 w-44 rounded-xl grid items-center"
            >
              <img src={e.logo} alt={e.title} className="w-32 m-auto" />
            </div>
          ))}
        </div>
      </div> */}
      </>
    </DenimLayout>
  );
};

export default OurNetwork;
