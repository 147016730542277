import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const AppPhoneInput = ({
  id,
  label,
  labelClass,
  asRequired,
  error,
  value = null,
  onChange = () => {},
}) => {
  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <span
        className={`relative flex with-transition ${labelClass} ${
          asRequired && "required-help"
        } ${error && "text-red-500"}`}
      >
        {label}  
        <span className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}>
          *
        </span>
      </span>
      <PhoneInput
        className="rounded-lg"
        country={"ca"}
        countryCodeEditable={false}
        value={value}
        onChange={(phone) => onChange?.(phone)}
      />
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};
