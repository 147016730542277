import { PARTNERS } from "./partners";

export const TOURS = [
  {
    img: require("../assets/img/partners/roedde-mouse-museum/roedde-house-museum.png"),
    link: "/",
    name: "Garden Tour",
    partnerLogo: PARTNERS.ROEDDE_HOUSE_MUSEUM.avatar,
    partnerName: PARTNERS.ROEDDE_HOUSE_MUSEUM.title,
  },
  {
    img: require("../assets/img/partners/roedde-mouse-museum/exploring.png"),
    link: "/",
    name: "Interior Tour",
    partnerLogo: PARTNERS.ROEDDE_HOUSE_MUSEUM.avatar,
    partnerName: PARTNERS.ROEDDE_HOUSE_MUSEUM.title,
  },
  {
    img: require("../assets/img/partners/roedde-mouse-museum/house-black-and-white.png"),
    link: "/",
    name: "Historical Tour",
    partnerLogo: PARTNERS.ROEDDE_HOUSE_MUSEUM.avatar,
    partnerName: PARTNERS.ROEDDE_HOUSE_MUSEUM.title,
  },
];

export const findBy = (searchKeys = {}) => {
  return TOURS.filter((tour) => {
    let apply = true;
    for (const key in searchKeys) {
      if (tour[key] !== searchKeys[key]) {
        apply = false;
      }
    }
    return apply;
  });
};

export const tourTypes = [
  "Accessibility",
  "Scavenger Hunt",
  "Walking",
  "Interior",
  "Digital",
  "Other",
];

export const guideTypes = [
  "Kids At Heart",
  "Institutional",
  "Walking",
];
