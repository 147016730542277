import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import AppInput from "../components/global/form/AppInput";

import { notifyUser } from "../actions/userActions";

import {
  USER_NOTIFY_FAIL,
  USER_NOTIFY_RESET,
} from "../constants/userConstants";

import { validateEmail } from "../constants/helpers";

const ForgotPasswordScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo } = vrvUserLogin;

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.role === "admin") {
      navigate("/");
    }
  }, [userInfo, navigate]);

  const userNotify = useSelector((state) => state.userNotify);
  const {
    loading: loadingNotify,
    error: errorNotify,
    success: notified,
  } = userNotify;

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const emailHandler = (e) => {
    setEmail(e.target.value);
    e.preventDefault();
  };

  const sendPasswordResetLink = (e) => {
    e.preventDefault();

    if (email.length > 0 && validateEmail(email)) {
      dispatch(notifyUser("user", email));
    } else {
      dispatch({
        type: USER_NOTIFY_FAIL,
        payload: "Invalid email address!",
      });
    }
  };

  useEffect(() => {
    if (notified) {
      setTimeout(() => {
        dispatch({ type: USER_NOTIFY_RESET });
        navigate("/");
      }, 7000);
    }
  }, [notified, dispatch, navigate]);

  useEffect(() => {
    if (errorNotify) {
      setTimeout(() => {
        dispatch({ type: USER_NOTIFY_RESET });
      }, 3000);
    }
  }, [errorNotify, dispatch]);

  return (
    <>
      {errorNotify && <Message error body={errorNotify} />}
      {notified && (
        <Message
          info
          body={`A password reset link has been sent to ${email}! Please follow the instructions from there to complete the operation.`}
          delay={7}
        />
      )}
      {loadingNotify ? (
        <Loader />
      ) : (
        <MainLayout
          scrollNavClass="bg-gray"
          logo={Logo}
          textHover="text-green"
          textHoverWithScroll="text-green"
          styleBtnWithScroll="hover:text-denim hover:border-denim"
        >
          <NavLink to="/" className="lg:hidden block ml-4 mt-6">
            <img src={Logo} alt="Logo" className="w-48" />
          </NavLink>
          <div>
            <>
              <div className="container lg:items-center mt-12 lg:mt-32 lg:grid grid-cols-7 gap-24 pb-24">
                <div className="lg:col-span-3 flex flex-col gap-4">
                  <h1 className="mb-5 text-3xl font-semibold uppercase">
                    Forgot your password?
                  </h1>
                  <p className="mb-5">
                    Enter your verified email address and we will send you a
                    password reset link.
                  </p>
                  <form className="grid gap-5" onSubmit={sendPasswordResetLink}>
                    <AppInput
                      asRequired
                      id="Email"
                      type="email"
                      label="Email"
                      value={email}
                      onChange={emailHandler}
                      labelClass="font-semibold"
                      className="bg-transparent border-gray-border border-2 rounded-lg"
                    />
                    <div className="w-full rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto lg:mx-0 with-transition">
                      <button
                        type="submit"
                        className="font-semibold text-white hover:text-denim uppercase animate-bg app-primary-button h-14 w-full flex justify-center lg:ml-0 with-transition"
                      >
                        <span className="block">send</span>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="hidden lg:block col-span-4 h-[540px]">
                  <div
                    className="bg-cover bg-center bg-no-repeat h-full rounded-tl-2xl rounded-br-2xl"
                    style={{
                      backgroundImage: `url(${require("../assets/img/login/forgot-password.png")})`,
                    }}
                  />
                </div>
              </div>
            </>
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default ForgotPasswordScreen;
