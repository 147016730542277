import React from "react";
import AppInput from "../../global/form/AppInput";

const SimpleSearch = ({
  type,
  className,
  inputClass,
  prepend,
  onChange,
}) => {

  const keywordHandler = (e) => {
    onChange?.(e.target.value);
  };

  return (
    <div
      className={`w-full md:w-[565px] flex items-center flex-wrap mx-auto lg:mx-0 rounded-[10px] ${className}`}
    >
      <div className={`w-full ${inputClass ? inputClass : ""}`}>
        <div className="w-full flex items-center bg-white px-2 rounded-lg">
          {prepend}
          <AppInput
            placeholder={`Search ${type}s`}
            className="pb-3 placeholder:denim border-transparent outline-none"
            onChange={keywordHandler}
          />
          <i className="iconify font-bold" data-icon="ion:search" />
        </div>
      </div>
    </div>
  );
};

export default SimpleSearch;
