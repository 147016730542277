import React, { useEffect, useState } from "react";
import { TabContent, TabLabel, TabWrapper } from "../components/global/AppTabs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/logos/logo-denim-green.svg";
import { useBreakpoints } from "../hooks/useBreakpoints";
import MainLayout from "../layouts/MainLayout";
import LegalOverview from "../components/legal/LegalOverview";
import LegalPrivacyPolicy from "../components/legal/LegalPrivacyPolicy";
import LegalPublisherPolicy from "../components/legal/LegalPublisherPolicy";
import LegalRevenueSharePolicy from "../components/legal/LegalRevenueSharePolicy";
import LegalPromotionsPolicy from "../components/legal/LegalPromotionsPolicy";
import LegalForeingLanguagePolicy from "../components/legal/LegalForeingLanguagePolicy";
import LegalRefundCancellationPolicy from "../components/legal/LegalRefundCancellationPolicy";
// import LegalTermsConditions from "../components/Legal/LegalTermsConditions";
// import LegalTermsOfUse from "../components/Legal/LegalTermsOfUse";

const LegalScreen = () => {
  const LegalCategories = [
    {
      name: "",
      items: [
        {
          link: "/Legal/overview",
          text: "Overview",
          icon: "iconamoon:eye-fill",
          content: <LegalOverview />,
        },
      ],
    },
    {
      name: "Policies",
      items: [
        {
          link: "/Legal/policies/privacy-policy",
          text: "Privacy Policy",
          icon: "mdi:shield-search",
          content: <LegalPrivacyPolicy />,
        },
        {
          link: "/Legal/policies/revenue-share-policy",
          text: "Revenue Share Policy",
          icon: "solar:chat-round-money-bold",
          content: <LegalRevenueSharePolicy />,
        },
        {
          link: "/Legal/policies/publisher-policy",
          text: "Publisher Policy",
          icon: "mingcute:arrow-to-up-fill",
          content: <LegalPublisherPolicy />,
        },
        {
          link: "/Legal/policies/promotions-policy",
          text: "Promotions Policy",
          icon: "tabler:percentage",
          content: <LegalPromotionsPolicy />,
        },
        {
          link: "/Legal/policies/foreign-language-policy",
          text: "Foreign Language Policy",
          icon: "clarity:language-solid",
          content: <LegalForeingLanguagePolicy />,
        },
        {
          link: "/Legal/policies/refund-&-cancellation-policy",
          text: "Refund & Cancellation Policy",
          icon: "humbleicons:arrow-go-back",
          content: <LegalRefundCancellationPolicy />,
        },
      ],
    },
    // {
    //   name: "Terms",
    //   items: [
    //     {
    //       link: "/Legal/terms/terms-of-use",
    //       text: "Terms of Use",
    //       icon: "mdi:partnership",
    //       content: <LegalTermsOfUse />,
    //     },
    //     {
    //       link: "/Legal/terms/terms-&-conditions",
    //       text: "Terms & Conditions",
    //       icon: "icon-park-solid:doc-search",
    //       content: <LegalTermsConditions />,
    //     },
    //   ],
    // },
  ];

  const navigate = useNavigate();
  const { category, policy } = useParams();
  const { lg } = useBreakpoints();
  const [activeTabLink, setActiveTab] = useState(lg ? "overview" : null);

  useEffect(() => {
    let link = "/Legal";
    if (category) {
      link += `/${category}`;
    }
    if (policy) {
      link += `/${policy}`;
    }
    if (category || policy) {
      setActiveTab(link);
    } else {
      setActiveTab(null);
    }
  }, [category, policy]);

  return (
    <MainLayout
      scrollNavClass="bg-gray"
      logo={Logo}
      textHover="text-green"
      textHoverWithScroll="text-green"
      styleBtn="hover:text-denim"
      styleBtnWithScroll="hover:text-denim hover:border-denim"
      styleBurger="hidden"
    >
      <div className="flex lg:hidden justify-between mt-5 ml-4">
        {activeTabLink && (
          <button onClick={() => navigate("/Legal")}>
            <i
              className="iconify text-xl"
              data-icon="material-symbols:arrow-back-ios-rounded"
            />
          </button>
        )}
        <NavLink to="/" className="pr-4">
          <img src={Logo} alt="Logo" className="w-48" />
        </NavLink>
      </div>
      <div className="container pb-24 xl:pb-28">
        <TabWrapper
          value={activeTabLink}
          tabClass="flex justify-between w-full lg:px-2 lg:py-[10px]"
          activeTabClass="bg-white rounded-md"
          onChange={(tab) => setActiveTab(tab)}
        >
          <div className="grid lg:grid-cols-12 gap-6 pt-10 lg:pt-24">
            <div
              className={`${
                activeTabLink ? "hidden" : "grid"
              } lg:flex flex-col col-span-12 lg:col-span-4 xl:col-span-3 gap-5 lg:gap-0`}
            >
              <div className="lg:sticky lg:top-20">
                {LegalCategories.map((category, i) => (
                  <div key={i} className="w-full">
                    {category.name && (
                      <p className="text-lg font-bold my-4">{category.name}</p>
                    )}
                    {category.items.map((policy) => (
                      <TabLabel
                        key={policy.text}
                        name={policy.link}
                        onSelected={() => {
                          navigate(policy.link);
                        }}
                      >
                        <div className="items-center flex p-2 py-3 lg:p-0 w-full justify-between">
                          <div className="flex gap-2 xl:gap-3 items-center">
                            <i
                              className={`iconify text-right`}
                              data-icon={policy.icon}
                            />
                            <span className="text-left">{policy.text}</span>
                          </div>
                          <i
                            className="iconify text-lg lg:hidden"
                            data-icon="material-symbols:arrow-back-ios-rounded"
                            data-rotate="180deg"
                          />
                        </div>
                      </TabLabel>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`${
                activeTabLink ? "block" : "hidden lg:block"
              } col-span-12 lg:col-span-8 xl:col-span-9 pl-2`}
            >
              {LegalCategories.map((category) =>
                category.items.map((policy) => (
                  <TabContent
                    key={policy.text}
                    name={policy.link}
                    className="h-full"
                  >
                    {policy.content}
                  </TabContent>
                ))
              )}
            </div>
          </div>
        </TabWrapper>
      </div>
    </MainLayout>
  );
};

export default LegalScreen;
