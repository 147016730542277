import React, { useState } from "react";

const AppAccordion = ({ header, headerClass, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <div
        className={`flex items-center justify-between cursor-pointer ${headerClass}`}
        tabIndex="0"
        onClick={toggle}
      >
        {header}
        <div className={`${isOpen ? "-rotate-90" : "rotate-90"}`}>
          <i
            className="iconify text-xl rotate-90"
            data-icon="ep:arrow-up-bold"
          />
        </div>
      </div>
      <div
        className={`with-transition app-accordion-wrapper ${
          isOpen && "is-open"
        }`}
      >
        <div className="app-accordion__inner">{children}</div>
      </div>
    </div>
  );
};

export default AppAccordion;
