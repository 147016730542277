import React from "react";
import { Link } from "react-router-dom";

const LegalPromotionsPolicy = () => {
  return (
    <main>
      <section className="grid gap-10 lg:gap-12">
        <div className="grid gap-5">
          <h1 className="text-3xl uppercase font-semibold">
            PROMOTIONS POLICY
          </h1>
          <span className="text-xs font-bold">
            This Policy was last updated on 26 August 2023.
          </span>
        </div>
        <p>
          This Promotions Policy (“Promotions Policy”) includes information
          about means by which The VR Voyage Publishers (“Publisher”) can
          promote their own virtual tour products (“Experiences”), as well as
          how their revenue may be affected by The VR Voyage promotional
          activities.
        </p>

        <ol className="grid gap-10 lg:gap-12">
          <li>
            <p className="text-lg font-bold pb-5">1. PUBLISHER URLS</p>
            <p>
              As a Publisher, you are included in The VR Voyage’s{" "}
              <Link
                to="/legal/policies/revenue-share-policy"
                className="text-green underline font-bold"
              >
                Revenue Share
              </Link>{" "}
              scheme. You are able to increase your revenue share by promoting
              your own Experiences. By integrating your unique Publisher URL,
              you can promote your Experiences through social media, email
              lists, and to your members. Every time a Publisher URL registers
              on The VR Voyage’s system, any sales made by that customer within
              the same sessions are categorised as a Publisher Sale granting you
              access to 90% of the net amount of the sale (“Net Amount”), or 10%
              if it is another Publisher’s tour.
            </p>
          </li>

          <li>
            <p className="text-lg font-bold pb-5">2. PROMOTIONAL PROGRAMS</p>
            <ol className="grid gap-5 pl-5">
              <li>
                <p className="font-bold">
                  2.1. Publisher Promotions & Discounts
                </p>
                <p>
                  Publishers are able to offer their own discounts on their
                  Experiences. These discounts are at the sole discretion of
                  each Publisher. Revenue Share for The VR Voyage is calculated
                  at the full price of the ticket, and the remaining after
                  discount is distributed to you.
                </p>
                <p className="mt-5">
                  Note that if an Experience is purchased in a foreign currency,
                  the actual discount may be slightly higher due to rounding and
                  currency conversion.
                </p>
              </li>

              <li>
                <p className="font-bold">
                  2.2. The VR Voyage Promotions & Discounts
                </p>
                <p>
                  From time to time, The VR Voyage will implement marketing
                  campaigns that offer discounts on some or all Experiences.
                  This is at the full discretion of The VR Voyage. Revenue Share
                  for Publishers remains unchanged, regardless of discount
                  price. You will receive 10% of the sale price.
                </p>
              </li>
              <li>
                <p className="text-lg font-bold pb-5">2.3. Opting Out</p>
                <p>
                  You have the right to opt-out of participating in The VR
                  Voyage promotional campaigns at any time. Contact your Account
                  Manager to arrange this. Your Experiences will be removed from
                  the discounts list.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="text-lg font-bold">3. MODIFICATIONS</p>
            <p className="py-5 ">
              On occasion, we may update this Policy to further define our
              promotional program. The VR Voyage reserves the right to modify or
              make changes to this policy at any time. When changes to the
              Policy are made, you will be notified by the email that is
              associated with your account. Changes will take effect on the day
              the Policy is posted.
            </p>
            <p>
              Continued use of The VR Voyage’s marketplace after changes are
              posted shall mean each Publisher’s acceptance of the
              modifications. The revised Policy shall supersede all previous
              editions of the Policy.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold">4. HOW TO CONTACT US</p>
            <p className="pt-5">
              The best way to contact The VR Voyage is through your Account
              Manager. If you are a prospective publisher or have not been
              assigned a Account Manager, use{" "}
              <span className="text-green underline font-bold">
                partners@foragereducation.com
              </span>{" "}
              and an agent will be in touch. We are always looking for feedback
              on our services and would love to hear from you.
            </p>
          </li>
        </ol>
      </section>
    </main>
  );
};

export default LegalPromotionsPolicy;
