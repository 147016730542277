import React, { useEffect, useState } from "react";
import WhiteLayout from "../layouts/WhiteLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppInput from "../components/global/form/AppInput";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validateEmail,
  validatePassword,
} from "../constants/helpers";
import { login } from "../actions/userActions";
import { USER_LOGIN_FAIL, USER_LOGIN_RESET } from "../constants/userConstants";
import Message from "../components/global/Message";
import Loader from "../components/global/Loader";

const BusinessLoginScreen = () => {
  const { role } = useParams();
  const navigate = useNavigate();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { loading: loadingLogin, error: errorLogin, userInfo } = vrvUserLogin;

  useEffect(() => {
    if (userInfo && role) {
      navigate(`/${role}/profile/${userInfo.slug}`);
    }
  }, [userInfo, role, navigate]);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const resetFields = () => {
    setEmail("");
    setPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0) {
      if (validateEmail(email) && validatePassword(password)) {
        dispatch(login(role, email, password));
        resetFields();
      } else {
        if (!validateEmail(email)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: "Invalid email format!",
          });
        }
        if (!validatePassword(password)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: passwordValidateErrorMessage,
          });
        }
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorLogin) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }

    // eslint-disable-next-line
  }, [errorLogin, dispatch]);

  return (
    <>
      {errorLogin && <Message error body={errorLogin} />}
      {loadingLogin ? (
        <Loader />
      ) : (
        !userInfo && (
          <WhiteLayout>
            <div className="container">
              <div className="grid grid-cols-1 lg:grid-cols-3 mt-24 md:mt-28 mb-24">
                <div>
                  <h1 className="mb-5 text-3xl font-semibold uppercase">
                    Welcome {role}
                  </h1>
                  <p className="mt-5">
                    New to The VR Voyage?
                    <Link
                      to={`/join/${role}`}
                      className="text-green hover:text-magenta pl-3 inline-flex font-semibold with-transition"
                    >
                      Sign up
                      <i
                        className="iconify my-auto p-1"
                        data-icon="ep:arrow-up-bold"
                        data-rotate="90deg"
                      />
                    </Link>
                  </p>
                  <form className="grid gap-4 mt-10" onSubmit={submitHandler}>
                    <AppInput
                      id="email"
                      label="E-mail"
                      value={email}
                      onChange={emailHandler}
                      labelClass="text-denim/40"
                      className="bg-transparent"
                    />
                    <AppInput
                      id="password"
                      type="password"
                      label="Password"
                      value={password}
                      onChange={passwordHandler}
                      labelClass="text-denim/40"
                      className="bg-transparent"
                    />
                    <Link
                      to="/user/forgot-password"
                      className="font-semibold text-green pt-4 pb-8"
                    >
                      Forgot password?
                    </Link>
                    <div className="mx-auto md:mx-0 w-full h-fit border-2 border-transparent hover:border-denim  rounded-full">
                      <button
                        type="submit"
                        className="font-semibold text-white hover:text-denim uppercase app-primary-button h-14 animate-bg w-full flex justify-center gap-5"
                        onSubmit={submitHandler}
                      >
                        <span className="block">log in</span>
                      </button>
                    </div>
                  </form>
                </div>

                <div className="hidden lg:block col-span-2 pl-20">
                  <div
                    className="bg-cover bg-center bg-no-repeat w-full h-full rounded-tl-2xl rounded-br-2xl"
                    style={{
                      backgroundImage: `url(${require("../assets/img/partners/login.jpg")})`,
                    }}
                  />
                </div>
              </div>
            </div>
          </WhiteLayout>
        )
      )}{" "}
    </>
  );
};

export default BusinessLoginScreen;
