import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listTours } from "../actions/tourActions";
import { listGuides } from "../actions/guideActions";
import { TOUR_LIST_RESET } from "../constants/tourConstants";
import { GUIDE_LIST_RESET } from "../constants/guideConstants";

import { guideTypes, tourTypes } from "../constants/tours";
import HeaderPages from "../components/global/HeaderPages";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import Search from "../components/catalogue/Search";
import DenimLayout from "../layouts/DenimLayout";
import AppCarousel from "../components/global/AppCarousel";
import AppTourCard from "../components/global/AppTourCard";
import { listUsers } from "../actions/userActions";
import { categories } from "../constants/categories";
import { Link, useParams } from "react-router-dom";
import AppAccordion from "../components/global/AppAccordion";

const ExperienceCatalogueScreen = () => {
  const { type } = useParams();

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  const [categoriesDrawer, setCategoriesDrawer] = useState(false);

  const tourList = useSelector((state) => state.tourList);
  const { loading: loadingTours, error: errorTours, tours } = tourList;

  const guideList = useSelector((state) => state.guideList);
  const { loading: loadingGuides, error: errorGuides, guides } = guideList;

  useEffect(() => {
    dispatch({ type: TOUR_LIST_RESET });
    dispatch({ type: GUIDE_LIST_RESET });

    if (type === "online") {
      dispatch(listTours());
    } else if (type === "in-person") {
      dispatch(listGuides());
    }

    dispatch(listUsers("partner"));
  }, [type, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorTours) {
      setTimeout(() => {
        dispatch({ type: TOUR_LIST_RESET });
      }, 5000);
    }
  }, [errorTours, dispatch, tours]);

  useEffect(() => {
    // resetting state due to errors
    if (errorGuides) {
      setTimeout(() => {
        dispatch({ type: TOUR_LIST_RESET });
      }, 5000);
    }
  }, [errorGuides, dispatch, tours]);

  function filterTours(value) {
    // console.log(value);
  }

  return (
    <DenimLayout>
      {errorTours && <Message error body={errorTours} />}
      {errorGuides && <Message error body={errorGuides} />}
      <HeaderPages
        titlePage={
          <>
            <p className="text-green font-semibold text-3xl lg:text-[80px] text-left">
              {type}{" "}
              <span className="text-white block md:inline-block">
                {" "}
                Experiences
              </span>
            </p>
          </>
        }
        styleTitlePage="pb-[88px] xl:pb-[56px] pt-[112px] xl:pt-[150px]"
        img={require("../assets/img/experience-catalogue/man-in-parachute.png")}
        styleImg="w-[128px] xl:w-[313px] ml-24 lg:ml-0 bottom-3 xl:-bottom-52"
      />
      <section>
        <div className="container">
          <p className="my-12 xl:w-[70%]">
            Use this catalogue to search for the best experiences to explore
            arts, culture, and heritage from a growing number of communities.
            Our partners have professionals working on new interactive
            experiences to explore topics big and small. Their tireless work is
            what makes this platform the rich resource it has become. If you are
            looking for something specific, use the filters to sort your results
            and find the right experience for you. Whether it is to explore
            somewhere new or to get inspired for your next trip, there is
            something exciting waiting.
          </p>
          {!errorTours && tours?.length && (
            <Search
              type={type}
              className="mt-0 mb-20"
              collection={tours}
              collectionClass="-top-12 md:-top-0"
              prepend={
                <button
                  className="ml-1"
                  onClick={() => setCategoriesDrawer(true)}
                >
                  <i
                    className="iconify font-bold"
                    data-icon="mi:filter"
                    data-rotate="180deg"
                  />
                </button>
              }
              append={
                <div className="dropdown mx-auto mt-8 md:mt-0 md:pl-5 md:border-l-2 md:border-denim/40">
                  <label tabIndex="0" className="flex items-center w-max">
                    Filter:
                    <span className="font-bold px-3">Overall Rating</span>
                    <i
                      className="iconify text-lg rotate-0"
                      data-icon="ep:arrow-up-bold"
                      data-rotate="180deg"
                    />
                  </label>
                  <ul
                    tabIndex="0"
                    className="dropdown-content mt-3 md:mt-6 menu shadow rounded-[10px] bg-white"
                  >
                    <li>
                      <Link>Price (High to Low)</Link>
                    </li>
                    <li>
                      <Link>Price (High to High)</Link>
                    </li>
                    <li>
                      <Link>Recommended</Link>
                    </li>
                    <li>
                      <Link>Most Popular</Link>
                    </li>
                    <li>
                      <Link>Partner</Link>
                    </li>
                  </ul>
                </div>
              }
              inputClass="grid md:flex md:items-center rounded-[10px] md:bg-white md:gap-3 md:pr-5"
              onChange={(value) => filterTours(value)}
            />
          )}
        </div>
      </section>
      {loadingTours || loadingGuides || loadingPartners ? (
        <Loader />
      ) : (!errorTours && tours?.length) || (!errorGuides && guides?.length) ? (
        <>
          {categoriesDrawer && (
            <aside className="overflow-auto fixed top-0 left-0 h-screen w-[96%] md:w-96 py-5 px-5 bg-white z-50">
              <div className="flex mb-5">
                <div className="mr-2 flex items-center gap-3">
                  <i
                    className="iconify font-bold"
                    data-icon="mi:filter"
                    data-rotate="180deg"
                  />
                  <h3 className="font-bold text-lg">Categories</h3>
                </div>
                <button
                  className="ml-auto"
                  onClick={() => setCategoriesDrawer(false)}
                >
                  <i
                    className="iconify text-xl"
                    data-icon="heroicons-solid:x"
                  />
                </button>
              </div>

              <div className="grid px-4 pb-12">
                {categories.map((category) => (
                  <div className="mb-3 font-bold" key={category.name}>
                    <AppAccordion
                      header={<p>{category.name}</p>}
                      headerClass="mb-3"
                    >
                      <div className="space-y-1">
                        {category.types.map((type, i) =>
                          type.name ? (
                            <AppAccordion
                              key={i}
                              header={<p>{type.name}</p>}
                              headerClass="mb-3"
                            >
                              {type.items?.map((item) => (
                                <li key={item.name} className="list-none">
                                  <button className="flex gap-4 py-2">
                                    <i
                                      className={`iconify text-denim ${item.styleIcon}`}
                                      data-icon={item.icon}
                                    />
                                    <span>{item.name}</span>
                                  </button>
                                </li>
                              ))}
                            </AppAccordion>
                          ) : (
                            type.items.map((item) => (
                              <li key={item.name} className="list-none">
                                <button className="flex gap-4 py-2">
                                  <i
                                    className={`iconify text-denim ${item.styleIcon}`}
                                    data-icon={item.icon}
                                  />
                                  <span>{item.name}</span>
                                </button>
                              </li>
                            ))
                          ),
                        )}
                      </div>
                    </AppAccordion>
                  </div>
                ))}
              </div>
            </aside>
          )}
          {!errorPartners && partners?.length ? (
            <div className="flex flex-col pb-24">
              <h2 className="container text-green uppercase text-3xl font-semibold text-start lg:text-center lg:mb-3">
                Explore {type} Experiences
              </h2>
              {type === "online" ? (
                <div className="flex flex-col gap-8 mt-5">
                  {tourTypes
                    .filter((type) =>
                      tours?.map((tour) => tour.type)?.includes(type),
                    )
                    .map((type, indexType) => (
                      <div key={indexType}>
                        <h3 className="ml-4 lg:ml-24 mb-2 text-lg font-bold font-plus">
                          {type} Tours (
                          {tours?.filter((tour) => tour.type === type)?.length})
                        </h3>
                        <AppCarousel withArrows>
                          <div className="w-20 hidden lg:block" />
                          {tours
                            ?.filter((tour) => tour.type === type)
                            ?.map((tour, indexTour) => (
                              <AppTourCard
                                key={indexTour}
                                img={tour.cover}
                                partnerLogo={
                                  partners.find(
                                    (partner) => partner.slug === tour.partner,
                                  )?.avatar
                                }
                                name={tour.title}
                                link={`/tour/${tour.slug}/demo`}
                                className="h-60 lg:h-56 w-40 border-4 border-transparent hover:border-green"
                              />
                            ))}
                        </AppCarousel>
                      </div>
                    ))}
                </div>
              ) : type === "in-person" ? (
                <div className="flex flex-col gap-8 mt-5">
                  {guideTypes
                    .filter((type) =>
                      guides?.map((tour) => tour.type)?.includes(type),
                    )
                    .map((type, indexType) => (
                      <div key={indexType}>
                        <h3 className="ml-4 lg:ml-24 mb-2 text-lg font-bold font-plus">
                          {type} Tours (
                          {guides?.filter((tour) => tour.type === type)?.length}
                          )
                        </h3>
                        <AppCarousel withArrows>
                          <div className="w-20 hidden lg:block" />
                          {guides
                            ?.filter((tour) => tour.type === type)
                            ?.map((tour, indexTour) => (
                              <AppTourCard
                                key={indexTour}
                                img={tour.cover}
                                partnerLogo={
                                  partners.find(
                                    (partner) => partner.slug === tour.partner,
                                  )?.avatar
                                }
                                name={tour.title}
                                link={`/guide/${tour.slug}/demo`}
                                className="h-60 lg:h-56 w-40 border-4 border-transparent hover:border-green"
                              />
                            ))}
                        </AppCarousel>
                      </div>
                    ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <div className="mt-8">
          <div className="container flex justify-center gap-8 text-denim px-4 pb-6">
            <p className="my-14">No experiences available</p>
          </div>
        </div>
      )}
    </DenimLayout>
  );
};

export default ExperienceCatalogueScreen;
