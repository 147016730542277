import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { googleOAuthReducer } from "./reducers/googleReducers";

import { stripeKeyReducer } from "./reducers/stripeReducers";

import {
  userListReducer,
  userCreateReducer,
  userRegisterReducer,
  userActivateReducer,
  userValidateReducer,
  userNotifyReducer,
  userLoginReducer,
  userDetailsReducer,
  userDetailsUpdateReducer,
  userDeleteReducer,
  //
  subscriberRegisterReducer,
  userToggleReducer,
} from "./reducers/userReducers";

import {
  tourListReducer,
  tourCreateReducer,
  tourDetailsReducer,
  tourRequestValidateReducer,
  tourUpdateReducer,
  tourDeleteReducer,
} from "./reducers/tourReducers";

import {
  orderDeleteReducer,
  orderDetailsReducer,
  orderListReducer,
  orderPlaceReducer,
} from "./reducers/orderReducers";

import {
  markerCreateReducer,
  markerListReducer,
  markerToggleReducer,
  markerDeleteReducer,
} from "./reducers/mapReducers";

import {
  mediaDeleteReducer,
  mediaListReducer,
  mediaUploadReducer,
} from "./reducers/mediaReducers";

import {
  contactMailCreateReducer,
  contactMailDeleteReducer,
  contactMailListReducer,
  contactMailDetailsReducer,
} from "./reducers/contactMailReducers";

import {
  sessionDetailsReducer,
  accessListReducer,
  guideAccessRequestReducer,
  guideListReducer,
  sessionDisableReducer,
} from "./reducers/guideReducers";

const reducer = combineReducers({
  //
  googleOAuth: googleOAuthReducer,
  //
  stripeKey: stripeKeyReducer,
  //
  userList: userListReducer,
  userCreate: userCreateReducer,
  vrvUserRegister: userRegisterReducer,
  userActivate: userActivateReducer,
  userValidate: userValidateReducer,
  userNotify: userNotifyReducer,
  vrvUserLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userDetailsUpdate: userDetailsUpdateReducer,
  userToggle: userToggleReducer,
  userDelete: userDeleteReducer,
  //
  subscriberRegister: subscriberRegisterReducer,
  //
  mediaList: mediaListReducer,
  mediaUpload: mediaUploadReducer,
  mediaDelete: mediaDeleteReducer,
  //
  tourList: tourListReducer,
  tourCreate: tourCreateReducer,
  tourDetails: tourDetailsReducer,
  tourRequestValidate: tourRequestValidateReducer,
  tourUpdate: tourUpdateReducer,
  tourDelete: tourDeleteReducer,
  //
  orderPlace: orderPlaceReducer,
  orderDetails: orderDetailsReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  //
  guideList: guideListReducer,
  guideAccessRequest: guideAccessRequestReducer,
  accessList: accessListReducer,
  sessionDetails: sessionDetailsReducer,
  sessionDisable: sessionDisableReducer,
  //
  markerList: markerListReducer,
  markerCreate: markerCreateReducer,
  markerToggle: markerToggleReducer,
  markerDelete: markerDeleteReducer,
  //
  contactMailCreate: contactMailCreateReducer,
  contactMailDelete: contactMailDeleteReducer,
  contactMailList: contactMailListReducer,
  contactMailDetails: contactMailDetailsReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  vrvUserLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
