import React from "react";

export const AppTimeInput = ({
  id,
  className,
  label,
  labelClass,
  asRequired,
  error,
  min = null,
  max = null,
  value,
  onChange = () => ({}),
  ...rest
}) => {
  return (
    <label htmlFor={id} className="grid grid-cols-1 gap-1 relative w-full">
      <span
        className={`relative flex with-transition ${labelClass} ${
          asRequired && "required-help"
        } ${error && "text-red-500"}`}
      >
        {label}
        <span className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}>
          *
        </span>
      </span>
      <input
        type="time"
        id={id}
        value={value}
        min={min}
        max={max}
        className={`
          w-full py-1.5 px-2 focus:outline-none caret-current border-2 border-gray-border rounded-lg
          ${error && "border-red-500"}
          ${className}
        `}
        onChange={(val) => onChange?.(val)}
        {...rest}
      />
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};
