import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { USER_CREATE_RESET } from "../constants/userConstants";

import { roles } from "../data/data";

import HeaderPages from "../components/global/HeaderPages";
import BecomePartner from "../components/our-network/BecomePartner";
import DenimLayout from "../layouts/DenimLayout";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";

const BusinessRegistrationScreen = () => {
  const { role } = useParams();

  const navigate = useNavigate();
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: userInfoLogin } = vrvUserLogin;

  useEffect(() => {
    if (userInfoLogin) {
      navigate("/");
    }
  }, [userInfoLogin, navigate]);

  const dispatch = useDispatch();

  const userCreate = useSelector((state) => state.userCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    message: created,
  } = userCreate;

  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET });
      }, 5000);
    }
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET });
        navigate("/why-vrv")
      }, 5000);
    }
  }, [created, dispatch, navigate]);

  return (
    <DenimLayout>
      {loadingCreate && <Loader />}
      {errorCreate && <Message error body={errorCreate} />}
      {created && <Message info body={created} />}
      <HeaderPages
        titlePage={
          <>
            <p className="text-green font-semibold text-[38px] lg:text-[80px]">
              {roles.find((ele) => ele.role === role)?.cta}{" "}
              <span className="text-white">with us!</span>
            </p>
          </>
        }
        styleTitlePage="pb-28"
        bg={require("../assets/img/our-story/bg.jpg")}
        img={require("../assets/img/come-host-with-us/header.png")}
        styleImg="w-64 xl:w-[371px] xl:-mb-14"
      ></HeaderPages>
      <div className="container">
        <div className="my-12 lg:-mt-[85px] flex-wrap mx-auto lg:mx-0 flex items-center justify-center md:justify-start gap-4">
          <p className="">Already have an acount?</p>
          <Link
            to={`/login/${role}`}
            className="grid place-items-center text-denim uppercase bg-yellow hover:bg-magenta hover:text-white px-8 py-3 rounded-full with-transition font-oswald font-semibold z-20"
          >
            Log in
          </Link>
        </div>
        <div className="lg:w-10/12 lg:mx-auto">
          <div className="lg:mt-[166px]">
            <BecomePartner role={roles.find((ele) => ele.role === role)} />
            <p className="lg:mt-8">
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our{" "}
              <Link
                to="/legal/policies/privacy-policy"
                className="capitalize underline text-green hover:text-magenta font-bold with-transition"
              >
                Privacy Policy
              </Link>
              .
            </p>
            <p className="mt-5">
              Fields marked with an <span className="text-green">*</span> are
              required
            </p>
            {roles.find((ele) => ele.role === role)?.component}
          </div>
        </div>
      </div>
    </DenimLayout>
  );
};

export default BusinessRegistrationScreen;
