import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  ORDER_PLACE_REQUEST,
  ORDER_PLACE_SUCCESS,
  ORDER_PLACE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
} from "../constants/orderConstants";

//

// place order
export const placeOrder =
  (tourSlug, partnerSlug, paymentId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PLACE_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/orders/place`,
        { tourSlug, partnerSlug, paymentId },
        config,
      );

      dispatch({
        type: ORDER_PLACE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_PLACE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

//

// create new order
export const createNewOrder =
  (tour, partner, user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PLACE_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/orders`,
        { tour, partner, user },
        config,
      );

      dispatch({
        type: ORDER_PLACE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_PLACE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// get order details
export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (userInfo.role === "user") {
      const { data } = await axios.get(`${MAIN_ROUTE}/orders/${id}`, config);

      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    }

    if (userInfo.role === "admin") {
      const { data } = await axios.get(
        `${MAIN_ROUTE}/orders/${id}/admin`,
        config,
      );

      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// delete order
export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`${MAIN_ROUTE}/orders/${id}`, config);

    dispatch({
      type: ORDER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// list all orders
export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${MAIN_ROUTE}/orders`, config);

    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// list orders related to a specific user
export const listOrdersByUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${MAIN_ROUTE}/orders/myorders/${id}`,
      config,
    );

    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
