import {
  MEDIA_UPLOAD_REQUEST,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_FAIL,
  MEDIA_LIST_REQUEST,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAIL,
  MEDIA_LIST_RESET,
  MEDIA_UPLOAD_RESET,
  MEDIA_DELETE_REQUEST,
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAIL,
  MEDIA_DELETE_RESET,
} from "../constants/mediaConstants";

// upload media
export const mediaUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDIA_UPLOAD_REQUEST:
      return {
        loading: true,
      };
    case MEDIA_UPLOAD_SUCCESS:
      return {
        loading: false,
        file: action.payload,
      };
    case MEDIA_UPLOAD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MEDIA_UPLOAD_RESET:
      return {};
    default:
      return state;
  }
};

// get all/related list
export const mediaListReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDIA_LIST_REQUEST:
      return {
        loading: true,
      };
    case MEDIA_LIST_SUCCESS:
      return {
        loading: false,
        files: action.payload,
      };
    case MEDIA_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MEDIA_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// delete media
export const mediaDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDIA_DELETE_REQUEST:
      return {
        loading: true,
      };
    case MEDIA_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case MEDIA_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MEDIA_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
