import React, { useEffect } from "react";
import AdminHeaderLayout from "../components/AdminHeaderLayout";
// import CreateAffiliates from "./CreateAffiliates";

import Message from "../../global/Message";
import Loader from "../../global/Loader";
import { HorizontalCards } from "../components/HorizontalCards";
// import { AppDropdown } from "../../global/AppDropdown";
// import { AppPagination } from "../../global/AppPagination";
import { listUsers } from "../../../actions/userActions";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { USER_LIST_RESET } from "../../../constants/userConstants";

const ManageAffiliates = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (location.hash.slice(1) === "affiliates") {
      dispatch(listUsers("affiliate"));
    }
  }, [location, dispatch]);

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingAffiliates,
    error: errorAffiliates,
    users: affiliates,
  } = userList;

  useEffect(() => {
    // resetting state due to errors
    if (errorAffiliates) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorAffiliates, dispatch]);

  return (
    <>
      {errorAffiliates && <Message error body={errorAffiliates} />}
      {/* <AdminHeaderLayout
        title="Manage Affiliates"
        placeholder="Search Affiliate"
        button={<CreateAffiliates />}
        pagination={<AppPagination totalItems="500" itemsPerPage={50} />}
      /> */}
      <AdminHeaderLayout
        title="Manage Affiliates"
        // placeholder="Search Affiliate"
        // button={<CreateAffiliate />}
        // pagination={
        //   affiliates ? (
        //     <AppPagination totalItems={affiliates.length} itemsPerPage={10} />
        //   ) : null
        // }
      />
      {loadingAffiliates ? (
        <Loader />
      ) : (
        !errorAffiliates && (
          <>
            {affiliates?.length ? (
              <div className="grid gap-2 mb-8">
                {affiliates.map((affiliate, index) => (
                  <HorizontalCards
                    key={index}
                    info={{
                      img: affiliate.avatar,
                      url: `/affiliate/profile/${affiliate.slug}`,
                      name: affiliate.title,
                    }}
                    // options={
                    //   <AppDropdown
                    //     activator={<i className="iconify" data-icon="tabler:dots" />}
                    //     className="dropdown-end"
                    //   >
                    //     <ul className="w-40 p-1">
                    //       <li>
                    //         <button onClick={() => editAfilliate(affiliate.id)}>
                    //           Edit affiliate
                    //         </button>
                    //       </li>
                    //       <li>
                    //         <button onClick={() => deleteAfilliate(affiliate.id)}>
                    //           Delete affiliate
                    //         </button>
                    //       </li>
                    //     </ul>
                    //   </AppDropdown>
                    // }
                  />
                ))}
              </div>
            ) : (
              <p>No affiliates found!</p>
            )}
          </>
        )
      )}
    </>
  );
};

export default ManageAffiliates;
