import React from "react";
import { useSelector } from "react-redux";

import Loader from "../../global/Loader";
import Message from "../../global/Message";

const UploadMenu = ({ children }) => {
  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { loading: uploading, error: errorUpload } = mediaUpload;

  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles } = mediaList;

  const mediaDelete = useSelector((state) => state.mediaDelete);
  const { loading: loadingDelete, error: errorDelete } = mediaDelete;

  return (
    <div className="absolute left-0 top-0 z-50 w-full h-full flex justiy-center items-center overflow-scroll">
      {uploading || loadingFiles || loadingDelete ? (
        <Loader />
      ) : errorUpload ? (
        <Message error body={errorUpload} />
      ) : errorDelete ? (
        <Message error body={errorDelete} />
      ) : errorFiles ? (
        <Message error body={errorFiles} />
      ) : (
        <></>
      )}
      <div className="absolute left-0 top-0 w-full h-full bg-denim opacity-80 mix-blend-multiply"></div>
      <div className="w-full h-full flex gap-2 justify-center items-center container overflow-scroll">
        <div className="flex flex-col relative gap-2 w-full overflow-scroll h-full pt-[100px]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default UploadMenu;
