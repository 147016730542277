import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  MEDIA_UPLOAD_REQUEST,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_FAIL,
  MEDIA_LIST_REQUEST,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAIL,
  MEDIA_DELETE_REQUEST,
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAIL,
} from "../constants/mediaConstants";

// upload media
export const uploadMedia = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_UPLOAD_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`${MAIN_ROUTE}/media`, formData, config);

    dispatch({
      type: MEDIA_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_UPLOAD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// upload guide media
export const uploadGuideMedia =
  (type, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MEDIA_UPLOAD_REQUEST,
      });

      const {
        vrvUserLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${MAIN_ROUTE}/media/${type}`,
        formData,
        config,
        );
          
      dispatch({
        type: MEDIA_UPLOAD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// get all media
export const listMediaFiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${MAIN_ROUTE}/media`, config);

    dispatch({
      type: MEDIA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// get all cloudinary images
export const listMedia = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_LIST_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${MAIN_ROUTE}/media`, config);

    dispatch({
      type: MEDIA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get user-related media
// export const listRelatedMedia = (userId) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: MEDIA_LIST_REQUEST,
//     });

//     const {
//       vrvUserLogin: { userInfo },
//     } = getState();

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };

//     const { data } = await axios.get(
//       `${MAIN_ROUTE}/media/related/${userId}`,
//       config,
//     );

//     dispatch({
//       type: MEDIA_LIST_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: MEDIA_LIST_FAIL,
//       payload:
//         error.response && error.response.data
//           ? error.response.data
//           : error.message,
//     });
//   }
// };

// delete media
export const deleteMedia = (public_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${MAIN_ROUTE}/media/${public_id}`,
      config,
    );

    dispatch({
      type: MEDIA_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
