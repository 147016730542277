import {
  MARKER_LIST_REQUEST,
  MARKER_LIST_SUCCESS,
  MARKER_LIST_FAIL,
  MARKER_LIST_RESET,
  MARKER_CREATE_REQUEST,
  MARKER_CREATE_SUCCESS,
  MARKER_CREATE_FAIL,
  MARKER_CREATE_RESET,
  MARKER_TOGGLE_REQUEST,
  MARKER_TOGGLE_SUCCESS,
  MARKER_TOGGLE_FAIL,
  MARKER_TOGGLE_RESET,
  MARKER_DELETE_REQUEST,
  MARKER_DELETE_SUCCESS,
  MARKER_DELETE_FAIL,
  MARKER_DELETE_RESET,
} from "../constants/mapConstants";

// list all markers
export const markerListReducer = (state = {}, action) => {
  switch (action.type) {
    case MARKER_LIST_REQUEST:
      return {
        loading: true,
      };
    case MARKER_LIST_SUCCESS:
      return {
        loading: false,
        markers: action.payload,
      };
    case MARKER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MARKER_LIST_RESET:
      return {
        markers: null,
      };
    default:
      return state;
  }
};

// create new marker
export const markerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MARKER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case MARKER_CREATE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MARKER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MARKER_CREATE_RESET:
      return {
        markers: null,
      };
    default:
      return state;
  }
};

// toggle marker status
export const markerToggleReducer = (state = {}, action) => {
  switch (action.type) {
    case MARKER_TOGGLE_REQUEST:
      return {
        loading: true,
      };
    case MARKER_TOGGLE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MARKER_TOGGLE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MARKER_TOGGLE_RESET:
      return {
        markers: null,
      };
    default:
      return state;
  }
};

// delete marker
export const markerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MARKER_DELETE_REQUEST:
      return {
        loading: true,
      };
    case MARKER_DELETE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MARKER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MARKER_DELETE_RESET:
      return {
        markers: null,
      };
    default:
      return state;
  }
};
