import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getUserDetails } from "../actions/userActions";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
} from "../constants/userConstants";
import Loader from "../components/global/Loader";
import PartnerProfileScreen from "./PartnerProfileScreen";
import AffiliateProfileScreen from "./AffiliateProfileScreen";

const BusinessProfileScreen = () => {
  const { role, slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_DETAILS_RESET });
  }, [dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: user,
  } = userDetails;

  useEffect(() => {
    dispatch({ type: USER_DETAILS_RESET });
  }, [slug, dispatch]);

  useEffect(() => {
    if (slug?.length && role?.length) {
      if (!user) {
        dispatch(getUserDetails(role, slug));
      }
    } else {
      dispatch({ type: USER_DETAILS_FAIL, payload: "Invalid inputs!" });
    }
  }, [user, slug, role, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorDetails, dispatch]);

  return loadingDetails ? (
    <Loader />
  ) : !errorDetails && user ? (
    <>
      {role === "partner" ? (
        <PartnerProfileScreen user={user} />
      ) : role === "affiliate" ? (
        <AffiliateProfileScreen user={user} />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default BusinessProfileScreen;
