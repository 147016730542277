export const GOOGLE_BUTTON_STYLES = {
  type: "standard",
  theme: "filled_black",
  shape: "circle",
  size: "large",
  width: "1000",
  text: "continue_with",
};

export const GOOGLE_AUTH_REQUEST = "GOOGLE_AUTH_REQUEST";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const GOOGLE_AUTH_RESET = "GOOGLE_AUTH_RESET";
