import AffiliateRegistrationForm from "../components/business-registration/AffiliateRegistrationForm";
import PartnerRegistrationForm from "../components/business-registration/PartnerRegistrationForm";

export const roles = [
  {
    role: "partner",
    cta: "come host",
    heading: "Become a part of The VR Voyage network",
    brief:
      "Explore an encouraging online community of experience hosts and gain immediate access to a comprehensive set of resources for creating tours.",
    body: "Enrolling as the latest participant in The VR Voyage is a straightforward and cost-free procedure. Complete the provided form below to register as a partner and gain access to the vast array of features on our platform. If you are a prospective partner, our team will reach out to assist in listing your experiences and devising compelling digital resources for publication in our expanding resource database.",
    img: require("../assets/img/dimensioning/partner.jpg"),
    className: "text-denim bg-green ring-transparent hover:ring-green w-full",
    color: "rgba(32, 198, 166, 1)",
    component: <PartnerRegistrationForm />,
    comingSoon: false,
  },
  {
    role: "affiliate",
    cta: "come sell",
    heading: "Spread the word & Amplify your earnings",
    brief:
      "Unlock a new revenue stream by joining The VR Voyage affiliate program. Expand your reach, drive sales through your POS channels, and earn a share of the profits.",
    body: "Are you interested in amplifying your earnings through our platform? Join our affiliate program and become a valuable part of The VR Voyage network. By leveraging your Point of Sale (POS) channels, you can increase platform awareness and drive sales while earning a share of the profits. Our dedicated team will support you every step of the way, ensuring you have the resources and assistance needed to maximize your revenue potential. Sign up today to start earning with The VR Voyage!",
    img: require("../assets/img/dimensioning/affiliate.jpg"),
    color: "rgba(232, 21, 107, 1)",
    className: "bg-magenta ring-transparent hover:ring-magenta w-full ",
    component: <AffiliateRegistrationForm />,
    comingSoon: false,
  },
  //   {
  //     role: "investor",
  //     cta: "come thrive",
  //     heading: "",
  //     brief: "",
  //     body: "",
  //     img: require("../assets/img/dimensioning/INVESTOR.jpg"),
  //     className: "text-denim bg-yellow ring-transparent hover:ring-yellow w-full",
  //     component: <PartnerRegistrationForm />,
  //     comingSoon: true,
  //   },
];
