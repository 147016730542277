import React from "react";
import { Link } from "react-router-dom";

const LegalRefundCancellationPolicy = () => {
  return (
    <main>
      <h1 className="mb-5 text-3xl font-semibold uppercase">
        Refund & Cancellation Policy
      </h1>
      <div className="grid gap-12">
        <span className="text-xs font-bold">
          This Policy was last updated on 26 August 2023.
        </span>
        <div>
          <p>
            This policy governing the procedure for refunds and cancellations
            (“Refund Policy”), in conjunction with our Terms and Conditions
            ("Terms"), outlines the rights of Users and obligations of Partners
            regarding refunds on The VR Voyage platform. The Refund Policy
            applies to Users who have purchased access to virtual experiences,
            self-guided tours, or entrance tickets to partnering institutions
            through our platform and encounter specific issues (as defined
            below).
          </p>
          <p className="mt-5">
            By utilizing The VR Voyage platform as either a Partner or User, you
            acknowledge that you have read, comprehended, and consented to abide
            by this Refund Policy.
          </p>
        </div>
        <ol className="grid gap-10">
          <li>
            <p className="font-bold text-lg mb-5">1. TRAVEL ISSUE</p>
            <div className="grid gap-5">
              <p>A “Travel Issue” means any one of the following:</p>
              <p>
                <span className="font-bold">
                  Cancelled Trip Due to Medical Emergency:
                </span>{" "}
                This refers to a situation in which a User's planned journey or
                excursion through The VR Voyage platform has to be canceled
                because of a sudden, unforeseen, and medically documented
                emergency. This may include serious health conditions, injuries,
                or medical emergencies that prevent the User from participating
                in the booked experience.
              </p>
              <p>
                <span className="font-bold">
                  Travel Delays Outside of the User's Control:
                </span>{" "}
                This encompass scenarios where Users encounter unexpected delays
                or disruptions in their travel plans that are entirely beyond
                their influence or control. Such delays may result from factors
                like severe weather conditions, transportation strikes, natural
                disasters, or unforeseen operational issues that impact the
                ability to enjoy a booked experience as planned.
              </p>
            </div>
          </li>
          <li>
            <p className="font-bold text-lg mb-5">2. PRODUCTS FOR REFUNDS</p>
            <ol className="pl-7 grid gap-5">
              <li>
                <span className="font-bold">2.1. Virtual Experiences</span>
                <p>
                  In the event that Users encounter technical difficulties with
                  virtual experiences, they may contact our support team at{" "}
                  <span className="text-green underline font-bold">
                    support@thevrvoyage.com
                  </span>{" "}
                  for assistance in resolving these issues. Please note that
                  refunds for this type of product will only be considered if
                  the technical issue cannot be reasonably resolved within a
                  reasonable timeframe.
                </p>
              </li>
              <li>
                <span className="font-bold">2.2. Self-Guided Tours</span>
                <p>
                  In the event that Users encounter technical issues while
                  utilizing our self-guided tours, we encourage them to promptly
                  contact our support team at{" "}
                  <span className="text-green underline font-bold">
                    support@thevrvoyage.com
                  </span>{" "}
                  or engage with a designated team member from the respective
                  institution responsible for the tour representation. We are
                  committed to assisting Users in resolving any technical
                  challenges they may face.
                </p>
                <p className="mt-5">
                  Please note that refunds for this type of product will only be
                  considered under exceptional circumstances where the issue
                  cannot be rectified within a reasonable period of time. Our
                  primary aim is to ensure that Users have a seamless and
                  enjoyable experience, and we will diligently work to address
                  any technical difficulties encountered during their
                  exploration.
                </p>
              </li>
              <li>
                <span className="font-bold">2.3. Entrance Tickets</span>
                <p>
                  In the event of a Travel Issue, this policy provides coverage
                  for a refund of your entrance ticket. To be eligible for a
                  refund, you must report the Travel Issue at least 24 hours
                  before the scheduled date for which the ticket is valid. If
                  you report a Travel Issue within 24 hours of the scheduled
                  date, no refund will be granted. In cases where you report a
                  Travel Issue at least 24 hours before your ticket date, we
                  commit to, at our discretion, either reimburse you for the
                  amount paid through The VR Voyage platform or make reasonable
                  efforts to assist you in finding an alternate date.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-bold text-lg mb-5">
              3. CONDITIONS FOR MAKING A CLAIM
            </p>
            <div className="grid gap-5">
              <p>
                To initiate a valid claim for a Travel Issue on The VR Voyage
                platform and access the associated benefits related to your
                purchase, you must fulfill the following conditions:
              </p>
              <ol className="pl-6">
                {[
                  "You must be the User who made the purchase",
                  "You must promptly report the Travel Issue to us in writing or via telephone within 24 hours of discovering its existence. Additionally, you must provide us with pertinent information, which may include photographs, videos, or other written or tangible evidence, pertaining to both the Accommodation and the circumstances surrounding the Travel Issue.",
                  "You are required to respond to any requests made by The VR Voyage for additional information or cooperation related to the Travel Issue within the time frame specified by The VR Voyage.",
                  "You must not be the direct or indirect cause of the Travel Issue, whether through your actions, omissions, or negligence.",
                ].map((condition, index) => (
                  <p key={index} className="flex gap-2">
                    <span>({String.fromCharCode(index + 97)})</span>
                    <span>{condition}</span>
                  </p>
                ))}
              </ol>
            </div>
          </li>
          <li>
            <p className="font-bold text-lg mb-5">
              4. RESPONSIBILITIES & REIMBURSEMENTS
            </p>
            <ol className="pl-7 grid gap-5">
              <li>
                <p>
                  <span className="font-bold">
                    4.1. Partner Responsibilities{" "}
                  </span>
                  As a Partner, you are responsible for ensuring that the dates
                  and times you have listed are accurately listed on The VR
                  Voyage platform to avoid Users purchasing entrance tickets for
                  days that are not open to the public. If a User purchases a
                  ticket for Partner locations and that Partner is found to have
                  not provided accurate information, the User will be reimbursed
                  the full ticket price and The VR Voyage will retain its admin
                  fee.
                </p>
              </li>
              <li>
                <span className="font-bold">4.2. User Responsibilities</span>
                <p>
                  As a User, you are responsible for booking the correct date
                  for the entrance ticket you wish to use for access to a
                  partnering institution. If you have purchased a wrong date,
                  you may contact The VR Voyage at{" "}
                  <Link className="text-green underline font-bold">
                    support@thevrvoyage.com
                  </Link>{" "}
                  for assistance in changing the date. All issues of this nature
                  must be done 24-hours in advance.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-bold text-lg mb-5">5. MODIFICATIONS</p>
            <p>
              On occasion, we may update the Refund Policy to further define our
              program. The VR Voyage reserves the right to modify or make
              changes to this policy at any time. When changes to the Refund
              Policy are made, Partners will be notified by the email that is
              associated with their account. Changes will take effect on the day
              the Policy is posted.
            </p>
            <p className="mt-5">
              Continued use of The VR Voyage’s marketplace after changes are
              posted shall mean each Publisher’s acceptance of the
              modifications. The revised Refund Policy shall supersede all
              previous editions of the Refund Policy.
            </p>
          </li>
          <li>
            <p className="font-bold text-lg mb-5">6. HOW TO CONTACT US</p>
            <p>
              For Partners, the best way to contact The VR Voyage is through
              your Account Manager. For Users, the best way is through{" "}
              <Link className="text-green underline font-bold">
                support@thevrvoyage.com.
              </Link>{" "}
            </p>
          </li>
        </ol>
      </div>
    </main>
  );
};

export default LegalRefundCancellationPolicy;
