import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { toggleMarker } from "../../actions/mapActions";
import { toggleUser } from "../../actions/userActions";

export const Toggle = ({ id, label, toggled, onClick, type }) => {
  const [isToggled, toggle] = useState(toggled);

  const dispatch = useDispatch();

  const toggleHandler = () => {
    toggle(!isToggled);

    if (onClick) {
      onClick(!isToggled);
    }

    if (id) {
      if (type === "marker") {
        dispatch(toggleMarker(type, id));
      }
      if (type === "partner") {
        dispatch(toggleUser(type, id));
      }
    }
  };

  return (
    type && (
      <label id={id} className="label-toggle" title={toggled ? "Hide" : "Show"}>
        <input
          className="input-toggle"
          type="checkbox"
          defaultChecked={isToggled}
          onClick={toggleHandler}
          id={id}
        />
        <span className="span-toggle" />
        <strong className="absolute w-max ml-3 cursor-pointer left-full">
          {label}
        </strong>
      </label>
    )
  );
};
