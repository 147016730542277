import React from "react";

const AudioPlayer = ({
  audio,
  audioEl,
  audioUrl,
  showAudio,
  setShowAudio,
  updateAudio,
  onAudioUpdate,
  playAudio,
  isPlaying,
  showText,
}) => {
  return (
    <div
      className={`absolute w-full ${
        showAudio ? "max-h-[122px] overflow-auto" : "max-h-0 overflow-hidden"
      } ${
        showText ? "bottom-0" : "bottom-[70px]"
      } h-screen px-4 bg-white rounded-t-3xl with-transition`}
    >
      <div className="flex pt-6">
        <div className="relative w-full ml-auto grid items-center mr-6">
          <span className="absolute z-0 top-[43%] block h-1.5 w-full bg-gray rounded-full pointer-events-none" />
          <span
            className="absolute z-0 top-[43%] block h-1.5 bg-gray-border rounded-full pointer-events-none"
            style={{ width: `${audio.progress}%` }}
          />
          <input
            type="range"
            min="0"
            max="100"
            className="w-full"
            value={audio.progress}
            onChange={updateAudio}
            style={{ position: "inherit" }}
          />
        </div>
        {isPlaying ? (
          <button
            className="mx-2 border-2 border-black rounded-full p-[6px]"
            onClick={playAudio}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              data-icon="ic:round-pause"
              className="iconify iconify--ic"
            >
              <path
                fill="currentColor"
                d="M8 19c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2m6-12v10c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2"
              ></path>
            </svg>
          </button>
        ) : (
          <button
            className="mx-2 border-2 border-black rounded-full p-[6px]"
            onClick={playAudio}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              viewBox="0 0 512 512"
              data-icon="ion:play"
              className="iconify iconify--ion"
            >
              <path
                fill="currentColor"
                d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440"
              ></path>
            </svg>
          </button>
        )}
        <button
          onClick={() => setShowAudio(false)}
          className="border-2 border-black rounded-full p-[6px]"
        >
          <i className="iconify" data-icon="ph:x-bold" />
        </button>
      </div>
      <div className="flex justify-between mt-5 font-oswald font-normal">
        {audio?.currentTime ? <p>{audio?.currentTime}</p> : <></>}
        {audio?.duration ? <p>{audio?.duration}</p> : <></>}
      </div>
      <audio
        onTimeUpdate={onAudioUpdate}
        ref={audioEl}
        onCanPlay={onAudioUpdate}
      >
        <source src={audioUrl} type="audio/ogg" />
      </audio>
    </div>
  );
};

export default AudioPlayer;
