import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createContactMail } from "../../actions/contactMailActions";

import {
  requiredFieldsErrorMessage,
  validateEmail,
} from "../../constants/helpers";

import AppSelect from "../global/form/AppSelect";
import AppInput from "../global/form/AppInput";
import AppTextArea from "../global/form/AppTextArea";
import { CONTACTMAIL_CREATE_FAIL } from "../../constants/contactMailConstants";

import { useForm } from "../../hooks/useForm";

const ContactForm = () => {
  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { message: successMessage } = contactMailCreate;

  const dispatch = useDispatch();

  const [subject, setSubject] = useState("hide");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [message, setMessage] = useState([]);

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(String(e.target.value).split("\n"));
  };

  const resetFields = () => {
    setSubject("hide");
    setName("");
    setEmail("");
    setPhone("");
    setCity("");
    setProvince("");
    setMessage([]);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      subject.length > 0 &&
      name.length > 0 &&
      email.length > 0 &&
      message.length > 0
    ) {
      if (validateEmail(email)) {
        const contactMail = {
          subject,
          name,
          email,
          phone,
          city,
          province,
          message,
        };

        dispatch(createContactMail(contactMail));
      } else {
        dispatch({
          type: CONTACTMAIL_CREATE_FAIL,
          payload: "Invalid email format!",
        });
      }
    } else {
      dispatch({
        type: CONTACTMAIL_CREATE_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    if (successMessage) {
      resetFields();
    }
  }, [successMessage]);

  const { register, errors } = useForm();

  return (
    <form onSubmit={submitHandler} className="grid grid-cols-2 gap-3 mb-16">
      <p className="text-denim/40 mt-5">Fields marked with an * are required</p>

      <div className="col-span-2">
        <AppSelect
          {...register("helpType", { required: [true] })}
          asRequired
          id="helpType"
          items={[
            "Becoming a Partner",
            "Registration/Subscription",
            "Help Using the Platform",
            "Technical Support",
            "Billing Issues",
            "Provide Feedback",
            "Other",
          ]}
          itemText="name"
          itemValue="id"
          label="How can we help you?"
          placeholder="-- Please select one --"
          labelClass="text-denim/40"
          className="bg-transparent border-gray-border border-2 rounded-lg"
          error={errors.helpType}
          selected={subject !== "hide"}
          onChange={subjectHandler}
        />
      </div>
      <div className="col-span-2">
        <AppInput
          {...register("name", { required: [true] })}
          asRequired
          id="name"
          label="Name"
          labelClass="text-denim/40"
          className="bg-transparent"
          error={errors.name}
          value={name}
          onChange={nameHandler}
        />
      </div>
      <div className="col-span-2">
        <AppInput
          {...register("email", { required: [true], email: [true] })}
          asRequired
          id="email"
          label="Email Address"
          labelClass="text-denim/40"
          className="bg-transparent"
          error={errors.email}
          value={email}
          onChange={emailHandler}
        />
      </div>
      <div className="col-span-2">
        <AppInput
          {...register("phone")}
          type="tel"
          id="phone"
          label="Phone Number (optional)"
          labelClass="text-denim/40"
          className="bg-transparent"
          error={errors.phone}
          value={phone}
          onChange={phoneHandler}
        />
      </div>
      <div className="col-span-2">
        <AppInput
          {...register("city")}
          id="city"
          label="City"
          labelClass="text-denim/40"
          className="bg-transparent"
          error={errors.city}
          value={city}
          onChange={cityHandler}
        />
      </div>
      <div className="col-span-2">
        <AppInput
          {...register("province")}
          id="province"
          label="Province/Territory"
          labelClass="text-denim/40"
          className="bg-transparent"
          error={errors.province}
          value={province}
          onChange={provinceHandler}
        />
      </div>
      <div className="col-span-2">
        <AppTextArea
          {...register("message", { required: [true] })}
          asRequired
          id="message"
          label="Write your message here"
          labelClass="text-denim/40"
          className="bg-transparent border-gray-border border-2 rounded-lg"
          rows="6"
          error={errors.message}
          value={message.join("\n")}
          onChange={messageHandler}
        />
      </div>
      <div className="mt-6 mx-auto md:mx-0 w-44 h-fit rounded-full with-transition">
        <button
          to=""
          type="submit"
          className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 border-2 border-transparent hover:border-denim with-transition"
          onSubmit={submitHandler}
        >
          <span className="block">Submit message</span>
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
