import React from "react";
import { Link } from "react-router-dom";

const AppWayToExploreCard = ({
  customClass,
  icon,
  title,
  description,
  comingSoon,
  link,
  linkText,
  linkClass,
  linkBorderColor,
}) => (
  <div
    className={`overflow-hidden flex flex-col gap-8 py-12 px-10 border-radius shadow-md ${customClass}`}
  >
    <span className="grid place-items-center w-12 aspect-square rounded-full bg-white">
      {icon}
    </span>
    <h3 className="uppercase font-semibold text-3xl">{title}</h3>
    <p>{description}</p>
    {comingSoon ? (
      <span
        className={`uppercase py-3 grid place-items-center mt-auto font-semibold rounded-full border-2 ${linkBorderColor} with-transition`}
      >
        <h6>Coming Soon</h6>
      </span>
    ) : (
      <Link
        to={link}
        className={`uppercase py-3 grid place-items-center mt-auto font-semibold rounded-full ${linkClass} with-transition`}
      >
        <h6>{linkText}</h6>
      </Link>
    )}
  </div>
);

export default AppWayToExploreCard;
