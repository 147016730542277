import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import {
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validateEmail,
  validatePassword,
} from "../constants/helpers";

import { getGoogleOAuthClientID } from "../actions/googleActions";
import { googleAuth } from "../actions/googleActions";
import { authWithGoogle, register } from "../actions/userActions";
import {
  USER_LOGIN_RESET,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
} from "../constants/userConstants";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Rock from "../assets/img/register/rock.png";
import AppInput from "../components/global/form/AppInput";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const {
    loading: loadingLogin,
    error: errorLogin,
    userInfo: userInfoLogin,
  } = vrvUserLogin;

  const googleOAuth = useSelector((state) => state.googleOAuth);
  const {
    loading: loadingGoogleAuth,
    error: errorGoogleAuth,
    key,
  } = googleOAuth;

  const vrvUserRegister = useSelector((state) => state.vrvUserRegister);
  const {
    loading: loadingRegister,
    error: errorRegister,
    message,
  } = vrvUserRegister;

  useEffect(() => {
    if (userInfoLogin) {
      if (userInfoLogin.role === "admin") {
        navigate("/");
      } else {
        navigate(`/user/profile/${userInfoLogin.slug}`);
      }
    }
  }, [userInfoLogin, navigate]);

  useEffect(() => {
    if (!key) {
      dispatch(getGoogleOAuthClientID());
    }
  }, [key, dispatch]);

  const continueWithGoogle = (res) => {
    dispatch(authWithGoogle(res.credential));
  };

  useEffect(() => {
    if (key) {
      googleAuth(key, continueWithGoogle);
    }

    // eslint-disable-next-line
  }, [key]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmedPassword("");
  };

  const registerUserHandler = (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      confirmedPassword.length > 0
    ) {
      if (password === confirmedPassword) {
        if (validateEmail(email) && validatePassword(password)) {
          const user = {
            name,
            email,
            password,
          };

          dispatch(register(user));
        } else {
          if (!validateEmail(email)) {
            dispatch({
              type: USER_REGISTER_FAIL,
              payload: "Invalid email format!",
            });
          }
          if (!validatePassword(password)) {
            dispatch({
              type: USER_REGISTER_FAIL,
              payload: passwordValidateErrorMessage,
            });
          }
        }
      } else {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: "Passwords don't match!",
        });
      }
    } else {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    if (message) {
      resetFields();
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, [message, navigate]);

  useEffect(() => {
    // resetting state due to errors
    if (message || errorRegister) {
      setTimeout(() => {
        dispatch({ type: USER_REGISTER_RESET });
      }, 5000);
    }

    if (errorLogin) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }
  }, [message, errorRegister, errorLogin, dispatch]);

  return (
    <>
      {errorRegister && <Message error body={errorRegister} />}
      {errorGoogleAuth && <Message error body={errorGoogleAuth} />}
      {errorLogin && <Message error body={errorLogin} />}
      {message && <Message info body={message} />}
      {loadingGoogleAuth ? (
        <Loader />
      ) : loadingRegister ? (
        <Loader />
      ) : loadingLogin ? (
        <Loader />
      ) : (
        !userInfoLogin && (
          <MainLayout
            scrollNavClass="bg-gray"
            logo={Logo}
            textHover="text-green"
            textHoverWithScroll="text-green"
            styleBtnWithScroll="hover:text-denim hover:border-denim"
          >
            <NavLink to="/" className="lg:hidden block ml-4 mt-6">
              <img src={Logo} alt="Logo" className="w-48" />
            </NavLink>
            <div className="grid grid-cols-1 lg:grid-cols-3 container mt-16 md:mt-28 mb-24">
              <div className="col-span-1">
                <div className="mb-12">
                  <h1 className="mb-5 text-3xl uppercase font-semibold">
                    create an account
                  </h1>
                  <p>
                    Already have an account?
                    <Link
                      to="/login"
                      className="ml-2 text-green hover:text-magenta font-semibold with-transition"
                    >
                      Log in
                    </Link>
                  </p>
                  <p className="text-denim/40 mt-5">
                    Fields marked with an * are required
                  </p>
                </div>
                <form
                  onSubmit={registerUserHandler}
                  className="grid gap-4 mb-4"
                >
                  <AppInput
                    id="name"
                    value={name}
                    onChange={nameHandler}
                    label="Name *"
                    labelClass="text-denim/40"
                    className="bg-transparent border-denim/50 border-b-2"
                  />
                  <AppInput
                    id="email"
                    type="email"
                    value={email}
                    onChange={emailHandler}
                    label="E-mail *"
                    labelClass="text-denim/40"
                    className="bg-transparent border-denim/50 border-b-2"
                  />
                  <AppInput
                    id="password"
                    type="password"
                    value={password}
                    onChange={passwordHandler}
                    label="Password *"
                    labelClass="text-denim/40"
                    className="bg-transparent border-denim/50 border-b-2"
                  />
                  <AppInput
                    id="confirmed-password"
                    type="password"
                    value={confirmedPassword}
                    onChange={confirmedPasswordHandler}
                    label="Confirm Password *"
                    labelClass="text-denim/40"
                    className="bg-transparent border-denim/50 border-b-2"
                  />
                  <div className="mx-auto md:mx-0 w-full h-fit border-2 border-transparent hover:border-denim mt-10 rounded-full with-transition">
                    <button
                      type="submit"
                      className="font-semibold text-white hover:text-denim uppercase app-primary-button h-14 animate-bg w-full flex justify-center gap-5 with-transition"
                      onSubmit={registerUserHandler}
                    >
                      <span className="block">Create my account</span>
                    </button>
                  </div>
                  {key && (
                    <>
                      <div className="relative h-0.5 my-3 bg-denim/50">
                        <p className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 grid place-items-center w-7 aspect-square rounded-full bg-gray text-denim">
                          or
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="border-transparent border-2 hover:border-2 hover:border-denim rounded-full font-semibold uppercase hover:bg-transparent flex justify-center gap-3 w-fit btn-google-cta"
                        >
                          <div id="login-google"></div>
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
              <div className="hidden lg:block col-span-2 pl-20">
                <div
                  className="bg-cover bg-center bg-no-repeat w-full h-full rounded-tl-2xl rounded-br-2xl"
                  style={{ backgroundImage: `url(${Rock})` }}
                />
              </div>
            </div>
          </MainLayout>
        )
      )}
    </>
  );
};

export default RegisterScreen;
