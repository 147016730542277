import React, { useEffect, useRef, useState } from "react";

import TourExploreHeader from "./TourExploreHeader";

export const TourExploreMap = ({
  tour,
  partner,
  onBackClick,
  onHotSpotClick,
}) => {
  const [showText, setShowText] = useState(false);
  const containerRef = useRef();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (tour) {
      setContent(tour.content);
    }
  }, [tour]);

  useEffect(() => {
    if (partner) {
      setTitle(partner.title);
    }
  }, [partner]);

  useEffect(() => {
    const { current } = containerRef;
    if (current) {
      current.scrollLeft = current.offsetWidth / 2;
    }
  }, []);

  const handleZoomIn = () => {
    setScale(scale + 0.2);
  };

  const handleZoomOut = () => {
    if (scale > 1) {
      setScale(scale - 0.2);
    }
  };

  useEffect(() => {
    let focusedDiv = document.querySelector(".map-handle");

    focusedDiv?.scrollIntoView({
      behavior: "smooth",
    });

    focusedDiv?.remove();
  });

  return (
    content && (
      <div className="relative flex flex-col max-h-screen">
        <div className="z-10 top-0 w-full bg-grey">
          <TourExploreHeader
            onBackClick={onBackClick}
            text="Map of the tour"
            title={title}
            styles="rounded-b-3xl bg-white shadow-md"
          />
        </div>
        <div className="flex-grow overflow-y-auto no-scroll-bar with-transition">
          <div
            style={{
              transform: `scale(${scale})`,
              transformOrigin: "center center",
            }}
            className="with-transition"
          >
            <div
              ref={containerRef}
              className="flex-grow-0 w-full mx-auto overflow-scroll no-scroll-bar h-[100vh]"
            >
              <div
                className="map-bg relative mx-auto h-[768px] w-[765px] bg-no-repeat flex justify-end items-center pr-[200px]"
                style={{ backgroundImage: `url(${content.map})` }}
              >
                <div className="map-handle w-[1px] h-[1px]"></div>
                {content.stops.map((stop, stopIndex) => (
                  <button
                    key={stopIndex}
                    className="absolute w-[1px] h-[1px]"
                    style={{
                      top: `${stop.coords.y}px`,
                      left: `${stop.coords.x}px`,
                    }}
                    onClick={() => onHotSpotClick({ ...stop, type: "gallery" })}
                    onTouchEnd={() =>
                      onHotSpotClick({ ...stop, type: "gallery" })
                    }
                  >
                    <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] grid place-items-center w-8 h-8 text-white text-xs rounded-full font-bold">
                      <span className="z-10 drop-shadow-xl shadow-xl">
                        {stop.number}
                      </span>
                      <span
                        className="absolute top-0 left-0 w-full h-full rounded-full opacity-80 z-0"
                        style={{
                          backgroundColor: content?.color,
                        }}
                      ></span>
                    </span>
                  </button>
                ))}
                {content.structures?.map((structure, structureIndex) => (
                  <button
                    key={structureIndex}
                    className="absolute w-[1px] h-[1px]"
                    style={{
                      top: `${structure.coords.y}px`,
                      left: `${structure.coords.x}px`,
                    }}
                    onClick={() =>
                      onHotSpotClick({ ...structure, type: "floor" })
                    }
                    onTouchEnd={() =>
                      onHotSpotClick({ ...structure, type: "floor" })
                    }
                  >
                    <span
                      className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] grid place-items-center w-10 h-10 text-white text-xs rounded-full font-bold opacity-95"
                      style={{ backgroundColor: content.color }}
                    >
                      <span className="z-10 drop-shadow-xl shadow-xl">
                        {(() => {
                          let stops = [];

                          structure.floorplans?.forEach((floorplan) => {
                            floorplan.stops?.forEach((stop) => {
                              stops.push(stop.number);
                            });
                          });

                          stops.sort((a, b) => {
                            return a < b ? -1 : a > b ? 1 : 0;
                          });

                          return `${stops[0]}-${stops[stops.length - 1]}`;
                        })()}
                      </span>
                      <span
                        className="absolute top-0 left-0 w-full h-full rounded-full opacity-90 z-0"
                        style={{
                          backgroundColor: content?.color,
                        }}
                      ></span>
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-grey">
          <button
            className="p-1 bg-white rounded-full fixed md:absolute top-[93vh] md:-top-[98.37vh] left-4 md:left-[90%] md:z-20"
            onClick={() => setShowText(true)}
          >
            <i className="iconify text-3xl" data-icon="uil:question" />
          </button>
          <div
            className={`absolute bottom-0 z-20 ${
              showText
                ? "max-h-[30vh] overflow-auto"
                : "max-h-0 overflow-hidden"
            } h-screen bg-white rounded-t-3xl with-transition`}
          >
            <div className="flex items-center justify-end h-12 px-3">
              <button onClick={() => setShowText(false)}>
                <i className="iconify text-gray-border" data-icon="ph:x-bold" />
              </button>
            </div>
            <p className="p-5 pt-0 overflow-auto">
              Welcome to our interactive map. You can zoom in to see details and
              navigate throught the different hotspots. For a better experince,
              we recommend you to navigate the stops by their numbers in an
              ascending order.
            </p>
          </div>

          <button
            className="p-1 bg-denim hover:bg-white text-white hover:text-denim border-2 border-transparent hover:border-denim rounded-full fixed top-[87vh] right-4"
            onClick={handleZoomIn}
          >
            <i className="iconify text-2xl" data-icon="ic:round-add" />
          </button>

          <button
            className="p-1 bg-denim hover:bg-white text-white hover:text-denim border-2 border-transparent hover:border-denim rounded-full fixed top-[93vh] right-4"
            onClick={handleZoomOut}
          >
            <i
              className="iconify text-2xl"
              data-icon="heroicons-solid:minus-sm"
            />
          </button>
        </div>
      </div>
    )
  );
};
