import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from "./AudioPlayer";

export const TourNavigation = ({
  textGuide,
  // visual,
  audioUrl,
  onMapClick,
  style,
  noMap,
  isFixed,
  autoPlay = true,
}) => {
  const [showText, setShowText] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioEl = useRef(null);
  const [audio, setAudio] = useState({
    currentTime: 0,
    duration: 0,
    progress: 0,
  });

  useEffect(() => {
    if (audioUrl) {
      setTimeout(() => {
        if (audioEl.current && autoPlay) {
          setShowAudio(true);
          setIsPlaying(true);
          audioEl.current.play();
        }
      }, 1000);
    }
  }, [audioUrl, autoPlay, audioEl]);

  useEffect(() => {
    if (audioUrl && !showAudio) {
      audioEl.current.pause();
    }
  }, [audioUrl, showAudio]);

  function playAudio() {
    if (!isPlaying) {
      audioEl.current.play();
      setIsPlaying(true);
    } else {
      audioEl.current.pause();
      setIsPlaying(false);
    }
  }

  function onAudioUpdate(ev) {
    const { currentTime, duration } = ev.target;
    setAudio({
      ...audio,
      duration: formatTime(duration),
      currentTime: formatTime(currentTime),
      progress: Math.floor((currentTime / duration) * 100),
    });
  }

  function updateAudio(ev) {
    const { value } = ev.target;
    audioEl.current.currentTime = (value / 100) * audioEl.current.duration;
  }

  function formatTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    minutes = minutes >= 10 ? minutes : "0" + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : "0" + seconds;
    return minutes + ":" + seconds;
  }

  return (
    <div className="sticky bottom-0 z-40">
      <ul
        className={`flex justify-center py-5 rounded-t-3xl bg-white ${style}`}
      >
        <li>
          <button
            className="flex flex-col justify-center gap-1 items-center w-28 text-xs font-bold"
            onClick={() => setShowText(true)}
          >
            <i className="iconify" data-icon="icon-park-solid:doc-search" />
            Text Guide
          </button>
        </li>
        {audioUrl && (
          <li>
            <button
              className="flex flex-col justify-center gap-1 items-center w-28 text-xs font-bold"
              onClick={() => setShowAudio(true)}
            >
              <i className="iconify" data-icon="lets-icons:sound-max-fill" />
              Audio Guide
            </button>
          </li>
        )}
        {noMap ? (
          ""
        ) : (
          <li>
            <button
              className="flex flex-col gap-1 justify-center items-center w-28 text-xs font-bold"
              onClick={() => onMapClick()}
            >
              <i className="iconify" data-icon="typcn:location" />
              Map
            </button>
          </li>
        )}
      </ul>
      <div
        className={`absolute bottom-0 ${
          showText
            ? "max-h-[80vh] md:max-h-[70vh] overflow-auto"
            : "max-h-0 overflow-hidden"
        } h-screen bg-white md:bg-gray rounded-t-3xl with-transition w-full`}
      >
        <div className="mr-3 md:mr-5">
          <button
            onClick={() => setShowText(false)}
            className={`grid items-center place-items-center ml-auto h-10 md:bg-white mt-5 aspect-square rounded-full ${
              isFixed && "fixed bg-white right-4"
            }`}
          >
            <i className="iconify text-gray-border" data-icon="ph:x-bold" />
          </button>
        </div>
        <div className="px-5 md:px-14 overflow-auto py-5 md:pt-20 flex flex-col gap-6">
          {/* {visual?.length ? (
            <img
              src={visual}
              alt={visual}
              className="rounded-2xl w-full shadow-2xl"
            />
          ) : (
            <></>
          )} */}
          <p className={`${isFixed ? "pt-14" : ""} flex flex-col gap-2`}>
            {textGuide.split("\n").map((ele, index) => (
              <span key={index}>{ele}</span>
            ))}
          </p>
        </div>
      </div>
      {audioUrl && (
        <AudioPlayer
          audio={audio}
          audioEl={audioEl}
          audioUrl={audioUrl}
          showAudio={showAudio}
          setShowAudio={setShowAudio}
          updateAudio={updateAudio}
          onAudioUpdate={onAudioUpdate}
          playAudio={playAudio}
          isPlaying={isPlaying}
          showText={showText}
        />
      )}
    </div>
  );
};
