import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createMarker } from "../../../actions/mapActions";
import { uploadMedia } from "../../../actions/mediaActions";
import {
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../../../constants/mediaConstants";
import {
  MARKER_CREATE_FAIL,
  MARKER_CREATE_RESET,
} from "../../../constants/mapConstants";

import locations from "../../../data/locations.json";
import { categories, subCategories } from "../../../constants/categories";
import { provinces, regions } from "../../../constants/geography";
import {
  invalidCoordinatesErrorMessage,
  requiredFieldsErrorMessage,
  requiredTagsErrorMessage,
  requiredVisualsErrorMessage,
  validateCoordinate,
  validateRequiredFields,
} from "../../../constants/helpers";

import AppModal from "../../global/AppModal";
import AppInput from "../../global/form/AppInput";
import AppSelect from "../../global/form/AppSelect";
import AppInputFile from "../../global/form/AppInputFile";
import AppTextArea from "../../global/form/AppTextArea";
import AppChipsInput from "../../global/form/AppChipsInput";
import { AppPhoneInput } from "../../global/form/AppPhoneInput";
import { Toggle } from "../../global/AppToggle";

const CreateMarker = () => {
  const markerCreate = useSelector((state) => state.markerCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: created,
  } = markerCreate;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  const dispatch = useDispatch();

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  //
  //
  //
  //
  // main
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [cultural, setCultural] = useState(false);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState([]);
  const [landAcknowledgement, setLandAcknowledgement] = useState("");

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const subtitleHandler = (e) => {
    setSubtitle(e.target.value);
  };

  const culturalHandler = () => {
    setCultural(!cultural);
  };

  const categoryHandler = (e) => {
    setCategory(e.target.value);
    setSubCategory("");
    setOrganizationType("");
  };

  const subCategoryHandler = (e) => {
    setSubCategory(e.target.value);
    setOrganizationType("");
  };

  const organizationTypeHandler = (e) => {
    setOrganizationType(e.target.value);
  };

  const tagsHandler = (e) => {
    setTags(e);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value.split("\n"));
  };

  const landAcknowledgementHandler = (e) => {
    setLandAcknowledgement(e.target.value);
  };

  // availability
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [availability, setAvailability] = useState("");

  const phoneHandler = (e) => {
    setPhone(e);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const availabilityHandler = (e) => {
    setAvailability(e.target.value);
  };

  // media
  const [mediaType, setMediaType] = useState("");
  const [banner, setBanner] = useState("");
  const [avatar, setAvatar] = useState("");

  const [photoCredit, setPhotoCredit] = useState("");
  const [imageSEOtitle, setImageSEOtitle] = useState("");
  const [references, setReferences] = useState([]);
  const [source, setSource] = useState("");

  const mediaTypeHandler = (type) => {
    setMediaType(type);
  };

  const uploadHandler = (file) => {
    if (/jpg|jpeg|png/.test(file.type)) {
      const form = new FormData();
      form.append("image", file);
      dispatch(uploadMedia(form));
    } else {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: jpg and png)",
      });
      return;
    }
  };

  useEffect(() => {
    if (uploaded) {
      if (mediaType === "avatar") {
        setAvatar(uploaded);
      }

      if (mediaType === "banner") {
        setBanner(uploaded);
      }
    }
    // eslint-disable-next-line
  }, [uploaded]);

  const photoCreditHandler = (e) => {
    setPhotoCredit(e.target.value);
  };

  const imageSEOtitleHandler = (e) => {
    setImageSEOtitle(e.target.value);
  };

  const referencesHandler = (e) => {
    setReferences(e.target.value.split(","));
  };

  const sourceHandler = (e) => {
    setSource(e.target.value);
  };

  // location
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [region, setRegion] = useState("");
  const [streetAddress, setStreetAddress] = useState("");

  const latitudeHandler = (e) => {
    setLatitude(e.target.value);
  };

  const longitudeHandler = (e) => {
    setLongitude(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
    setCity("");
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
    setRegion("");
  };

  const regionHandler = (e) => {
    setRegion(e.target.value);
  };

  const streetAddressHandler = (e) => {
    setStreetAddress(e.target.value);
  };

  // action handlers
  const resetFields = () => {
    setTitle("");
    setSubtitle("");
    setCultural(false);
    setCategory("");
    setOrganizationType("");
    setTags([]);
    setDescription([]);
    setLandAcknowledgement("");
    setPhone("");
    setWebsite("");
    setEmail("");
    setAvailability("");
    setMediaType("");
    setBanner("");
    setAvatar("");
    setPhotoCredit("");
    setImageSEOtitle("");
    setReferences([]);
    setSource("");
    setLatitude("");
    setLongitude("");
    setCountry("");
    setCity("");
    setProvince("");
    setRegion("");
    setStreetAddress("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (!banner?.length || !avatar?.length) {
        throw new Error(requiredVisualsErrorMessage);
      }

      if (
        !validateRequiredFields([
          title,
          category,
          subCategory,
          organizationType,
          photoCredit,
          imageSEOtitle,
          source,
          latitude,
          longitude,
        ]) ||
        !description.filter((word) => /\S/.test(word)).length ||
        !references.filter((word) => /\S/.test(word)).length
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!validateCoordinate(latitude) || !validateCoordinate(longitude)) {
        throw new Error(invalidCoordinatesErrorMessage);
      }

      if (!tags?.length) {
        throw new Error(requiredTagsErrorMessage);
      }

      // dispatching
      const marker = {
        title,
        subtitle,
        cultural,
        category,
        subCategory,
        organizationType,
        tags,
        description,
        landAcknowledgement,
        info: {
          phone,
          website,
          email,
          availability,
        },
        avatar,
        banner,
        photoCredit,
        imageSEOtitle,
        references,
        source,
        gpsCoordinates: { latitude, longitude },
        location: { country, province, region, city, streetAddress },
      };

      dispatch(createMarker(marker));
    } catch (error) {
      dispatch({
        type: MARKER_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: MARKER_CREATE_RESET });
      }, 5000);
    }
    // resetFields();
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        resetFields();
        dispatch({ type: MARKER_CREATE_RESET });
      }, 5000);
    }
  }, [created, dispatch]);

  return (
    <AppModal
      activator={
        <button
          type="button"
          className="order-2 lg:order-3 uppercase font-semibold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3 with-transition"
        >
          Create New marker
        </button>
      }
      styleModal="h-fit z-50 pb-10"
      styleLabelClose="sticky w-10 h-10 rounded-full"
      styleIconClose="text-denim text-3xl bg-gray rounded-full p-1"
      withLoading={uploading || loadingCreate}
    >
      <p className="font-bold text-lg lg:sticky absolute top-4 left-4 lg:pl-6 z-[1]">
        Create New Marker
      </p>
      <div className="pt-5 lg:px-24">
        <form className="px-4 grid gap-5 mt-5 pb-5" onSubmit={submitHandler}>
          <>
            <h6 className="mt-7 uppercase font-semibold text-2xl">
              General information
            </h6>
            <AppInput
              asRequired
              id="organizationName"
              label="Organization name"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={title}
              onChange={titleHandler}
            />
            <AppInput
              id="subtitle"
              label="Subtitle"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={subtitle}
              onChange={subtitleHandler}
            />
            <div className="flex gap-4 relative w-full">
              <span className={`font-bold flex relative with-transition`}>
                Is this a cultural organization?
              </span>
              <Toggle toggled={cultural} onClick={culturalHandler} />
            </div>
            <AppSelect
              asRequired
              id="category"
              items={categories.map((ele) => ele.name)}
              itemText="name"
              itemValue="id"
              label="Category"
              placeholder="-- Please select one --"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={category}
              onChange={categoryHandler}
            />
            <AppSelect
              asRequired
              id="subCategory"
              items={categories
                .find((ele) => ele.name === category)
                ?.items?.map((item) => item.name)}
              itemText="name"
              itemValue="id"
              label="Sub-category"
              placeholder={`-- Please select ${
                category?.length ? "one" : "category first"
              } --`}
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={subCategory}
              onChange={subCategoryHandler}
            />
            <AppSelect
              asRequired
              id="organizationType"
              items={
                category?.length && subCategory?.length ? subCategories : []
              }
              itemText="name"
              itemValue="id"
              label="Organization type"
              placeholder={`-- Please select ${
                category?.length && subCategory?.length
                  ? "one"
                  : "category and sub-category first"
              } --`}
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={organizationType}
              onChange={organizationTypeHandler}
            />
            <AppChipsInput
              asRequired
              items={[]
                .concat(
                  categories
                    .map((c) => c.types.map((t) => t.items))
                    .flat(2)
                    .map((t) => t.name)
                )
                .concat(subCategories)}
              id="tags"
              label="Tags"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              value={tags}
              onChange={(e) => tagsHandler(e)}
            />
            <AppTextArea
              asRequired
              id="description"
              label="Description"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              rows="4"
              value={description?.join("\n")}
              onChange={descriptionHandler}
            />
            <AppTextArea
              id="landAcknowledgment"
              label="Land acknowledgment"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              rows="4"
              value={landAcknowledgement}
              onChange={landAcknowledgementHandler}
            />
            <h6 className="mt-7 uppercase font-semibold text-2xl">
              Availability
            </h6>
            <div className="lg:flex lg:gap-8">
              <AppPhoneInput
                id="phone"
                label="Phone"
                labelClass="font-bold font-quicksand"
                className="-mt-1"
                value={phone}
                onChange={(e) => phoneHandler(e)}
              />
              <AppInput
                id="website"
                label="Website"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={website}
                onChange={websiteHandler}
              />
            </div>
            <div className="lg:flex lg:gap-8">
              <AppInput
                id="email"
                label="Email"
                type="email"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={email}
                onChange={emailHandler}
              />
              <AppInput
                id="availability"
                label="Availability"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={availability}
                onChange={availabilityHandler}
              />
            </div>
            <h6 className="mt-7 uppercase font-semibold text-2xl">Media</h6>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Avatar visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert an avatar visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("avatar")}
              />
            </span>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Banner visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert a banner visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("banner")}
              />
            </span>
            <AppInput
              asRequired
              id="photoCredit"
              label="Photo credit"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={photoCredit}
              onChange={photoCreditHandler}
            />
            <AppInput
              asRequired
              id="imageSEOtitle"
              label="Image SEO title"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={imageSEOtitle}
              onChange={imageSEOtitleHandler}
            />
            <AppInput
              asRequired
              id="references"
              label="References"
              labelClass="font-bold font-quicksand"
              placeholder="Separate multiple reference with a comma (,) if any"
              className="bg-transparent"
              value={references.join(",")}
              onChange={referencesHandler}
            />
            <AppInput
              asRequired
              id="source"
              label="Source"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={source}
              onChange={sourceHandler}
            />
            <h6 className="mt-7 uppercase font-semibold text-2xl">Location</h6>
            <div className="grid gap-5 lg:grid-cols-2 lg:gap-8">
              <AppInput
                asRequired
                id="latitude"
                label="Latitude"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={latitude}
                onChange={latitudeHandler}
              />
              <AppInput
                asRequired
                id="longitude"
                label="Longitude"
                labelClass="font-bold font-quicksand"
                className="bg-transparent"
                value={longitude}
                onChange={longitudeHandler}
              />
            </div>
            <div className="lg:flex lg:gap-8">
              <AppSelect
                id="country"
                items={locations.map((ele) => ele.country)}
                itemText="name"
                itemValue="id"
                label="Country"
                placeholder="-- Please select one --"
                labelClass="font-semibold"
                className="border-2 border-gray-border rounded-lg"
                value={country}
                onChange={countryHandler}
              />
              <AppSelect
                id="city"
                items={locations.find((ele) => ele.country === country)?.city}
                itemText="name"
                itemValue="id"
                label="City"
                placeholder="-- Please select a country first --"
                labelClass="font-semibold"
                className="bg-transparent border-gray-border border-2 rounded-lg"
                value={city}
                onChange={cityHandler}
              />
            </div>
            {country === "Canada" && (
              <div className="lg:flex lg:gap-8">
                <AppSelect
                  id="province"
                  items={provinces}
                  itemText="name"
                  itemValue="id"
                  label="Province"
                  placeholder="-- Please select one --"
                  labelClass="font-semibold"
                  className="border-2 border-gray-border rounded-lg"
                  value={province}
                  onChange={provinceHandler}
                />
                <AppSelect
                  id="region"
                  items={
                    province && province.length > 0
                      ? regions
                          .find((ele) => ele.province === province)
                          .list.sort()
                      : []
                  }
                  itemText="name"
                  itemValue="id"
                  label="Region"
                  placeholder="-- Please select a province first --"
                  labelClass="font-semibold"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  value={region}
                  onChange={regionHandler}
                />
              </div>
            )}
            <AppInput
              id="streetAddress"
              label="Street address"
              labelClass="font-bold font-quicksand"
              className="bg-transparent"
              value={streetAddress}
              onChange={streetAddressHandler}
            />
            <button
              type="submit"
              className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3"
              onSubmit={submitHandler}
            >
              Create Marker
            </button>
          </>
        </form>
      </div>
    </AppModal>
  );
};

export default CreateMarker;
