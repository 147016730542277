import axios from "axios";
import { MAIN_ROUTE } from "../constants/api";

import {
  MARKER_LIST_REQUEST,
  MARKER_LIST_SUCCESS,
  MARKER_LIST_FAIL,
  MARKER_CREATE_REQUEST,
  MARKER_CREATE_SUCCESS,
  MARKER_CREATE_FAIL,
  MARKER_TOGGLE_REQUEST,
  MARKER_TOGGLE_SUCCESS,
  MARKER_TOGGLE_FAIL,
  MARKER_DELETE_REQUEST,
  MARKER_DELETE_SUCCESS,
  MARKER_DELETE_FAIL,
} from "../constants/mapConstants";

//
// get all markers
export const listMarkers = () => async (dispatch) => {
  try {
    dispatch({
      type: MARKER_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/markers`);

    dispatch({
      type: MARKER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKER_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//
// create new marker
export const createMarker = (marker) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARKER_CREATE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/markers`,
      { marker },
      config,
    );

    dispatch({
      type: MARKER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKER_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//
// toggle marker status
export const toggleMarker = (type, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARKER_TOGGLE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${MAIN_ROUTE}/${type}s/${id}${type === "partner" ? "/toggle" : ""}`,
      {},
      config,
    );

    dispatch({
      type: MARKER_TOGGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKER_TOGGLE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//
// delete marker
export const deleteMarker = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARKER_DELETE_REQUEST,
    });

    const {
      vrvUserLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`${MAIN_ROUTE}/markers/${id}`, config);

    dispatch({
      type: MARKER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKER_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
