import {
  GUIDE_LIST_REQUEST,
  GUIDE_LIST_SUCCESS,
  GUIDE_LIST_FAIL,
  GUIDE_LIST_RESET,
  GUIDE_ACCESS_REQUEST,
  GUIDE_ACCESS_SUCCESS,
  GUIDE_ACCESS_FAIL,
  GUIDE_ACCESS_RESET,
  ACCESS_LIST_REQUEST,
  ACCESS_LIST_SUCCESS,
  ACCESS_LIST_FAIL,
  ACCESS_LIST_RESET,
  ACCESS_DETAILS_REQUEST,
  ACCESS_DETAILS_SUCCESS,
  ACCESS_DETAILS_FAIL,
  ACCESS_DETAILS_RESET,
  ACCESS_DISABLE_REQUEST,
  ACCESS_DISABLE_SUCCESS,
  ACCESS_DISABLE_FAIL,
  ACCESS_DISABLE_RESET,
} from "../constants/guideConstants";
//

// all guides
export const guideListReducer = (state = {}, action) => {
  switch (action.type) {
    case GUIDE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GUIDE_LIST_SUCCESS:
      return {
        loading: false,
        guides: action.payload,
      };
    case GUIDE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GUIDE_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// request guide access
export const guideAccessRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case GUIDE_ACCESS_REQUEST:
      return {
        loading: true,
      };
    case GUIDE_ACCESS_SUCCESS:
      return {
        loading: false,
        access: action.payload,
      };
    case GUIDE_ACCESS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GUIDE_ACCESS_RESET:
      return {};
    default:
      return state;
  }
};

// all sessions
export const accessListReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCESS_LIST_REQUEST:
      return {
        loading: true,
      };
    case ACCESS_LIST_SUCCESS:
      return {
        loading: false,
        sessions: action.payload,
      };
    case ACCESS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ACCESS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// single session by code
export const sessionDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCESS_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case ACCESS_DETAILS_SUCCESS:
      return {
        loading: false,
        session: action.payload,
      };
    case ACCESS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ACCESS_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

// disable session
export const sessionDisableReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCESS_DISABLE_REQUEST:
      return {
        loading: true,
      };
    case ACCESS_DISABLE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ACCESS_DISABLE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ACCESS_DISABLE_RESET:
      return {};
    default:
      return state;
  }
};
