import React from "react";
import { Link } from "react-router-dom";

const LegalPublisherPolicy = () => {
  return (
    <main>
      <section className="grid gap-10 lg:gap-12">
        <div className="grid gap-5">
          <h1 className="text-3xl font-semibold uppercase">Publisher Policy</h1>
          <span className="text-xs font-bold">
            This Policy was last updated on 23 August 2023.
          </span>
        </div>
        <p>
          When you sign up to become a The VR Voyage publisher (“Publishing”),
          you agree to the following Publishing Policy (“Policy”). This Policy
          covers all details of the relationship between you and The VR Voyage
          platform. As a publisher, you are contracting directly with Forager
          Education Ltd. (“Forager Education”). All policies are concerning
          Province of British Columbia law.
        </p>
        <ol className="grid gap-10 lg:gap-12">
          <li>
            <p className="text-lg font-bold">1. PRODUCTION OBLIGATIONS</p>
            <div className="py-5 pl-5">
              <span className="font-bold">1.1.Publishers</span>
              <p>
                As a publisher, you are responsible for supplying accurate,
                fact-based information and providing content that is engaging
                and informative (“Experiences”).  All images, text, and video
                content to accompany Experiences will hereby be referred to as
                “Submitted Content.”
              </p>
            </div>
            <ol className="grid gap-5 pl-5">
              <li>
                <span>You represent and warrant that:</span>
                <ul className="list-disc container pl-7">
                  <li>you will provide up-to-date account information;</li>
                  <li>
                    you own or have the necessary licenses, rights, consents,
                    permissions, and authority to authorize Forager Education to
                    use your Submitted Content as set out in this Policy;
                  </li>
                  <li>
                    your Submitted Content does not plagiarize content from any
                    third party; and
                  </li>
                  <li>
                    you have the necessary experience and qualifications
                    (including education, training, Traditional Knowledge, and
                    skillsets) to produce Experiences in your specific product
                    offering.
                  </li>
                </ul>
              </li>
              <li>
                <span>You warrant that you will not:</span>
                <ul className="list-disc container pl-7">
                  <li>
                    publish any Experiences or provide any Submitted Content
                    that is misleading, incorrect, defamatory, or cannot be
                    backed up by evidence;
                  </li>
                  <li>
                    publish any Experiences or provide any Submitted Content
                    that discriminates or implies any superiority over anyone
                    based on race, ethnic origin, sexual orientation, gender
                    identity, religious belief, or marital status.
                  </li>
                  <li>
                    engage in any activity that would require the payment of
                    license fees to any third-party organization, including
                    royalties to performers, musicians, and the like;
                  </li>
                  <li>
                    interfere with or otherwise prevent other Publishers from
                    utilizing The VR Voyage marketplace to publish their own
                    Experiences; or
                  </li>
                  <li>
                    abuse Forager Education resources, including our support
                    services team.
                  </li>
                </ul>
              </li>
              <li>
                <span className="font-bold">1.2. Forager Education</span>
                <p>
                  The VR Voyage is a marketplace for Publisher Experiences. The
                  platform does not create its own content or that of content
                  for any Publisher. Such services can be contracted to any
                  third-party service provider or produced in-house. Special
                  rates are available with Forager Virtual, a division of
                  Forager Media Group, through the Forager Partnership Program
                  if you are unable to find a production company to create your
                  own Experiences, but as a separate agreement outside of this
                  Policy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold pb-5">
              2. PARTNER REVENUE SHARE OVERVIEW
            </p>
            <div className="grid gap-5">
              <p>
                A basic breakdown of The VR Voyage’s revenue share program is
                provided below. Partner revenue share is calculated on Net
                Revenue, which you can read more in our
                <Link
                  to="/legal/policies/revenue-share-policy"
                  className="font-bold underline text-green"
                >
                  {" "}
                  Revenue Share Policy
                </Link>
                . You will also find more detailed information on The VR
                Voyage’s relationship with our Publishers there. 
              </p>
              <p>
                There is no cost to publishing Experiences to The VR Voyage.
                Creation of all Experiences is up to each Publisher. The VR
                Voyage is not responsible for any remuneration of guides,
                performers, or any labour required for you to produce your
                Experiences. All those costs are solely your responsibility as
                the Publisher.
              </p>
            </div>
          </li>
          <li>
            <p className="text-lg font-bold pb-5">
              3. LICENSE TO FORAGER EDUCATION
            </p>
            <div className="grid gap-5">
              <p>
                You grant Forager International the rights to offer, market, and
                otherwise exploit your Experiences and Submitted Content. This
                includes the right to add captions for any additional languages
                or otherwise modify content to ensure accessibility.
              </p>
              <p>
                You can request that your Experiences be removed from The VR
                Voyage marketplace anytime. Requests for Removal must be made in
                writing to assigned Partner Managers with 30 days' notice to
                ensure all purchases are fulfilled before the deletion of
                Experiences.
              </p>
              <p>
                You grant Forager Education permission to use your name, logo,
                voice, likeness, and image in connection with marketing,
                promoting, and selling Experiences. You waive any rights of
                privacy, publicity, or other rights of a similar nature, to the
                extent permissible under applicable law.
              </p>
            </div>
          </li>
          <li>
            <p className="text-lg font-bold">4. TRUST & SAFETY</p>
            <div className="pt-5 pl-5">
              <span className="font-bold">4.1. Trust & Safety Policies</span>
              <p>
                You agree to abide by the quality standards outlined in the
                Publisher Obligations section above.
              </p>
              <p className="pt-5">
                We reserve the right to remove Experiences, suspend payouts,
                and/or ban Publishers for any reason at any time, without prior
                notice, including in cases where:
              </p>
            </div>
            <ol className="grid gap-5 pl-5">
              <li>
                <ul className="list-disc container pl-7">
                  <li>
                    a Publisher or Experience does not comply with our policies;
                  </li>
                  <li>
                    a Publisher engages in behaviour that might reflect
                    unfavourably on Forager Education and other Forager brands
                    or bring Forager Education and other Forager brands into
                    public disrepute, contempt, scandal, or ridicule; or
                  </li>
                  <li>
                    as determined by Forager Education at its sole discretion.
                  </li>
                </ul>
              </li>
              <li>
                <span className="font-bold">4.2. Customer Data</span>
                <p>
                  You do not have any direct relationship with customers. The
                  only customer data that will be shared with you is the
                  location of each customer for basic demographic purposes.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold">5. PRICING</p>
            <ol className="pt-5 pl-5">
              <li className="grid gap-5">
                <p>
                  <span className="font-bold block">5.1. Price Setting</span>
                  When publishing an Experience, you will be consulted on the
                  base price you wish to set. The VR Voyage will suggest a base
                  price of $0.20/minute, but the final decision is yours as
                  Publisher.
                </p>
                <p>
                  When a customer purchases an Experience using a foreign
                  currency, we will convert the base price into their applicable
                  currency using a foreign currency conversion rate set by
                  Forager Education. This rate is updated at the discretion of
                  Forager International, and those conversion rates may not be
                  identical to current market rates at the time of purchase.
                </p>
                <p>
                  You permit us to share your Experiences for free with our
                  employees and selected partners. You understand that you will
                  not receive compensation in these cases.
                </p>
              </li>
              <li className="pt-5">
                <span className="font-bold">5.2. Transaction Taxes</span>
                <p>
                  If customers purchase Experiences in a country having
                  legislation on digital transactions that require national,
                  state/provincial, or local taxes to be charged, The VR Voyage
                  platform will add these onto relevant purchases and remit
                  those taxes to the appropriate authorities.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold">6. PAYMENTS</p>
            <ol className="pt-5 pl-5">
              <li className="grid gap-5">
                <p>
                  <span className="font-bold block">
                    6.1. Receiving Payments
                  </span>
                  To receive payments promptly, you must provide accurate
                  contact information linked to the account and any relevant tax
                  numbers that may be needed. You are responsible for any taxes
                  owed on income generated through The VR Voyage.
                </p>
                <p>
                  Transactions made between the 16th of the previous month and
                  the 15th of the current month will be paid out on the last
                  Friday of the current month.
                </p>
              </li>
              <li className="pt-5">
                <span className="font-bold">6.2. Refunds</span>
                <p>
                  You acknowledge and agree that customers have the right to
                  receive a refund, as detailed in the{" "}
                  <Link
                    to="/legal/policies/refund-&-cancellation-policy"
                    className="text-green underline font-bold"
                  >
                    Refund Policy
                  </Link>
                  . You will not receive any revenue from Experiences that have
                  been granted a refund. If revenue has already been distributed
                  to you, that amount will be subtracted from the next month’s
                  payment.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold">7. TRADEMARKS</p>
            <ol className="grid gap-5">
              <p className="pt-5">
                While in a publishing partnership with Forager Education, you
                may use the trademarks of The VR Voyage in promotional materials
                with the approval of Forager Education.
              </p>
              <div>
                <span>You may:</span>
                <ol className="list-disc pl-7">
                  <li>
                    only use approved trademarks, with appropriate uses outlined
                    in the available brand guidelines;
                  </li>
                  <li>
                    only use The VR Voyage trademarks while promoting your
                    Experiences through our marketplace; and
                  </li>
                  <li>
                    remove any trademark materials upon the request of Forager
                    Education.
                  </li>
                </ol>
              </div>
              <div>
                <span>You may not:</span>
                <ol className="list-disc pl-7">
                  <li>
                    use our trademarks in any way that may degrade our public
                    reputation.
                  </li>
                </ol>
              </div>
            </ol>
          </li>
          <li>
            <p className="text-lg font-bold">8. TERMINATING THE PARTNERSHIP</p>
            <p className="pt-5">
              You retain the right to terminate your publishing partnership at
              any time. This will follow the 30-day notice period, as with
              removing specific Experiences outlined above.
            </p>
          </li>
          <li>
            <p className="text-lg font-bold">9. MISCELLANEOUS LEGAL TERMS</p>
            <ol className="pt-5 pl-5">
              <li>
                <span className="font-bold block">
                  9.1. Updating These Terms
                </span>
                <p>
                  Occasionally, we may update our Policy to further define our
                  relationships with our Publishers. Forager Education reserves
                  the right to modify or change this policy at anytime. When
                  changes to the Policy are made, you will be notified by the
                  email associated with your account. Changes will take effect
                  on the day the Policy is posted.
                </p>
                <p className="pt-5">
                  Continued use of The VR Voyage’s marketplace after changes are
                  posted shall mean each Publisher’s acceptance of the
                  modifications. The revised Policy shall supersede all previous
                  editions of the Policy.
                </p>
              </li>
              <li className="pt-5">
                <span className="font-bold">9.2. Survival</span>
                <p>
                  The following sections shall survive the expiration or
                  termination of these Terms: 3 (License to Forager Education)
                  and 10 (Miscellaneous Legal Terms).
                </p>
              </li>
            </ol>
          </li>
          <li className="grid gap-5">
            <p className="text-lg font-bold">10. HOW TO CONTACT US</p>
            <p>
              The best way to contact The VR Voyage is through your Partner
              Manager. If you are a prospective publisher or have not been
              assigned a Partner Manager, use{" "}
              <span className="text-green underline font-bold">
                partners@foragereducation.com
              </span>{" "}
              and an agent will be in touch. We are always looking for feedback
              on our services and would love to hear from you.
            </p>
          </li>
        </ol>
      </section>
    </main>
  );
};

export default LegalPublisherPolicy;
