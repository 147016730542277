import {
  TOUR_LIST_REQUEST,
  TOUR_LIST_SUCCESS,
  TOUR_LIST_FAIL,
  TOUR_LIST_RESET,
  TOUR_CREATE_REQUEST,
  TOUR_CREATE_SUCCESS,
  TOUR_CREATE_FAIL,
  TOUR_CREATE_RESET,
  TOUR_VALIDATE_REQUEST,
  TOUR_VALIDATE_SUCCESS,
  TOUR_VALIDATE_FAIL,
  TOUR_VALIDATE_RESET,
  TOUR_DETAILS_REQUEST,
  TOUR_DETAILS_SUCCESS,
  TOUR_DETAILS_FAIL,
  TOUR_DETAILS_RESET,
  TOUR_UPDATE_REQUEST,
  TOUR_UPDATE_SUCCESS,
  TOUR_UPDATE_FAIL,
  TOUR_UPDATE_RESET,
  TOUR_DELETE_REQUEST,
  TOUR_DELETE_SUCCESS,
  TOUR_DELETE_FAIL,
  TOUR_DELETE_RESET,
} from "../constants/tourConstants";

// all tours
export const tourListReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOUR_LIST_SUCCESS:
      return {
        loading: false,
        tours: action.payload,
      };
    case TOUR_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// add tour
export const tourCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_CREATE_REQUEST:
      return {
        loading: true,
      };
    case TOUR_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case TOUR_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// single tour details
export const tourDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case TOUR_DETAILS_SUCCESS:
      return {
        loading: false,
        tour: action.payload,
      };
    case TOUR_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

// request tour validation from admin
export const tourRequestValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_VALIDATE_REQUEST:
      return {
        loading: true,
      };
    case TOUR_VALIDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case TOUR_VALIDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_VALIDATE_RESET:
      return {};
    default:
      return state;
  }
};

// update tour
export const tourUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case TOUR_UPDATE_SUCCESS:
      return {
        loading: false,
        tour: action.payload,
      };
    case TOUR_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

// delete tour
export const tourDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOUR_DELETE_REQUEST:
      return {
        loading: true,
      };
    case TOUR_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case TOUR_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOUR_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
