import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { uploadMedia } from "../../actions/mediaActions";
import {
  updateUserInfo,
} from "../../actions/userActions";

import {
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../../constants/mediaConstants";
import {
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
} from "../../constants/userConstants";

import { requiredVisualsErrorMessage } from "../../constants/helpers";

import AppInputFile from "../global/form/AppInputFile";
import Loader from "../global/Loader";
import Message from "../global/Message";

const FinalizePartnerRegistration = ({ partner }) => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  // // todo: if not loggedIn, should navigate
  //   const navigate = useNavigate();

  const dispatch = useDispatch();

  const [mediaType, setMediaType] = useState("");

  const [banner, setBanner] = useState("");
  const [avatar, setAvatar] = useState("");
  const [aboutVisual, setAboutVisual] = useState("");
  const [valuesVisual, setValuesVisual] = useState("");
  const [exploringVisual, setExploringVisual] = useState("");

  const mediaTypeHandler = (type) => {
    setMediaType(type);
  };

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  const uploadHandler = (file) => {
    if (/jpg|jpeg|png/.test(file.type)) {
      const form = new FormData();
      form.append("image", file);
      dispatch(uploadMedia(form));
    } else {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: jpg and png)",
      });
      return;
    }
  };

  useEffect(() => {
    if (uploaded) {
      if (mediaType === "avatar") {
        setAvatar(uploaded);
      }

      if (mediaType === "banner") {
        setBanner(uploaded);
      }

      if (mediaType === "about") {
        setAboutVisual(uploaded);
      }

      if (mediaType === "values") {
        setValuesVisual(uploaded);
      }

      if (mediaType === "exploring") {
        setExploringVisual(uploaded);
      }

      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }

    // eslint-disable-next-line
  }, [uploaded]);

  const completeProfileHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (
        !banner?.length ||
        !avatar?.length ||
        !aboutVisual?.length ||
        !valuesVisual?.length ||
        !exploringVisual?.length
      ) {
        throw new Error(requiredVisualsErrorMessage);
      }
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: error.message,
      });
    }

    dispatch(
      updateUserInfo("partner", partner.slug, {
        banner,
        avatar,
        aboutVisual,
        valuesVisual,
        exploringVisual,
      }),
    );
  };

  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    userInfo: userInfoUpdate,
  } = userDetailsUpdate;

  useEffect(() => {
    // resetting state due to errors
    if (errorUpdate) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
      }, 5000);
    }
  }, [errorUpdate, dispatch]);

  useEffect(() => {
    if (userInfoUpdate) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [userInfoUpdate, loggedIn, partner, dispatch]);

  return (
    <>
      {(uploading || loadingUpdate) && <Loader />}
      {errorUpload && <Message error body={errorUpload} />}
      {errorUpdate && <Message error body={errorUpdate} />}
      {userInfoUpdate && (
        <Message
          info
          body={
            "Congratulations on making updates to your profile! Please wait a moment as the page reloads to reflect the latest updates."
          }
        />
      )}

      <div className="container mt-32 gap-24 pb-80">
        <div className="lg:col-span-4 flex flex-col gap-4">
          <form className="grid gap-5" onSubmit={completeProfileHandler}>
            <h1 className="mb-4 text-3xl uppercase font-semibold uppercase">
              hello
            </h1>
            <p className="mb-5">paragraph comes here</p>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Banner visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert a banner visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("banner")}
              />
            </span>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Avatar visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert an avatar visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("avatar")}
              />
            </span>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="About visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert about section visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("about")}
              />
            </span>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Values visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert values section visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("values")}
              />
            </span>
            <span className="flex gap-2 justify-center items-center">
              <AppInputFile
                asRequired
                label="Exploring visual"
                labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                placeholder="Insert exploring section visual"
                className="bg-transparent"
                onChange={uploadHandler}
                onClick={() => mediaTypeHandler("exploring")}
              />
            </span>
            <div className="w-fit rounded-full mt-6 border-2 border-transparent hover:border-denim mx-auto lg:mx-0 overflow-hidden">
              <button
                type="submit"
                className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 flex justify-center lg:ml-0"
              >
                <span className="block">save</span>
              </button>
            </div>
          </form>
        </div>
        <div className="hidden lg:block col-span-3">
          <div
            className="bg-cover bg-center bg-no-repeat h-full rounded-tl-2xl rounded-br-2xl"
            style={{
              backgroundImage: `url(${require("../../assets/img/login/create-password.png")})`,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FinalizePartnerRegistration;
