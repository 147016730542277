import React, { useEffect, useState } from "react";

const Warning = ({ body, callback, whenDeclined }) => {
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    // eslint-disable-next-line
  }, [body]);

  const close = (e) => {
    e.preventDefault();
    setDisplay("none");
    whenDeclined(null);
  };

  return (
    <div
      className="message"
      style={{
        backgroundColor: "transparent",
        display: `${display}`,
        zIndex: "500000",
        top: "0",
        position: "absolute",
      }}
    >
      <div className="popup-overlay"></div>
      <div className="container message-body">
        <div className="mx-auto border-4 bg-white border-yellow border-radius flex flex-col align-center justify-center items-center p-3 md:p-6 gap-2 md:gap-5 message-box">
          <span className="p-1 bg-yellow aspect-square rounded-full">
            <i
              className="iconify font-semibold text-5xl"
              data-icon="uil:exclamation"
            />
          </span>
          <div className="flex flex-col gap-3">
            <h2 className="font-semibold text-2xl text-center">
              ARE YOU SURE?
            </h2>
            <p className="mt-3 text-center">{body}</p>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row">
            <button
              className="uppercase lg:mr-0 font-semibold border-2 rounded-full lg:w-fit w-full hover:bg-green hover:text-white with-transition px-8 h-12 flex items-center justify-center font-oswald gap-3"
              onClick={close}
            >
              No, I must think about it
            </button>
            <button
              className="uppercase lg:mr-0 font-semibold bg-yellow hover:bg-magenta hover:text-white rounded-full lg:w-fit w-full px-8 h-12 flex with-transition items-center justify-center font-oswald gap-3"
              onClick={(e) => {
                callback();
                close(e);
              }}
            >
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warning;