import React from "react";
import { useDispatch } from "react-redux";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { placeOrder } from "../../actions/orderActions";

import StripeLogo from "../../assets/img/tour-screen/StripeLogo";
import { ORDER_PLACE_FAIL } from "../../constants/orderConstants";

const CheckoutForm = ({ tour }) => {
  const dispatch = useDispatch();

  const cardElementOptions = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "white",
        color: "white",
        fontFamily: `"Quicksand", sans-serif`,
        padding: "10px",
        border: "2px solid white",
        borderRadius: "50px",
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      if (elements === null) {
        throw new Error("Something went wrong!");
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (paymentMethod) {
        const { id: paymentId } = paymentMethod;

        dispatch(placeOrder(tour.slug, tour.partner, paymentId));
      } else if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      dispatch({ type: ORDER_PLACE_FAIL, payload: error.message });
    }
  };

  // useEffect(() => {
  //   if (order) {
  //     setTimeout(() => {
  //       setLanguage("");
  //     }, 5000);
  //   }
  // }, [order]);

  return (
    <div className="action-card">
      <form
        onSubmit={submitHandler}
        className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5 mb-16"
      >
        <div className="mb-6">
          <StripeLogo />
        </div>
        <div className="flex flex-col gap-2 capitalize text-white mb-4">
          <p className="text-green font-bold text-3xl mb-2">
            Amount: ${tour.price}
            {tour.price.toString().split(".").length === 1 ? ".00" : ""}
            {tour.price.toString().split(".")[1]?.length === 1 ? "0" : ""}
          </p>
          <p>Tour: {tour.title}</p>
          <p>Partner: {tour.partner.split("-").join(" ")}</p>
          {tour?.guide?.length ? <p>Guide: {tour.guide}</p> : ""}
        </div>
        {/* <AppSelect
          asRequired
          id="language"
          items={["English", "Français"]}
          itemText="name"
          itemValue="id"
          label="Tour Language"
          labelClass="font-bold font-quicksand text-white"
          className="border-[1px] border-white/50 mb-4"
          selectClassName="text-white focus:text-white"
          placeholder="-- Please select your preferred language --"
          value={language}
          onChange={languageHandler}
        /> */}
        <div className="stripe-input-container">
          <CardElement options={cardElementOptions} />
        </div>
        <button
          type="submit"
          disabled={!stripe || !elements}
          className="w-fit uppercase bg-white font-oswald font-bold hover:bg-gradient-primary hover:text-white flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full mt-4"
        >
          <span>Pay Now</span>
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
