import React from "react";
import Message from "../global/Message";
import { tourLoadingCautionMessage } from "../../constants/helpers";

const TourViewer = ({ tour, order, demoVersion }) => {
  return (
    <>
      <Message caution body={tourLoadingCautionMessage} />
      <iframe
        title={tour.title}
        src={demoVersion ? demoVersion : order?.tour?.src}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "clip",
          overflowClipMargin: "content-box",
        }}
        allow="fullscreen; accelerometer; autoplay; gyroscope; magnetometer; vr; camera; microphone; clipboard-write; encrypted-media; picture-in-picture"
      />
    </>
  );
};

export default TourViewer;
