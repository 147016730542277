import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { loginFromToken } from "../actions/userActions";

import Message from "../components/global/Message";
import Loader from "../components/global/Loader";
import { USER_LOGIN_RESET } from "../constants/userConstants";

const TokenLoginScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const {
    loading: loadingLogin,
    error: errorLogin,
    userInfo: alreadyLoggedIn,
  } = vrvUserLogin;

  const navigate = useNavigate();

  useEffect(() => {
    if (alreadyLoggedIn) {
      navigate(`/${alreadyLoggedIn.role}/profile/${alreadyLoggedIn.slug}`);
    }
  }, [alreadyLoggedIn, navigate]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (errorLogin) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
        navigate("/");
      }, 5000);
    }
  }, [errorLogin, dispatch, navigate]);

  const { role, token } = useParams();

  useEffect(() => {
    dispatch(loginFromToken(token, role));
  }, [role, token, dispatch]);

  return (
    <>
      {loadingLogin && <Loader />}
      {errorLogin && <Message error body={errorLogin} />}
    </>
  );
};

export default TokenLoginScreen;
