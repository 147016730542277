import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "../hooks/useForm";

import {
  passwordValidateErrorMessage,
  requiredFieldsErrorMessage,
  validateEmail,
  validatePassword,
} from "../constants/helpers";

import { getGoogleOAuthClientID } from "../actions/googleActions";
import { googleAuth } from "../actions/googleActions";
import { authWithGoogle, login } from "../actions/userActions";
import { USER_LOGIN_RESET, USER_LOGIN_FAIL } from "../constants/userConstants";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Source from "../assets/img/login/source.png";
import AppInput from "../components/global/form/AppInput";
import Message from "../components/global/Message";
import Loader from "../components/global/Loader";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors } = useForm();

  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { loading: loadingLogin, error: errorLogin, userInfo } = vrvUserLogin;

  const googleOAuth = useSelector((state) => state.googleOAuth);
  const {
    loading: loadingGoogleAuth,
    error: errorGoogleAuth,
    key,
  } = googleOAuth;

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "admin") {
        navigate("/");
      } else {
        navigate(`/${userInfo.role}/profile/${userInfo.slug}`);
      }
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (!key) {
      dispatch(getGoogleOAuthClientID());
    }
  }, [key, dispatch]);

  const continueWithGoogle = async (res) => {
    dispatch(authWithGoogle(res.credential));
  };

  useEffect(() => {
    if (key) {
      googleAuth(key, continueWithGoogle);
    }

    // eslint-disable-next-line
  }, [key]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const resetFields = () => {
    setEmail("");
    setPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0) {
      if (validateEmail(email) && validatePassword(password)) {
        dispatch(login("user", email, password));
        resetFields();
      } else {
        if (!validateEmail(email)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: "Invalid email format!",
          });
        }
        if (!validatePassword(password)) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: passwordValidateErrorMessage,
          });
        }
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: requiredFieldsErrorMessage,
      });
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorLogin) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
        googleAuth(key, continueWithGoogle);
      }, 5000);
    }

    // eslint-disable-next-line
  }, [errorLogin, dispatch]);

  return (
    <>
      {errorGoogleAuth && <Message error body={errorGoogleAuth} />}
      {errorLogin && <Message error body={errorLogin} />}
      {loadingGoogleAuth ? (
        <Loader />
      ) : loadingLogin ? (
        <Loader />
      ) : (
        !userInfo && (
          <MainLayout
            scrollNavClass="bg-gray"
            logo={Logo}
            textHover="text-green"
            textHoverWithScroll="text-green"
            styleBtnWithScroll="hover:text-denim hover:border-denim"
          >
            <NavLink to="/" className="lg:hidden block ml-4 mt-6">
              <img src={Logo} alt="Logo" className="w-48" />
            </NavLink>
            <div className="grid grid-cols-1 lg:grid-cols-3 container mt-16 md:mt-28 mb-24">
              <div className="col-span-1">
                <div className="mb-12">
                  <h1 className="mb-5 text-3xl uppercase font-semibold">
                    Welcome back
                  </h1>
                  <p>
                    New to The VR Voyage?
                    <Link
                      to="/register"
                      className="text-green hover:text-magenta pl-3 inline-flex font-semibold with-transition"
                    >
                      Sign up
                      <i
                        className="iconify my-auto p-1"
                        data-icon="ep:arrow-up-bold"
                        data-rotate="90deg"
                      />
                    </Link>
                  </p>
                </div>
                <form className="grid gap-4" onSubmit={submitHandler}>
                  <AppInput
                    id="email"
                    label="E-mail"
                    value={email}
                    onChange={emailHandler}
                    labelClass="text-denim/40"
                    className="bg-transparent"
                    error={errors.email}
                  />
                  <AppInput
                    id="password"
                    type="password"
                    label="Password"
                    value={password}
                    onChange={passwordHandler}
                    labelClass="text-denim/40"
                    className="bg-transparent"
                    error={errors.password}
                  />
                  <Link
                    to="/user/forgot-password"
                    className="font-semibold text-green hover:text-magenta pt-4 pb-8 with-transition w-fit"
                  >
                    Forgot password?
                  </Link>
                  <div className="mx-auto md:mx-0 w-full h-fit border-2 border-transparent hover:border-denim rounded-full with-transition">
                    <button
                      type="submit"
                      className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-full flex justify-center gap-5 with-transition"
                      onSubmit={submitHandler}
                    >
                      <span className="block">log in</span>
                    </button>
                  </div>
                  {/* <label
                    htmlFor="rememberMe"
                    className="flex items-center gap-2"
                  >
                    <input
                      {...register("rememberMe")}
                      type="checkbox"
                      id="rememberMe"
                      className="before:bg-green"
                    />
                    <span className="text-black">Remember me</span>
                  </label> */}
                  {key && (
                    <>
                      <div className="relative h-0.5 my-3 bg-denim/50">
                        <p className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 grid place-items-center w-7 aspect-square rounded-full bg-gray text-denim">
                          or
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="border-transparent border-2 hover:border-2 hover:border-denim rounded-full  hover:bg-transparent flex justify-center gap-3 btn-google-cta w-fit"
                        >
                          <div
                            className="w-full font-oswald font-semibold uppercase"
                            id="login-google"
                          ></div>
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
              <div className="hidden lg:block col-span-2 pl-20">
                <div
                  className="bg-cover bg-center bg-no-repeat w-full h-full rounded-tl-2xl rounded-br-2xl"
                  style={{ backgroundImage: `url(${Source})` }}
                />
              </div>
            </div>
          </MainLayout>
        )
      )}
    </>
  );
};

export default LoginScreen;
