import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useMap from "../hooks/useMap";

import { listMarkers } from "../actions/mapActions";
import { listUsers } from "../actions/userActions";
import { markersFromPartners } from "../constants/helpers";

// import MapCategoriesDrawer from "../components/map/MapCategoriesDrawer";
import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";

import MapSelectedMarkerInfo from "../components/map/MapSelectedMarkerInfo";

import MapView from "../components/map/MapView";

const MapScreen = () => {
  const dispatch = useDispatch();

  const markerList = useSelector((state) => state.markerList);
  const { loading: loadingMarkers, error: errorMarkers, markers } = markerList;

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  // const [categoryDrawer, setCategoryDrawer] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { createMap, addMarker } = useMap();
  const { selectedMarkerId } = useParams();

  // const toggleDrawer = () => {
  //   setCategoryDrawer(!categoryDrawer);
  // };

  const addMarkers = (markers) => {
    try {
      markers.forEach((marker) => {
        const mapMarker = addMarker(
          [
            Number(marker.gpsCoordinates.latitude),
            Number(marker.gpsCoordinates.longitude),
          ],
          {
            icon: { iconAnchor: [10, 30] },
            obj: marker,
          },
        );
        mapMarker.on("click", () => {
          setSelectedMarker(marker);
        });
      });
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    if (!markers) {
      dispatch(listMarkers());
    }
  }, [markers, dispatch]);

  useEffect(() => {
    if (!partners) {
      dispatch(listUsers("partner"));
    }
  }, [partners, dispatch]);

  useEffect(() => {
    if (markers && partners) {
      try {
        const activeMarkers = markers
          .filter((marker) => marker.enabled)
          .concat(markersFromPartners(partners));

        createMap("map", { center: [48.433494, -123.567963], zoom: 7 });
        addMarkers(activeMarkers);

        if (selectedMarkerId && activeMarkers) {
          const comingMarker = activeMarkers.find(
            (marker) => marker._id === selectedMarkerId,
          );
          if (comingMarker) {
            setSelectedMarker(comingMarker);
          }
        }
      } catch (error) {
        // console.log(error);
      }
    }
    // eslint-disable-next-line
  }, [markers, dispatch]);

  return (
    <>
      <MainLayout
        scrollNavClass="bg-gray"
        logo={Logo}
        hiddeFooter
        textHover="text-green"
        textHoverWithScroll="text-green"
        styleBtnWithScroll="hover:text-denim hover:border-denim"
      >
        {loadingMarkers && <Loader />}
        {loadingPartners && <Loader />}
        {errorMarkers && <Message error body={errorMarkers} />}
        {errorPartners && <Message error body={errorPartners} />}
        <NavLink to="/" className="lg:hidden absolute ml-4 mt-5">
          <img src={Logo} alt="Logo" className="w-48" />
        </NavLink>
        <section className="pt-20">
          {/* <MapCategoriesDrawer
            isOpen={categoryDrawer}
            toggleDrawer={toggleDrawer}
          /> */}
          <div className="relative h-[87vh] w-screen z-0">
            {/* <div className="flex justify-around absolute z-20 top-5 right-4 w-fit px-3 bg-white rounded-lg shadow-lg">
              <div className="flex gap-4 py-2">
                <button onClick={toggleDrawer}>
                  <i
                    className="iconify"
                    data-icon="uil:sliders-v-alt"
                    data-rotate="90deg"
                  />
                </button>
                <input
                  type="text"
                  className="h-12 outline-none"
                  placeholder="Search category"
                />
              </div>
              <button>
                <i
                  className="iconify"
                  data-icon="ion:search"
                  data-rotate="90deg"
                />
              </button>
            </div> */}
            {markers && partners && (
              <MapView
                markers={markers.concat(markersFromPartners(partners)).filter(marker => marker.enabled)}
                className="z-10 h-full"
                onSelectMarker={(marker) => setSelectedMarker(marker)}
              />
            )}
          </div>
        </section>
        {selectedMarker && (
          <MapSelectedMarkerInfo
            selectedMarker={selectedMarker}
            onClose={() => setSelectedMarker(null)}
          />
        )}
      </MainLayout>
    </>
  );
};

export default MapScreen;
