import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listUsers } from "../../../actions/userActions";
import { uploadMedia } from "../../../actions/mediaActions";
import { createTour, listTours } from "../../../actions/tourActions";
import { USER_LIST_RESET } from "../../../constants/userConstants";
import {
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_RESET,
} from "../../../constants/mediaConstants";
import {
  TOUR_CREATE_FAIL,
  TOUR_CREATE_RESET,
} from "../../../constants/tourConstants";

import {
  generateSlug,
  requiredFieldsErrorMessage,
  requiredTagsErrorMessage,
  requiredVisualsErrorMessage,
  validateRequiredFields,
} from "../../../constants/helpers";
import locations from "../../../data/locations.json";
import { tourTypes } from "../../../constants/tours";
import { subCategories } from "../../../constants/categories";

import AppModal from "../../global/AppModal";
import AppInput from "../../global/form/AppInput";
import AppSelect from "../../global/form/AppSelect";
import AppTextArea from "../../global/form/AppTextArea";
import AppChipsInput from "../../global/form/AppChipsInput";
import AppInputFile from "../../global/form/AppInputFile";

export const CreateTour = () => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const {
    loading: uploading,
    error: errorUpload,
    file: uploaded,
  } = mediaUpload;

  const tourCreate = useSelector((state) => state.tourCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: created,
  } = tourCreate;

  useEffect(() => {
    dispatch(listUsers("partner"));
  }, [dispatch]);

  //

  const [partner, setPartner] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [guide, setGuide] = useState("");
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [tags, setTags] = useState([]);
  const [cover, setCover] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const [demoUrl, setDemoUrl] = useState("");
  // const [enFullUrl, setEnFullUrl] = useState("");
  // const [enDemoUrl, setEnDemoUrl] = useState("");
  // const [frFullUrl, setFrFullUrl] = useState("");
  // const [frDemoUrl, setFrDemoUrl] = useState("");
  const [price, setPrice] = useState(0.0);
  const [note, setNote] = useState("");

  const partnerHandler = (e) => {
    setPartner(e.target.value);
  };

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const subtitleHandler = (e) => {
    setSubtitle(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const guideHandler = (e) => {
    setGuide(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const tagsHandler = (e) => {
    setTags(e);
  };

  const fullUrlHandler = (e) => {
    setFullUrl(e.target.value);
  };

  const demoUrlHandler = (e) => {
    setDemoUrl(e.target.value);
  };

  // const enFullUrlHandler = (e) => {
  //   setEnFullUrl(e.target.value);
  // };

  // const enDemoUrlHandler = (e) => {
  //   setEnDemoUrl(e.target.value);
  // };

  // const frFullUrlHandler = (e) => {
  //   setFrFullUrl(e.target.value);
  // };

  // const frDemoUrlHandler = (e) => {
  //   setFrDemoUrl(e.target.value);
  // };

  const priceHandler = (e) => {
    setPrice(Number(e.target.value));
  };

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  //

  useEffect(() => {
    if (partners?.length > 0 && partner?.length > 0) {
      const found = partners?.find((ptr) => ptr.slug === partner);
      setCategory(found?.category);
      setTags(found?.tags);
    }
  }, [partners, partner]);

  // action handlers
  const resetFields = () => {
    setPartner("");
    setTitle("");
    setSubtitle("");
    setDescription("");
    setType("");
    setGuide("");
    setCategory("");
    setCity("");
    setCountry("");
    setTags([]);
    setCover("");
    setFullUrl("");
    setDemoUrl("");
    // setEnFullUrl("");
    // setEnDemoUrl("");
    // setFrFullUrl("");
    // setFrDemoUrl("");
    setPrice(0.0);
    setNote("");
  };

  const uploadHandler = (file) => {
    if (/jpg|jpeg|png/.test(file.type)) {
      const form = new FormData();
      form.append("image", file);
      dispatch(uploadMedia(form));
    } else {
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        payload: "Invalid file format! (Supported formats: jpg and png)",
      });
      return;
    }
  };

  useEffect(() => {
    // resetting state due to errors
    if (errorUpload) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorUpload, dispatch]);

  useEffect(() => {
    if (uploaded) {
      setCover(uploaded);
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [uploaded, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      // validating
      if (!cover?.length) {
        throw new Error(requiredVisualsErrorMessage);
      }

      if (
        !validateRequiredFields([
          partner,
          title,
          description,
          type,
          country,
          city,
          category,
          fullUrl,
          demoUrl,
          // enFullUrl,
          // enDemoUrl,
          // frFullUrl,
          // frDemoUrl,
        ]) ||
        !price
      ) {
        throw new Error(requiredFieldsErrorMessage);
      }

      if (!tags?.length) {
        throw new Error(requiredTagsErrorMessage);
      }

      const tour = {
        partner,
        title,
        subtitle,
        slug: generateSlug(title),
        description,
        type,
        category,
        guide,
        location: { country, city },
        tags,
        cover,
        urls: {
          fullUrl,
          demoUrl,
          // enFullUrl,
          // enDemoUrl,
          // frFullUrl,
          // frDemoUrl,
        },
        price,
        reviews: [],
        numPurchased: 0,
        numReviews: 0,
        rating: 0,
        note,
      };

      dispatch(createTour(tour));
    } catch (error) {
      dispatch({
        type: TOUR_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

  //
  useEffect(() => {
    // resetting state due to errors
    if (errorCreate) {
      setTimeout(() => {
        dispatch({ type: TOUR_CREATE_RESET });
      }, 5000);
    }
  }, [errorCreate, dispatch]);

  useEffect(() => {
    // reset fields after success
    if (created) {
      setTimeout(() => {
        dispatch({ type: TOUR_CREATE_RESET });
        resetFields();
        dispatch(listTours());
      }, 5000);
    }
  }, [created, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  return (
    <AppModal
      activator={
        <button
          type="button"
          className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta hover:bg-denim rounded-full w-full lg:w-48 h-12 flex items-center justify-center font-oswald gap-3 with-transition"
        >
          Create New Tour
        </button>
      }
      styleModal="h-full lg:h-3/4 pb-14"
      styleLabelClose="sticky w-10 h-10 rounded-full"
      styleIconClose="text-denim text-3xl bg-gray rounded-full p-1"
      withLoading={uploading || loadingCreate || loadingPartners}
    >
      <div>
        <p className="font-bold text-lg lg:sticky absolute top-4 left-4 lg:pl-6 z-[1]">
          Create New Tour
        </p>
        {!errorPartners && partners?.length > 0 ? (
          <form
            className="px-4 lg:px-24 grid gap-6 pt-10 lg:pt-5"
            onSubmit={submitHandler}
          >
            <AppSelect
              asRequired
              id="partner"
              items={partners.map((partner) => partner.slug)}
              itemText="name"
              itemValue="id"
              label="Partner"
              labelClass="font-bold font-quicksand"
              className="bg-transparent border-gray-border border-2 rounded-lg"
              placeholder="-- Please select one --"
              value={partner}
              onChange={partnerHandler}
            />
            {partner?.length > 0 && (
              <>
                <AppInputFile
                  asRequired
                  label="Cover"
                  placeholder="Add photo library"
                  labelClass="bg-gray-border rounded-[10px] px-4 py-3"
                  className="bg-transparent"
                  onChange={uploadHandler}
                />
                <AppInput
                  asRequired
                  id="title"
                  label="Title"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={title}
                  onChange={titleHandler}
                />
                <AppInput
                  id="subtitle"
                  label="Subtitle (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={subtitle}
                  onChange={subtitleHandler}
                />
                <AppTextArea
                  asRequired
                  id="description"
                  label="Description"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={description}
                  onChange={descriptionHandler}
                />
                <div className="grid lg:grid-cols-2 gap-5 lg:gap-8">
                  <AppSelect
                    asRequired
                    id="type"
                    items={tourTypes}
                    itemText="name"
                    itemValue="id"
                    label="Type"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={type}
                    onChange={typeHandler}
                  />
                  <AppInput
                    id="guide"
                    label="Guide (optional)"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent"
                    value={guide}
                    onChange={guideHandler}
                  />
                  <AppSelect
                    asRequired
                    id="country"
                    items={locations.map((ele) => ele.country)}
                    itemText="name"
                    itemValue="id"
                    label="Country"
                    placeholder="-- Please select one --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={country}
                    onChange={countryHandler}
                  />
                  <AppSelect
                    asRequired
                    id="city"
                    items={
                      locations.find((ele) => ele.country === country)?.city
                    }
                    itemText="name"
                    itemValue="id"
                    label="City"
                    placeholder="-- Please select a country first --"
                    labelClass="font-bold font-quicksand"
                    className="bg-transparent border-gray-border border-2 rounded-lg"
                    value={city}
                    onChange={cityHandler}
                  />
                </div>
                <AppChipsInput
                  items={subCategories}
                  asRequired
                  id="tags"
                  label="Tags"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  value={tags}
                  onChange={(e) => tagsHandler(e)}
                />
                <AppInput
                  asRequired
                  id="enFullUrl"
                  label="Full tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={fullUrl}
                  onChange={fullUrlHandler}
                />
                <AppInput
                  asRequired
                  id="enDemoUrl"
                  label="Demo tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={demoUrl}
                  onChange={demoUrlHandler}
                />
                {/* <AppInput
                  asRequired
                  id="enFullUrl"
                  label="EN Full tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={enFullUrl}
                  onChange={enFullUrlHandler}
                />
                <AppInput
                  asRequired
                  id="enDemoUrl"
                  label="EN Demo tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={enDemoUrl}
                  onChange={enDemoUrlHandler}
                />
                <AppInput
                  asRequired
                  id="frFullUrl"
                  label="FR Full tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={frFullUrl}
                  onChange={frFullUrlHandler}
                />
                <AppInput
                  asRequired
                  id="frDemoUrl"
                  label="FR Demo tour URL"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={frDemoUrl}
                  onChange={frDemoUrlHandler}
                /> */}
                <AppInput
                  asRequired
                  id="price"
                  label="Price (CAD)"
                  type="number"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent"
                  value={price}
                  onChange={priceHandler}
                />
                <AppTextArea
                  id="note"
                  label="Note (optional)"
                  labelClass="font-bold font-quicksand"
                  className="bg-transparent border-gray-border border-2 rounded-lg"
                  rows="4"
                  value={note}
                  onChange={noteHandler}
                />
                <button
                  type="submit"
                  className="order-2 lg:order-3 uppercase font-bold text-white bg-magenta rounded-full w-full lg:w-48 py-3 flex items-center justify-center font-oswald gap-3"
                  onSubmit={submitHandler}
                >
                  Create New Tour
                </button>
              </>
            )}
          </form>
        ) : (
          <p className="px-4 lg:px-24 pt-10 lg:pt-5">
            Please add some partners first in order to be able to create and
            link related tours to them.
          </p>
        )}
      </div>
    </AppModal>
  );
};
