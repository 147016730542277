import React from "react";

const Copyrights = () => {
  return (
    <div className="col-span-full mt-5 mb-10">
      <span className="block w-full border-t-[1px] border-white mb-2 mx-auto" />
      <p className="lg:flex lg:justify-between px-3 lg:px-0 text-xs text-center">
        Copyright © {new Date().getFullYear()}, forager group holdings ltd.
        <span className="block">
          created by
          <span className="font-semibold">
            {" "}
            forager | <span className="text-orange">digital</span>
          </span>
        </span>
      </p>
    </div>
  );
};

export default Copyrights;
