import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getOrderDetails } from "../actions/orderActions";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { getTourDetails } from "../actions/tourActions";
import {
  TOUR_DETAILS_RESET,
  TOUR_UPDATE_RESET,
} from "../constants/tourConstants";
import { getUserDetails } from "../actions/userActions";
import { USER_DETAILS_RESET } from "../constants/userConstants";

import { generateSlug } from "../constants/helpers";

import MainLayout from "../layouts/MainLayout";
import Logo from "../assets/logos/logo-green.svg";
import Message from "../components/global/Message";
import Loader from "../components/global/Loader";
import PartnerInfo from "../components/partners/PartnerInfo";
import ReviewForm from "../components/global/form/ReviewForm";
import Counter from "../components/tours/Counter";
import Rating from "../components/global/Rating";
import TourViewer from "../components/tours/TourViewer";

const PostPurchaseTourScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const { userInfo: loggedIn } = vrvUserLogin;

  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [loggedIn, navigate]);

  const orderDetails = useSelector((state) => state.orderDetails);
  const {
    loading: loadingOrderDetails,
    error: errorOrderDetails,
    order,
  } = orderDetails;

  useEffect(() => {
    // check if only the buyer is authorized
    if (order && loggedIn) {
      if (loggedIn.role !== "admin" && order.user.id !== loggedIn._id) {
        navigate("/login");
      }
    }
  }, [order, loggedIn, navigate]);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetails(id));

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorOrderDetails) {
      setTimeout(() => {
        dispatch({ type: ORDER_DETAILS_RESET });
      }, 5000);
    }

    // eslint-disable-next-line
  }, [errorOrderDetails, dispatch]);

  const tourDetails = useSelector((state) => state.tourDetails);
  const {
    loading: loadingTourDetails,
    error: errorTourDetails,
    tour,
  } = tourDetails;

  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    if (order) {
      if (order.isValid) {
        setRemaining(
          new Date(order.createdAt).getTime() +
            172800000 -
            new Date().getTime(),
        );
      }
      if (!tour) {
        dispatch(getTourDetails(generateSlug(order.tour?.title)));
      }
    }
  }, [order, tour, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorTourDetails) {
      setTimeout(() => {
        dispatch({ type: TOUR_DETAILS_RESET });
      }, 5000);
    }
  }, [errorTourDetails, dispatch]);

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    userInfo: partner,
  } = userDetails;

  useEffect(() => {
    if (tour) {
      dispatch(getUserDetails("partner", tour.partner));
    }
  }, [tour, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorUserDetails) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorUserDetails, dispatch]);

  const tourUpdate = useSelector((state) => state.tourUpdate);
  const {
    loading: loadingTourUpdate,
    error: errorTourUpdate,
    tour: updated,
  } = tourUpdate;

  useEffect(() => {
    // resetting state due to errors
    if (errorTourUpdate) {
      setTimeout(() => {
        dispatch({ type: TOUR_UPDATE_RESET });
      }, 5000);
    }
  }, [errorTourUpdate, dispatch]);

  useEffect(() => {
    if (updated && order) {
      setTimeout(() => {
        dispatch(getTourDetails(generateSlug(order.tour?.title)));
      }, 5000);
    }
  }, [order, updated, dispatch]);

  //

  const [tourViewer, setTourViewer] = useState(false);

  const tourViewerHandler = (e) => {
    e.preventDefault();
    setTourViewer(!tourViewer);
  };

  return tourViewer ? (
    <>
      {tour && order && (
        <>
          <TourViewer tour={tour} order={order} />
          <button
            className="uppercase bg-white hover:bg-gradient-primary hover:text-white flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full absolute bottom-[40px] left-[40px] font-semibold font-oswald gap-3 flex justify-center md:justify-start"
            onClick={tourViewerHandler}
          >
            <i className="iconify text-xl" data-icon="bxs:left-arrow" />
          </button>
        </>
      )}
    </>
  ) : (
    <MainLayout
      logo={Logo}
      navClass="text-white"
      textHover="text-green"
      styleBtn="hover:text-white hover:border-white"
    >
      <div className="min-h-[100vh]">
        {loadingUserDetails && <Loader />}
        {loadingTourUpdate && <Loader />}
        {errorOrderDetails && <Message error body={errorOrderDetails} />}
        {errorTourDetails && <Message error body={errorTourDetails} />}
        {errorUserDetails && <Message error body={errorUserDetails} />}
        {errorTourUpdate && <Message error body={errorTourUpdate} />}
        {updated && (
          <Message
            info
            body={"Thank you for your review! We appreicate your feedback!"}
          />
        )}
        {loadingOrderDetails ? (
          <Loader />
        ) : loadingTourDetails ? (
          <Loader />
        ) : !errorOrderDetails && !errorTourDetails && order && tour ? (
          <>
            {!order.isValid && <Message error body="Order is expired!" />}
            <>
              <div
                className="app-hero gradient-y"
                style={{
                  "--bg-image": `url(${tour.cover})`,
                }}
              >
                <div>
                  <span className="md:hidden absolute top-5 left-4">
                    <i
                      className="iconify text-white text-2xl"
                      data-icon="material-symbols:arrow-back-ios-new-rounded"
                    />
                  </span>
                  <div className="container text-white font-semibold pt-80 lg:pt-[32rem]">
                    <div className="md:-translate-y-28">
                      <div className="flex gap-1">
                        {tour.tags?.map((tag, index) => (
                          <span
                            key={index}
                            className="text-xs border-2 rounded-full px-4 py-1"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                      <h1 className="text-2xl md:text-3xl mt-7 uppercase">
                        {tour.title}
                      </h1>
                      {tour.subtitle?.length > 0 && (
                        <p className="text-lg font-oswald mt-1 capitalize">
                          {tour.subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-denim">
                <div className="md:-translate-y-28">
                  <div className="container flex justify-center md:justify-start gap-3 pt-5 md:pt-6 font-semibold font-oswald">
                    {order.isValid ? (
                      <>
                        <button
                          className="uppercase bg-white hover:bg-gradient-primary hover:text-white flex items-center gap-1 px-5 lg:px-6 py-3 rounded-full"
                          onClick={tourViewerHandler}
                        >
                          <i
                            className="iconify text-3xl"
                            data-icon="material-symbols:play-arrow-rounded"
                            id="en"
                          />
                          <span>Launch tour</span>
                        </button>
                        <p className="font-oswald uppercase text-yellow text-2xl font-semibold my-auto">
                          {remaining ? (
                            <Counter value={remaining}></Counter>
                          ) : (
                            <>
                              <span className="text-magenta">
                                Order expired!
                              </span>
                              {setTimeout(() => {
                                if (remaining && order.isValid) {
                                  window.location.reload();
                                }
                              }, 5000)}
                            </>
                          )}
                        </p>
                      </>
                    ) : (
                      <span className="font-oswald uppercase text-2xl font-semibold my-auto text-magenta">
                        Order expired!
                      </span>
                    )}
                  </div>
                  <div className="container mt-4 md:mt-6 flex items-center gap-5 font-oswald text-2xl">
                    <p className="text-white text-2xl md:text-3xl">
                      ${tour.price}
                      {tour.price.toString().split(".").length === 1
                        ? ".00"
                        : ""}
                      {tour.price.toString().split(".")[1]?.length === 1
                        ? "0"
                        : ""}
                    </p>
                    {tour.reviews?.length ? (
                      <Rating
                        rating={
                          tour.reviews?.reduce(
                            (acc, review) => acc + review.rating,
                            0,
                          ) / tour.reviews?.length
                        }
                        numReviews={tour.reviwes?.length}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-denim">
                <div className="container flex flex-col md:flex-row gap-5 md:gap-32 text-white py-12 lg:pb-20 lg:pt-0 lg:-mt-9">
                  <div className="text-sm md:order-2">
                    <p>
                      Partner:
                      <span className="font-bold"> {order.partner?.title}</span>
                    </p>
                    <p>
                      Type:
                      <span className="font-bold"> {tour.type} Tour</span>
                    </p>
                    <p>
                      Location:
                      <span className="font-bold">
                        {" "}
                        {tour.location?.city}, {tour.location?.country}
                      </span>
                    </p>
                    {tour.guide?.length > 0 && (
                      <p>
                        Guide:
                        <span className="font-bold capitalize">
                          {" "}
                          {tour.guide}
                        </span>
                      </p>
                    )}
                  </div>
                  <p className="md:w-3/5">{tour.description}</p>
                </div>
              </div>
            </>
            {partner && <PartnerInfo partner={partner} />}
            <aside className="container mt-24 lg:mt-12 lg:mb-28">
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-2 grid gap-8 md:gap-y-8 mb-24 xl:mb-0 md:pr-32">
                  {tour.reviews?.length ? (
                    <h2 className="mt-24 md:mt-0 md:mb-4 text-3xl text-green uppercase font-semibold">
                      reviews
                    </h2>
                  ) : (
                    <></>
                  )}
                  {order.user.id === loggedIn._id ? (
                    <>
                      {!tour.reviews?.length ||
                      !tour.reviews
                        ?.map((review) => review.user)
                        ?.includes(loggedIn._id) ? (
                        <ReviewForm tour={tour} user={loggedIn} />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {tour.reviews?.map((review, index) => (
                    <div key={index} className="flex gap-5">
                      <div
                        className="w-20 h-20 aspect-square rounded-full bg-cover bg-norepeat bg-center"
                        style={{
                          backgroundImage: `url(${review.avatar})`,
                        }}
                      />
                      <div className="flex flex-col gap-1">
                        <p className="font-bold text-lg">{review.name}</p>
                        <Rating rating={review.rating} />
                        <p>{review.comment}</p>
                        <span className="font-semibold text-gray-border text-xs">
                          {review.date
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>
          </>
        ) : (
          <></>
        )}
      </div>
    </MainLayout>
  );
};

export default PostPurchaseTourScreen;
