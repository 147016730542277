export const categories = [
  {
    name: "Cultural Organizations",
    types: [
      {
        items: [
          {
            name: "Museums & Ecomuseums",
            icon: "mdi:museum",
          },
          {
            name: "Arts Galleries & Organizations",
            icon: "material-symbols:photo",
          },
          {
            name: "Botanic Gardens",
            icon: "maki:garden",
          },
          {
            name: "Zoos & Aquariums",
            icon: "maki:zoo",
          },
          {
            name: "Science Centres",
            icon: "mingcute:science-fill",
          },
          {
            name: "Cultural Centres",
            icon: "mdi:house",
          },
          {
            name: "Archives",
            icon: "mdi:archive",
          },
          {
            name: "Libraries",
            icon: "ph:books-fill",
          },
        ],
      },
    ],
  },
  {
    name: "Cultural Heritage",
    types: [
      {
        name: "Tangible Heritage",
        items: [
          {
            name: "Religious Sites & Sanctuaries",
            icon: "fa6-solid:hands-praying",
            styleIcon: "text-xl",
          },
          {
            name: "Archaeological Sites",
            icon: "emojione-monotone:pick",
          },
          {
            name: "Monuments & Public Artwork",
            icon: "mingcute:monument-fill",
          },
          {
            name: "Historical Sites",
            icon: "mingcute:pisa-tower-fill",
          },
        ],
      },
      {
        name: "Intangible Heritage",
        items: [
          {
            name: "Performing Arts",
            icon: "fa6-solid:masks-theater",
            styleIcon: "text-xl",
          },
          {
            name: "Culinary Traditions",
            icon: "mdi:cutlery-fork-knife",
          },
          {
            name: "Events & Festivals",
            icon: "ic:baseline-festival",
          },
          {
            name: "Games & Sports",
            icon: "fluent:sport-hockey-24-filled",
          },
          {
            name: "Oral Traditions & Expressions",
            icon: "icon-park-solid:people-speak",
          },
          {
            name: "Film & TV",
            icon: "mdi:film-open",
          },
          {
            name: "Historical Event",
            icon: "material-symbols:history-edu",
          },
          {
            name: "Cultural Figures",
            icon: "material-symbols:person-4-rounded",
          },
        ],
      },
    ],
  },
  {
    name: "Natural Heritage",
    types: [
      {
        items: [
          {
            name: "Parks & Gardens",
            icon: "mdi:museum",
          },
          {
            name: "Wetlands",
            icon: "material-symbols:water-rounded",
          },
          {
            name: "Alpine",
            icon: "fa6-solid:mountain-sun",
            styleIcon: "text-xl",
          },
          {
            name: "Tundra",
            icon: "mingcute:snow-line",
          },
          {
            name: "Forests",
            icon: "ph:tree-evergreen-fill",
          },
        ],
      },
    ],
  },
  {
    name: "Indigenous Culture",
    types: [
      {
        items: [
          {
            name: "Art Galleries",
            icon: "material-symbols:photo",
          },
          {
            name: "Cultural Centres",
            icon: "mdi:house",
          },
          {
            name: "Performing Arts",
            icon: "fa6-solid:masks-theater",
            styleIcon: "text-xl",
          },
          {
            name: "Oral Traditions & Expressions",
            icon: "icon-park-solid:people-speak",
          },
        ],
      },
    ],
  },
];

export const subCategories = [
  "Museum",
  "Ecomuseum",
  "Art Gallery",
  "Botanic Gardens",
  "Zoo",
  "Aquarium",
  "Science Centre",
  "Cultural Centre",
  "Archive",
  "Library",
  "Religious Site",
  "Sanctuary",
  "Archaeological Site",
  "Monument",
  "Public Artwork",
  "Historical Site",
  "Performing Art",
  "Culinary Tradition",
  "Event",
  "Festival",
  "Game",
  "Sport",
  "Oral Tradition",
  "Expression",
  "Film",
  "TV",
  "Historical Event",
  "Cultural Figure",
  "Natural Park",
  "Garden",
  "Wetland",
  "Alpine",
  "Tundra",
  "Forest",
  "Indigenous Arts Gallery",
  "Indigenous Cultural Centre",
  "Indigenous Performing Art",
  "Indigenous Oral Tradition",
  "Indigenous Expression",
];

export const affiliateCategories = [
  "Hospitality Business",
  "Airline",
  "Cruise Line",
  "Bus Company",
  "Travel Agency",
  "Tourism Centre",
  "Travel Site",
  "Other",
];
