export const TOUR_LIST_REQUEST = "TOUR_LIST_REQUEST";
export const TOUR_LIST_SUCCESS = "TOUR_LIST_SUCCESS";
export const TOUR_LIST_FAIL = "TOUR_LIST_FAIL";
export const TOUR_LIST_RESET = "TOUR_LIST_RESET";

export const TOUR_CREATE_REQUEST = "TOUR_CREATE_REQUEST";
export const TOUR_CREATE_SUCCESS = "TOUR_CREATE_SUCCESS";
export const TOUR_CREATE_FAIL = "TOUR_CREATE_FAIL";
export const TOUR_CREATE_RESET = "TOUR_CREATE_RESET";

export const TOUR_VALIDATE_REQUEST = "TOUR_VALIDATE_REQUEST";
export const TOUR_VALIDATE_SUCCESS = "TOUR_VALIDATE_SUCCESS";
export const TOUR_VALIDATE_FAIL = "TOUR_VALIDATE_FAIL";
export const TOUR_VALIDATE_RESET = "TOUR_VALIDATE_RESET";

export const TOUR_DETAILS_REQUEST = "TOUR_DETAILS_REQUEST";
export const TOUR_DETAILS_SUCCESS = "TOUR_DETAILS_SUCCESS";
export const TOUR_DETAILS_FAIL = "TOUR_DETAILS_FAIL";
export const TOUR_DETAILS_RESET = "TOUR_DETAILS_RESET";

export const TOUR_UPDATE_REQUEST = "TOUR_UPDATE_REQUEST";
export const TOUR_UPDATE_SUCCESS = "TOUR_UPDATE_SUCCESS";
export const TOUR_UPDATE_FAIL = "TOUR_UPDATE_FAIL";
export const TOUR_UPDATE_RESET = "TOUR_UPDATE_RESET";

export const TOUR_DELETE_REQUEST = "TOUR_DELETE_REQUEST";
export const TOUR_DELETE_SUCCESS = "TOUR_DELETE_SUCCESS";
export const TOUR_DELETE_FAIL = "TOUR_DELETE_FAIL";
export const TOUR_DELETE_RESET = "TOUR_DELETE_RESET";
