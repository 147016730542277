import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteUser, listUsers } from "../../../actions/userActions";
import {
  USER_DELETE_RESET,
  USER_LIST_RESET,
} from "../../../constants/userConstants";

import CreatePartner from "./CreatePartner";
import { HorizontalCards } from "../components/HorizontalCards";
import { AppDropdown } from "../../global/AppDropdown";
import AdminHeaderLayout from "../components/AdminHeaderLayout";
// import { AppPagination } from "../../global/AppPagination";
import Message from "../../global/Message";
import Loader from "../../global/Loader";
import Warning from "../../global/Warning";

const ManagePartners = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.hash.slice(1) === "partners") {
      dispatch(listUsers("partner"));
    }
  }, [location, dispatch]);

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingPartners,
    error: errorPartners,
    users: partners,
  } = userList;

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
  }, [dispatch]);

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: deleting,
    error: errorDelete,
    success: deleted,
  } = userDelete;

  const deletePartnerHandler = (e) => {
    e.preventDefault();

    dispatch(deleteUser("partner", e.target.id));
  };

  const [warning, setWarning] = useState(null);

  const showDeleteWarning = (e) => {
    e.preventDefault();
    setWarning({
      body: `Are you sure you want to delete ${
        partners.find((partner) => partner.slug === e.target.id).title
      }?`,
      callback: () => {
        deletePartnerHandler(e);
      },
      whenDeclined: setWarning,
    });
  };

  //

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { error: errorUpload } = mediaUpload;

  useEffect(() => {
    // resetting state due to errors
    if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
      }, 5000);
    }
  }, [errorDelete, dispatch]);

  useEffect(() => {
    // reset state after success
    if (deleted) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
        dispatch(listUsers("partner"));
      }, 5000);
    }
  }, [deleted, dispatch]);

  useEffect(() => {
    // resetting state due to errors
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  const userCreate = useSelector((state) => state.userCreate);
  const { error: errorCreate, message: created } = userCreate;

  useEffect(() => {
    if (created) {
      setTimeout(() => {
        dispatch(listUsers("partner"));
      }, 5000);
    }
  }, [created, dispatch]);

  return (
    <section>
      {errorPartners && <Message error body={errorPartners} />}
      {errorDelete && <Message error body={errorDelete} />}
      {errorUpload && <Message error body={errorUpload} />}
      {errorCreate && <Message error body={errorCreate} />}
      {created && <Message info body={"Partner created successfully!"} />}
      {/*  */}
      {warning && (
        <Warning
          body={warning.body}
          callback={warning.callback}
          whenDeclined={() => {
            setWarning(null);
          }}
        />
      )}
      {deleted && <Message info body={"Partner deleted successfully!"} />}

      <AdminHeaderLayout
        title="Manage Partners"
        // placeholder="Search Partner"
        button={<CreatePartner />}
        // pagination={
        //   partners ? (
        //     <AppPagination totalItems={partners.length} itemsPerPage={10} />
        //   ) : null
        // }
      />
      {loadingPartners ? (
        <Loader />
      ) : deleting ? (
        <Loader />
      ) : (
        !errorDelete &&
        !deleted && (
          <>
            {partners?.length ? (
              <div className="grid gap-2 mb-8">
                {partners.map((partner) => (
                  <HorizontalCards
                    key={partner._id}
                    info={{
                      img: partner.avatar,
                      url: `/partner/profile/${partner.slug}`,
                      name: partner.title,
                    }}
                    options={
                      <AppDropdown
                        activator={
                          <i className="iconify" data-icon="tabler:dots" />
                        }
                        className="dropdown-end"
                      >
                        <ul className="w-40 p-1">
                          {/* <li>
                        <button id={partner._id} onClick={editPartner}>
                          Edit Partner
                        </button>
                      </li> */}
                          <li className="hover:bg-magenta hover:font-bold hover:text-white w-full rounded-md">
                            <button
                              id={partner.slug}
                              onClick={showDeleteWarning}
                            >
                              Delete Partner
                            </button>
                          </li>
                        </ul>
                      </AppDropdown>
                    }
                  />
                ))}
              </div>
            ) : (
              <p>No partners found!</p>
            )}
          </>
        )
      )}
    </section>
  );
};

export default ManagePartners;
