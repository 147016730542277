import React from "react";

const AdminHeaderLayout = ({
  title,
  placeholder,
  button,
  styleSearch,
  onSearch,
  pagination,
}) => {
  return (
    <>
      <div className="flex items-center flex-col lg:flex-row gap-4 mb-5">
        <h3 className="order-1 uppercase text-2xl mb-3 lg:mb-0 lg:mr-auto font-semibold">
          {title}
        </h3>
        {placeholder && (
          <div
            className={`my-1 order-3 lg:order-2 flex justify-between items-center w-full lg:w-[275px] lg:mx-0 bg-white overflow-hidden rounded-[10px] p-3 ${styleSearch}`}
          >
            <input
              type="text"
              placeholder={placeholder}
              className="h-full w-full placeholder:text-denim border-transparent outline-none"
              onChange={(ev) => onSearch?.(ev.target.value)}
            />
            <i className="iconify font-bold" data-icon="octicon:search-16" />
          </div>
        )}
        {button}
      </div>
      {pagination && (
        <div className="flex justify-center lg:justify-end mb-5">
          {pagination}
        </div>
      )}
    </>
  );
};

export default AdminHeaderLayout;
