import React, { useState } from "react";
import AppModal from "../global/AppModal";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const MapSelectedMarkerInfo = ({ selectedMarker, onClose }) => {
  const { md } = useBreakpoints();
  const [isExpanded, setIsExpanded] = useState(false || !md);

  return (
    <AppModal
      isOpen
      onClose={() => onClose()}
      styleIconClose={`text-white`}
      styleLabelClose={`${selectedMarker.role ? "text-green" : "text-white"}`}
    >
      <div className="overflow-auto md:overflow-hidden md:relative flex flex-col h-full">
        <div
          className={`absolute w-full z-10 top-0 px-4 py-4 ${
            selectedMarker.role ? "bg-green" : "bg-magenta"
          }`}
        >
          <i className={`iconify text-white`} data-icon="mdi:museum" />
        </div>
        <div className="flex-grow">
          <div
            className="relative bg-cover bg-center bg-no-repeat h-96 md:h-full w-full"
            style={{ backgroundImage: `url(${selectedMarker.banner})` }}
          />
          <div
            className="absolute w-full h-full top-0 left-0"
            style={{
              backgroundImage:
                `linear-gradient(180deg, #1a1e29 -10%, transparent 20%)`, mixBlendMode: "multiply"
            }}
          ></div>
          <div
            className={`overflow-hidden md:absolute bottom-0 w-full px-5 lg:px-7 py-5 rounded-t-2xl bg-white`}
          >
            <div
              className="flex justify-between cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h6
                className={`uppercase font-semibold ${
                  selectedMarker.role ? "text-green" : "text-magenta"
                } text-2xl`}
              >
                {selectedMarker.title}
              </h6>
              <div className="hidden md:flex items-center gap-4">
                <span className={`uppercase font-oswald font-bold text-denim`}>
                  {isExpanded ? "collapse" : "expand"}
                </span>
                <span
                  className={`with-transition ${
                    isExpanded ? "rotate-180" : " rotate-0"
                  } `}
                >
                  <i
                    className={`iconify text-lg text-denim`}
                    data-icon="ep:arrow-up-bold"
                  />
                </span>
              </div>
            </div>
            <div
              className={`overflow-hidden with-transition ${
                isExpanded ? "md:max-h-screen" : "max-h-0"
              }`}
            >
              <div className={`overflow-auto md:max-h-96 mt-5 pb-6 text-denim`}>
                <p className="mb-5 font-semibold text-lg">
                  {selectedMarker.subtitle}
                </p>
                <p className="py-4">{selectedMarker.description}</p>
                <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-y-5 lg:gap-20">
                  <p>
                    <strong className="block">Photo Credit:</strong>
                    {selectedMarker.title}
                  </p>
                  <p>
                    <strong className="block">Information Provided By:</strong>
                    {selectedMarker.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default MapSelectedMarkerInfo;
