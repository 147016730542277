import {
  CONTACTMAIL_CREATE_REQUEST,
  CONTACTMAIL_CREATE_SUCCESS,
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
  CONTACTMAIL_DELETE_REQUEST,
  CONTACTMAIL_DELETE_SUCCESS,
  CONTACTMAIL_DELETE_FAIL,
  CONTACTMAIL_DELETE_RESET,
  CONTACTMAIL_LIST_REQUEST,
  CONTACTMAIL_LIST_SUCCESS,
  CONTACTMAIL_LIST_FAIL,
  CONTACTMAIL_LIST_RESET,
  CONTACTMAIL_DETAILS_REQUEST,
  CONTACTMAIL_DETAILS_SUCCESS,
  CONTACTMAIL_DETAILS_FAIL,
  CONTACTMAIL_DETAILS_RESET,
} from "../constants/contactMailConstants";

// all contactmails
export const contactMailListReducer = (
  state = { loading: true, contactMails: [] },
  action,
) => {
  switch (action.type) {
    case CONTACTMAIL_LIST_REQUEST:
      return {
        loading: true,
        contactMails: [],
      };
    case CONTACTMAIL_LIST_SUCCESS:
      return {
        loading: false,
        contactMails: action.payload,
      };
    case CONTACTMAIL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONTACTMAIL_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// all contactmails
export const contactMailDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACTMAIL_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_DETAILS_SUCCESS:
      return {
        loading: false,
        contactMail: action.payload,
      };
    case CONTACTMAIL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONTACTMAIL_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const contactMailCreateReducer = (
  state = {
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case CONTACTMAIL_CREATE_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_CREATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CONTACTMAIL_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONTACTMAIL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const contactMailDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACTMAIL_DELETE_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONTACTMAIL_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONTACTMAIL_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
