import React from "react";
import OurStoryContent from "../components/our-story/OurStoryContent";
import DenimLayout from "../layouts/DenimLayout";

const OurStory = () => {
  return (
    <DenimLayout>
      <OurStoryContent />
    </DenimLayout>
  );
};

export default OurStory;
