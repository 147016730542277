import React, { forwardRef, useRef, useState } from "react";

const AppInputFile = (
  {
    id,
    label,
    placeholder,
    type,
    error,
    className,
    labelClass,
    asRequired,
    onChange,
    audio,
    ...rest
  },
  ref,
) => {
  const inputFileRef = useRef();
  const input = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  function onSelectFile(event) {
    const [file] = event.target.files;
    input.current.value = file;
    setSelectedFile(file);
    onChange?.(file);
  }

  return (
    <label
      htmlFor={id}
      className="grid grid-cols-1 gap-1 relative w-full cursor-pointer"
    >
      <p className="flex">
        <label className={`font-semibold`}>{label}</label>
        <span className={`text-green pl-1 ${asRequired ? "block" : "hidden"}`}>
          *
        </span>
      </p>
      <div
        className={`flex justify-between relative with-transition ${labelClass} ${
          asRequired && "required-help"
        } ${error && "text-red-500"}`}
      >
        <span className="break-all">{selectedFile?.name || placeholder}</span>
        <i
          className={`iconify ${audio ? "text-sm" : ""}`}
          data-icon={
            audio ? "ant-design:sound-filled" : "material-symbols:image"
          }
        />
      </div>
      <input
        ref={input}
        readOnly
        className={`
          hidden w-full py-1.5 px-2 focus:outline-none caret-current with-transition
          ${error && "border-red-500"}
          ${className ? className : ""}
        `}
        onClick={() => inputFileRef.current.click()}
      />
      <input
        ref={inputFileRef}
        id={id}
        type="file"
        className="hidden focus:outline-none"
        {...rest}
        onInput={onSelectFile}
      />
      <span className="empty:hidden shake text-red-500">{error}</span>
    </label>
  );
};

export default forwardRef(AppInputFile);
