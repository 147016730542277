import React from "react";

import { useBreakpoints } from "../../hooks/useBreakpoints";

import cityDescriptions from "../../constants/cityDescriptions";
import daysOfWeek from "../../constants/daysOfWeek";

import { TabContent, TabLabel, TabWrapper } from "../global/AppTabs";
import MapView from "../map/MapView";

const AffiliateShowcase = ({ affiliate, activateHandler, deleteHandler }) => {
  const { lg } = useBreakpoints();

  return affiliate ? (
    <div className="relative grid grid-cols-12 grid-rows-auto container lg:min-h-[75rem] xl:min-h-[88rem] mt-9 lg:mt-28 overflow-hidden">
      <section className="col-span-12 lg:col-span-8 mb-20">
        <div className="mb-3 flex flex-col">
          <div
            className="rounded-t-2xl mt-11 h-32 md:h-[305px] w-full z-0 relative bg-cover bg-center bg-no-repeat bg-magenta"
            style={{
              backgroundImage: `url(${require("../../assets/img/activacion/woman.jpg")})`,
            }}
          ></div>
          <div
            className="w-24 lg:w-40 h-24 lg:h-40 mb-2 lg:mb-5 -mt-6 lg:-mt-20 ml-auto md:ml-6 mr-6 lg:mr-auto rounded-full relative bg-cover bg-center bg-no-repeat border-4 border-white z-0"
            style={{
              backgroundImage: `url(${require("../../assets/img/profile/profile.jpg")})`,
            }}
          ></div>
          <div className="flex gap-2">
            <button
              className="uppercase font-semibold text-white bg-green rounded-full hover:bg-transparent border-2 border-transparent hover:border-denim hover:text-denim w-full md:w-fit h-12 flex items-center justify-center font-oswald with-transition px-1 md:px-8"
              onClick={activateHandler}
            >
              <i
                className="iconify text-xl mr-0.5 md:mr-2"
                data-icon="codicon:activate-breakpoints"
              />
              activate profile
            </button>
            <button
              className="uppercase font-semibold text-white bg-magenta rounded-full hover:bg-transparent border-2 border-transparent hover:border-denim hover:text-denim w-full md:w-fit h-12 flex items-center justify-center font-oswald with-transition px-1 md:px-8"
              onClick={deleteHandler}
            >
              <i
                className="iconify text-xl mr-0.5 md:mr-2"
                data-icon="quill:discard"
              />
              discard request
            </button>
          </div>
        </div>
        <div>
          <h1 className="mb-2 text-3xl font-semibold uppercase">
            {affiliate.title}
          </h1>
          <p className="mb-5 text-lg font-semibold">
            {affiliate.location?.city}, {affiliate.location?.country}
          </p>
        </div>
      </section>
      <div className="col-span-12 lg:col-span-8">
        <TabWrapper
          value="about"
          renderAllContent={lg}
          tabClass="w-1/2 px-5 py-2 font-semibold uppercase rounded-lg with-transition"
          inactiveTabClass="opacity-50"
          activeTabClass="opacity-100 bg-yellow scale-105 shadow-lg"
        >
          <div className="flex lg:hidden justify-between mb-14 rounded-lg border-2 border-gray">
            <TabLabel name="about">
              <h6>about</h6>
            </TabLabel>
            <TabLabel name="interact">
              <h6>interact</h6>
            </TabLabel>
          </div>
          <div>
            <section>
              <TabContent name="about">
                <TabWrapper
                  value="about-about"
                  tabClass="uppercase font-semibold"
                  inactiveTabClass="opacity-50 hover:text-green"
                >
                  <div className="flex gap-6 mb-7">
                    <TabLabel name="about-about">
                      <h6>about</h6>
                    </TabLabel>
                    <TabLabel name="about-location">
                      <h6>location</h6>
                    </TabLabel>
                  </div>
                  <TabContent name="about-location">
                    <p className="mb-7 text-lg font-semibold">Our Location</p>
                    <p className="mb-7">{affiliate.location?.city}</p>
                    <p className="mb-7">
                      {
                        cityDescriptions.find(
                          (ele) => ele.name === affiliate.location?.city,
                        )?.description
                      }
                    </p>
                  </TabContent>
                  <p className="pr-12 mb-16">
                    To learn more about {affiliate.title}, visit their website:{" "}
                    <a
                      href={affiliate.info?.website}
                      className="text-green font-semibold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {affiliate.info?.website}
                    </a>
                  </p>
                </TabWrapper>
              </TabContent>
            </section>
            <TabContent
              name="interact"
              className="lg:absolute lg:top-0 lg:right-8 xl:right-24 lg:w-3/12 flex h-full pb-10"
            >
              <aside className="overflow-auto lg:px-1 h-full lg:rounded-lg lg:shadow-lg pb-5 z-0">
                <MapView
                  defaultCenter={[
                    affiliate.location?.coordinates?.latitude,
                    affiliate.location?.coordinates?.longitude,
                  ]}
                  single
                  zoom={12}
                  className="rounded-t-3xl w-full h-48"
                  alt=""
                />
                <div className="lg:px-5">
                  <div className="grid grid-cols-2 gap-3 my-6">
                    <p className="col-span-2 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        First Name
                      </span>
                      {affiliate.firstName}
                    </p>
                    <p className="col-span-2 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Last Name
                      </span>
                      {affiliate.lastName}
                    </p>
                    <p className="col-span-2 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Organization
                      </span>
                      {affiliate.title}
                    </p>
                    <p className="col-span-1 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        City
                      </span>
                      {affiliate.location?.city}
                    </p>
                    {affiliate.location?.region?.length > 0 && (
                      <p className="col-span-1 font-semibold">
                        <span className="block text-xs text-neutral-400">
                          Region
                        </span>
                        {affiliate.location?.region}
                      </p>
                    )}
                    {affiliate.location?.province?.length > 0 && (
                      <p className="col-span-1 font-semibold">
                        <span className="block text-xs text-neutral-400">
                          Province/Territory
                        </span>
                        {affiliate.location?.province}
                      </p>
                    )}
                    <p className="col-span-1 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Country
                      </span>
                      {affiliate.location?.country}
                    </p>
                    <p className="col-span-2 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Category
                      </span>
                      {affiliate.category}
                    </p>
                    <p className="col-span-2 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Email
                      </span>
                      {affiliate.email}
                    </p>
                    <p className="col-span-1 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        Phone
                      </span>
                      +{affiliate.info?.phone}
                    </p>
                  </div>
                  <div className="grid grid-cols-1 gap-1 relative w-full">
                    <span className="block text-xs text-neutral-400">
                      Availability
                    </span>
                    <div className="flex gap-2">
                      {affiliate.info?.availability?.daysOfWeek?.map(
                        (day, index) => (
                          <button
                            key={index}
                            className={`cursor-default pointer-events-none w-9 col-span-6 aspect-square py-1.5 border-2 rounded-lg border-${
                              day ? "green" : "gray"
                            }-border font-bold ${
                              day ? "bg-green text-white" : ""
                            }`}
                          >
                            {daysOfWeek[index].text}
                          </button>
                        ),
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-3 my-6">
                    <p className="col-span-1 font-semibold">
                      <span className="block text-xs text-neutral-400">
                        From
                      </span>
                      {Number(
                        affiliate.info?.availability?.timeFrom.split(":")[0],
                      ) === 0
                        ? "12"
                        : Number(
                            affiliate.info?.availability?.timeFrom.split(
                              ":",
                            )[0],
                          ) > 12
                        ? Number(
                            affiliate.info?.availability?.timeFrom.split(
                              ":",
                            )[0],
                          ) - 12
                        : Number(
                            affiliate.info?.availability?.timeFrom.split(
                              ":",
                            )[0],
                          )}
                      {":"}
                      {
                        affiliate.info?.availability?.timeFrom.split(":")[1]
                      }{" "}
                      {Number(
                        affiliate.info?.availability?.timeTo.split(":")[0],
                      ) === 0
                        ? "12"
                        : Number(
                            affiliate.info?.availability?.timeFrom?.split(
                              ":",
                            )[0],
                          ) > 11
                        ? "PM"
                        : "AM"}
                    </p>
                    <p className="col-span-1 font-semibold">
                      <span className="block text-xs text-neutral-400">To</span>
                      {Number(
                        affiliate.info?.availability?.timeTo.split(":")[0],
                      ) > 12
                        ? Number(
                            affiliate.info?.availability?.timeTo.split(":")[0],
                          ) - 12
                        : Number(
                            affiliate.info?.availability?.timeTo.split(":")[0],
                          )}
                      {":"}
                      {affiliate.info?.availability?.timeTo.split(":")[1]}{" "}
                      {Number(
                        affiliate.info?.availability?.timeTo?.split(":")[0],
                      ) > 11
                        ? "PM"
                        : "AM"}
                    </p>
                  </div>
                  <div className="mx-auto mt-6 w-44 h-fit border-2 border-transparent hover:border-denim rounded-full overflow-hidden">
                    <a
                      href={affiliate.info?.website}
                      className="font-semibold text-white hover:text-denim uppercase app-primary-button animate-bg h-14 w-44 mx-auto flex justify-center gap-5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="block">Visit their website</span>
                    </a>
                  </div>
                </div>
              </aside>
            </TabContent>
          </div>
        </TabWrapper>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AffiliateShowcase;
