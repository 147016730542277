import {
  GOOGLE_AUTH_REQUEST,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_RESET,
} from "../constants/googleConstants";

// auth
export const googleOAuthReducer = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_AUTH_REQUEST:
      return {
        loading: true,
      };
    case GOOGLE_AUTH_SUCCESS:
      return {
        loading: false,
        key: action.payload,
      };
    case GOOGLE_AUTH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GOOGLE_AUTH_RESET:
      return {};
    default:
      return state;
  }
};
