export const areArraysEqual = (array1 = [], array2 = []) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (const array1Elem of array1) {
    if (array2.includes(array1Elem) === false) {
      return false;
    }
  }
  for (const array2Elem of array2) {
    if (array1.includes(array2Elem) === false) {
      return false;
    }
  }
  return true;
};

export const removeAccents = (str = "") => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};
