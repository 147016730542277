import React from "react";
import MainLayout from "./MainLayout";
import Logo from "../assets/logos/logo-denim-green.svg";
import { NavLink } from "react-router-dom";

const WhiteLayout = ({ children }) => {
  return (
    <MainLayout
      scrollNavClass="bg-gray"
      logo={Logo}
      textHover="text-green"
      textHoverWithScroll="text-green"
      styleBtn="hover:text-denim"
      styleBtnWithScroll="hover:text-denim hover:border-denim"
    >
      <div className="relative">
        <NavLink to="/" className="lg:hidden absolute ml-4 mt-5">
          <img src={Logo} alt="Logo" className="w-48" />
        </NavLink>
      </div>
      {children}
    </MainLayout>
  );
};

export default WhiteLayout;
