import React from "react";
import { NavLink } from "react-router-dom";

import AppCarousel from "../global/AppCarousel";
import AppPartnerCard from "../global/AppPartnerCard";

const ListingCarousel = ({ collection, heading, type, link, styleOptions }) => {
  return collection ? (
    <div className={`h-fit ${styleOptions ? styleOptions : ""}`}>
      <div>
        <div className="flex flex-col lg:flex-row lg:pl-[19.3rem] gap-8 text-white px-4 mb-6">
          {heading && (
            <h2 className="uppercase text-3xl uppercase font-semibold text-center">
              {heading} ({collection?.length})
            </h2>
          )}
          {link && (
            <div className="ml-auto pr-32 hidden lg:flex">
              <NavLink
                to={link}
                className="group/arrow font-semibold text-white mx-auto w-40 flex items-center gap-4 hover:gap-7 with-transition hover:text-green "
              >
                Explore all
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 1.5L8.75 9L1.25 16.5"
                    className="stroke-white group-hover/arrow:stroke-green"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </NavLink>
            </div>
          )}
        </div>
        <AppCarousel withArrows className="z-[1]">
          <div className="hidden lg:block w-72" />
          {collection?.length &&
            collection.map((ele, index) => (
              <AppPartnerCard
                key={index}
                link={
                  type === "partner"
                    ? `/${type}/profile/${ele?.slug}`
                    : type === "tour"
                    ? `/${type}/${ele?.slug}/demo`
                    : ""
                }
                img={ele?.banner || ele?.cover}
                title={ele?.title}
                text={`${(type === "partner" && ele.role === "partner"
                  ? ele?.about[0]
                  : type === "tour"
                  ? ele?.description
                  : ""
                )?.substring(0, 110)}... Read more`}
                className={`bg-${
                  index % 2 ? "green" : "yellow"
                } ring-transparent hover:ring-${
                  index % 2 ? "green" : "yellow"
                }`}
                ringColor={`ring-${index % 2 ? "green" : "yellow"}`}
              />
            ))}
          <div className="w-28 h-32" />
        </AppCarousel>
      </div>
    </div>
  ) : null;
};

export default ListingCarousel;
