import React from "react";

const HeaderPages = ({
  titlePage,
  styleTitlePage,
  bg,
  img,
  styleImg,
  children,
}) => {
  return (
    <div className="bg-gradiant">
      <div className="container">
        <h1
          className={`font-title uppercase font-semibold text-center xl:text-start text-white pt-28 lg:pt-32 xl:pb-8 ${
            styleTitlePage || ""
          }`}
        >
          {titlePage}
        </h1>
      </div>
      <div
        className={`bg-cover bg-center bg-no-repeat bg-gray ${
          bg ? "h-32" : "h-0"
        }`}
        style={{ backgroundImage: `url(${bg})` }}
      >
        {img ? (
          <div className="bg-gradient h-full">
            <div className="container relative h-full flex">
              {children}
              <img
                src={img}
                alt=""
                className={`absolute bottom-0 left-1/2 lg:left-[95%] -translate-x-1/2 lg:-translate-x-full ${
                  styleImg || ""
                }`}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeaderPages;
