import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

import {
  activateUserRegiteration,
  getUserDetails,
  loginFromToken,
} from "../actions/userActions";
import Loader from "../components/global/Loader";
import Message from "../components/global/Message";
import {
  USER_ACTIVATE_RESET,
  // USER_DETAILS_RESET,
} from "../constants/userConstants";
import WhiteLayout from "../layouts/WhiteLayout";

const ActivationScreen = () => {
  const vrvUserLogin = useSelector((state) => state.vrvUserLogin);
  const {
    loading: loadingLogin,
    error: errorLogin,
    userInfo: alreadyLoggedIn,
  } = vrvUserLogin;

  const userActivate = useSelector((state) => state.userActivate);
  const {
    loading: loadingActivate,
    error: errorActivate,
    userInfo: activated,
  } = userActivate;

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    userInfo: found,
  } = userDetails;

  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (alreadyLoggedIn?.role === "admin") {
      navigate("/");
    }
  }, [alreadyLoggedIn, navigate]);

  useEffect(() => {
    // visiting link from email notification
    if (!alreadyLoggedIn && !activated) {
      dispatch(getUserDetails("user", jwt_decode(params.token).slug));
    }
  }, [alreadyLoggedIn, activated, params.token, dispatch]);

  useEffect(() => {
    // already verified
    if (!alreadyLoggedIn && !activated && found) {
      // dispatch({ type: USER_DETAILS_RESET });
      navigate(`/`);
    }
  }, [alreadyLoggedIn, activated, found, dispatch, navigate]);

  useEffect(() => {
    // revisiting the link while verified and logged in already
    if (alreadyLoggedIn && !activated) {
      // dispatch({ type: USER_DETAILS_RESET });
      navigate(`/user/profile/${alreadyLoggedIn.slug}`);
    }
  }, [alreadyLoggedIn, activated, found, dispatch, navigate]);

  useEffect(() => {
    // logged in after verification
    if (alreadyLoggedIn && activated) {
      setTimeout(() => {
        dispatch({ type: USER_ACTIVATE_RESET });
        navigate(`/user/profile/${alreadyLoggedIn.slug}`);
      }, 5000);
    }
  }, [alreadyLoggedIn, activated, dispatch, navigate]);

  // resetting state due to errors
  useEffect(() => {
    if (errorDetails) {
      setTimeout(() => {
        // dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorDetails, dispatch]);

  useEffect(() => {
    if (errorActivate) {
      setTimeout(() => {
        dispatch({ type: USER_ACTIVATE_RESET });
      }, 5000);
    }
  }, [errorActivate, dispatch]);

  useEffect(() => {
    // automatic login after verification
    if (activated) {
      dispatch(loginFromToken(activated));
    }
  }, [activated, dispatch]);

  useEffect(() => {}, [activated, alreadyLoggedIn]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (params.token) {
      dispatch(activateUserRegiteration(params.token));
    }
  };

  return (
    <>
      {errorActivate && <Message error body={errorActivate} />}
      {errorLogin && <Message error body={errorLogin} />}
      {activated && (
        <Message
          info
          body={
            "Congratulations! You made it! Please wait. We're logging you in!"
          }
        />
      )}
      {loadingDetails || loadingActivate || loadingLogin ? (
        <Loader />
      ) : (
        !alreadyLoggedIn && (
          <WhiteLayout>
            <button onClick={submitHandler}>click me if you can</button>
            <div className="container mt-52 lg:mt-40 mb-64 w-fit mx-auto text-center grid gap-5 lg:gap-8">
              <h1 className="font-title font-light uppercase">
                {activated ? "You" : "One step"}
                <strong className="text-green font-semibold">
                  {" "}
                  {activated ? " made it!" : "closer!"}
                </strong>
              </h1>
              <img
                src={require(activated
                  ? "../assets/img/activacion/woman-vr.jpg"
                  : "../assets/img/activacion/woman.jpg")}
                alt=""
                className="border-radius with-transition lg:w-1/2 mx-auto"
              />
              <p>
                Just one more step until your account is activated. Click the
                button below and start exploring!
              </p>
              {!activated ? (
                <button
                  type="submit"
                  className={`font-semibold text-white hover:text-denim uppercase h-14 w-fit flex justify-center gap-5 with-transition mx-auto bg-magenta hover:bg-transparent flex justify-center items-center px-14 rounded-full border-2 border-transparent hover:border-denim ${
                    loadingActivate ? "opacity-50" : "opacity-100 animate-bg"
                  }`}
                  onClick={submitHandler}
                >
                  {loadingActivate ? "verifying..." : "verify"}
                </button>
              ) : (
                <></>
              )}
              {activated && loadingLogin && (
                <span
                  className={`uppercase mx-auto font-semibold text-white bg-magenta rounded-full w-44 h-14 flex items-center justify-center font-oswald gap-3 cursor-pointer ${
                    loadingLogin
                      ? "opacity-50"
                      : "opacity-100 app-primary-button animate-bg hover:bg-gradient-primary"
                  }`}
                >
                  Logging in...
                </span>
              )}
            </div>
          </WhiteLayout>
        )
      )}
    </>
  );
};

export default ActivationScreen;
